import styled from "styled-components"
import src from "../../static/neutral-logo.svg"

const Logo = styled.img.attrs(props => ({
  src,
  alt: "modaresa",
  ...props,
}))`
  width: 100px;
`
export default Logo
