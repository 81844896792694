import {
  CREATE_STAFF_MEMBER,
  FETCH_STAFF,
  SELECT_STAFF_MEMBER,
  UNSELECT_STAFF_MEMBER,
  UPDATE_STAFF_MEMBER,
} from "../actions/staff"

import { createReducer } from "../..//helpers/redux"

const initialState = {
  staff: {},
  selectedMember: null,
  requestPending: false,
  requestError: null,
}

const handlers = {
  [FETCH_STAFF.SUCCESS]: (state, { payload }) => ({
    ...state,
    staff: payload,
  }),
  [SELECT_STAFF_MEMBER.SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedMember: state.staff.byId[payload.id],
  }),
  [UNSELECT_STAFF_MEMBER]: state => ({
    ...state,
    selectedMember: null,
  }),
  [CREATE_STAFF_MEMBER.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [CREATE_STAFF_MEMBER.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [CREATE_STAFF_MEMBER.SUCCESS]: (state, { payload }) => ({
    ...state,
    staff: {
      ...state.staff,
      byId: {
        ...state.staff.byId,
        [payload._id]: {
          ...payload,
        },
      },
      allIds: [payload._id, ...state.staff.allIds],
    },
    selectedMember: payload,
    requestPending: false,
  }),
  [UPDATE_STAFF_MEMBER.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [UPDATE_STAFF_MEMBER.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [UPDATE_STAFF_MEMBER.SUCCESS]: (state, { payload }) => ({
    ...state,
    staff: {
      ...state.staff,
      byId: {
        ...state.staff.byId,
        [payload._id]: {
          ...payload,
        },
      },
    },
    selectedSession: payload,
    requestPending: false,
  }),
}

export default createReducer(initialState, handlers)
