import {
  getRequestError,
  getRequestPending,
  getSharedRetailer,
} from "../../../../store/selectors"

import Component from "./CompanyProfile"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { updateRetailer } from "../../../../store/actions/retailers"

const selector = createStructuredSelector({
  retailer: getSharedRetailer,
  requestPending: getRequestPending,
  requestError: getRequestError,
})

const actions = {
  onUpdateRetailer: updateRetailer,
}

export const CompanyProfile = connect(selector, actions)(Component)
