import styled from "styled-components"
export const Container = styled.div`
  background-color: #ffcab1;
  color: #000;
  font-size: 14px;
  padding: 10px;
`
export const Header = styled.h4`
  margin-bottom: 20px;
`
export const Body = styled.div`
  & p {
    margin-bottom: 10px;
  }
`
