import React, { useCallback, useState } from "react"
import Modal from "../UI/Modal"
import { SelectContainer } from "./styles"

import { Button, Div, InputTags, Textarea } from "../UI"
import { Text, Body, ContainerButtons } from "./ModalConfirmationBooking.styles"
import moment from "moment"
import { ValidationError } from "../UI/Input"
import B from "../UI/B"

type Props = {
  onClose: Function,
  onConfirm: Function,
  brandName: string,
  timezone: string,
  bookings: [],
}

const ModalConfirmationBooking = ({ onConfirm, brandName, timezone, bookings, onClose }: Props) => {
  const [errors, setErrors] = useState({})
  const [messageToBrand, setMessageToBrand] = useState("")
  const [emails, setEmails] = useState([])

  const handleChangeTags = useCallback(tags => {
    if (tags.emails !== undefined) {
      setEmails(tags.emails)
    }
    if (tags.errors !== undefined) {
      setErrors({ ...tags.errors })
    }
  }, [])
  const handleChangeMessage = useCallback(e => setMessageToBrand(e.target.value), [])
  const handleClickConfirm = useCallback(() => onConfirm(emails, messageToBrand), [onConfirm, emails, messageToBrand])
  let date = false
  return (
    <Modal showHeader title="" isOpen onClose={onClose} width="600px">
      <Body>
        <div>
          You are about to book an appointment with <B>{brandName}</B> for
        </div>
        <Div.Flex justify="flex-start">
          <Text>
            {bookings &&
              bookings.length &&
              bookings.map((booking, index) => {
                const lastDate = date
                const newDate = moment.utc(booking[0].startTime, "X").format("dddd Do [of] MMMM")
                date = newDate
                return (
                  <div key={index}>
                    {newDate !== lastDate ? (
                      <p style={{ marginTop: 20 }}>
                        <B>{newDate}</B>
                      </p>
                    ) : (
                      false
                    )}
                    {booking.map((room, roomIndex) => {
                      const start = moment.utc(room.startTime, "X").format("HH:mm")
                      const end = moment.utc(room.endTime, "X").format("HH:mm")
                      return (
                        <p key={`${index}-${roomIndex}`}>
                          {`${room.room} ${start} - ${end}`}
                          <br />
                          <span>({`Timezone:  ${timezone}`})</span>
                        </p>
                      )
                    })}
                  </div>
                )
              })}
          </Text>
        </Div.Flex>
        <Text margin="20px 0 20px 0">
          Do you have any <B>other colleagues</B> at your company outside of this booking, that must be notified about
          this booking toot? CC them below.
        </Text>
        <SelectContainer flexRow margin="0 0px 20px 0px">
          <div style={{ width: "100%" }}>
            <InputTags
              errors={errors}
              newState={handleChangeTags}
              values={emails}
              placeholder="Enter additional colleagues email address"
            />
          </div>
          {errors.emails && <ValidationError>{errors.emails}</ValidationError>}
        </SelectContainer>
        <Textarea
          rows={3}
          name="messageToBrand"
          placeholder="Do you want to leave a comment to the brand with your booking? Enter it here"
          value={messageToBrand}
          onChange={handleChangeMessage}
        />
        <ContainerButtons>
          <Button bgcolor="#516bef" borderColor="#516bef" onClick={handleClickConfirm}>
            Confirm my booking
          </Button>
        </ContainerButtons>
      </Body>
    </Modal>
  )
}

export default ModalConfirmationBooking
