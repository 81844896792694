import { NavLink } from "react-router-dom"
import styled from "styled-components"
export const Container = styled.div`
  height: 100%;
  overflow: hidden;
  margin: 0px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 54px;
  min-width: 200px;
  border-right: 2px solid ${props => props.theme.gray75};
`
const activeClassName = "menuitem-active"
export const MenuItem = styled(NavLink).attrs({
  activeClassName,
})`
  align-items: center;
  text-decoration: none;
  padding: 15px;
  background-color: ${props => props.theme.gray75};
  padding-left: 20px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    padding-left: 14px;
    border-left: 6px solid ${props => props.theme.primary};
  }
  &.${activeClassName} {
    padding-left: 14px;
    border-left: 6px solid ${props => props.theme.primary};
  }
`
export const MenuItemText = styled.span`
  color: #333;
  margin: 0;
  padding: 0;
  ${MenuItem}:hover & {
    color: ${props => props.theme.primary};
    text-decoration: none;
  }
  ${MenuItem}.${activeClassName} & {
    color: ${props => props.theme.primary};
  }
`
export const SubMenu = styled.ul`
  margin: 0;
`

export const SubMenuItem = styled.li`
  display: flex;
`

export const SubMenuItemContent = styled(NavLink).attrs({
  activeClassName,
})`
  cursor: pointer;
  color: #333;
  text-decoration: none;
  align-items: center;
  padding: 10px;
  padding-left: 40px;
  &:hover {
    padding-left: 34px;
    border-left: 6px solid ${props => props.theme.primary};
    color: ${props => props.theme.primary};
    text-decoration: none;
  }
  &.${activeClassName} {
    padding-left: 34px;
    border-left: 6px solid ${props => props.theme.primary};
    color: ${props => props.theme.primary};
  }
`
