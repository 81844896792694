export const CountryCodes = [
  { name: "Afghanistan", value: "+93", code: "AF", flag: "🇦🇫" },
  { name: "Åland Islands", value: "+358", code: "AX", flag: "🇦🇽" },
  { name: "Albania", value: "+355", code: "AL", flag: "🇦🇱" },
  { name: "Algeria", value: "+213", code: "DZ", flag: "🇩🇿" },
  {
    name: "American Samoa",
    value: "+1684",
    code: "AS",
    flag: "🇺🇸",
  },
  { name: "Andorra", value: "+376", code: "AD", flag: "🇦🇩" },
  { name: "Angola", value: "+244", code: "AO", flag: "🇦🇴" },
  { name: "Anguilla", value: "+1264", code: "AI", flag: "🇦🇮" },
  { name: "Antarctica", value: "+672", code: "AQ", flag: "🇦🇶" },
  {
    name: "Antigua and Barbuda",
    value: "+1268",
    code: "AG",
    flag: "🇦🇬",
  },
  { name: "Argentina", value: "+54", code: "AR", flag: "🇦🇷" },
  { name: "Armenia", value: "+374", code: "AM", flag: "🇦🇲" },
  { name: "Aruba", value: "+297", code: "AW", flag: "🇦🇼" },
  { name: "Australia", value: "+61", code: "AU", flag: "🇦🇺" },
  { name: "Austria", value: "+43", code: "AT", flag: "🇦🇹" },
  { name: "Azerbaijan", value: "+994", code: "AZ", flag: "🇦🇿" },
  { name: "Bahamas", value: "+1242", code: "BS", flag: "🇧🇸" },
  { name: "Bahrain", value: "+973", code: "BH", flag: "🇧🇸" },
  { name: "Bangladesh", value: "+880", code: "BD", flag: "🇧🇩" },
  { name: "Barbados", value: "+1246", code: "BB", flag: "🇧🇧" },
  { name: "Belarus", value: "+375", code: "BY", flag: "🇧🇾" },
  { name: "Belgium", value: "+32", code: "BE", flag: "🇧🇪" },
  { name: "Belize", value: "+501", code: "BZ", flag: "🇧🇿" },
  { name: "Benin", value: "+229", code: "BJ", flag: "🇧🇯" },
  { name: "Bermuda", value: "+1441", code: "BM", flag: "🇧🇲" },
  { name: "Bhutan", value: "+975", code: "BT", flag: "🇧🇹" },
  {
    name: "Bolivia, Plurinational State of bolivia",
    value: "+591",
    code: "BO",
    flag: "🇧🇴",
  },
  {
    name: "Bosnia and Herzegovina",
    value: "+387",
    code: "BA",
    flag: "🇧🇦",
  },
  { name: "Botswana", value: "+267", code: "BW", flag: "🇧🇼" },
  { name: "Bouvet Island", value: "+47", code: "BV", flag: "🏳" },
  { name: "Brazil", value: "+55", code: "BR", flag: "🇧🇷" },
  {
    name: "British Indian Ocean Territory",
    value: "+246",
    code: "IO",
    flag: "🇮🇴",
  },
  {
    name: "Brunei Darussalam",
    value: "+673",
    code: "BN",
    flag: "🇧🇳",
  },
  { name: "Bulgaria", value: "+359", code: "BG", flag: "🇧🇬" },
  { name: "Burkina Faso", value: "+226", code: "BF", flag: "🇧🇫" },
  { name: "Burundi", value: "+257", code: "BI", flag: "🇧🇮" },
  { name: "Cambodia", value: "+855", code: "KH", flag: "🇰🇭" },
  { name: "Cameroon", value: "+237", code: "CM", flag: "🇨🇲" },
  { name: "Canada", value: "+1", code: "CA", flag: "🇨🇦" },
  { name: "Cape Verde", value: "+238", code: "CV", flag: "🇨🇻" },
  {
    name: "Cayman Islands",
    value: "+ 345",
    code: "KY",
    flag: "🇰🇾",
  },
  {
    name: "Central African Republic",
    value: "+236",
    code: "CF",
    flag: "🇨🇫",
  },
  { name: "Chad", value: "+235", code: "TD", flag: "🇹🇩" },
  { name: "Chile", value: "+56", code: "CL", flag: "🇨🇱" },
  { name: "China", value: "+86", code: "CN", flag: "🇨🇳" },
  {
    name: "Christmas Island",
    value: "+61",
    code: "CX",
    flag: "🇨🇽",
  },
  {
    name: "Cocos (Keeling) Islands",
    value: "+61",
    code: "CC",
    flag: "🇨🇨",
  },
  { name: "Colombia", value: "+57", code: "CO", flag: "🇨🇴" },
  { name: "Comoros", value: "+269", code: "KM", flag: "🇰🇲" },
  { name: "Congo", value: "+242", code: "CG", flag: "🇨🇬" },
  {
    name: "Congo, The Democratic Republic of the Congo",
    value: "+243",
    code: "CD",
    flag: "🇨🇩",
  },
  { name: "Cook Islands", value: "+682", code: "CK", flag: "🇨🇰" },
  { name: "Costa Rica", value: "+506", code: "CR", flag: "🇨🇷" },
  { name: "Cote d'Ivoire", value: "+225", code: "CI", flag: "🇨🇮" },
  { name: "Croatia", value: "+385", code: "HR", flag: "🇭🇷" },
  { name: "Cuba", value: "+53", code: "CU", flag: "🇨🇺" },
  { name: "Cyprus", value: "+357", code: "CY", flag: "🇨🇾" },
  { name: "Czech Republic", value: "+420", code: "CZ", flag: "🇨🇿" },
  { name: "Denmark", value: "+45", code: "DK", flag: "🇩🇰" },
  { name: "Djibouti", value: "+253", code: "DJ", flag: "🇩🇯" },
  { name: "Dominica", value: "+1767", code: "DM", flag: "🇩🇲" },
  {
    name: "Dominican Republic",
    value: "+1849",
    code: "DO",
    flag: "🇨🇩",
  },
  { name: "Ecuador", value: "+593", code: "EC", flag: "🇪🇨" },
  { name: "Egypt", value: "+20", code: "EG", flag: "🇪🇬" },
  { name: "El Salvador", value: "+503", code: "SV", flag: "🇸🇻" },
  {
    name: "Equatorial Guinea",
    value: "+240",
    code: "GQ",
    flag: "🇬🇶",
  },
  { name: "Eritrea", value: "+291", code: "ER", flag: "🇪🇷" },
  { name: "Estonia", value: "+372", code: "EE", flag: "🇪🇪" },
  { name: "Ethiopia", value: "+251", code: "ET", flag: "🇪🇹" },
  {
    name: "Falkland Islands (Malvinas)",
    value: "+500",
    code: "FK",
    flag: "🇫🇰",
  },
  { name: "Faroe Islands", value: "+298", code: "FO", flag: "🇫🇴" },
  { name: "Fiji", value: "+679", code: "FJ", flag: "🇫🇯" },
  { name: "Finland", value: "+358", code: "FI", flag: "🇫🇮" },
  { name: "France", value: "+33", code: "FR", flag: "🇫🇷" },
  { name: "French Guiana", value: "+594", code: "GF", flag: "🇬🇫" },
  {
    name: "French Polynesia",
    value: "+689",
    code: "PF",
    flag: "🇵🇫",
  },
  {
    name: "French Southern Territories",
    value: "+262",
    code: "TF",
    flag: "🇹🇫",
  },
  { name: "Gabon", value: "+241", code: "GA", flag: "🇬🇦" },
  { name: "Gambia", value: "+220", code: "GM", flag: "🇬🇲" },
  { name: "Georgia", value: "+995", code: "GE", flag: "🇬🇪" },
  { name: "Germany", value: "+49", code: "DE", flag: "🇩🇪" },
  { name: "Ghana", value: "+233", code: "GH", flag: "🇬🇭" },
  { name: "Gibraltar", value: "+350", code: "GI", flag: "🇬🇮" },
  { name: "Greece", value: "+30", code: "GR", flag: "🇬🇷" },
  { name: "Greenland", value: "+299", code: "GL", flag: "🇬🇱" },
  { name: "Grenada", value: "+1473", code: "GD", flag: "🇬🇩" },
  { name: "Guadeloupe", value: "+590", code: "GP", flag: "🇬🇵" },
  { name: "Guam", value: "+1671", code: "GU", flag: "🇬🇺" },
  { name: "Guatemala", value: "+502", code: "GT", flag: "🇬🇹" },
  { name: "Guernsey", value: "+44", code: "GG", flag: "🇬🇬" },
  { name: "Guinea", value: "+224", code: "GN", flag: "🇬🇳" },
  { name: "Guinea-Bissau", value: "+245", code: "GW", flag: "🇬🇼" },
  { name: "Guyana", value: "+592", code: "GY", flag: "🇬🇾" },
  { name: "Haiti", value: "+509", code: "HT", flag: "🇭🇹" },
  {
    name: "Heard Island and Mcdonald Islands",
    value: "+0",
    code: "HM",
    flag: "🏳",
  },
  {
    name: "Holy See (Vatican City State)",
    value: "+379",
    code: "VA",
    flag: "🇻🇦",
  },
  { name: "Honduras", value: "+504", code: "HN", flag: "🇭🇳" },
  { name: "Hong Kong", value: "+852", code: "HK", flag: "🇭🇰" },
  { name: "Hungary", value: "+36", code: "HU", flag: "🇭🇺" },
  { name: "Iceland", value: "+354", code: "IS", flag: "🇮🇸" },
  { name: "India", value: "+91", code: "IN", flag: "🇮🇳" },
  { name: "Indonesia", value: "+62", code: "ID", flag: "🇮🇩" },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    value: "+98",
    code: "IR",
    flag: "🇮🇷",
  },
  { name: "Iraq", value: "+964", code: "IQ", flag: "🇮🇶" },
  { name: "Ireland", value: "+353", code: "IE", flag: "🇮🇪" },
  { name: "Isle of Man", value: "+44", code: "IM", flag: "🇮🇲" },
  { name: "Israel", value: "+972", code: "IL", flag: "🇮🇱" },
  { name: "Italy", value: "+39", code: "IT", flag: "🇮🇹" },
  { name: "Jamaica", value: "+1876", code: "JM", flag: "🇯🇲" },
  { name: "Japan", value: "+81", code: "JP", flag: "🇯🇵" },
  { name: "Jersey", value: "+44", code: "JE", flag: "🇯🇪" },
  { name: "Jordan", value: "+962", code: "JO", flag: "🇯🇴" },
  { name: "Kazakhstan", value: "+7", code: "KZ", flag: "🇰🇿" },
  { name: "Kenya", value: "+254", code: "KE", flag: "🇰🇪" },
  { name: "Kiribati", value: "+686", code: "KI", flag: "🇰🇮" },
  {
    name: "Korea, Democratic People's Republic of Korea",
    value: "+850",
    code: "KP",
    flag: "🇰🇵",
  },
  {
    name: "Korea, Republic of South Korea",
    value: "+82",
    code: "KR",
    flag: "🇰🇷",
  },
  { name: "Kosovo", value: "+383", code: "XK", flag: "🇽🇰" },
  { name: "Kuwait", value: "+965", code: "KW", flag: "🇰🇼" },
  { name: "Kyrgyzstan", value: "+996", code: "KG", flag: "🇰🇬" },
  { name: "Laos", value: "+856", code: "LA", flag: "🇱🇦" },
  { name: "Latvia", value: "+371", code: "LV", flag: "🇱🇻" },
  { name: "Lebanon", value: "+961", code: "LB", flag: "🇱🇧" },
  { name: "Lesotho", value: "+266", code: "LS", flag: "🇱🇸" },
  { name: "Liberia", value: "+231", code: "LR", flag: "🇱🇷" },
  {
    name: "Libyan Arab Jamahiriya",
    value: "+218",
    code: "LY",
    flag: "🇱🇾",
  },
  { name: "Liechtenstein", value: "+423", code: "LI", flag: "🇱🇮" },
  { name: "Lithuania", value: "+370", code: "LT", flag: "🇱🇹" },
  { name: "Luxembourg", value: "+352", code: "LU", flag: "🇱🇺" },
  { name: "Macao", value: "+853", code: "MO", flag: "🇲🇴" },
  { name: "Macedonia", value: "+389", code: "MK", flag: "🇲🇰" },
  { name: "Madagascar", value: "+261", code: "MG", flag: "🇲🇬" },
  { name: "Malawi", value: "+265", code: "MW", flag: "🇲🇼" },
  { name: "Malaysia", value: "+60", code: "MY", flag: "🇲🇾" },
  { name: "Maldives", value: "+960", code: "MV", flag: "🇲🇻" },
  { name: "Mali", value: "+223", code: "ML", flag: "🇲🇱" },
  { name: "Malta", value: "+356", code: "MT", flag: "🇲🇹" },
  {
    name: "Marshall Islands",
    value: "+692",
    code: "MH",
    flag: "🇲🇭",
  },
  { name: "Martinique", value: "+596", code: "MQ", flag: "🇲🇶" },
  { name: "Mauritania", value: "+222", code: "MR", flag: "🇲🇷" },
  { name: "Mauritius", value: "+230", code: "MU", flag: "🇲🇺" },
  { name: "Mayotte", value: "+262", code: "YT", flag: "🇾🇹" },
  { name: "Mexico", value: "+52", code: "MX", flag: "🇲🇽" },
  {
    name: "Micronesia, Federated States of Micronesia",
    value: "+691",
    code: "FM",
    flag: "🇫🇲",
  },
  { name: "Moldova", value: "+373", code: "MD", flag: "🇲🇩" },
  { name: "Monaco", value: "+377", code: "MC", flag: "🇲🇨" },
  { name: "Mongolia", value: "+976", code: "MN", flag: "🇲🇳" },
  { name: "Montenegro", value: "+382", code: "ME", flag: "🇲🇪" },
  { name: "Montserrat", value: "+1664", code: "MS", flag: "🇲🇸" },
  { name: "Morocco", value: "+212", code: "MA", flag: "🇲🇦" },
  { name: "Mozambique", value: "+258", code: "MZ", flag: "🇲🇿" },
  { name: "Myanmar", value: "+95", code: "MM", flag: "🇲🇲" },
  { name: "Namibia", value: "+264", code: "NA", flag: "🇳🇦" },
  { name: "Nauru", value: "+674", code: "NR", flag: "🇳🇷" },
  { name: "Nepal", value: "+977", code: "NP", flag: "🇳🇵" },
  { name: "Netherlands", value: "+31", code: "NL", flag: "🇳🇱" },
  {
    name: "Netherlands Antilles",
    value: "+599",
    code: "AN",
    flag: "🇳🇱",
  },
  { name: "New Caledonia", value: "+687", code: "NC", flag: "🇳🇨" },
  { name: "New Zealand", value: "+64", code: "NZ", flag: "🇳🇿" },
  { name: "Nicaragua", value: "+505", code: "NI", flag: "🇳🇮" },
  { name: "Niger", value: "+227", code: "NE", flag: "🇳🇪" },
  { name: "Nigeria", value: "+234", code: "NG", flag: "🇳🇬" },
  { name: "Niue", value: "+683", code: "NU", flag: "🇳🇺" },
  { name: "Norfolk Island", value: "+672", code: "NF", flag: "🇳🇫" },
  {
    name: "Northern Mariana Islands",
    value: "+1670",
    code: "MP",
    flag: "🏳",
  },
  { name: "Norway", value: "+47", code: "NO", flag: "🇳🇴" },
  { name: "Oman", value: "+968", code: "OM", flag: "🇴🇲" },
  { name: "Pakistan", value: "+92", code: "PK", flag: "🇵🇰" },
  { name: "Palau", value: "+680", code: "PW", flag: "🇵🇼" },
  {
    name: "Palestinian Territory, Occupied",
    value: "+970",
    code: "PS",
    flag: "🇵🇸",
  },
  { name: "Panama", value: "+507", code: "PA", flag: "🇵🇦" },
  {
    name: "Papua New Guinea",
    value: "+675",
    code: "PG",
    flag: "🇵🇬",
  },
  { name: "Paraguay", value: "+595", code: "PY", flag: "🇵🇾" },
  { name: "Peru", value: "+51", code: "PE", flag: "🇵🇪" },
  { name: "Philippines", value: "+63", code: "PH", flag: "🇵🇭" },
  { name: "Pitcairn", value: "+64", code: "PN", flag: "🇵🇳" },
  { name: "Poland", value: "+48", code: "PL", flag: "🇵🇱" },
  { name: "Portugal", value: "+351", code: "PT", flag: "🇵🇹" },
  { name: "Puerto Rico", value: "+1939", code: "PR", flag: "🇵🇷" },
  { name: "Qatar", value: "+974", code: "QA", flag: "🇶🇦" },
  { name: "Romania", value: "+40", code: "RO", flag: "🇷🇴" },
  { name: "Russia", value: "+7", code: "RU", flag: "🇷🇺" },
  { name: "Rwanda", value: "+250", code: "RW", flag: "🇷🇼" },
  { name: "Reunion", value: "+262", code: "RE", flag: "🇫🇷" },
  {
    name: "Saint Barthelemy",
    value: "+590",
    code: "BL",
    flag: "🇧🇱",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    value: "+290",
    code: "SH",
    flag: "🇸🇭",
  },
  {
    name: "Saint Kitts and Nevis",
    value: "+1869",
    code: "KN",
    flag: "🇰🇳",
  },
  { name: "Saint Lucia", value: "+1758", code: "LC", flag: "🇱🇨" },
  { name: "Saint Martin", value: "+590", code: "MF", flag: "🏳" },
  {
    name: "Saint Pierre and Miquelon",
    value: "+508",
    code: "PM",
    flag: "🇵🇲",
  },
  {
    name: "Saint Vincent and the Grenadines",
    value: "+1784",
    code: "VC",
    flag: "🇻🇨",
  },
  { name: "Samoa", value: "+685", code: "WS", flag: "🇼🇸" },
  { name: "San Marino", value: "+378", code: "SM", flag: "🇸🇲" },
  {
    name: "Sao Tome and Principe",
    value: "+239",
    code: "ST",
    flag: "🇸🇹",
  },
  { name: "Saudi Arabia", value: "+966", code: "SA", flag: "🇸🇦" },
  { name: "Senegal", value: "+221", code: "SN", flag: "🇸🇳" },
  { name: "Serbia", value: "+381", code: "RS", flag: "🇷🇸" },
  { name: "Seychelles", value: "+248", code: "SC", flag: "🇸🇨" },
  { name: "Sierra Leone", value: "+232", code: "SL", flag: "🇸🇱" },
  { name: "Singapore", value: "+65", code: "SG", flag: "🇸🇬" },
  { name: "Slovakia", value: "+421", code: "SK", flag: "🇸🇰" },
  { name: "Slovenia", value: "+386", code: "SI", flag: "🇸🇮" },
  {
    name: "Solomon Islands",
    value: "+677",
    code: "SB",
    flag: "🇸🇧",
  },
  { name: "Somalia", value: "+252", code: "SO", flag: "🇸🇴" },
  { name: "South Africa", value: "+27", code: "ZA", flag: "🇿🇦" },
  { name: "South Sudan", value: "+211", code: "SS", flag: "🇸🇸" },
  {
    name: "South Georgia and the South Sandwich Islands",
    value: "+500",
    code: "GS",
    flag: "🇬🇸",
  },
  { name: "Spain", value: "+34", code: "ES", flag: "🇪🇸" },
  { name: "Sri Lanka", value: "+94", code: "LK", flag: "🇱🇰" },
  { name: "Sudan", value: "+249", code: "SD", flag: "🇸🇩" },
  { name: "Suriname", value: "+597", code: "SR", flag: "🇸🇷" },
  {
    name: "Svalbard and Jan Mayen",
    value: "+47",
    code: "SJ",
    flag: "🇩🇰",
  },
  { name: "Swaziland", value: "+268", code: "SZ", flag: "🇸🇿" },
  { name: "Sweden", value: "+46", code: "SE", flag: "🇸🇪" },
  { name: "Switzerland", value: "+41", code: "CH", flag: "🇨🇭" },
  {
    name: "Syrian Arab Republic",
    value: "+963",
    code: "SY",
    flag: "🇸🇾",
  },
  { name: "Taiwan", value: "+886", code: "TW", flag: "🇹🇼" },
  { name: "Tajikistan", value: "+992", code: "TJ", flag: "🇹🇯" },
  {
    name: "Tanzania, United Republic of Tanzania",
    value: "+255",
    code: "TZ",
    flag: "🇹🇿",
  },
  { name: "Thailand", value: "+66", code: "TH", flag: "🇹🇭" },
  { name: "Timor-Leste", value: "+670", code: "TL", flag: "🇹🇱" },
  { name: "Togo", value: "+228", code: "TG", flag: "🇹🇬" },
  { name: "Tokelau", value: "+690", code: "TK", flag: "🇹🇰" },
  { name: "Tonga", value: "+676", code: "TO", flag: "🇹🇴" },
  {
    name: "Trinidad and Tobago",
    value: "+1868",
    code: "TT",
    flag: "🇹🇹",
  },
  { name: "Tunisia", value: "+216", code: "TN", flag: "🇹🇳" },
  { name: "Turkey", value: "+90", code: "TR", flag: "🇹🇷" },
  { name: "Turkmenistan", value: "+993", code: "TM", flag: "🇹🇲" },
  {
    name: "Turks and Caicos Islands",
    value: "+1649",
    code: "TC",
    flag: "🇹🇨",
  },
  { name: "Tuvalu", value: "+688", code: "TV", flag: "🇹🇻" },
  { name: "Uganda", value: "+256", code: "UG", flag: "🇺🇬" },
  { name: "Ukraine", value: "+380", code: "UA", flag: "🇺🇦" },
  {
    name: "United Arab Emirates",
    value: "+971",
    code: "AE",
    flag: "🇦🇪",
  },
  { name: "United Kingdom", value: "+44", code: "GB", flag: "🇬🇧" },
  { name: "United States", value: "+1", code: "US", flag: "🇺🇸" },
  { name: "Uruguay", value: "+598", code: "UY", flag: "🇺🇾" },
  { name: "Uzbekistan", value: "+998", code: "UZ", flag: "🇺🇿" },
  { name: "Vanuatu", value: "+678", code: "VU", flag: "🇻🇺" },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    value: "+58",
    code: "VE",
    flag: "🇻🇪",
  },
  { name: "Vietnam", value: "+84", code: "VN", flag: "🇻🇳" },
  {
    name: "Virgin Islands, British",
    value: "+1284",
    code: "VG",
    flag: "🇻🇬",
  },
  {
    name: "Virgin Islands, U.S.",
    value: "+1340",
    code: "VI",
    flag: "🇻🇮",
  },
  {
    name: "Wallis and Futuna",
    value: "+681",
    code: "WF",
    flag: "🇼🇫",
  },
  { name: "Yemen", value: "+967", code: "YE", flag: "🇾🇪" },
  { name: "Zambia", value: "+260", code: "ZM", flag: "🇿🇲" },
  { name: "Zimbabwe", value: "+263", code: "ZW", flag: "🇿🇼" },
]

export const OptionCounryCode = CountryCodes.map(item => ({
  value: item.value,
  label: item.value,
  flag: item.flag,
}))

export const countries = {
  af: { name: "Afghanistan ", isoCode: "af", countryCode: "+93" },
  al: { name: "Albania ", isoCode: "al", countryCode: "+355" },
  dz: { name: "Algeria ", isoCode: "dz", countryCode: "+213" },
  as: { name: "American Samoa", isoCode: "as", countryCode: "+1-684" },
  ad: { name: "Andorra", isoCode: "ad", countryCode: "+376" },
  ao: { name: "Angola", isoCode: "ao", countryCode: "+244" },
  ai: { name: "Anguilla ", isoCode: "ai", countryCode: "+1-264" },
  aq: { name: "Antarctica", isoCode: "aq", countryCode: "+672" },
  ag: { name: "Antigua and Barbuda", isoCode: "ag", countryCode: "+1-268" },
  ar: { name: "Argentina ", isoCode: "ar", countryCode: "+54" },
  am: { name: "Armenia", isoCode: "am", countryCode: "+374" },
  aw: { name: "Aruba", isoCode: "aw", countryCode: "+297" },
  au: { name: "Australia", isoCode: "au", countryCode: "+61" },
  at: { name: "Austria", isoCode: "at", countryCode: "+43" },
  az: { name: "Azerbaijan", isoCode: "az", countryCode: "+994" },
  bs: { name: "Bahamas", isoCode: "bs", countryCode: "+1-242" },
  bh: { name: "Bahrain", isoCode: "bh", countryCode: "+973" },
  bd: { name: "Bangladesh ", isoCode: "bd", countryCode: "+880" },
  bb: { name: "Barbados ", isoCode: "bb", countryCode: "+1-246" },
  by: { name: "Belarus", isoCode: "by", countryCode: "+375" },
  be: { name: "Belgium ", isoCode: "be", countryCode: "+32" },
  bz: { name: "Belize ", isoCode: "bz", countryCode: "+501" },
  bj: { name: "Benin ", isoCode: "bj", countryCode: "+229" },
  bm: { name: "Bermuda ", isoCode: "bm", countryCode: "+1-441" },
  bt: { name: "Bhutan", isoCode: "bt", countryCode: "+975" },
  bo: { name: "Bolivia ", isoCode: "bo", countryCode: "+591" },
  ba: { name: "Bosnia and Herzegovina ", isoCode: "ba", countryCode: "+387" },
  bw: { name: "Botswana ", isoCode: "bw", countryCode: "+267" },
  br: { name: "Brazil ", isoCode: "br", countryCode: "+55" },
  bn: { name: "Brunei ", isoCode: "bn", countryCode: "+673" },
  bg: { name: "Bulgaria ", isoCode: "bg", countryCode: "+359" },
  bf: { name: "Burkina Faso", isoCode: "bf", countryCode: "+226" },
  bi: { name: "Burundi ", isoCode: "bi", countryCode: "+257" },
  kh: { name: "Cambodia", isoCode: "kh", countryCode: "+855" },
  cm: { name: "Cameroon ", isoCode: "cm", countryCode: "+237" },
  ca: { name: "Canada ", isoCode: "ca", countryCode: "+1" },
  cv: { name: "Cape Verde ", isoCode: "cv", countryCode: "+238" },
  ky: { name: "Cayman Islands ", isoCode: "ky", countryCode: "+1-345" },
  cf: { name: "Central African Republic ", isoCode: "cf", countryCode: "+236" },
  td: { name: "Chad ", isoCode: "td", countryCode: "+235" },
  cl: { name: "Chile ", isoCode: "cl", countryCode: "+56" },
  cn: { name: "China ", isoCode: "cn", countryCode: "+86" },
  cx: { name: "Christmas Island ", isoCode: "cx", countryCode: "+53" },
  cc: { name: "Cocos (Keeling) Islands ", isoCode: "cc", countryCode: "+61" },
  co: { name: "Colombia ", isoCode: "co", countryCode: "+57" },
  km: { name: "Comoros", isoCode: "km", countryCode: "+269" },
  cd: { name: "Congo", isoCode: "cd", countryCode: "+243" },
  cg: { name: "Congo", isoCode: "cg", countryCode: "+242" },
  ck: { name: "Cook Islands", isoCode: "ck", countryCode: "+682" },
  cr: { name: "Costa Rica ", isoCode: "cr", countryCode: "+506" },
  ci: { name: "Cote DIvoire(Ivory Coast)", isoCode: "ci", countryCode: "+225" },
  hr: { name: "Croatia", isoCode: "hr", countryCode: "+385" },
  cu: { name: "Cuba ", isoCode: "cu", countryCode: "+53" },
  cy: { name: "Cyprus ", isoCode: "cy", countryCode: "+357" },
  cz: { name: "Czech Republic", isoCode: "cz", countryCode: "+420" },
  dk: { name: "Denmark ", isoCode: "dk", countryCode: "+45" },
  dj: { name: "Djibouti ", isoCode: "dj", countryCode: "+253" },
  dm: { name: "Dominica ", isoCode: "dm", countryCode: "+1-767" },
  do: { name: "Dominican Republic ", isoCode: "do", countryCode: "+1-809  " },
  tp: { name: "East Timor ", isoCode: "tp", countryCode: "+670" },
  ec: { name: "Ecuador ", isoCode: "ec", countryCode: "+593 " },
  eg: { name: "Egypt ", isoCode: "eg", countryCode: "+20" },
  sv: { name: "El Salvador ", isoCode: "sv", countryCode: "+503" },
  gq: { name: "Equatorial Guinea ", isoCode: "gq", countryCode: "+240" },
  er: { name: "Eritrea ", isoCode: "er", countryCode: "+291" },
  ee: { name: "Estonia", isoCode: "ee", countryCode: "+372" },
  et: { name: "Ethiopia", isoCode: "et", countryCode: "+251" },
  fk: { name: "Falkland Islands ", isoCode: "fk", countryCode: "+500" },
  fo: { name: "Faroe Islands ", isoCode: "fo", countryCode: "+298" },
  fj: { name: "Fiji ", isoCode: "fj", countryCode: "+679" },
  fi: { name: "Finland ", isoCode: "fi", countryCode: "+358" },
  fr: { name: "France ", isoCode: "fr", countryCode: "+33" },
  gf: { name: "French Guiana", isoCode: "gf", countryCode: "+594" },
  pf: { name: "French Polynesia ", isoCode: "pf", countryCode: "+689" },
  ga: { name: "Gabon ", isoCode: "ga", countryCode: "+241" },
  gm: { name: "Gambia", isoCode: "gm", countryCode: "+220" },
  ge: { name: "Georgia", isoCode: "ge", countryCode: "+995" },
  de: { name: "Germany ", isoCode: "de", countryCode: "+49" },
  gh: { name: "Ghana", isoCode: "gh", countryCode: "+233" },
  gi: { name: "Gibraltar ", isoCode: "gi", countryCode: "+350" },
  gr: { name: "Greece ", isoCode: "gr", countryCode: "+30" },
  gl: { name: "Greenland ", isoCode: "gl", countryCode: "+299" },
  gd: { name: "Grenada ", isoCode: "gd", countryCode: "+1-473" },
  gp: { name: "Guadeloupe", isoCode: "gp", countryCode: "+590" },
  gu: { name: "Guam", isoCode: "gu", countryCode: "+1-671" },
  gt: { name: "Guatemala ", isoCode: "gt", countryCode: "+502" },
  gn: { name: "Guinea ", isoCode: "gn", countryCode: "+224" },
  gw: { name: "Guinea-Bissau ", isoCode: "gw", countryCode: "+245" },
  gy: { name: "Guyana ", isoCode: "gy", countryCode: "+592" },
  ht: { name: "Haiti ", isoCode: "ht", countryCode: "+509" },
  hn: { name: "Honduras ", isoCode: "hn", countryCode: "+504" },
  hk: { name: "Hong Kong ", isoCode: "hk", countryCode: "+852" },
  hu: { name: "Hungary ", isoCode: "hu", countryCode: "+36" },
  is: { name: "Iceland ", isoCode: "is", countryCode: "+354" },
  in: { name: "India ", isoCode: "in", countryCode: "+91" },
  id: { name: "Indonesia ", isoCode: "id", countryCode: "+62" },
  ir: { name: "Iran", isoCode: "ir", countryCode: "+98" },
  iq: { name: "Iraq ", isoCode: "iq", countryCode: "+964" },
  ie: { name: "Ireland ", isoCode: "ie", countryCode: "+353" },
  il: { name: "Israel ", isoCode: "il", countryCode: "+972" },
  it: { name: "Italy ", isoCode: "it", countryCode: "+39" },
  jm: { name: "Jamaica ", isoCode: "jm", countryCode: "+1-876" },
  jp: { name: "Japan ", isoCode: "jp", countryCode: "+81" },
  jo: { name: "Jordan ", isoCode: "jo", countryCode: "+962" },
  kz: { name: "Kazakstan", isoCode: "kz", countryCode: "+7" },
  ke: { name: "Kenya", isoCode: "ke", countryCode: "+254" },
  ki: { name: "Kiribati", isoCode: "ki", countryCode: "+686" },
  kp: { name: "North Korea", isoCode: "kp", countryCode: "+850" },
  kr: { name: "South Korea", isoCode: "kr", countryCode: "+82" },
  kw: { name: "Kuwait ", isoCode: "kw", countryCode: "+965" },
  kg: { name: "Kyrgyzstan ", isoCode: "kg", countryCode: "+996" },
  la: { name: "Laos", isoCode: "la", countryCode: "+856" },
  lv: { name: "Latvia", isoCode: "lv", countryCode: "+371" },
  lb: { name: "Lebanon ", isoCode: "lb", countryCode: "+961" },
  ls: { name: "Lesotho", isoCode: "ls", countryCode: "+266" },
  lr: { name: "Liberia ", isoCode: "lr", countryCode: "+231" },
  ly: { name: "Libya ", isoCode: "ly", countryCode: "+218" },
  li: { name: "Liechtenstein ", isoCode: "li", countryCode: "+423" },
  lt: { name: "Lithuania ", isoCode: "lt", countryCode: "+370" },
  lu: { name: "Luxembourg ", isoCode: "lu", countryCode: "+352" },
  mo: { name: "Macau ", isoCode: "mo", countryCode: "+853" },
  mk: { name: "Macedonia", isoCode: "mk", countryCode: "+389" },
  mg: { name: "Madagascar", isoCode: "mg", countryCode: "+261" },
  mw: { name: "Malawi ", isoCode: "mw", countryCode: "+265" },
  my: { name: "Malaysia ", isoCode: "my", countryCode: "+60" },
  mv: { name: "Maldives ", isoCode: "mv", countryCode: "+960" },
  ml: { name: "Mali ", isoCode: "ml", countryCode: "+223" },
  mt: { name: "Malta ", isoCode: "mt", countryCode: "+356" },
  mh: { name: "Marshall Islands", isoCode: "mh", countryCode: "+692" },
  mq: { name: "Martinique", isoCode: "mq", countryCode: "+596" },
  mr: { name: "Mauritania ", isoCode: "mr", countryCode: "+222" },
  mu: { name: "Mauritius ", isoCode: "mu", countryCode: "+230" },
  yt: { name: "Mayotte ", isoCode: "yt", countryCode: "+269" },
  mx: { name: "Mexico ", isoCode: "mx", countryCode: "+52" },
  fm: { name: "Micronesia", isoCode: "fm", countryCode: "+691" },
  md: { name: "Moldova", isoCode: "md", countryCode: "+373" },
  mc: { name: "Monaco", isoCode: "mc", countryCode: "+377" },
  me: { name: "Montenegro", isoCode: "me", countryCode: "+382" },
  mn: { name: "Mongolia ", isoCode: "mn", countryCode: "+976" },
  ms: { name: "Montserrat ", isoCode: "ms", countryCode: "+1-664" },
  ma: { name: "Morocco ", isoCode: "ma", countryCode: "+212" },
  mz: { name: "Mozambique", isoCode: "mz", countryCode: "+258" },
  mm: { name: "Myanmar", isoCode: "mm", countryCode: "+95" },
  na: { name: "Namibia ", isoCode: "na", countryCode: "+264" },
  nr: { name: "Nauru ", isoCode: "nr", countryCode: "+674" },
  np: { name: "Nepal ", isoCode: "np", countryCode: "+977" },
  nl: { name: "Netherlands ", isoCode: "nl", countryCode: "+31" },
  an: { name: "Netherlands Antilles ", isoCode: "an", countryCode: "+599" },
  nc: { name: "New Caledonia ", isoCode: "nc", countryCode: "+687" },
  nz: { name: "New Zealand ", isoCode: "nz", countryCode: "+64" },
  ni: { name: "Nicaragua ", isoCode: "ni", countryCode: "+505" },
  ne: { name: "Niger ", isoCode: "ne", countryCode: "+227" },
  ng: { name: "Nigeria ", isoCode: "ng", countryCode: "+234" },
  nu: { name: "Niue ", isoCode: "nu", countryCode: "+683" },
  nf: { name: "Norfolk Island ", isoCode: "nf", countryCode: "+672" },
  mp: {
    name: "Northern Mariana Islands ",
    isoCode: "mp",
    countryCode: "+1-670",
  },
  no: { name: "Norway ", isoCode: "no", countryCode: "+47" },
  om: { name: "Oman", isoCode: "om", countryCode: "+968" },
  pk: { name: "Pakistan ", isoCode: "pk", countryCode: "+92" },
  pw: { name: "Palau ", isoCode: "pw", countryCode: "+680" },
  ps: { name: "Palestinian State", isoCode: "ps", countryCode: "+970" },
  pa: { name: "Panama ", isoCode: "pa", countryCode: "+507" },
  pg: { name: "Papua New Guinea ", isoCode: "pg", countryCode: "+675" },
  py: { name: "Paraguay ", isoCode: "py", countryCode: "+595" },
  pe: { name: "Peru ", isoCode: "pe", countryCode: "+51" },
  ph: { name: "Philippines ", isoCode: "ph", countryCode: "+63" },
  pl: { name: "Poland ", isoCode: "pl", countryCode: "+48" },
  pt: { name: "Portugal ", isoCode: "pt", countryCode: "+351" },
  pr: { name: "Puerto Rico ", isoCode: "pr", countryCode: "+1-787" },
  qa: { name: "Qatar", isoCode: "qa", countryCode: "+974 " },
  re: { name: "Reunion ", isoCode: "re", countryCode: "+262" },
  ro: { name: "Romania ", isoCode: "ro", countryCode: "+40" },
  rs: { name: "Serbia ", isoCode: "rs", countryCode: "+381" },
  ru: { name: "Russian Federation ", isoCode: "ru", countryCode: "+7" },
  rw: { name: "Rwanda ", isoCode: "rw", countryCode: "+250" },
  sh: { name: "St Helena ", isoCode: "sh", countryCode: "+290" },
  kn: { name: "St Kitts and Nevis", isoCode: "kn", countryCode: "+1-869" },
  lc: { name: "St Lucia ", isoCode: "lc", countryCode: "+1-758" },
  pm: { name: "St Pierre and Miquelon ", isoCode: "pm", countryCode: "+508" },
  vc: { name: "St Vincent, Grenadines ", isoCode: "vc", countryCode: "+1-784" },
  ws: { name: "Samoa ", isoCode: "ws", countryCode: "+685" },
  sm: { name: "San Marino ", isoCode: "sm", countryCode: "+378" },
  st: { name: "Sao Tome and Principe ", isoCode: "st", countryCode: "+239" },
  sa: { name: "Saudi Arabia ", isoCode: "sa", countryCode: "+966" },
  sn: { name: "Senegal", isoCode: "sn", countryCode: "+221" },
  sc: { name: "Seychelles ", isoCode: "sc", countryCode: "+248" },
  sl: { name: "Sierra Leone ", isoCode: "sl", countryCode: "+232" },
  sg: { name: "Singapore ", isoCode: "sg", countryCode: "+65" },
  sk: { name: "Slovakia", isoCode: "sk", countryCode: "+421" },
  si: { name: "Slovenia ", isoCode: "si", countryCode: "+386" },
  sb: { name: "Solomon Islands", isoCode: "sb", countryCode: "+677" },
  so: { name: "Somalia", isoCode: "so", countryCode: "+252" },
  za: { name: "South Africa", isoCode: "za", countryCode: "+27" },
  es: { name: "Spain", isoCode: "es", countryCode: "+34" },
  lk: { name: "Sri Lanka", isoCode: "lk", countryCode: "+94" },
  sd: { name: "Sudan ", isoCode: "sd", countryCode: "+249" },
  sr: { name: "Suriname", isoCode: "sr", countryCode: "+597" },
  sj: { name: "Svalbard", isoCode: "sj", countryCode: "+47" },
  sz: { name: "Swaziland", isoCode: "sz", countryCode: "+268" },
  se: { name: "Sweden", isoCode: "se", countryCode: "+46" },
  ch: { name: "Switzerland ", isoCode: "ch", countryCode: "+41" },
  sy: { name: "Syria", isoCode: "sy", countryCode: "+963" },
  tw: { name: "Taiwan", isoCode: "tw", countryCode: "+886" },
  tj: { name: "Tajikistan", isoCode: "tj", countryCode: "+992" },
  tz: { name: "Tanzania", isoCode: "tz", countryCode: "+255" },
  th: { name: "Thailand", isoCode: "th", countryCode: "+66" },
  tk: { name: "Tokelau", isoCode: "tk", countryCode: "+690" },
  to: { name: "Tonga", isoCode: "to", countryCode: "+676" },
  tt: { name: "Trinidad and Tobago ", isoCode: "tt", countryCode: "+1-868" },
  tn: { name: "Tunisia", isoCode: "tn", countryCode: "+216" },
  tr: { name: "Turkey", isoCode: "tr", countryCode: "+90" },
  tm: { name: "Turkmenistan", isoCode: "tm", countryCode: "+993" },
  tc: {
    name: "Turks and Caicos Islands",
    isoCode: "tc",
    countryCode: "+1-649",
  },
  tv: { name: "Tuvalu", isoCode: "tv", countryCode: "+688" },
  ug: { name: "Uganda", isoCode: "ug", countryCode: "+256" },
  ua: { name: "Ukraine", isoCode: "ua", countryCode: "+380" },
  ae: { name: "United Arab Emirates", isoCode: "ae", countryCode: "+971" },
  gb: { name: "United Kingdom", isoCode: "gb", countryCode: "+44" },
  us: { name: "United States", isoCode: "us", countryCode: "+1" },
  uy: { name: "Uruguay", isoCode: "uy", countryCode: "+598" },
  uz: { name: "Uzbekistan", isoCode: "uz", countryCode: "+998" },
  vu: { name: "Vanuatu", isoCode: "vu", countryCode: "+678" },
  va: { name: "Vatican City State", isoCode: "va", countryCode: "+418" },
  ve: { name: "Venezuela", isoCode: "ve", countryCode: "+58" },
  vn: { name: "Vietnam", isoCode: "vn", countryCode: "+84" },
  vi: {
    name: "Virgin Islands, British ",
    isoCode: "vi",
    countryCode: "+1-284",
  },
  vq: { name: "Virgin Islands, US", isoCode: "vq", countryCode: "+1-340" },
  wf: {
    name: "Wallis and Futuna Islands ",
    isoCode: "wf",
    countryCode: "+681",
  },
  ye: { name: "Yemen", isoCode: "ye", countryCode: "+967" },
  zm: { name: "Zambia", isoCode: "zm", countryCode: "+260" },
  zw: { name: "Zimbabwe", isoCode: "zw", countryCode: "+263" },
}
