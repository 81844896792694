import { Button, ButtonsRow } from "../styles"
import { Content, Footer, LoaderContainer, LogoContainer, Page } from "./styles"
import React, { Component } from "react"

import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { SyncLoader } from "react-spinners"
import logo from "../../static/neutral-logo.svg"
import moment from "moment"

export const videoToolUrl = {
  whatsapp: id =>
    `https://api.whatsapp.com/send?phone=${encodeURIComponent(id)}`,
  facetime: id => `facetime:${id}`,
  skype: id => `skype:${id}?call`,
  zoom: id => id,
  googlemeet: id => id,
  msteams: id => id,
  // `https://teams.microsoft.com/l/meeting/new?subject=ModaResa&attendees=${encodeURIComponent(
  //   id,
  // )}`,
  whereby: id => id,
}

class VideoCall extends Component {
  state = {}

  componentDidMount() {
    const { match, session } = this.props
    if (match.params && match.params.id && !session) {
      this.props.onFetchVideoCallEvent({ eventId: match.params.id })
      this.props.history.replace(`/video-call/${match.params.id}`)
    }

    this.setState({
      interval: setInterval(() => {
        this.forceUpdate()
      }, 2000),
    })
  }

  componentWillUnmount() {
    clearInterval(this.state.interval)
  }

  redirect() {
    const {
      event: { videoTool, host },
    } = this.props
    const url = videoToolUrl[videoTool](host.videoIDs[videoTool])
    window.location.href = url
  }

  renderContent() {
    const { loading, event } = this.props

    if (loading) {
      return (
        <LoaderContainer>
          <SyncLoader color={"#a60c46"} loading size={20} sizeUnit={"px"} />
        </LoaderContainer>
      )
    }

    if (!event) {
      return <div>Oops, looks like this event has been cancelled.</div>
    }

    if (!this.state.inviteDataRequested) {
      setTimeout(() => {
        this.setState({ inviteDataRequested: true })
        this.props.onFetchInviteData(event.bookingId)
      })
    }

    const now = moment.utc().local()
    const startTime = moment
      .utc(event.startTime, "X")
      .tz(event.session.timezone, true)
      .local()
    const endTime = moment
      .utc(event.endTime, "X")
      .tz(event.session.timezone, true)
      .local()

    if (endTime.diff(now) < 0) {
      return <p>This meeting is over.</p>
    }

    if (startTime.format("MM-DD-YYYY") === now.format("MM-DD-YYYY")) {
      const diff = Math.floor(startTime.diff(now) / 1000)
      if (diff <= 0) {
        this.redirect()
        clearInterval(this.state.interval)
        return <p>Redirecting...</p>
      }

      const timeLeft = [`${diff % 60} seconds`]
      if (diff > 60) {
        const unit = diff > 60 * 2 ? "minutes" : "minute"
        timeLeft.unshift(`${Math.floor(diff / 60) % 60} ${unit} and`)
      }
      if (diff > 60 * 60) {
        const unit = diff > 60 * 60 * 2 ? "hours" : "hour"
        timeLeft.unshift(`${Math.floor(diff / 60 / 60)} ${unit}, `)
      }

      return (
        <p>
          Your call will start in <strong>{timeLeft.join(" ")}</strong>
          .<br />
          Keep this page open, and you will be redirected to the call
          automatically once the time comes.
        </p>
      )
    }

    let startFormat = "h A"
    if (startTime.format("mm") !== "00") {
      startFormat = "h:mm A"
    }

    let endFormat = "h A"
    if (endTime.format("mm") !== "00") {
      endFormat = "h:mm A"
    }

    return (
      <p>
        Your call is scheduled for{" "}
        <strong>{startTime.format("dddd, MMMM Do, YYYY")}</strong> from{" "}
        <strong>{startTime.format(startFormat)}</strong> to{" "}
        <strong>{endTime.format(endFormat)}</strong> (GMT
        {`${moment().format("Z")} ${moment.tz.guess().split("/")[1]}`}). See you
        then.
      </p>
    )
  }

  render() {
    const { loading, isAuthed } = this.props
    return (
      <div className="App">
        <Page>
          <LogoContainer alt="logo" src={logo} />
          <Content>{this.renderContent()}</Content>
          {!isAuthed && !loading && (
            <Footer>
              <strong>Don’t have your free ModaResa buyer calendar yet?</strong>
              <br />
              Sign-up below to be notified through the app directly when your
              call is about to start.
              <ButtonsRow>
                <Link to="/sync">
                  <Button large primary>
                    Create ModaResa Calendar
                  </Button>
                </Link>
              </ButtonsRow>
            </Footer>
          )}
        </Page>
      </div>
    )
  }
}

VideoCall.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  event: PropTypes.object,
  loading: PropTypes.bool,
  isAuthed: PropTypes.bool,
  onFetchVideoCallEvent: PropTypes.func,
  onFetchInviteData: PropTypes.func,
}

export default VideoCall
