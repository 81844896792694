import React, { memo, useCallback, useState, useEffect } from "react"
import { useQuery } from "@apollo/client"

import { Button, Checkbox } from "../UI"
import { ButtonSecondary } from "../UI/Button"
import Modal from "../UI/Modal"
import Loading from "../UI/Loading"

import { ButtonsRow, Error, StepContainer, TabRow, Text, Title } from "../styles"
import { GET_AVAILABLE_ROOMS_FOR_BOOKING } from "../../graphql/queries/booking"

type Props = {
  bookingsData: {
    _id: string,
    rooms: [
      {
        roomName: string,
      },
    ],
  },
  onPrevClick: Function,
  onNextClick: Function,
}
const Categories = ({ bookingsData, onPrevClick, onNextClick, session }: Props) => {
  const [errors, setErrors] = useState({})
  const [selectedRooms, setSelectedRooms] = useState(() =>
    bookingsData.rooms.reduce((roomsObj, { roomName }) => {
      roomsObj[roomName] = true
      return roomsObj
    }, {}),
  )
  const [openModal, setOpenModal] = useState(false)

  const handleCheckboxChange = useCallback(
    room => {
      setSelectedRooms({
        ...selectedRooms,
        [room]: !selectedRooms[room],
      })
      setErrors({})
    },
    [selectedRooms],
  )

  const fillRoom = room => {
    const alreadySelectedBookingRoom = bookingsData.rooms.find(rm => rm.roomName === room.name)
    if (!alreadySelectedBookingRoom) {
      const events = [...bookingsData.events, ...bookingsData.jokerRequests]
      const event = events.find(event => event.room === room.name)
      return {
        clients: (event && event.clients) || [null],
        roomName: room.name,
        length: (event && (event.endTime - event.startTime) / 60) || 0,
      }
    }
    return alreadySelectedBookingRoom
  }

  const handleNextClick = useCallback(() => {
    const bookingRoomsObj = []
    session.rooms.forEach(room => {
      if (selectedRooms[room.name]) {
        bookingRoomsObj.push(fillRoom(room))
      }
    })
    if (bookingRoomsObj.length < 1) {
      return setErrors({
        errors: { categories: "Required" },
      })
    }
    onNextClick({ selectedRooms: bookingRoomsObj })
  }, [bookingsData.events, bookingsData.jokerRequests, bookingsData.rooms, onNextClick, selectedRooms, session.rooms])

  const { loading, error, data } = useQuery(GET_AVAILABLE_ROOMS_FOR_BOOKING, {
    variables: { bookingId: bookingsData._id },
    onCompleted: ({ getAvailableRoomsForBooking: availableRooms }) => {
      const selectedRoomsFiltered = Object.keys(selectedRooms).reduce((filteredRooms, selectedRoomName) => {
        if (availableRooms.find(availableRoom => availableRoom.name === selectedRoomName)) {
          filteredRooms[selectedRoomName] = true
        }
        return filteredRooms
      }, {})
      setSelectedRooms(selectedRoomsFiltered)
    },
  })

  // when there is only 1 visible room, autoselect and go to next step;
  const autoSelectRoom = rooms => {
    if (rooms && rooms.length === 1) {
      if (selectedRooms[rooms[0].name]) {
        return
      }
      setOpenModal(true)
      setSelectedRooms({
        ...selectedRooms,
        [rooms[0].name]: true,
      })
      setTimeout(() => {
        if (openModal) setOpenModal(false)
        onNextClick({ selectedRooms: [fillRoom(rooms[0])] })
      }, 2500)
    }
  }

  useEffect(() => {
    if (loading === false && data && data?.getAvailableRoomsForBooking) {
      const { getAvailableRoomsForBooking } = data
      autoSelectRoom(getAvailableRoomsForBooking)
    }
  }, [data, loading])

  if (error) return <div>an error occured</div>
  return (
    <StepContainer alignItems="stretch">
      <Title>3. SELECT ROOMS FOR MEETINGS</Title>
      <Text margin="5px 0 10px 0">
        <b>ONLY</b> select the room(s) that includes all product categories/brands <b>you and your colleagues</b> want
        to see.
      </Text>
      <Text margin="0 0 10px 0">
        On the next step you can then allocate colleagues to their respective product category.
      </Text>
      <TabRow>
        {(data?.getAvailableRoomsForBooking || []).map(room => (
          <Checkbox
            checked={selectedRooms[room.name]}
            id={room.name}
            key={room.name}
            label={room.name}
            name={room.name}
            onChange={handleCheckboxChange}
          />
        ))}
        {errors.categories && <Error>{errors.categories}</Error>}
      </TabRow>
      <ButtonsRow>
        <ButtonSecondary margin="0 20px 0 0" onClick={onPrevClick}>
          Previous
        </ButtonSecondary>
        <Button onClick={handleNextClick} disabled={!data}>
          Next
        </Button>
      </ButtonsRow>
      <Modal isOpen={openModal} title={<Loading />} minHeight="155px" width="320px">
        <p>WE ARE PREPARING THE NEXT STEP.</p>
      </Modal>
    </StepContainer>
  )
}

export default memo(Categories)
