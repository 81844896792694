import React, { useCallback, useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import arrowRight from "../../static/arrow-right.svg"
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  overflow-x: scroll;
  padding-left: 44px;

  margin-left: 0px;
  transition: margin-left 0.3s;
  ${props =>
    props.withSidebar &&
    css`
      margin-left: 240px;
    `}
`

export const MoreSuggestions = styled.div`
  position: fixed;
  z-index: 4;
  bottom: 96px;
  right: 16px;
  font-size: 20px;
  color: ${p => p.theme.primary};
  border-radius: 10px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  & img {
    width: 28px;
    margin-left: 12px;
  }
`

type Props = {
  withSidebar: boolean,
  children: any,
}

const Suggestions = ({ withSidebar, children }: Props) => {
  const containerRef = useRef(null)
  const [showMoreSuggestions, setShowMoreSuggestions] = useState(true)
  const handleScroll = useCallback(() => {
    const element = containerRef.current
    const maxScrollLeft = element.scrollWidth - element.clientWidth
    if (showMoreSuggestions && element.scrollLeft >= maxScrollLeft - 10) {
      setShowMoreSuggestions(false)
    }
  }, [showMoreSuggestions])
  useEffect(() => {
    const scrollRef = containerRef.current
    handleScroll()
    scrollRef.addEventListener("scroll", handleScroll, {
      passive: true,
    })
    return () => {
      scrollRef.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])
  return (
    <Container ref={containerRef} withSidebar={withSidebar}>
      {showMoreSuggestions && (
        <MoreSuggestions>
          More suggestions
          <img src={arrowRight} alt="arrow right" />
        </MoreSuggestions>
      )}
      {children}
    </Container>
  )
}

export default Suggestions
