import { Dialog, StyledModal } from "./styles"
import React, { Component } from "react"

import PropTypes from "prop-types"
import { SyncLoader } from "react-spinners"

const customStyles = {
  content: {
    backgroundColor: "transparent",
  },
  overlay: {
    backgroundColor: "rgba(204, 204, 204, 0.65)",
  },
}

class Spinner extends Component {
  render() {
    return (
      <StyledModal closeTimeoutMS={150} {...this.props} style={customStyles}>
        <Dialog>
          <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
        </Dialog>
      </StyledModal>
    )
  }
}

Spinner.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
}

Spinner.defaultProps = {}

export { Spinner }
