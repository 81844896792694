import { Content, LoaderContainer, LogoContainer, Page } from "./styles"
import React, { Component } from "react"

import PropTypes from "prop-types"
import { SyncLoader } from "react-spinners"
import logo from "../../static/neutral-logo.svg"

class VideoPresentation extends Component {
  state = {}

  componentDidMount() {
    const { match, session } = this.props
    if (match.params && match.params.id && !session) {
      this.props.onFetchVideoCallEvent({
        eventId: match.params.id,
        markAsViewed: true,
      })
      this.props.history.replace(`/video-presentation/${match.params.id}`)
    }
  }

  redirect() {
    const {
      event: { videoPresentation },
    } = this.props
    setTimeout(() => {
      window.location.href = videoPresentation
    }, 100)
  }

  renderContent() {
    const { loading, event } = this.props

    if (!loading && !event) {
      return <div>Event not found.</div>
    }

    if (!loading && event) {
      this.redirect()
    }

    return (
      <LoaderContainer>
        <SyncLoader color={"#a60c46"} loading size={20} sizeUnit={"px"} />
      </LoaderContainer>
    )
  }

  render() {
    return (
      <div className="App">
        <Page>
          <LogoContainer alt="logo" src={logo} />
          <Content>{this.renderContent()}</Content>
        </Page>
      </div>
    )
  }
}

VideoPresentation.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  event: PropTypes.object,
  loading: PropTypes.bool,
  onFetchVideoCallEvent: PropTypes.func,
}

export default VideoPresentation
