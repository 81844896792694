import { all, fork } from "redux-saga/effects"

import authSagas from "./auth"
import buyerSaga from "./buyer"
import inviteSagas from "./invite"
import retailerSaga from "./retailers"
import schedulesSagas from "./schedule"
import sessionSaga from "./session"
import userSaga from "./user"

export default function* root(params) {
  const sagas = [
    ...inviteSagas,
    ...schedulesSagas,
    ...authSagas,
    ...buyerSaga,
    ...retailerSaga,
    ...userSaga,
    ...sessionSaga,
  ]
  yield all([...sagas].map(saga => fork(saga, params)))
}
