import {
  clearSuggestions,
  confirmAutoBookings,
  editAutoBookings,
  editAutoBookingsJoker,
  editJokerRequest,
  fetchSuggestions,
  requestJoker,
} from "../../store/actions/schedules"
import {
  getBookingError,
  getBookingStatus,
  getBrand,
  getClients,
  getInviteBookingsData,
  getInvitedClient,
  getRequestJokerError,
  getRequestJokerPending,
  getSelectedSession,
  getSuggestionFetchStatus,
  getSuggestions,
  getSuggestionsClientIndex,
  getSuggestionsClients,
  getSuggestionsSlice,
  selectedEvent,
} from "../../store/selectors"

import Component from "./MeetingMatches"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

const selector = createStructuredSelector({
  clients: getClients,
  brand: getBrand,
  bookingsData: getInviteBookingsData,
  invitedClient: getInvitedClient,
  session: getSelectedSession,
  suggestions: getSuggestions,
  suggestionsClients: getSuggestionsClients,
  suggestionsClientIndex: getSuggestionsClientIndex,
  suggestionsSlice: getSuggestionsSlice,
  isFetchingSuggestions: getSuggestionFetchStatus,
  bookingRequestPending: getBookingStatus,
  bookingRequestError: getBookingError,
  jokerRequestStatus: getRequestJokerPending,
  jokerRequestError: getRequestJokerError,
  selectedEvent,
})

const actions = {
  onEditAutoBookings: editAutoBookings,
  onEditJokerRequest: editJokerRequest,
  onEditAutoJokerBookings: editAutoBookingsJoker,
  onFetchSuggestions: fetchSuggestions,
  onClearSuggestion: clearSuggestions,
  onConfirmAutoBookings: confirmAutoBookings,
  onRequest: requestJoker,
}

export default connect(selector, actions)(Component)
