import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useParams, useHistory } from "react-router-dom"
import styled from "styled-components"
import { setupAuthToken } from "../../store/actions/auth"

const MargedDiv = styled.div`
  margin: 64px;
`

const ConnectAsComponent = ({ onSetupAuthToken }) => {
  const history = useHistory()
  const { token } = useParams()
  useEffect(() => {
    async function setupToken() {
      if (token) {
        localStorage.setItem("controller-token", "master")
        onSetupAuthToken(token)
        history.push("/")
      } else {
        localStorage.removeItem("controller-token")
        history.push("/logout")
        window.open(process.env.REACT_APP_BRAND_URL)
      }
    }
    setupToken()
  }, [history, token, onSetupAuthToken])
  return <MargedDiv>Connecting as...</MargedDiv>
}

const actions = {
  onSetupAuthToken: setupAuthToken,
}
const ConnectAs = connect(() => ({}), actions)(ConnectAsComponent)
export default ConnectAs
