import { Button, Div } from "../../UI"
import { ButtonsRow, Form, Loader, MrLogo, SmallContainer } from "../styles"
import React, { Component } from "react"

import PropTypes from "prop-types"
import SmllLogo from "../../../static/mR2.png"
import { SyncLoader } from "react-spinners"
import isEmpty from "lodash/isEmpty"

class ResetPasswordEmail extends Component {
  state = {
    values: {
      email: "",
    },
    resetToken: "",
    errors: {},
    touched: {},
  }

  validate = (value, name) => {
    const { values, errors } = this.state
    switch (name) {
      case "email":
        const reg = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        } else if (reg.test(value) === true) {
          delete errors[name]
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        errors[name] = `Invalid email address`
        return {
          values: { ...values, [name]: value },
          errors,
        }

      default:
        break
    }
  }

  onInputChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ ...this.validate(value, name), isChanged: true })
  }

  onBlur = e => {
    const { touched } = this.state
    const name = e.target.name
    touched[name] = true
    this.setState({ touched })
  }

  onSubmit = () => {
    const { values, touched, errors } = this.state
    const { onSentResetPasswordEmail, history } = this.props

    for (const key in values) {
      this.validate(values[key], key)
      touched[key] = true
    }

    if (isEmpty(errors)) {
      onSentResetPasswordEmail({ email: values.email }, history)
    } else {
      this.setState({ errors, touched })
    }
  }

  render() {
    const { errors, touched, values } = this.state
    const { requestPending } = this.props
    return (
      <Div.Flex style={{ minHeight: "100vh" }}>
        <SmallContainer className="agreement">
          {requestPending && (
            <Loader style={{ zIndex: 3 }}>
              <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
            </Loader>
          )}
          <MrLogo style={{ marginBottom: "20px" }}>
            <img alt="modaresa logo" src={SmllLogo} />
          </MrLogo>
          <div style={{ marginBottom: "10px" }}>
            Please enter your email to search for your account.
          </div>
          <Form>
            <div>
              <input
                name="email"
                placeholder="Email"
                type="test"
                value={values.email}
                onBlur={this.onBlur}
                onChange={this.onInputChange}
              />
              {errors.email && touched.email && <span>{errors.email}</span>}
            </div>
          </Form>
          <ButtonsRow style={{ marginTop: "25px" }}>
            <Button onClick={this.onSubmit}>Send Email</Button>
          </ButtonsRow>
        </SmallContainer>
      </Div.Flex>
    )
  }
}

ResetPasswordEmail.propTypes = {
  emailIsSent: PropTypes.bool,
  history: PropTypes.object,

  requestPending: PropTypes.bool,
  onSentResetPasswordEmail: PropTypes.func,
}

export default ResetPasswordEmail
