import { REST_API_URL } from "../../config"
import axios from "axios"

export const fetchInviteData = async ({ id }) => {
  const response = await axios.get(`${REST_API_URL}/bookings/${id}`)

  return response.data
}

export const fetchInviteLink = async params => {
  const config = {
    params: { ...params },
  }
  const response = await axios.get(
    `${REST_API_URL}/auth/buyer/inviteLink`,
    config,
  )

  return response.data
}

export const sendInviteEmail = async params => {
  const config = {}
  const response = await axios.post(
    `${REST_API_URL}/auth/buyer/sendInvite`,
    params,
    config,
  )

  return response.data
}

export const fetchTokenData = async ({ token }) => {
  const response = await axios.get(
    `${REST_API_URL}/auth/buyer/tokenData?token=${token}`,
  )

  return response.data
}
