import { ButtonsRow, SelectContainer, StepContainer, Title } from "../styles"
import React, { Component } from "react"

import { Button } from "../UI"
import { PropTypes } from "prop-types"
import Select from "react-select"
import { videoTools } from "../../constants/meetings"
import { ButtonSecondary } from "../UI/Button"

export class VideoTool extends Component {
  constructor(props) {
    super(props)
    const { bookingsData, session } = this.props

    let staff = session.staff

    // Filter staff out by the meeting type
    staff = staff.filter(
      staff =>
        staff.availableMeetingTypes &&
        staff.availableMeetingTypes.indexOf(bookingsData.meetingType) > -1,
    )

    // Filter staff out by the languages
    const customLanguage = session ? session.algorithms.lang : false
    if (customLanguage && bookingsData.languages) {
      staff = staff.filter(
        staff =>
          bookingsData.languages.filter(
            lang => staff.languages && staff.languages.indexOf(lang) > -1,
          ).length,
      )
    }

    const availableVideoIDs = {}
    staff.forEach(staff => {
      if (staff.videoIDs) {
        videoTools.forEach(tool => {
          const key = tool.value
          if (
            !availableVideoIDs[key] &&
            staff.videoIDs[key] &&
            !staff.videoIDs[`${key}Disabled`]
          ) {
            availableVideoIDs[key] = true
          }
        })
      }
    })

    const availableTools = videoTools.filter(
      tool => availableVideoIDs[tool.value],
    )

    let selected = null
    if (bookingsData.videoTool) {
      selected = availableTools.find(
        type => type.value === bookingsData.videoTool,
      )
    }
    if (!selected) {
      selected = availableTools[0]
    }

    this.state = {
      videoTools: availableTools,
      videoTool: selected,
    }
  }

  onNextClick = () => {
    const { onNextClick } = this.props
    const { videoTool } = this.state
    onNextClick({ videoTool: videoTool.value })
  }

  onSelect = option => {
    this.setState({ videoTool: option })
  }

  render() {
    const { onPrevClick } = this.props
    const { videoTools, videoTool } = this.state

    return (
      <StepContainer>
        <Title>Type of Virtual Meeting</Title>
        <div>Please confirm the type of Virtual Meeting</div>
        <SelectContainer
          margin="30px 0px 40px 0px"
          style={{ flexDirection: "column" }}
        >
          <Select
            classNamePrefix="meetingSelect"
            options={videoTools}
            value={videoTool}
            onChange={this.onSelect}
          />
        </SelectContainer>
        <ButtonsRow>
          <ButtonSecondary
            margin="0 20px 0 0"
            onClick={onPrevClick}
          >
            Previous
          </ButtonSecondary>
          <Button onClick={this.onNextClick}>Next</Button>
        </ButtonsRow>
      </StepContainer>
    )
  }
}

VideoTool.propTypes = {
  bookingsData: PropTypes.object,
  session: PropTypes.object,
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
}
