import { REST_API_URL } from "../../config"
import axios from "axios"

export const fetchSchedules = async ({ brandId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(
    `${REST_API_URL}/schedules?brandId=${brandId}`,
    config,
  )

  return response.data
}

export const fetchSessionSchedule = async ({ sessionId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(
    `${REST_API_URL}/schedules/?sessionId=${sessionId}`,
    config,
  )

  return response.data[0]
}

export const reserveBooking = async ({ data, id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.post(
    `${REST_API_URL}/schedules/${id}/reserve`,
    data,
    config,
  )

  return response.data
}

export const deleteBooking = async ({ bookingId, id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.delete(
    `${REST_API_URL}/schedules/${id}/booking/${bookingId}`,
    config,
  )

  return response.data
}

export const confirmBooking = async ({ bookingId, token, id, bookingData }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.post(
    `${REST_API_URL}/schedules/${id}/booking/${bookingId}/confirm`,
    { bookingData },
    config,
  )

  return response.data
}

export const fetchSuggestions = async ({
  bookingsData,
  sessionId,
  clientId,
  selectedBookings,

  part,
}) => {
  const {
    meetingType,
    meetingApproach,
    videoTool,
    languages,
    brandId,
    rooms,
    eventId,
    _id,
  } = bookingsData

  const config = {
    params: {
      sessionId,
      meetingType,
      meetingApproach,
      videoTool,
      languages,
      selectedBookings,

      rooms,
      brandId,
      eventId,
      clientId,
      part,
    },
  }

  const response = await axios.get(
    `${REST_API_URL}/bookings/${_id}/suggestions`,
    config,
  )

  return response.data
}

export const cancelAutoBookings = async ({ bookingId, eventId }) => {
  const response = await axios.delete(
    `${REST_API_URL}/bookings/${bookingId}/auto/${eventId}`,
  )

  return response.data
}

export const fetchBookings = async ({ bookingIds, sessionId }) => {
  const config = {
    params: {
      bookingIds,
      sessionId,
    },
  }

  const response = await axios.get(`${REST_API_URL}/schedules/bookings`, config)

  return response.data
}

export const fetchEvent = async ({ eventId, markAsViewed }) => {
  const args = []
  if (markAsViewed) {
    args.push("markAsViewed=1")
  }
  const response = await axios.get(
    `${REST_API_URL}/bookings/event/${eventId}${
      args.length ? "?" + args.join("&") : ""
    }`,
  )
  return response.data
}

export const fetchEvents = async ({ params }) => {
  const config = {
    params,
  }
  const response = await axios.get(`${REST_API_URL}/events`, config)

  return response.data
}

export const fetchBookingId = async eventId => {
  const response = await axios.get(
    `${REST_API_URL}/schedules/events/${eventId}/booking`,
  )

  return response.data
}
