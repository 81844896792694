import React, { Component } from "react"
import { Tab, TabsContainer } from "../../../UI/Tabs"

import { CompanyGeneral } from "../CompanyGeneral"
import { CompanyStructure } from "../CompanyStructure"
import { Container } from "./styles"
import PropTypes from "prop-types"

const getUrlParameter = (name, str = window.location.search) => {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)")
  const results = regex.exec(str)
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, ""))
}

class CompanyProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSection: "general",
    }
  }

  componentDidMount() {
    const { history } = this.props
    const CreateTeam = getUrlParameter("CreateTeam")
    if (CreateTeam) {
      history.replace("/settings/company")
      this.setState({ activeSection: "structure" })
    }
  }

  onTabClick = tab => {
    this.setState({ activeSection: tab })
  }

  onSaveChanges = data => {
    const { onUpdateRetailer, retailer } = this.props
    onUpdateRetailer(data, retailer._id)
  }

  render() {
    const { activeSection } = this.state
    const { requestError, requestPending, retailer } = this.props
    if (!retailer._id) return null
    return (
      <Container>
        <TabsContainer>
          <Tab
            className={activeSection === "general" ? "tab-active" : ""}
            onClick={() => this.onTabClick("general")}
          >
            General
          </Tab>
          <Tab
            className={activeSection === "structure" ? "tab-active" : ""}
            onClick={() => this.onTabClick("structure")}
          >
            Company Structure
          </Tab>
        </TabsContainer>
        {activeSection === "general" && (
          <CompanyGeneral
            requestError={requestError}
            requestPending={requestPending}
            retailer={retailer}
            onSaveChanges={this.onSaveChanges}
          />
        )}
        {activeSection === "structure" && (
          <CompanyStructure
            requestError={requestError}
            requestPending={requestPending}
            retailer={retailer}
            onSaveChanges={this.onSaveChanges}
          />
        )}
      </Container>
    )
  }
}

CompanyProfile.propTypes = {
  requestError: PropTypes.string,
  requestPending: PropTypes.bool,
  retailer: PropTypes.object,
  onUpdateRetailer: PropTypes.func,
}

export default CompanyProfile
