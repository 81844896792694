import React, { memo } from "react"
import {
  ActionButton,
  ActionButtonChangeAppointment,
  BackgroundModaresaCalendar,
  BodyContent,
  BodyContentText,
  ConfirmationContainerBody,
  ConfirmationContainerFooter,
  ConfirmationStepContainer,
  ContainerBackground,
  Signature,
  Title,
  MargedBulletPoint,
} from "./NewFinalStepView.styles"
import { Bold, BulletPoint, Button, ButtonsRow, Icon } from "../styles"
import Shape from "../../static/shape.png"
import { Link } from "react-router-dom"
import calendarIcon from "../../static/icon-calendar.svg"
import Logo from "../../static/PinkLogo.png"
import ModalSpecialRequest from "./ModalSpecialRequest"
import ModalSpreadTheWord from "./ModalSpreadTheWord"

type Props = {
  showSpecialRequestModal: boolean,
  onCloseSpecialRequestModal: Function,
  onDownloadPress: Function,
  brand: {},
  bookingId: string,
}

const NewFinalStepView = ({
  bookingId,
  showSpecialRequestModal,
  showSpreadTheWordModal,
  onDownloadPress,
  onCloseSpecialRequestModal,
  onCloseSpreadTheWordModal,
  brand,
}: Props) => (
  <>
    <ContainerBackground>
      <BackgroundModaresaCalendar />
    </ContainerBackground>
    <ConfirmationStepContainer>
      {showSpecialRequestModal && <ModalSpecialRequest brand={brand} onCloseModal={onCloseSpecialRequestModal} />}
      {showSpreadTheWordModal && <ModalSpreadTheWord bookingId={bookingId} onCloseModal={onCloseSpreadTheWordModal} />}
      <ConfirmationContainerBody>
        <div>
          <Title>Your appointment is confirmed!</Title>
          <BodyContent>
            <BodyContentText>
              The owner of this booking link will receive a confirmation shortly.
              <br />
              Now, create your complimentary ModaResa buyer calendar built for fashion weeks, so you can:
            </BodyContentText>
            <BulletPoint>
              <Icon height="25px" src={Shape} />
              View the <Bold>official show schedule</Bold> for all cities straight into your calendar
            </BulletPoint>
            <MargedBulletPoint>
              <Icon height="25px" src={Shape} />
              Have fun scheduling thanks to our <Bold>sexy team-calendars</Bold> and much more.
            </MargedBulletPoint>
          </BodyContent>
        </div>
        <div>
          <ButtonsRow>
            <Link to="/sync">
              <ActionButton large primary>
                Access Show Calendar
              </ActionButton>
            </Link>
          </ButtonsRow>
          <ButtonsRow>
            <Link to={`${window.location.pathname}/edit`}>
              <ActionButtonChangeAppointment large>I want to change my appointment</ActionButtonChangeAppointment>
            </Link>
          </ButtonsRow>
          <ButtonsRow>
            <Link to="#" onClick={onDownloadPress}>
              <Button large plain>
                Download .ics
                <Icon height="20px" src={calendarIcon} />
              </Button>
            </Link>
          </ButtonsRow>
        </div>
      </ConfirmationContainerBody>
      <Signature>Sincerely, your new best friend</Signature>
      <ConfirmationContainerFooter>
        <Icon height="60px" src={Logo} />
      </ConfirmationContainerFooter>
    </ConfirmationStepContainer>
  </>
)

export default memo(NewFinalStepView)
