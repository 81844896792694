export const JOIN_SOCKET_ROOM = "JOIN_SOCKET_ROOM"
export const LEAVE_SOCKET_ROOM = "LEAVE_SOCKET_ROOM"
export const API_ERROR = "API_ERROR"

// Buyer Booking
export const BOOK_BUYER_EVENTS = "BOOK_BUYER_EVENTS"
export const BUYER_EVENTS_BOOKED = "BUYER_EVENTS_BOOKED"
export const DELETE_BUYER_EVENT = "DELETE_BUYER_EVENT"
export const BUYER_EVENT_DELETED = "BUYER_EVENT_DELETED"
export const UPDATE_BUYER_FULL_BOOKING = "UPDATE_BUYER_FULL_BOOKING"
export const BUYER_FULL_BOOKING_UPDATED = "BUYER_FULL_BOOKING_UPDATED"
export const UPDATE_BUYER_EVENTS_WITH_JOKER = "UPDATE_BUYER_EVENTS_WITH_JOKER"
export const BUYER_EVENTS_UPDATED_WITH_JOKER = "BUYER_EVENTS_UPDATED_WITH_JOKER"
export const UPDATE_BUYER_EVENT = "UPDATE_BUYER_EVENT"
export const BUYER_EVENT_UPDATED = "BUYER_EVENT_UPDATED"
export const UPDATE_BUYER_EVENT_WITH_JOKER = "UPDATE_BUYER_EVENT_WITH_JOKER"
export const BUYER_EVENT_UPDATED_WITH_JOKER = "BUYER_EVENT_UPDATED_WITH_JOKER"
export const REQUEST_BUYER_JOKER = "REQUEST_BUYER_JOKER"
export const BUYER_JOKER_REQUESTED = "BUYER_JOKER_REQUESTED"
export const REPLACE_DECLINED_BUYER_JOKER = "REPLACE_DECLINED_BUYER_JOKER"
export const DECLINED_BUYER_JOKER_REPLACED = "DECLINED_BUYER_JOKER_REPLACED"

export const DELAY_APPOINTMENT = "DELAY_APPOINTMENT"
export const APPOINTMENT_DELAYED = "APPOINTMENT_DELAYED"

export const APPOINTMENTS_DELAYED = "APPOINTMENTS_DELAYED"

// Shared Buyer
export const UPDATE_SHARED_BUYER = "UPDATE_SHARED_BUYER"
export const SHARED_BUYER_UPDATED = "SHARED_BUYER_UPDATED"
export const DELETE_SHARED_BUYER = "DELETE_SHARED_BUYER"
export const SHARED_BUYER_DELETED = "SHARED_BUYER_DELETED"
