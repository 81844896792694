import styled from "styled-components"

export const Textarea = styled.textarea`
  display: block;
  width: 100%;
  border-radius: 2px;
  border-color: #0a1069;
  resize: vertical;
  margin-bottom: 10px;
  box-sizing: border-box;
  color: #0a1069;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  padding: 5px 8px;
  &::placeholder {
    color: #9b9b9b;
  }
`
