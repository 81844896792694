const AUTH_URLS = {
  production: "https://app.modaresa.fr/callback",
  development: "https://app-dev.modaresa.fr/callback",
  local: "http://localhost:3000/callback",
}
const API_URLS = {
  production: "https://api.modaresa.fr",
  development: "https://api-dev.modaresa.fr",
  local: "http://localhost:4001",
}

const staging = JSON.parse(
  (process.env.REACT_APP_IS_STAGING || "false").toLowerCase(),
)

/* eslint-disable import/no-mutable-exports */
let API_URL = ""
let AUTH_URL = ""
let REST_API_URL = ""
let SOCKET_URL = ""
let GRAPHQL_URL = ""
/* eslint-enable import/no-mutable-exports */

if (process.env.NODE_ENV === "production") {
  API_URL = API_URLS[staging ? "development" : "production"]
  AUTH_URL = AUTH_URLS[staging ? "development" : "production"]
  REST_API_URL = `${API_URL}/V1`
  SOCKET_URL = API_URL
  GRAPHQL_URL = `${API_URL}/graphql`
} else {
  API_URL = API_URLS[staging ? "development" : "local"]
  AUTH_URL = AUTH_URLS.local
  REST_API_URL = `${API_URL}/V1`
  SOCKET_URL = API_URL
  GRAPHQL_URL = `${API_URL}/graphql`
}

export { AUTH_URL, REST_API_URL, SOCKET_URL, GRAPHQL_URL, API_URL }
