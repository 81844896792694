import styled from "styled-components"

export const Container = styled.div`
  padding-top: 54px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const Timeline = styled.div`
  padding-bottom: 4px;
  height: auto;
  display: flex;
  flex-direction: column;
  width: 60px;
  border: 2px solid transparent;
  position: sticky;
  min-width: 60px;
  background-color: #fff;
  z-index: 3;
  left: 0;
  margin-top: -5px;
  border-right: 2px solid #ccc;
  margin-right: -2px;
`

export const CalendarDays = styled.div`
  min-height: 40px;
  display: flex;
  border: 2px solid #ccc;
  position: sticky;
  background-color: #fff;
  z-index: 3;
  top: 0;
  margin-left: 58px;
  margin-bottom: -5px;
`

export const StaffTableHeader = styled.div`
  display: flex;
  min-height: 40px;
  border-bottom: 2px solid #ccc;
  width: auto;
  min-width: calc(100% - 58px);
  position: sticky;
  background-color: #fff;
  z-index: 1;
  top: 0;
  margin-left: 58px;
  margin-bottom: -5px;
`

export const Hour = styled.div`
  display: flex;
  flex: ${props => props.flex || 1};
  justify-content: center;
  flex-basis: 50px;
  min-height: 50px;
`

export const CalendarContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  min-height: 100%;
  overflow-x: auto;
`
export const DateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  background-color: ${props => props.theme.gray75};
  align-items: center;
  padding: 0 20px;
  z-index: 4;

  & > div {
    width: 33.33%;
  }

  & ${DateContainer} {
    width: 50%;
  }
`

export const Icon = styled.img`
  display: block;
  width: auto;
  height: 18px;
  cursor: pointer;
  margin: 0 10px;
  ${props => (props.rotate ? "transform: rotate(180deg)" : "")};
  ${props => (props.hidden ? "visibility: hidden" : "")};
`
export const Date = styled.span``
export const CalendarContent = styled.div`
  height: auto;
  min-height: calc(100% - 60px);
  overflow: auto;
  position: relative;

  &:before {
    content: "";
    position: fixed;
    left: 0px;
    top: 114px;
    height: 40px;
    width: 60px;
    background: #fff;
    display: none;
    z-index: 2;
    border-bottom-right-radius: 5px;

    ${({ allColleagues }) => {
      if (allColleagues) {
        return "display: block"
      }
    }}
  }

  &:after {
    content: "";
    position: fixed;
    left: 58px;
    top: 114px;
    height: 38px;
    width: 2px;
    background: #fff;
    display: none;
    z-index: 3;
    border-bottom-right-radius: 5px;

    ${({ allColleagues }) => {
      if (allColleagues) {
        return "display: block"
      }
    }}
  }
`

export const Calendars = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: auto;
  border: 2px solid #ccc;
`
export const StaffCalendars = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
  width: auto;
  min-width: calc(100% - 58px);
  height: auto;
  border: 2px solid #ccc;
`

export const Day = styled.div`
  flex: 1;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    border-right: 2px solid #ccc;
  }
`

export const CalendarDay = styled.div`
  flex: 1;
  min-height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;

  &:not(:last-child) {
    border-right: 1px solid #ccc;
  }
`
export const EventContainer = styled.div`
  position: absolute;
  left: ${({ eventLeft }) => eventLeft};
  top: ${({ eventTop }) => eventTop};
  width: ${({ eventWidth }) => `calc(${eventWidth} - 2px)`};
  margin: 1px 1px 2px 1px;
  height: ${({ eventHeight }) => `calc(${eventHeight} - 2px)`};

  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  & div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const RoomCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${props => props.flex};
`
export const RoomHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
`
export const RoomSchedule = styled.div`
  display: flex;
  flex: 1;
  min-width: ${props => props.minWidth * 80 + 4}px;
`

export const DaySchedule = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #000;
  flex: 1;

  &:not(:last-child) {
    border-right: none;
  }

  & ${RoomSchedule} {
    border: none;
  }

  & ${RoomCol}:not(:last-child) {
    border-right: 2px solid #000;
  }
`
export const Table = styled.div`
  display: flex;
  flex: 1 0 5px;
  flex-direction: column;
  min-width: 80px;
  border-right: ${props => (props.last ? "none" : "2px solid #ccc")};
  position: relative;
`

export const StaffMemberTableHeader = styled.div`
  display: flex;
  height: 40px;
  min-width: ${props => props.minWidth * 120 + 4}px;
`

export const StaffColumn = styled.div`
  display: flex;
  flex: 1 0 5px;
  flex-direction: column;
  min-width: 120px;
  position: relative;
  border-right: ${props => (props.last ? "none" : "1px solid #ccc")};
`

export const StaffColumnHeader = styled.div`
  display: flex;
  flex: 1 0 5px;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Timeslot = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 50px;
  flex-basis: 50px;
  border-bottom: ${props =>
    props.even ? "1px solid #ccc" : "1px dotted #ccc"};

  &:last-child {
    border: none;
  }
`

export const TimeslotStaff = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 40px;
  border-bottom: 3px dotted #ccc;

  &:nth-child(2n) {
    border-bottom: 2px solid #ccc;
  }
`

export const Booklot = styled.div`
  display: flex;
  flex: ${props => props.flex};
  justify-content: center;
  min-height: ${props => props.flex * 50}px;
  flex-basis: 50px;
  border-bottom: 2px solid #ccc;
  ${props => props.blocked && "background-color: #d8d8d8;"}
`

export const Booking = styled.div`
  background: #ffcab1;
  height: 100%;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 2px solid #d1ad9b;
  transition: all 0.15s ease;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    background: #d1ad9b;
    color: #fff;
  }

  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const FashionEvent = styled.div`
  background: #516bef;
  height: 100%;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 2px solid #32428f;
  ${({ added }) =>
    added
      ? `cursor: pointer;
opacity: 1;
transition: all 0.15s ease;

&:hover {
background: #32428f;
color: #fff;
}`
      : ""}

  & span {
    font-weight: bold;
  }

  & .icon {
    fill: #000;
    stroke: #000;
    transition: all 0.15s ease;

    &:hover {
      fill: #fff;
      stroke: #fff;
    }
  }
`

export const CalendarEvent = styled.div`
  background: #f8f1e8;
  height: 100%;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 2px solid #cfc4b5;

  & > div {
    margin-bottom: 8px;
  }

  & p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const Placeholder = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: rgba(167, 12, 71, 0.77);
  position: absolute;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  pointer-events: none;
`
export const Block = styled.div`
  display: block;
`
export const MeetingInfo = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: ${props => props.margin || "20px"};

  & .meetingTypes {
    margin-bottom: 5px;
  }

  & .mrEvent__time {
    z-index: 21;
  }
`

export const MeetingDate = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${props => props.margin || "20px"};

  & img {
    display: block;
    margin: 10px;
    height: 25px;
    width: auto;
  }
`

export const Flex = styled.div`
  display: flex;
  justify-content: ${props => props.justify || "space-between"};
  flex-direction: ${props => (props.column ? "column" : "")};
  align-items: ${props => props.align || "center"};
  margin: ${props => props.margin};

  & .startTime {
    margin-bottom: 5px;
  }
`

export const Override = styled.div`
  display: flex;
  align-items: center;
  padding-left: 14px;
  font-size: 15px;
  color ${props => props.theme.textBlack} & input[type="checkbox"] {
    margin-top: 0;
    margin-right: 20px;
  }
`
export const MeetingClients = styled.div`
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 2px 5px 20px 5px;
  margin-bottom: 20px;

  & .colleagues__indicator.colleagues__clear-indicator,
  & .colleagues__indicator-separator {
    display: none;
  }
`
export const MeetingBudget = styled.div`
  width: 50%;
  border: 1px solid #ccc;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px 10px;
  margin-bottom: 20px;
`
export const Bold = styled.span`
  font-weight: 600;
`
export const Rotate = styled.span`
  transform: rotate(-75deg);
  white-space: nowrap;
  max-width: 40px;
  padding-left: 15px;
`
export const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 30px;

  ${({ image }) => {
    if (image) {
      return `
    font-size: 14px;
    flex-direction: column
    `
    }
  }}

  & img {
    object-fit: contain;
    width: 75%;
    height: 75%;
  }
`
export const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255);
`
export const Text = styled.div`
  margin: ${props => props.margin};

  & p {
    margin: 0;
  }
`
export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`

export const ButtonsRow = styled(InputRow)`
  width: 100%;
  min-height: 37px;
  justify-content: center;
  margin: ${props => props.margin || "0px"};
`
export const CloseModal = styled.span`
  position: absolute;
  right: 5px;
  top: 5px;
  width: 1em;
  text-align: center;
  cursor: pointer;
  color: ${props => props.theme.strawberry};
`
export const TodayButton = styled.div`
  height: 38px;
  padding: 0 20px 0 20px;
  background: #fff;
  cursor: pointer;
  border: 1px solid #ccc;
  line-height: 38px;
  border-radius: 3px;
`
export const Timezone = styled.span`
  padding-left: 20px;
`
export const Clients = styled.div`
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
`
export const ClientsList = styled.div``
export const ClientItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.gray50};
  margin-bottom: 7px;

  & p {
    margin: 0;
  }
`

export const EventInput = styled.input`
  border: ${({ error }) => (error ? `1px solid #f8263f` : `1px solid #f7f7f2`)};
  background-color: #f7f7f2;
  border-radius: 3px;
  padding: 0 10px;
  outline: none;

  width: 100%;
  min-height: 38px;
`

export const Icons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    margin-left: 10px;
    cursor: pointer;

    & path {
      fill: inherit !important;
    }
  }
`
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`
export const ErrorText = styled.div`
  color: ${props => props.theme.primary};
`
export const Dots = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;

  & span {
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin-right: 10px;
  }

  ${({ index }) => `& span:nth-child(${index}) {
    background-color: #a60c46;
    border-color: #a60c46;
    }`}
`
export const AutocompleteContainer = styled.div`
  width: 100%;
  max-height: 400px;
  position: absolute;
  z-index: 10;
  border-radius: 4px;
  border: ${props => props.border || "1px solid hsl(0,0%,80%)"};
  overflow: auto;
  padding: 5px;
`
