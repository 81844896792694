import auth from "./auth"
import brand from "./brand"
import clients from "./clients"
import { combineReducers } from "redux"
import invite from "./invite"
import retailers from "./retailers"
import salesSessions from "./salesSessions"
import schedules from "./schedules"
import staff from "./staff"
import user from "./user"

export default combineReducers({
  clients,
  staff,
  salesSessions,
  brand,
  auth,
  schedules,
  retailers,
  invite,
  user,
})
