import styled from "styled-components"

export const Flex = styled.div`
  display: flex;
  justify-content: ${props => props.justify || "space-between"};
  flex-direction: ${props => (props.column ? "column" : "")};
  align-items: ${props => props.align || "center"};
  margin: ${props => props.margin || ""};
`
export const Relative = styled.div`
  position: relative;
`
