import { cancelBooking, delayAppoinment, editAppoinment } from "../../store/actions/schedules"
import {
  getAppointmentRequestError,
  getAppointmentRequestStatus,
  getBrand,
  getCancelBookingPending,
  getClients,
  getInviteBookingsData,
  getInvitedClient,
  getRetailer,
  getSelectedSession,
} from "../../store/selectors"

import Component from "./EditPage"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { fetchInviteData } from "../../store/actions/invites"
import { fetchLanguages } from "../../store/actions/salesSessions"
import { updateClientPhoneNumber } from "../../store/actions/clients"

const selector = createStructuredSelector({
  clients: getClients,
  brand: getBrand,
  retailer: getRetailer,
  bookingsData: getInviteBookingsData,

  invitedClient: getInvitedClient,
  session: getSelectedSession,
  cancelPendeing: getCancelBookingPending,
  selectedSession: getSelectedSession,
  requestPending: getAppointmentRequestStatus,
  requestError: getAppointmentRequestError,
})

const actions = {
  onCancelBooking: cancelBooking,
  onFetchLanguages: fetchLanguages,
  onFetchInviteData: fetchInviteData,
  onEdit: editAppoinment,
  onUpdatePhoneNumber: updateClientPhoneNumber,
  onDelayAppointment: delayAppoinment,
}

export default connect(selector, actions)(Component)
