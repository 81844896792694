import { REST_API_URL } from "../../config"
import axios from "axios"

export const fetchSaleSessions = async ({ brandId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(
    `${REST_API_URL}/sale-sessions?brand=${brandId}`,
    config,
  )

  return response.data
}

export const fetchSession = async ({ id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(
    `${REST_API_URL}/sale-sessions/${id}`,
    config,
  )

  return response.data
}

export const fetchLanguages = async ({ sessionId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(
    `${REST_API_URL}/sale-sessions/${sessionId}/languages`,
    config,
  )

  return response.data
}
