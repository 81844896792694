import { login, socialLogin } from "../../store/actions/auth"

import _Login from "./Login"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

const selector = createStructuredSelector({})

const actions = {
  onLogin: login,
  onSocialLogin: socialLogin,
}

export default connect(selector, actions)(_Login)
