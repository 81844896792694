import {
  getAppointmentRequestError,
  getInviteRequestPending,
  getSuggestionFetchStatus,
  getVideoCallEvent,
} from "../../store/selectors"

import Component from "./VideoCall"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { fetchInviteData } from "../../store/actions/invites"
import { fetchVideoCallEvent } from "../../store/actions/schedules"

const selector = state => ({
  ...createStructuredSelector({
    loading: getSuggestionFetchStatus || getInviteRequestPending || getAppointmentRequestError,
    event: getVideoCallEvent,
  })(state),
  isAuthed: state.auth.isAuthed,
})

const actions = {
  onFetchVideoCallEvent: fetchVideoCallEvent,
  onFetchInviteData: fetchInviteData,
}

export default connect(selector, actions)(Component)
