import "./styles.css"

import React, { Component } from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { CompanyProfile } from "./blocks/CompanyProfile"
import { Container } from "./styles"
import PropTypes from "prop-types"
import { SideMenu } from "./blocks/SideMenu"

class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSection: "profile",
    }
  }

  onSectionSelect = section => {
    this.setState({
      activeSection: section,
    })
  }

  render() {
    const { match } = this.props
    return (
      <React.Fragment>
        <Container>
          <SideMenu match={match} />
          <Switch>
            <Route
              component={CompanyProfile}
              exact
              path={`${match.path}/company`}
            />
            <Redirect to={`${match.path}/company`} />
          </Switch>
        </Container>
      </React.Fragment>
    )
  }
}

Settings.propTypes = {
  match: PropTypes.object,
}

export default Settings
