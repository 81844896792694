export const meetingTypes = [
  { value: "businessMeeting", label: "Business Meeting" },
  { value: "walkthrough", label: "Walkthrough" },
  { value: "buyingAppointment", label: "Buying Appointment" },
]

export const markets = [
  { value: "men", label: "Men" },
  { value: "women", label: "Women" },
  { value: "womenMen", label: "Men & Women" },
]

export const marketsByValue = {
  men: "Men",
  women: "Women",
  womenMen: "Men & Women",
}

export const priorityGroupList = [
  { value: 1, label: "Priority group 1" },
  { value: 2, label: "Priority group 2" },
  { value: 3, label: "Priority group 3" },
]

export const statusList = [
  { value: "stockist", label: "Stockist" },
  { value: "prospect", label: "Prospect" },
]

export const inviteStatuses = {
  invited: "Invited",
  booked: "Booked",
  inviteOpened: "Invite Opened",
  joker: "Joker",
  followedUp: "Followed Up",
  cancelled: "Cancelled",
}

export const appointmentStatusOptions = [
  { value: "all", label: "All Appointments" },
  { value: "arrived", label: "Arrived" },
  { value: "delayed", label: "Delayed" },
  { value: "pending", label: "Pending" },
]

export const seasonsKey = {
  preSummerSpring: "Pre-Summer Spring",
  summerSpring: "Summer Spring",
  preAutumnWinter: "Pre-Autumn Winter",
  autumnWinter: "Autumn Winter",
}

export const eventType = {
  show: "Show",
  presentation: "Presentation",
}
