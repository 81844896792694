import Modal from "react-modal"
import styled from "styled-components"
if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root")

export const StyledModal = styled(Modal)`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  outline: none;
  justify-content: center;
  align-items: center;
`
export const Dialog = styled.div`
  position: relative;
  max-width: 650px;
  padding: 25px;
  width: auto;
  border-radius: 3px;
`
export const CloseIcon = styled.div`
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: #000;
  right: 10px;
  top: 10px;
  cursor: pointer;
`
export const HeaderContainer = styled.div`
  padding: 20px 20px 0 20px;
  height: 100px;
  background-color: ${props => {
    if (props.dark) return props.theme.secondary
    if (props.light) return props.theme.primary
  }}
  border-radius: 3px;
`
