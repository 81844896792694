import styled from "styled-components"

export const Text = styled.div`
  margin: ${props => props.margin};

  & p {
    margin: 0;
  }
`

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`

export const Body = styled.div`
  color: #0a1069;
  padding: 20px 32px;
`
