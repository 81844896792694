import "react-virtualized-select/styles.css"

import { ButtonsRow, SelectContainer, StepContainer, Title } from "../styles"
import React, { useCallback, useMemo, useState } from "react"

import { Button } from "../UI"
import Select from "react-select"
import { mainTheme } from "./../../constants/themes"
import { languages as allLanguages } from "../../config/languages"
import { useQuery } from "@apollo/client"
import { GET_AVAILABLE_LANGUAGES_FOR_BOOKING } from "../../graphql/queries/booking"
import { ButtonSecondary } from "../UI/Button"

const selectStyles = {
  loadingIndicator: provided => ({
    ...provided,
    fontSize: 7,
    color: mainTheme.primary,
  }),
}

type Props = {
  bookingId: string,
  languages: [],
  onNextClick: Function,
  onPrevClick: Function,
  rooms: [],
}

const LanguageRequest = ({ bookingId, languages, onNextClick, onPrevClick, rooms }: Props) => {
  const [selectedLanguages, setSelectedLanguages] = useState(
    languages.length
      ? languages.map(lang => ({
          value: allLanguages[lang].isoCode,
          label: allLanguages[lang].name,
        }))
      : [{ value: "en", label: "English" }],
  )

  const handleSelectLanguage = useCallback(newLanguages => setSelectedLanguages(newLanguages), [])
  const handleClickNext = useCallback(() => {
    onNextClick({ languages: selectedLanguages.map(lang => lang.value) })
  }, [onNextClick, selectedLanguages])

  const roomNames = useMemo(() => rooms.map(room => room.roomName), [rooms])
  const { loading, error, data } = useQuery(GET_AVAILABLE_LANGUAGES_FOR_BOOKING, {
    variables: {
      bookingId,
      roomNames,
    },
  })
  const languagesOptions = useMemo(() => {
    if (!data?.getAvailableLanguagesForBooking) return []
    return data.getAvailableLanguagesForBooking.map(lang => ({
      value: lang.isoCode,
      label: lang.name,
    }))
  }, [data])
  if (error) return <div>an error occured</div>
  return (
    <StepContainer>
      <Title>4. Language Request</Title>
      <div>
        If you’d like a sales manager that speaks another language than English, please choose from the list of
        available languages:
      </div>
      <SelectContainer margin="30px 0px 40px 0px">
        <Select
          className="languageSelect"
          isLoading={loading}
          isMulti
          options={languagesOptions}
          styles={selectStyles}
          value={selectedLanguages}
          onChange={handleSelectLanguage}
        />
      </SelectContainer>
      <ButtonsRow>
        <ButtonSecondary margin="0 20px 0 0" onClick={onPrevClick}>
          Previous
        </ButtonSecondary>
        <Button onClick={handleClickNext}>Next</Button>
      </ButtonsRow>
    </StepContainer>
  )
}

export default LanguageRequest
