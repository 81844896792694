import {
  addEvent,
  createMREvent,
  deleteMREvent,
  fetchBuyerSchegule,
  fetchColleaguesCalendar,
  nudgeBrand,
  removeEvent,
  updateMREvent,
} from "../../store/actions/clients"
import { cancelBuyerBooking, fetchBookingId } from "../../store/actions/schedules"
import {
  getAllBuyerColleagues,
  getBuyer,
  getBuyerColleagues,
  getBuyerEventsIds,
  getCancelBookingError,
  getCancelBookingPending,
  getCollegeauesCalendar,
  getRequestAddEventError,
  getRequestAddEventPending,
  getRequestBookingIdError,
  getRequestBookingIdPending,
  getRequestMREventError,
  getRequestMREventPending,
  getRequestNudgeEmailError,
  getRequestNudgeEmailPending,
  getRequestRemoveEventError,
  getRequestRemoveEventPending,
  getRequestScheduleError,
  getRequestSchedulePending,
  getRequestVerifyEmailError,
  getRequestVerifyEmailPending,
  getSchedule,
  getScheduleEvents,
  getSelectedEventId,
  getSharedRetailer,
} from "../../store/selectors"

import Component from "./Calendar"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { fetchTimezone } from "../../store/actions/salesSessions"
import { getFetchedTimezone } from "../../store/selectors/saleSessions"
import { verifyEmail } from "../../store/actions/user"

const selector = createStructuredSelector({
  allColleagues: getAllBuyerColleagues,
  buyer: getBuyer,
  retailer: getSharedRetailer,
  schedule: getSchedule,
  fashionEvents: getScheduleEvents,
  buyerEvents: getBuyerEventsIds,
  colleagues: getBuyerColleagues,
  allColleaguesCalendar: getCollegeauesCalendar,
  cancelPendeing: getCancelBookingPending,
  cancelPendingError: getCancelBookingError,
  requestScheduleError: getRequestScheduleError,
  requestSchedulePending: getRequestSchedulePending,
  requestAddEventPending: getRequestAddEventPending,
  requestAddEventError: getRequestAddEventError,
  requestRemoveEventPending: getRequestRemoveEventPending,
  requestRemoveEventError: getRequestRemoveEventError,
  requestMREventPending: getRequestMREventPending,
  requestMREventError: getRequestMREventError,
  requestBookingIdPending: getRequestBookingIdPending,
  requestBookingIdError: getRequestBookingIdError,
  selectedBookingId: getSelectedEventId,
  requestVerifyEmailPending: getRequestVerifyEmailPending,
  requestVerifyEmailError: getRequestVerifyEmailError,
  requestNudgeEmailPending: getRequestNudgeEmailPending,
  requestNudgeEmailError: getRequestNudgeEmailError,
  fetchedTimezone: getFetchedTimezone,
})

const actions = {
  onFetchTimezone: fetchTimezone,
  onCancelBooking: cancelBuyerBooking,
  onFetchSchedule: fetchBuyerSchegule,
  onFetchColleaguesCalendar: fetchColleaguesCalendar,
  onAddEvent: addEvent,
  onRemoveEvent: removeEvent,
  onSaveEvent: createMREvent,
  onRemoveMREvent: deleteMREvent,
  onUpdateMREvent: updateMREvent,
  onFetchBookingId: fetchBookingId,
  onNudgeBrand: nudgeBrand,
  onVerify: verifyEmail,
}

export const Calendar = connect(selector, actions)(Component)
