import {
  Bold,
  ButtonsRow,
  Info,
  InviteContainer,
  MrLogo,
  StepContainer,
} from "../styles"
import { Button, Div } from "../UI"
import React, { Component } from "react"

import ArrowIcon from "../../static/horizontal-resize-option.svg"
import CalendarIcon from "../../static/calendar-with-spring-binder-and-date-blocks.svg"
import { Link } from "react-router-dom"
import Logo from "../../static/logo.png"
import { PropTypes } from "prop-types"
import SmllLogo from "../../static/mR2.png"
import { SyncLoader } from "react-spinners"

class Invite extends Component {
  componentDidMount() {
    const { onFetchData, match } = this.props
    if (match.params && match.params.token) {
      onFetchData(match.params.token)
    }
  }

  render() {
    const { requestPending } = this.props
    return (
      <InviteContainer>
        <h2 style={{ textAlign: "center" }}>
          Book your buying appointments in one-click through ModaResa
        </h2>
        <StepContainer
          className="last-step"
          flexRow="flex-start"
          style={{ height: "450px" }}
        >
          {requestPending && (
            <Div.Flex justify="center" style={{ height: "100%" }}>
              <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
            </Div.Flex>
          )}
          {!requestPending && (
            <React.Fragment>
              <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
                Sync your calendar with ModaResa, so we can:
              </h3>
              <div style={{ paddingLeft: "15px" }}>
                <ul>
                  <li>
                    Add your buying appointments booked through ModaResa
                    automatically into your calendar
                  </li>
                  <li>
                    View events from your external calendar live in ModaResa to
                    display your accurate availability in real-time
                  </li>
                  <li>Allow you to reschedule bookings from your calendar</li>
                </ul>
              </div>
              <Info>
                <div>
                  <div>
                    <img alt="logo" src={Logo} />
                  </div>
                  <Bold>ModaResa</Bold>
                  <span>Real-time calendar</span>
                </div>
                <div className="icon">
                  <img alt="icon" src={ArrowIcon} />
                </div>
                <div>
                  <div>
                    <img alt="logo" src={CalendarIcon} />
                  </div>
                  <Bold>Your calendar</Bold>
                </div>
              </Info>
              <ButtonsRow>
                <Link to={"/sync/"}>
                  <Button>+ Connect Calendar</Button>
                </Link>
              </ButtonsRow>
            </React.Fragment>
          )}
        </StepContainer>
        <MrLogo>
          <p>powered by</p>
          <img alt="modaresa logo" src={SmllLogo} />
        </MrLogo>
      </InviteContainer>
    )
  }
}

Invite.propTypes = {
  inviteData: PropTypes.object,
  match: PropTypes.object,

  requestPending: PropTypes.bool,
  onFetchData: PropTypes.func,
}

export default Invite
