import { countries } from "./../config/countries"

export const getOneOf = (list, query) => {
  for (let i = 0; i < list.length; i++) {
    const item = list[i]
    if (item.key === "country") {
      if (
        countries[item.value] &&
        countries[item.value].name.toLowerCase().indexOf(query) === 0
      ) {
        return {
          value: countries[item.value].name,
          column: { label: item.label, key: item.key, iso: item.value },
        }
      }
    } else if (item.value.toLowerCase().indexOf(query) === 0) {
      return {
        value: item.value,
        column: { label: item.label, key: item.key },
      }
    }
  }
}

export const setOptionList = list => {
  const columnUnique = {}
  const valueUnique = {}
  const qtyValue = {}
  const filteredOptionsList = list.filter(item => {
    if (valueUnique[item.value] && columnUnique[item.column]) {
      qtyValue[item.value + item.column] =
        qtyValue[item.value + item.column] + 1
    } else {
      valueUnique[item.value] = true
      columnUnique[item.column] = true
      qtyValue[item.value + item.column] = 1
    }
    return item
  })

  const optionsList = filteredOptionsList.map(item => {
    item.label = `${item.label} (${qtyValue[item.value + item.column]})`
    return item
  })
  return optionsList
}

export const uniqueList = list => {
  const valueUnique = {}
  const optionsList = list.filter(item => {
    if (!valueUnique[item.label]) {
      valueUnique[item.label] = true
      return item
    }
    return false
  })

  return optionsList
}

export const filterByRetailer = (value, booking, clients, retailers) => {
  if (clients.byId[booking.clients[0]]) {
    const retailerId = clients.byId[booking.clients[0]].retailerId
    if (value.toLowerCase() === retailers.byId[retailerId].name.toLowerCase()) {
      return true
    }
  }
}

export const filterByCountry = (value, booking, clients, retailers) => {
  if (clients.byId[booking.clients[0]]) {
    const retailerId = clients.byId[booking.clients[0]].retailerId
    if (
      value.toLowerCase() === retailers.byId[retailerId].storeAddress.country
    ) {
      return true
    }
  }
}

export const filterByBuyerName = (value, clients, id) => {
  const firstName = clients.byId[id].firstName
  if (firstName.toLowerCase().indexOf(value) === 0) {
    return true
  }
}
