import styled from "styled-components"

export const Progressbar = styled.div`
  position: relative;
  overflow: hidden;
  height: 10px;
  background-color: ${props => props.theme.veryLightGray};
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${props => props.progress * 18}%;
    //background-color: ${props => props.theme.primary};
    background-color: rgb(255,229,228);
    transition: width 0.3s ease;
  }
`
