import * as Api from "../../helpers/api"

import {
  CHANGE_PASSWORD,
  LOGIN,
  RESET_PASSWORD,
  SEND_RESET_EMAIL,
  SIGNUP,
  SOCIAL_LOGIN,
  VERIFY_TOKEN,
  SETUP_AUTH_TOKEN,
  LOGOUT,
} from "../actions/auth"
import { all, call, cancelled, put } from "redux-saga/effects"
import { onToastError, onToastSuccess } from "../../helpers/toast"

import { FETCH_COLLEAGUES } from "../actions/clients"
import { FETCH_DB_DATA } from "../actions/user"
import { FETCH_SHARED_RETAILER } from "../actions/retailers"
import { normalizeData } from "../../helpers/normalize"
import { takeLatest } from "../../helpers/saga"
import { parseJwt } from "../../helpers/auth"

const LOGIN_CRISP = buyer => {
  window.$crisp.push([
    "set",
    "user:nickname",
    `${buyer.firstName} ${buyer.lastName}`,
  ])
  window.$crisp.push(["set", "user:email", [`${buyer.email}`]])
  window.$crisp.push(["set", "session:segments", [[`BUYER`]]])
}

function* authorize({ payload }) {
  try {
    const { values, history } = payload
    const response = yield call(Api.login, values)

    const { accessToken, buyer } = response
    const dbData = yield all([
      call(Api.fetchSharedRetailer, {
        token: accessToken,
        retailerId: buyer.retailerId,
      }),
      call(Api.fetchColleagues, { token: accessToken, buyerId: buyer._id }),
    ])
    const colleagues = normalizeData(dbData[1].colleagues)
    const sharedRetailer = dbData[0]

    yield put({ type: FETCH_COLLEAGUES.SUCCESS, payload: colleagues })
    yield put({ type: FETCH_SHARED_RETAILER.SUCCESS, payload: sharedRetailer })
    yield put({ type: LOGIN.SUCCESS, payload: response })
    yield put({ type: FETCH_DB_DATA.SUCCESS })

    LOGIN_CRISP(buyer)
    history.replace("/login")
  } catch (error) {
    let customError = "Oops, something went wrong"
    if (
      error.response &&
      error.response.data.message &&
      error.response.data.errorType === "auth"
    ) {
      customError = error.response.data.message
    }
    onToastError(customError)

    const errosMsg =
      error.message ||
      (error.response &&
        error.response.data.errors &&
        error.response.data.errors.message)
    yield put({ type: LOGIN.FAILURE, payload: errosMsg })
  } finally {
    yield cancelled()
  }
}

function* loginFlow() {
  yield takeLatest(LOGIN.REQUEST, authorize)
}

function* registration({ payload }) {
  try {
    const { data, history } = payload
    const response = yield call(Api.signup, data)

    const { accessToken, buyer } = response
    const dbData = yield all([
      call(Api.fetchSharedRetailer, {
        token: accessToken,
        retailerId: buyer.retailerId,
      }),
      call(Api.fetchColleagues, { token: accessToken, buyerId: buyer._id }),
    ])
    const colleagues = normalizeData(dbData[1].colleagues)
    const sharedRetailer = dbData[0]

    yield put({ type: FETCH_COLLEAGUES.SUCCESS, payload: colleagues })
    yield put({ type: FETCH_SHARED_RETAILER.SUCCESS, payload: sharedRetailer })
    yield put({ type: SIGNUP.SUCCESS, payload: response })
    yield put({ type: FETCH_DB_DATA.SUCCESS })

    history.replace("/login")
  } catch (error) {
    let customError = "Oops, something went wrong"
    if (
      error.response &&
      error.response.data.message &&
      error.response.data.errorType === "auth"
    ) {
      customError = error.response.data.message
    }
    onToastError(customError)

    const errosMsg =
      error.message ||
      (error.response &&
        error.response.data.errors &&
        error.response.data.errors.message)
    yield put({ type: SIGNUP.FAILURE, payload: errosMsg })
  }
}

function* registrationFlow() {
  yield takeLatest(SIGNUP.REQUEST, registration)
}

function* socialLogin({ payload }) {
  try {
    const { accessToken, buyer } = payload
    const dbData = yield all([
      call(Api.fetchSharedRetailer, {
        token: accessToken,
        retailerId: buyer.retailerId,
      }),
      call(Api.fetchColleagues, { token: accessToken, buyerId: buyer._id }),
    ])
    const colleagues = normalizeData(dbData[1].colleagues)
    const sharedRetailer = dbData[0]

    yield put({ type: FETCH_COLLEAGUES.SUCCESS, payload: colleagues })
    yield put({ type: FETCH_SHARED_RETAILER.SUCCESS, payload: sharedRetailer })
    yield put({ type: SOCIAL_LOGIN.SUCCESS, payload })
    yield put({ type: FETCH_DB_DATA.SUCCESS })

    LOGIN_CRISP(buyer)
  } catch (error) {
    let customError = "Oops, something went wrong"
    if (
      error.response &&
      error.response.data.message &&
      error.response.data.errorType === "auth"
    ) {
      customError = error.response.data.message
    }
    onToastError(customError)

    const errosMsg =
      error.message ||
      (error.response &&
        error.response.data.errors &&
        error.response.data.errors.message)
    yield put({ type: SOCIAL_LOGIN.FAILURE, payload: errosMsg })
  }
}

function* socialLoginFlow() {
  yield takeLatest(SOCIAL_LOGIN.REQUEST, socialLogin)
}

function* resetPassword({ payload }) {
  try {
    const { data, history } = payload
    yield call(Api.resetPassword, data)

    yield put({ type: RESET_PASSWORD.SUCCESS })
    onToastSuccess("Password has been changed")
    yield call(history.push, "/login")
  } catch (error) {
    let customError = "Oops, something went wrong"
    if (
      error.response &&
      error.response.data.message &&
      error.response.data.errorType === "custom"
    ) {
      customError = error.response.data.message
    }
    onToastError(customError)

    const errosMsg =
      error.message ||
      (error.response &&
        error.response.data.errors &&
        error.response.data.errors.message)
    yield put({ type: RESET_PASSWORD.FAILURE, payload: errosMsg })
  }
}

function* resetPasswordFlow() {
  yield takeLatest(RESET_PASSWORD.REQUEST, resetPassword)
}

function* changePassword({ payload }) {
  try {
    const { data } = payload
    yield call(Api.changePassword, data)

    yield put({ type: CHANGE_PASSWORD.SUCCESS })
    onToastSuccess("Password has been changed")
  } catch (error) {
    let customError = "Oops, something went wrong"
    if (
      error.response &&
      error.response.data.message &&
      error.response.data.errorType === "custom"
    ) {
      customError = error.response.data.message
    }
    onToastError(customError)

    const errosMsg =
      error.message ||
      (error.response &&
        error.response.data.errors &&
        error.response.data.errors.message)
    yield put({ type: CHANGE_PASSWORD.FAILURE, payload: errosMsg })
  }
}

function* changePasswordFlow() {
  yield takeLatest(CHANGE_PASSWORD.REQUEST, changePassword)
}

function* verifyToken({ payload }) {
  try {
    const { data } = payload
    yield call(Api.verifyToken, data)

    yield put({ type: VERIFY_TOKEN.SUCCESS })
  } catch (error) {
    const { history } = payload
    let customError = "Oops, something went wrong"
    if (
      error.response &&
      error.response.data.message &&
      error.response.data.errorType === "custom"
    ) {
      customError = error.response.data.message
    }
    onToastError(customError)

    const errosMsg =
      error.message ||
      (error.response &&
        error.response.data.errors &&
        error.response.data.errors.message)
    yield put({ type: VERIFY_TOKEN.FAILURE, payload: errosMsg })
    yield call(history.push, "/login")
  }
}

function* verifyTokenFlow() {
  yield takeLatest(VERIFY_TOKEN.REQUEST, verifyToken)
}

function* sendResetPasswordEmail({ payload }) {
  try {
    const { data, history } = payload

    const response = yield call(Api.sendResetEmail, data)

    yield put({ type: SEND_RESET_EMAIL.SUCCESS })
    onToastSuccess(
      `Reset password email has been sent to ${response.userEmail}`,
    )
    yield call(history.push, "/login")
  } catch (error) {
    let customError = "Oops, something went wrong"
    if (
      error.response &&
      error.response.data.message &&
      error.response.data.errorType === "custom"
    ) {
      customError = error.response.data.message
    }
    onToastError(customError)

    const errosMsg =
      error.message ||
      (error.response &&
        error.response.data.errors &&
        error.response.data.errors.message)
    yield put({ type: SEND_RESET_EMAIL.FAILURE, payload: errosMsg })
  }
}

function* sendResetPasswordEmailFlow() {
  yield takeLatest(SEND_RESET_EMAIL.REQUEST, sendResetPasswordEmail)
}

function* saveAccessTokenFlow() {
  yield takeLatest(LOGIN.SUCCESS, ({ payload: { accessToken } }) => {
    localStorage.setItem("modaresa-access-token", accessToken)
  })
}

function* setupAuthToken({ payload: token }) {
  const { userId } = parseJwt(token)
  yield put({ type: LOGOUT })
  const buyer = yield call(Api.fetchSharedBuyerByUserId, {
    token,
    id: userId,
  })
  const loginPayload = {
    accessToken: token,
    buyer,
    user: {
      _id: userId,
    },
  }
  const dbData = yield all([
    call(Api.fetchSharedRetailer, {
      token,
      retailerId: buyer.retailerId,
    }),
    call(Api.fetchColleagues, { token, buyerId: buyer._id }),
  ])
  const colleagues = normalizeData(dbData[1].colleagues)
  const sharedRetailer = dbData[0]

  yield put({ type: FETCH_COLLEAGUES.SUCCESS, payload: colleagues })
  yield put({ type: FETCH_SHARED_RETAILER.SUCCESS, payload: sharedRetailer })
  yield put({ type: FETCH_DB_DATA.SUCCESS })
  yield put({ type: LOGIN.SUCCESS, payload: loginPayload })
  yield put({ type: SETUP_AUTH_TOKEN.SUCCESS })
}

function* setupAuthTokenFlow() {
  yield takeLatest(SETUP_AUTH_TOKEN.REQUEST, setupAuthToken)
}

export default [
  loginFlow,
  registrationFlow,
  socialLoginFlow,
  resetPasswordFlow,
  verifyTokenFlow,
  sendResetPasswordEmailFlow,
  changePasswordFlow,
  saveAccessTokenFlow,
  setupAuthTokenFlow,
]
