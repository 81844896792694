import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { setContext } from "@apollo/client/link/context"
import { createUploadLink } from "apollo-upload-client"
import customFetch from "../helpers/customFetch"
import { GRAPHQL_URL } from "../config"

/* When implementing subscriptions is needed
const wsLink = new WebSocketLink({
  uri: SUBSCRIPTIONS_LINK,
  options: {
    reconnect: true,
    reconnectionAttempts: 50,
    timeout: 20000,
    connectionParams: () => {
      const token = localStorage.getItem("modaresa-access-token")
      return { Authorization: token ? `Bearer ${token}` : undefined }
    },
  },
}) */

/* When implementing subscriptions is needed
const queryLink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === "OperationDefinition" && operation === "subscription"
  },
  // wsLink,
  httpLink,
)*/

const httpLink = createUploadLink({
  uri: GRAPHQL_URL,
  fetch: customFetch,
  credentials: "same-origin",
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("modaresa-access-token")
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(e => {
      if (!e) return
      const { message: errorMsg } = e
      console.error(errorMsg)
    })
  }
  if (networkError) {
    if (
      networkError &&
      networkError.bodyText &&
      networkError.bodyText.includes("invalid signature")
    ) {
      /* Fail safely for now, we don't want to show a popup to users with previous invalid tokens */
    } else {
      console.error(networkError)
    }
  }
})

const link = ApolloLink.from([authLink, errorLink, httpLink])

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    dataIdFromObject: object => object.id,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
})

export default client
//export { wsLink }
