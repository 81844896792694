// TODO: Fix the duplicate keys here because this means we are missing languages
/* eslint-disable no-dupe-keys */
export const languages = {
  ab: { name: "Abkhazian", isoCode: "ab" },
  aa: { name: "Afar", isoCode: "aa" },
  af: { name: "Afrikaans", isoCode: "af" },
  ak: { name: "Akan", isoCode: "ak" },
  sq: { name: "Albanian", isoCode: "sq" },
  am: { name: "Amharic", isoCode: "am" },
  ar: { name: "Arabic", isoCode: "ar" },
  an: { name: "Aragonese", isoCode: "an" },
  hy: { name: "Armenian", isoCode: "hy" },
  as: { name: "Assamese", isoCode: "as" },
  av: { name: "Avaric", isoCode: "av" },
  ae: { name: "Avestan", isoCode: "ae" },
  ay: { name: "Aymara", isoCode: "ay" },
  az: { name: "Azerbaijani", isoCode: "az" },
  bm: { name: "Bambara", isoCode: "bm" },
  ba: { name: "Bashkir", isoCode: "ba" },
  eu: { name: "Basque", isoCode: "eu" },
  be: { name: "Belarusian", isoCode: "be" },
  bn: { name: "Bengali", isoCode: "bn" },
  bh: { name: "Bihari", isoCode: "bh" },
  bi: { name: "Bislama", isoCode: "bi" },
  bs: { name: "Bosnian", isoCode: "bs" },
  br: { name: "Breton", isoCode: "br" },
  bg: { name: "Bulgarian", isoCode: "bg" },
  my: { name: "Burmese", isoCode: "my" },
  ca: { name: "Catalan", isoCode: "ca" },
  ch: { name: "Chamorro", isoCode: "ch" },
  ce: { name: "Chechen", isoCode: "ce" },
  ny: { name: "Chichewa", isoCode: "ny" },
  zh: { name: "Chinese", isoCode: "zh" },
  "zh-Hans": { name: "Chinese(Simplified)", isoCode: "zh-Hans" },
  "zh-Hant": { name: "Chinese", isoCode: "zh-Hant" },
  cv: { name: "Chuvash", isoCode: "cv" },
  kw: { name: "Cornish", isoCode: "kw" },
  co: { name: "Corsican", isoCode: "co" },
  cr: { name: "Cree", isoCode: "cr" },
  hr: { name: "Croatian", isoCode: "hr" },
  cs: { name: "Czech", isoCode: "cs" },
  da: { name: "Danish", isoCode: "da" },
  dv: { name: "Divehi", isoCode: "dv" },
  nl: { name: "Dutch", isoCode: "nl" },
  dz: { name: "Dzongkha", isoCode: "dz" },
  en: { name: "English", isoCode: "en" },
  eo: { name: "Esperanto", isoCode: "eo" },
  et: { name: "Estonian", isoCode: "et" },
  ee: { name: "Ewe", isoCode: "ee" },
  fo: { name: "Faroese", isoCode: "fo" },
  fj: { name: "Fijian", isoCode: "fj" },
  fi: { name: "Finnish", isoCode: "fi" },
  fr: { name: "French", isoCode: "fr" },
  ff: { name: "Fula", isoCode: "ff" },
  gl: { name: "Galician", isoCode: "gl" },
  gd: { name: "Gaelic", isoCode: "gd" },
  gv: { name: "Gaelic", isoCode: "gv" },
  ka: { name: "Georgian", isoCode: "ka" },
  de: { name: "German", isoCode: "de" },
  el: { name: "Greek", isoCode: "el" },
  kl: { name: "Greenlandic", isoCode: "kl" },
  gn: { name: "Guarani", isoCode: "gn" },
  gu: { name: "Gujarati", isoCode: "gu" },
  ht: { name: "Haitian", isoCode: "ht" },
  ha: { name: "Hausa", isoCode: "ha" },
  he: { name: "Hebrew", isoCode: "he" },
  hz: { name: "Herero", isoCode: "hz" },
  hi: { name: "Hindi", isoCode: "hi" },
  ho: { name: "Hiri", isoCode: "ho" },
  hu: { name: "Hungarian", isoCode: "hu" },
  is: { name: "Icelandic", isoCode: "is" },
  io: { name: "Ido", isoCode: "io" },
  ig: { name: "Igbo", isoCode: "ig" },
  in: { name: "Indonesian", isoCode: "in" },
  ia: { name: "Interlingua", isoCode: "ia" },
  ie: { name: "Interlingue", isoCode: "ie" },
  iu: { name: "Inuktitut", isoCode: "iu" },
  ik: { name: "Inupiak", isoCode: "ik" },
  ga: { name: "Irish", isoCode: "ga" },
  it: { name: "Italian", isoCode: "it" },
  ja: { name: "Japanese", isoCode: "ja" },
  jv: { name: "Javanese", isoCode: "jv" },
  kn: { name: "Kannada", isoCode: "kn" },
  kr: { name: "Kanuri", isoCode: "kr" },
  ks: { name: "Kashmiri", isoCode: "ks" },
  kk: { name: "Kazakh", isoCode: "kk" },
  km: { name: "Khmer", isoCode: "km" },
  ki: { name: "Kikuyu", isoCode: "ki" },
  rw: { name: "Kinyarwanda", isoCode: "rw" },
  rn: { name: "Kirundi", isoCode: "rn" },
  ky: { name: "Kyrgyz", isoCode: "ky" },
  kv: { name: "Komi", isoCode: "kv" },
  kg: { name: "Kongo", isoCode: "kg" },
  ko: { name: "Korean", isoCode: "ko" },
  ku: { name: "Kurdish", isoCode: "ku" },
  kj: { name: "Kwanyama", isoCode: "kj" },
  lo: { name: "Lao", isoCode: "lo" },
  la: { name: "Latin", isoCode: "la" },
  lv: { name: "Latvian", isoCode: "lv" },
  li: { name: "Limburgish", isoCode: "li" },
  ln: { name: "Lingala", isoCode: "ln" },
  lt: { name: "Lithuanian", isoCode: "lt" },
  lu: { name: "Luga-Katanga", isoCode: "lu" },
  lg: { name: "Luganda", isoCode: "lg" },
  lb: { name: "Luxembourgish", isoCode: "lb" },
  glv: { name: "Manx", isoCode: "glv" },
  mk: { name: "Macedonian", isoCode: "mk" },
  mg: { name: "Malagasy", isoCode: "mg" },
  ms: { name: "Malay", isoCode: "ms" },
  ml: { name: "Malayalam", isoCode: "ml" },
  mt: { name: "Maltese", isoCode: "mt" },
  mi: { name: "Maori", isoCode: "mi" },
  mr: { name: "Marathi", isoCode: "mr" },
  mh: { name: "Marshallese", isoCode: "mh" },
  mo: { name: "Moldavian", isoCode: "mo" },
  mn: { name: "Mongolian", isoCode: "mn" },
  na: { name: "Nauru", isoCode: "na" },
  nv: { name: "Navajo", isoCode: "nv" },
  ng: { name: "Ndonga", isoCode: "ng" },
  nd: { name: "Northern", isoCode: "nd" },
  ne: { name: "Nepali", isoCode: "ne" },
  no: { name: "Norwegian", isoCode: "no" },
  ii: { name: "Nuosu", isoCode: "ii" },
  oc: { name: "Occitan", isoCode: "oc" },
  oj: { name: "Ojibwe", isoCode: "oj" },
  or: { name: "Oriya", isoCode: "or" },
  os: { name: "Ossetian", isoCode: "os" },
  pi: { name: "Pāli", isoCode: "pi" },
  ps: { name: "Pashto", isoCode: "ps" },
  fa: { name: "Persian", isoCode: "fa" },
  pl: { name: "Polish", isoCode: "pl" },
  pt: { name: "Portuguese", isoCode: "pt" },
  pa: { name: "Punjabi", isoCode: "pa" },
  qu: { name: "Quechua", isoCode: "qu" },
  rm: { name: "Romansh", isoCode: "rm" },
  ro: { name: "Romanian", isoCode: "ro" },
  ru: { name: "Russian", isoCode: "ru" },
  se: { name: "Sami", isoCode: "se" },
  sm: { name: "Samoan", isoCode: "sm" },
  sg: { name: "Sango", isoCode: "sg" },
  sa: { name: "Sanskrit", isoCode: "sa" },
  sr: { name: "Serbian", isoCode: "sr" },
  sh: { name: "Serbo-Croatian", isoCode: "sh" },
  st: { name: "Sesotho", isoCode: "st" },
  tn: { name: "Setswana", isoCode: "tn" },
  sn: { name: "Shona", isoCode: "sn" },
  Yi: { name: "Sichuan", isoCode: "Yi" },
  sd: { name: "Sindhi", isoCode: "sd" },
  si: { name: "Sinhalese", isoCode: "si" },
  sk: { name: "Slovak", isoCode: "sk" },
  sl: { name: "Slovenian", isoCode: "sl" },
  so: { name: "Somali", isoCode: "so" },
  nr: { name: "Southern", isoCode: "nr" },
  es: { name: "Spanish", isoCode: "es" },
  su: { name: "Sundanese", isoCode: "su" },
  sw: { name: "Swahili", isoCode: "sw" },
  ss: { name: "Swati", isoCode: "ss" },
  sv: { name: "Swedish", isoCode: "sv" },
  tl: { name: "Tagalog", isoCode: "tl" },
  ty: { name: "Tahitian", isoCode: "ty" },
  tg: { name: "Tajik", isoCode: "tg" },
  ta: { name: "Tamil", isoCode: "ta" },
  tt: { name: "Tatar", isoCode: "tt" },
  te: { name: "Telugu", isoCode: "te" },
  th: { name: "Thai", isoCode: "th" },
  bo: { name: "Tibetan", isoCode: "bo" },
  ti: { name: "Tigrinya", isoCode: "ti" },
  to: { name: "Tonga", isoCode: "to" },
  ts: { name: "Tsonga", isoCode: "ts" },
  tr: { name: "Turkish", isoCode: "tr" },
  tk: { name: "Turkmen", isoCode: "tk" },
  tw: { name: "Twi", isoCode: "tw" },
  ug: { name: "Uyghur", isoCode: "ug" },
  uk: { name: "Ukrainian", isoCode: "uk" },
  ur: { name: "Urdu", isoCode: "ur" },
  uz: { name: "Uzbek", isoCode: "uz" },
  ve: { name: "Venda", isoCode: "ve" },
  vi: { name: "Vietnamese", isoCode: "vi" },
  vo: { name: "Volapük", isoCode: "vo" },
  wa: { name: "Wallon", isoCode: "wa" },
  cy: { name: "Welsh", isoCode: "cy" },
  wo: { name: "Wolof", isoCode: "wo" },
  fy: { name: "Western", isoCode: "fy" },
  xh: { name: "Xhosa", isoCode: "xh" },
  yi: { name: "Yiddish", isoCode: "yi" },
  yo: { name: "Yoruba", isoCode: "yo" },
  za: { name: "Zhuang", isoCode: "za" },
  zu: { name: "Zulu", isoCode: "zu" },
}
