import { REST_API_URL } from "../../config"
import axios from "axios"

export * from "./invite"
export * from "./session"
export * from "./schedule"
export * from "./brand"
export * from "./staff"
export * from "./client"
export * from "./retailers"

export const login = async creds => {
  const response = await axios.post(`${REST_API_URL}/auth/login`, creds)
  return response.data
}

export const signup = async creds => {
  const response = await axios.post(`${REST_API_URL}/auth/register`, creds)
  return response.data
}

export const resetPassword = async data => {
  const response = await axios.post(`${REST_API_URL}/auth/resetpassword`, data)
  return response.data
}
export const changePassword = async data => {
  const id = data.userId
  const response = await axios.post(
    `${REST_API_URL}/auth/changePassword/${id}`,
    data,
  )
  return response.data
}

export const sendResetEmail = async data => {
  const response = await axios.post(
    `${REST_API_URL}/auth/resetPassword/email`,
    data,
  )
  return response.data
}

export const verifyToken = async data => {
  const response = await axios.post(
    `${REST_API_URL}/auth/resetpassword/verifytoken`,
    data,
  )
  return response.data
}

export const fetchUser = async ({ id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/staff/${id}`, config)

  return response.data
}

export const fetchBrand = async ({ id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(`${REST_API_URL}/brands/${id}`, config)

  return response.data
}

export const fetchStaff = async ({ brandId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(
    `${REST_API_URL}/staff?brandId=${brandId}`,
    config,
  )

  return response.data
}

export const fetchTimezone = async ({ timestamp, lat, lng }) => {
  const response = await axios.get(
    `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=AIzaSyC3boOLqNgMBUDAzCemBK_B0-yO8u7VfmU`,
  )

  return response.data
}
