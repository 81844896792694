import PropTypes from "prop-types"
import React from "react"

function NoMatch({ location }) {
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  )
}

NoMatch.propTypes = {
  location: PropTypes.object,
}

export default NoMatch
