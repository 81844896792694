import { createAsyncAction } from "../../helpers/redux"

export const LOGIN = createAsyncAction("LOGIN")
export const login = payload => ({
  type: LOGIN.REQUEST,
  payload,
})

export const SIGNUP = createAsyncAction("SIGNUP")
export const signup = (data, history) => ({
  type: SIGNUP.REQUEST,
  payload: { data, history },
})

export const SOCIAL_LOGIN = createAsyncAction("SOCIAL_LOGIN")
export const socialLogin = payload => ({
  type: SOCIAL_LOGIN.REQUEST,
  payload,
})

export const finishLogin = payload => ({
  type: LOGIN.SUCCESS,
  payload,
})

export const LOGOUT = "LOGOUT"
export const logout = () => {
  window.localStorage.removeItem("firstTime")
  window.localStorage.removeItem("timeZoneModal")
  return {
    type: LOGOUT,
  }
}

export const RESET_PASSWORD = createAsyncAction("RESET_PASSWORD")
export const resetPassword = (data, history) => ({
  type: RESET_PASSWORD.REQUEST,
  payload: { data, history },
})

export const CHANGE_PASSWORD = createAsyncAction("CHANGE_PASSWORD")
export const changePass = (data, history) => ({
  type: CHANGE_PASSWORD.REQUEST,
  payload: { data, history },
})

export const VERIFY_TOKEN = createAsyncAction("VERIFY_TOKEN")
export const verifyToken = (data, history) => ({
  type: VERIFY_TOKEN.REQUEST,
  payload: { data, history },
})

export const SEND_RESET_EMAIL = createAsyncAction("SEND_RESET_EMAIL")
export const sendResetEmail = (data, history) => ({
  type: SEND_RESET_EMAIL.REQUEST,
  payload: { data, history },
})

export const SETUP_AUTH_TOKEN = createAsyncAction("SETUP_AUTH_TOKEN")
export const setupAuthToken = payload => ({
  type: SETUP_AUTH_TOKEN.REQUEST,
  payload,
})
