import styled from "styled-components"

const Error = styled.span`
  ${p => `
    display: ${p.block ? "block" : "inline"};
    color: ${p.theme.danger};
    margin: ${p.margin}
  `}
`

Error.defaultProps = {
  margin: "0",
}
export default Error
