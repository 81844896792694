import moment from "moment"

export const getHourList = (format = "12") => {
  const today = moment()
    .utc()
    .set({ hour: 0, minute: 0, second: 0 })
  const hoursList = []
  for (let i = 1; i <= 48; i++) {
    const value =
      format === "12"
        ? today.utc().format("HH:mm")
        : today.utc().format("hh:mm A")
    hoursList.push({ value, label: value, timestamp: today.unix() })
    today.add(30, "minutes")
  }
  return hoursList
}

export const getTimeOptions = dayDate => {
  const today = moment.utc(dayDate, "X")
  const hoursList = []
  for (let i = 1; i <= 48; i++) {
    const value = today.utc().format("HH:mm")
    hoursList.push({ value, label: value, timestamp: today.unix() })
    today.add(30, "minutes")
  }
  return hoursList
}

export const CreateDateOptions = (dateForm, dateTo) => {
  const dateOptions = []

  const dateFromx = moment.utc(dateForm, "X")
  const dateTox = moment.utc(dateTo, "X")

  for (let i = 0; i < 365; i++) {
    if (!(dateFromx.diff(dateTox) > 0)) {
      dateOptions.push({
        value: dateFromx.unix(),
        label: dateFromx.format("dddd, Do MMM"),
      })
      dateFromx.add(1, "day")
    } else {
      break
    }
  }

  return dateOptions
}
