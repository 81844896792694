import * as Api from "../../helpers/api"

import { FETCH_CLIENT, FETCH_CLIENTS } from "../actions/clients"
import {
  FETCH_INVITE_DATA,
  FETCH_INVITE_LINK,
  FETCH_TOKEN_DATA,
  SEND_INVITE_EMAIL,
} from "../actions/invites"
import { FETCH_LANGUAGES, FETCH_SALE_SESSION } from "../actions/salesSessions"
import { all, call, put } from "redux-saga/effects"
import { createIcs, download as downloadIcs } from "../../helpers/ics"
import { onToastError, onToastSuccess } from "./../../helpers/toast"

import { FETCH_BRAND } from "../actions/brand"
import { FETCH_RETAILER } from "../actions/retailers"
import { normalizeData } from "../../helpers/normalize"
import { takeLatest } from "../../helpers/saga"

function* fetchInviteData({ payload }) {
  const { id, download } = payload
  try {
    const invite = yield call(Api.fetchInviteData, { id })
    const dbData = yield all([
      call(Api.fetchSession, { id: invite.sessionId }),
      call(Api.fetchClient, { id: invite.clientIds[0] }),
      call(Api.fetchBrand, { id: invite.brandId }),
    ])

    const { retailerId, brandId } = dbData[1]
    yield put({ type: FETCH_CLIENT.SUCCESS, payload: dbData[1] })
    let clients = yield call(Api.fetchClients, { retailerId, brandId })
    const retailer = yield call(Api.fetchRetailer, { retailerId })
    clients = normalizeData(clients)

    // TODO: When virtual meeting settings will be moved to
    // each room separately, remove this code
    invite.events.forEach(event => {
      if (event.videoTool) {
        invite.videoTool = event.videoTool
      }
      if (event.meetingApproach) {
        invite.meetingApproach = event.meetingApproach
      }
    })

    const eventsArray = [...invite.events, ...invite.jokerRequests]
    const rooms = dbData[0].rooms.map(room => {
      const event = eventsArray.find(event => event.room === room.name)
      if (event) {
        return {
          roomName: event.room,
          clients: event.clients,
          length: (event.endTime - event.startTime) / 60,
        }
      }
      return {
        room: room.name,
        clients: [null],
        length: 0,
      }
    })
    yield put({
      type: FETCH_INVITE_DATA.SUCCESS,
      payload: { bookingsData: { ...invite, rooms } },
    })
    yield put({ type: FETCH_CLIENTS.SUCCESS, payload: clients })
    yield put({ type: FETCH_SALE_SESSION.SUCCESS, payload: dbData[0] })
    yield put({ type: FETCH_BRAND.SUCCESS, payload: dbData[2] })
    yield put({ type: FETCH_RETAILER.SUCCESS, payload: retailer })
    if (download) {
      const client = clients.byId[invite.clientId]
      const { timezone, name, address } = dbData[0]
      const { name: brandName } = dbData[2]
      const ics = createIcs(
        invite,
        timezone,
        address,
        name,
        retailer.name,
        brandName,
        client.email,
      )
      downloadIcs(`${retailer.name}-${brandName}`, ics)
    }
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message ||
      (error.response &&
        error.response.data.errors &&
        error.response.data.errors.message)
    yield put({
      type: FETCH_INVITE_DATA.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchInviteDataFlow() {
  yield takeLatest(FETCH_INVITE_DATA.REQUEST, fetchInviteData)
}

function* fetchLanguages({ payload }) {
  const { sessionId } = payload
  try {
    const response = yield call(Api.fetchLanguages, { sessionId })

    yield put({ type: FETCH_LANGUAGES.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message ||
      (error.response &&
        error.response.data.errors &&
        error.response.data.errors.message)
    yield put({
      type: FETCH_LANGUAGES.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchLanguagesFlow() {
  yield takeLatest(FETCH_LANGUAGES.REQUEST, fetchLanguages)
}

function* fetchInviteLink({ payload }) {
  try {
    const response = yield call(Api.fetchInviteLink, payload)

    yield put({ type: FETCH_INVITE_LINK.SUCCESS, payload: response.inviteLink })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message ||
      (error.response &&
        error.response.data.errors &&
        error.response.data.errors.message)
    yield put({
      type: FETCH_INVITE_LINK.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchInviteLinkFlow() {
  yield takeLatest(FETCH_INVITE_LINK.REQUEST, fetchInviteLink)
}

function* sendInviteEmail({ payload }) {
  try {
    const response = yield call(Api.sendInviteEmail, payload)

    onToastSuccess("Invite has been sent")
    yield put({ type: SEND_INVITE_EMAIL.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message ||
      (error.response &&
        error.response.data.errors &&
        error.response.data.errors.message)
    yield put({
      type: SEND_INVITE_EMAIL.FAILURE,
      payload: errosMsg,
    })
  }
}

function* sendInviteEmailFlow() {
  yield takeLatest(SEND_INVITE_EMAIL.REQUEST, sendInviteEmail)
}

function* fetchTokenData({ payload }) {
  const { token } = payload
  try {
    const response = yield call(Api.fetchTokenData, { token })

    yield put({ type: FETCH_TOKEN_DATA.SUCCESS, payload: { ...response.data } })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message ||
      (error.response &&
        error.response.data.errors &&
        error.response.data.errors.message)
    yield put({
      type: FETCH_TOKEN_DATA.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchTokenDataFlow() {
  yield takeLatest(FETCH_TOKEN_DATA.REQUEST, fetchTokenData)
}

export default [
  fetchInviteDataFlow,
  fetchLanguagesFlow,
  fetchInviteLinkFlow,
  sendInviteEmailFlow,
  fetchTokenDataFlow,
]
