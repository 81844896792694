import React, { useEffect, useState } from "react"

import { AddButton, ClientRow, ClientsList, DeleteButton, Message, RoomoOuter, SelectRow } from "./styles"

import PropTypes from "prop-types"

import Select from "react-select"
import { ValidationError } from "../../UI/Input"
import { mainTheme } from "./../../../constants/themes"

const Options = [
  { value: 30, label: "0.5 hour" },
  { value: 60, label: "1 hour" },
  { value: 90, label: "1.5 hours" },
  { value: 120, label: "2 hours" },
]

export const Room = props => {
  const {
    clients,
    onAddColleague,
    roomName,
    meetingLength,
    onChange,
    onChangeLength,
    visibleTimeSelect,
    onDeleteColleague,
    errors,
    allClients,
    clientLength,
  } = props

  const [selectedClient, setSelectedClient] = useState(null)

  let timeOptions
  if (meetingLength > 120) {
    timeOptions = Options.concat([{ value: meetingLength, label: `${meetingLength / 60} hours` }])
  } else {
    timeOptions = Options
  }

  const allClientsIds = allClients.allIds.filter(id => {
    const client = allClients.byId[id]
    return client.dbStatus !== "archived"
  })

  const Buyers = clients.map((id, index) => {
    const client = id
      ? {
          value: id,
          label: `${allClients.byId[id].firstName} ${allClients.byId[id].lastName}`,
        }
      : null

    return (
      <ClientRow first={index === 0} key={index}>
        <Select
          className="peopleSelect"
          filterOption={option => {
            if (clients.findIndex(id => id === option.value) > -1) {
              return false
            }
            return true
          }}
          menuPortalTarget={document.body}
          options={allClientsIds.map(id => {
            const client = allClients.byId[id]
            return {
              value: id,
              label: `${client.firstName} ${client.lastName}`,
            }
          })}
          styles={{
            control: base => (errors ? { ...base, borderColor: mainTheme.strawberry } : { ...base }),
          }}
          value={client}
          onChange={option => onChange(option.value, roomName, index)}
        />
        {clients.length > 1 && (
          <DeleteButton className="deleteButton" onClick={() => onDeleteColleague(roomName, index)}>
            X
          </DeleteButton>
        )}
        {client && clientLength[roomName][index] && (
          <Message>Booking confirmation will be sent to this person automatically</Message>
        )}
      </ClientRow>
    )
  })

  // when there is only 1 client, trigger onChange to select this one
  useEffect(() => {
    if (allClientsIds.length === 1 && !selectedClient) {
      onChange(allClientsIds[0], roomName, 0)
      setSelectedClient(allClientsIds[0])
    }
  }, [Buyers, allClientsIds, selectedClient, roomName])

  return (
    <RoomoOuter>
      <span>{roomName}</span>
      <div style={{ position: "relative" }}>
        <SelectRow visibleTimeSelect={visibleTimeSelect}>
          {visibleTimeSelect && (
            <Select
              {...props}
              className="timeSelect lengthSelect"
              menuPortalTarget={document.body}
              options={timeOptions}
              value={timeOptions.find(({ value }) => meetingLength === value)}
              onChange={({ value }) => onChangeLength(value, roomName)}
            />
          )}
          <ClientsList>{Buyers}</ClientsList>
        </SelectRow>
        <AddButton>{clients.length < allClientsIds.length && <span onClick={onAddColleague}>+Add</span>}</AddButton>
      </div>
      {errors && <ValidationError className="SelectValidation">{errors}</ValidationError>}
    </RoomoOuter>
  )
}

Room.propTypes = {
  allClients: PropTypes.object,
  clientLength: PropTypes.object,
  clients: PropTypes.array,
  errors: PropTypes.string,
  meetingLength: PropTypes.number,
  options: PropTypes.array,
  roomName: PropTypes.string,
  visibleTimeSelect: PropTypes.bool,
  onAddColleague: PropTypes.func,
  onChange: PropTypes.func,
  onChangeLength: PropTypes.func,
  onDeleteColleague: PropTypes.func,
}

Room.defaultProps = {
  meetingLength: 0,
}
