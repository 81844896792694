import { applyMiddleware, compose, createStore } from "redux"
import { createMigrate, persistReducer, persistStore } from "redux-persist"

import { composeWithDevTools } from "redux-devtools-extension"
import createMySocketMiddleware from "./sockets/middleware"
import createSagaMiddleware from "redux-saga"
import storage from "redux-persist/lib/storage"

let persistor = null
const migrations = {
  0: state => {
    persistor.purge()
    return {
      ...state,
    }
  },
}

export default (rootReducer, rootSaga) => {
  const middlewares = []
  const enhancers = []
  const persistConfig = {
    key: "root",
    version: 9,
    storage,
    whitelist: ["auth"],
    migrate: createMigrate(migrations, { debug: false }),
  }

  const sagaMonitor = null
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor })
  const socketMiddleware = createMySocketMiddleware()

  middlewares.push(socketMiddleware)
  middlewares.push(sagaMiddleware)

  const composeEnhancers = composeWithDevTools

  enhancers.push(composeEnhancers(applyMiddleware(...middlewares)))

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const createAppropriateStore = createStore
  const store = createAppropriateStore(persistedReducer, compose(...enhancers))

  sagaMiddleware.run(rootSaga)
  persistor = persistStore(store)

  return { persistor, store }
}
