import React, { PureComponent } from "react"

import PropTypes from "prop-types"
import { Timeslot } from "../styles"
import times from "lodash/times"

const HALFHOUR = 1800

export class DayGrid extends PureComponent {
  render() {
    const { dayTimeStamp, isStaff } = this.props
    let itemIndex = 0
    if (isStaff) {
      return times(48, idx => {
        itemIndex++
        return <Timeslot even={itemIndex % 2 === 0} key={idx} />
      })
    }
    return times(48, idx => {
      const timeStamp = dayTimeStamp + itemIndex * HALFHOUR
      itemIndex++
      return (
        <Timeslot
          even={itemIndex % 2 === 0}
          key={idx}
          onClick={e => this.props.onSlotClick(e, dayTimeStamp, timeStamp)}
        />
      )
    })
  }
}

DayGrid.propTypes = {
  dayTimeStamp: PropTypes.number,
  isStaff: PropTypes.string,
  onSlotClick: PropTypes.func,
}

export default DayGrid
