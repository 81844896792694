import {
  getEmailSent,
  getRequestResetPasswordEmailError,
  getRequestResetPasswordEmailStatus,
} from "../../../store/selectors"

import Component from "./EmailForm.js"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { sendResetEmail } from "../../../store/actions/auth"

const selector = createStructuredSelector({
  requestError: getRequestResetPasswordEmailError,
  requestPending: getRequestResetPasswordEmailStatus,
  emailIsSent: getEmailSent,
})

const actions = {
  onSentResetPasswordEmail: sendResetEmail,
}

export const ResetPasswordEmail = connect(selector, actions)(Component)
