import { createAsyncAction } from "../../helpers/redux"

export const FETCH_STAFF = createAsyncAction("FETCH_STAFF")
export const fetchStaff = brandId => ({
  type: FETCH_STAFF.REQUEST,
  payload: {
    brandId,
  },
})

export const SELECT_STAFF_MEMBER = createAsyncAction("SELECT_STAFF_MEMBER")
export const fetchStaffMember = id => ({
  type: SELECT_STAFF_MEMBER.SUCCESS,
  payload: {
    id,
  },
})

export const UNSELECT_STAFF_MEMBER = "UNSELECT_STAFF_MEMBER"
export const unselectStaffMember = () => ({
  type: UNSELECT_STAFF_MEMBER,
})

export const CREATE_STAFF_MEMBER = createAsyncAction("CREATE_STAFF_MEMBER")
export const createStaffMember = data => ({
  type: CREATE_STAFF_MEMBER.REQUEST,
  payload: {
    data,
  },
})

export const UPDATE_STAFF_MEMBER = createAsyncAction("UPDATE_STAFF_MEMBER")
export const updateStaffMember = (data, id) => ({
  type: UPDATE_STAFF_MEMBER.REQUEST,
  payload: {
    data,
    id,
  },
})
