import {
  CHANGE_PASSWORD,
  LOGIN,
  LOGOUT,
  RESET_PASSWORD,
  SEND_RESET_EMAIL,
  SIGNUP,
  SOCIAL_LOGIN,
  VERIFY_TOKEN,
} from "../actions/auth"
import { FETCH_BUYER, VERIFY_EMAIL } from "../actions/user"

import { UPDATE_CLIENT } from "../actions/clients"
import { createReducer } from "../..//helpers/redux"

const initialState = {
  isAuthed: false,
  accessToken: null,
  user: null,
  requestSignUpPending: false,
  requestSignUpError: null,
  buyer: null,
  requestSavePending: false,
  requestSaveError: null,
  requestResetPasswordPending: false,
  requestChangePasswordPending: false,
  requestResetPasswordError: null,
  requestChangePasswordError: null,
  requestVerifyTokenPending: false,
  requestVerifyTokenError: null,
  tokenIsVerified: false,
  password: null,
  newPassword: null,
  userId: null,
}

const handlers = {
  [LOGIN.SUCCESS]: (state, { payload }) => ({
    ...state,
    isAuthed: true,
    ...payload,
  }),
  [SIGNUP.REQUEST]: state => ({
    ...state,
    requestSignUpPending: true,
    requestSignUpError: null,
  }),
  [SIGNUP.SUCCESS]: (state, { payload }) => ({
    ...state,
    isAuthed: true,
    ...payload,
    requestSignUpPending: false,
  }),
  [SIGNUP.FAILURE]: (state, { payload }) => ({
    ...state,
    requestSignUpPending: false,
    requestSignUpError: payload,
  }),
  [SOCIAL_LOGIN.REQUEST]: state => state,
  [SOCIAL_LOGIN.SUCCESS]: (state, { payload }) => ({
    ...state,
    isAuthed: true,
    ...payload,
  }),
  [SOCIAL_LOGIN.FAILURE]: state => state,
  [LOGOUT]: () => initialState,
  [UPDATE_CLIENT.REQUEST]: state => ({
    ...state,
    requestSavePending: true,
    requestSaveError: null,
  }),
  [UPDATE_CLIENT.SUCCESS]: (state, { payload }) => {
    const { updatedBuyer } = payload
    return {
      ...state,
      buyer: updatedBuyer,
      requestSavePending: false,
    }
  },
  [UPDATE_CLIENT.FAILURE]: (state, { payload }) => ({
    ...state,
    requestSavePending: false,
    requestSaveError: payload,
  }),
  [RESET_PASSWORD.REQUEST]: state => ({
    ...state,
    requestResetPasswordPending: true,
    requestResetPasswordError: null,
  }),
  [RESET_PASSWORD.SUCCESS]: state => ({
    ...state,
    requestResetPasswordPending: false,
  }),
  [RESET_PASSWORD.FAILURE]: (state, { payload }) => ({
    ...state,
    requestResetPasswordPending: false,
    requestResetPasswordError: payload,
  }),
  [CHANGE_PASSWORD.REQUEST]: (state, { payload }) => ({
    ...state,
    userId: payload.data.userId,
    password: payload.data.values.currentPassword,
    newPassword: payload.data.values.newPassword,
    requestChangePasswordPending: true,
    requestChangePasswordError: null,
  }),
  [CHANGE_PASSWORD.SUCCESS]: state => ({
    ...state,
    requestChangePasswordPending: false,
  }),
  [CHANGE_PASSWORD.FAILURE]: (state, { payload }) => ({
    ...state,
    requestChangePasswordPending: false,
    requestChangePasswordError: payload,
  }),
  [VERIFY_TOKEN.REQUEST]: state => ({
    ...state,
    requestVerifyTokenPending: true,
    requestVerifyTokenError: null,
    tokenIsVerified: false,
  }),
  [VERIFY_TOKEN.SUCCESS]: state => ({
    ...state,
    requestVerifyTokenPending: false,
    tokenIsVerified: true,
  }),
  [VERIFY_TOKEN.FAILURE]: (state, { payload }) => ({
    ...state,
    requestVerifyTokenPending: false,
    requestVerifyTokenError: payload,
  }),
  [SEND_RESET_EMAIL.REQUEST]: state => ({
    ...state,
    requestResetPasswordEmailPending: true,
    requestResetPasswordEmailError: null,
    emailSent: false,
  }),
  [SEND_RESET_EMAIL.SUCCESS]: state => ({
    ...state,
    requestResetPasswordEmailPending: false,
    emailSent: true,
  }),
  [SEND_RESET_EMAIL.FAILURE]: (state, { payload }) => ({
    ...state,
    requestResetPasswordEmailPending: false,
    requestResetPasswordEmailError: payload,
  }),
  [VERIFY_EMAIL.SUCCESS]: (state, { payload }) => ({
    ...state,
    buyer: payload,
  }),
  [FETCH_BUYER.SUCCESS]: (state, { payload }) => ({
    ...state,
    buyer: payload,
  }),
}

export default createReducer(initialState, handlers)
