import { Button, Div } from "../UI"

import {
  ButtonsRow,
  Form,
  MrLogo,
  Services,
  SmallContainer,
  Text,
} from "./styles"
import { Link, Redirect } from "react-router-dom"
import React, { Component } from "react"

import GoogleButton from "react-google-button"
import PropTypes from "prop-types"
import { REST_API_URL } from "../../config"
import SmllLogo from "../../static/mR2.png"
import isEmpty from "lodash/isEmpty"
import logo from "../../static/smallOutlook.png"
import { onToastError } from "../../helpers/toast"
import queryString from "query-string"

const authenticated = false
class Login extends Component {
  state = {
    values: {
      email: "",
      password: "",
    },
    errors: {},
    touched: {},
  }

  componentDidMount = () => {
    const { location, history, onSocialLogin } = this.props
    const queryParams = queryString.parse(location.search)

    if (
      queryParams &&
      queryParams.user &&
      queryParams.accessToken &&
      queryParams.buyer
    ) {
      const user = JSON.parse(queryParams.user)
      const buyer = JSON.parse(queryParams.buyer)
      const { accessToken } = queryParams
      onSocialLogin({ accessToken, user, buyer })
      history.replace("/login")
      return
    }
    if (queryParams && queryParams.errorType === "auth" && queryParams.error) {
      onToastError(queryParams.error)
      history.replace("/login")
    }
  }

  validate = (value, name) => {
    const { values, errors } = this.state
    switch (name) {
      case "email":
        const reg = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        } else if (reg.test(value) === true) {
          delete errors[name]
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        errors[name] = `Invalid email address`
        return {
          values: { ...values, [name]: value },
          errors,
        }

      case "password":
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        delete errors[name]
        return {
          values: { ...values, [name]: value },
          errors,
        }

      default:
        break
    }
  }

  onInputChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ ...this.validate(value, name), isChanged: true })
  }

  onBlur = e => {
    const { touched } = this.state
    const name = e.target.name
    touched[name] = true
    this.setState({ touched })
  }

  onSubmit = () => {
    const { values, touched, errors } = this.state
    const { history } = this.props
    for (const key in values) {
      this.validate(values[key], key)
      touched[key] = true
    }

    if (isEmpty(errors)) {
      this.props.onLogin({ values, history })
    } else {
      this.setState({ errors, touched })
    }
  }

  render() {
    const { errors, touched, values } = this.state

    if (authenticated) return <Redirect to="/calendars" />

    return (
      <React.Fragment>
        <Div.Flex style={{ minHeight: "100vh" }}>
          <SmallContainer className="agreement">
            <MrLogo style={{ marginBottom: "20px" }}>
              <img alt="modaresa logo" src={SmllLogo} />
            </MrLogo>
            <Text margin="0px 0px 20px 0px" style={{ textAlign: "center" }}>
              {`Welcome onboard ModaResa, scheduling will never be the same again.`}
            </Text>
            <Form>
              <div>
                <input
                  name="email"
                  placeholder="Email"
                  type="text"
                  value={values.email}
                  onBlur={this.onBlur}
                  onChange={this.onInputChange}
                />
                {errors.email && touched.email && <span>{errors.email}</span>}
              </div>
              <div>
                <input
                  name="password"
                  placeholder="Password"
                  type="password"
                  value={values.password}
                  onBlur={this.onBlur}
                  onChange={this.onInputChange}
                />
                {errors.password && touched.password && (
                  <span>{errors.password}</span>
                )}
              </div>
            </Form>
            <ButtonsRow style={{ marginTop: "25px" }}>
              <Button onClick={this.onSubmit}>Login</Button>
            </ButtonsRow>
            <p style={{ textAlign: "center", marginTop: "10px" }}>or</p>
            <Services>
              <a href={`${REST_API_URL}/auth/google/login`}>
                <GoogleButton />
              </a>
              <div>
                <a href={`${REST_API_URL}/auth/outlook/login`}>
                  <div className="icon">
                    <img alt="logo" src={logo} />
                  </div>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <span>Sign in with Outlook</span>
                  </div>
                </a>
              </div>
            </Services>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <span>
                Forgot your password?{" "}
                <Link
                  style={{ color: "inherit", textDecoration: "underline" }}
                  to={"/user/email"}
                >
                  Reset here
                </Link>
                .
              </span>
            </div>
          </SmallContainer>
        </Div.Flex>
      </React.Fragment>
    )
  }
}

Login.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  onLogin: PropTypes.func,
  onSocialLogin: PropTypes.func,
}

export default Login
