export const videoTools = [
  { value: "whatsapp", label: "WhatsApp" },
  { value: "facetime", label: "FaceTime" },
  // { value: "skype", label: "Skype" },
  { value: "zoom", label: "Zoom" },
  { value: "googlemeet", label: "Google Meet" },
  { value: "msteams", label: "Microsoft Teams" },
  { value: "whereby", label: "WhereBy" },
]

export const RESTRICTION_TYPE = {
  CAN_BOOK: "canBook",
  CAN_NOT_BOOK: "canNotBook",
}
