import {
  FETCH_DB_DATA,
  FETCH_USER,
  UPDATE_USER,
  VERIFY_EMAIL,
} from "../actions/user"

import { createReducer } from "../..//helpers/redux"

const initialState = {
  user: null,
  dataLoaded: false,
  requestVerifyEmailPending: false,
  requestVerifyEmailError: null,
}

const handlers = {
  [FETCH_USER.SUCCESS]: (state, { payload }) => ({
    ...state,
    user: payload.user,
    dataLoaded: true,
  }),
  [FETCH_DB_DATA.SUCCESS]: state => ({
    ...state,
    dataLoaded: true,
  }),
  [UPDATE_USER]: (state, { payload }) => ({
    ...state,
    user: payload,
  }),
  [VERIFY_EMAIL.REQUEST]: state => ({
    ...state,
    requestVerifyEmailPending: true,
    requestVerifyEmailError: null,
  }),
  [VERIFY_EMAIL.SUCCESS]: state => ({
    ...state,
    requestVerifyEmailPending: false,
  }),
  [VERIFY_EMAIL.FAILURE]: (state, { payload }) => ({
    ...state,
    requestVerifyEmailPending: false,
    requestVerifyEmailError: payload,
  }),
}

export default createReducer(initialState, handlers)
