import { Button, Div, Input, Multiselect } from "../../../UI"
import {
  ControlBlock,
  ControlButton,
  Form,
  FormRow,
  Image,
  ImageContainer,
  InputImage,
  InputImageLabel,
  InputsContainer,
  PageContainer,
} from "./styles"
import { InputContainer, ValidationError } from "../../../UI/Input"
import React, { Component } from "react"

import PropTypes from "prop-types"
import ReactPhoneInput from "react-phone-input-2"
import { countries } from "config/countries"
import { isEmpty } from "lodash"
import { storeTypeOptions } from "config/retailers"

const countryOptions = Object.keys(countries).map(isoCode => ({
  value: isoCode,
  label: countries[isoCode].name,
  type: "country",
}))

class CompanyGeneral extends Component {
  constructor(props) {
    super(props)
    const { retailer } = this.props
    this.state = {
      values: {
        name: (retailer && retailer.name) || "",
        phoneNumber: (retailer && retailer.phoneNumber) || "",
        type: (retailer && retailer.type) || "",
        shippingAddress: (retailer && {
          city: retailer.shippingAddress && retailer.shippingAddress.city,
          country: retailer.shippingAddress && retailer.shippingAddress.country,
        }) || { city: "", country: "" },
        storeAddress: (retailer && {
          city: retailer.storeAddress && retailer.storeAddress.city,
          country: retailer.storeAddress && retailer.storeAddress.country,
        }) || { city: "", country: "" },
      },
      errors: {},
      touched: {},
      imageUrl:
        (retailer &&
          retailer.imageUrl &&
          "https://s3-eu-west-1.amazonaws.com/modaresabrandsdev/brands_logos/" +
            retailer.imageUrl) ||
        "",
      isChanged: false,
      savingRequest: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { savingRequest } = prevState
    const { requestPending, requestError } = nextProps

    if (savingRequest && !requestPending && !requestError) {
      return { savingRequest: false }
    }
    return null
  }

  validate = (value, name) => {
    const { values, errors } = this.state
    switch (name) {
      case "name":
      case "address":
      case "country":
        if (value.trim().length > 0) {
          delete errors[name]
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        errors[name] = `Required`
        return {
          values: { ...values, [name]: value },
          errors,
        }

      case "phoneNumber":
        if (value.trim().length < 3) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        } else if (value.trim().length < 8) {
          errors[name] = `Invalid phone number`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        delete errors[name]
        return {
          values: { ...values, [name]: value },
          errors,
        }

      case "type":
        return {
          values: { ...values, [name]: value },
        }
      case "city": {
        if (value.trim().length > 0) {
          delete errors[name]
          return {
            values: {
              ...values,
              shippingAddress: { ...values.shippingAddress, city: value },
            },
            errors,
          }
        }
        errors[name] = `Required`
        return {
          values: {
            ...values,
            shippingAddress: { ...values.shippingAddress, city: value },
          },
          errors,
        }
      }
      default:
        break
    }
  }

  onInputChange = e => {
    if (typeof e === "string") {
      this.setState({ ...this.validate(e, "phoneNumber"), isChanged: true })
    } else {
      const name = e.target.name
      const value = e.target.value
      this.setState({ ...this.validate(value, name), isChanged: true })
    }
  }

  onBlur = e => {
    const { touched } = this.state
    const name = e.target.name
    touched[name] = true
    this.setState({ touched })
  }

  addImage = e => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.setState({ imageUrl: reader.result, isChanged: true })
      }
    }
    e.target.value = ""
  }

  onSelectChange = (value, name) => {
    const { touched, values } = this.state
    this.validate(value.value, name)
    if (name === "country") {
      touched[name] = true
      this.setState({
        values: {
          ...values,
          shippingAddress: { ...values.shippingAddress, country: value.value },
        },
        isChanged: true,
        touched,
      })
    } else {
      touched[name] = true
      this.setState({
        values: { ...values, [name]: value.value },
        touched,
        isChanged: true,
      })
    }
  }

  removeImage = e => {
    e.preventDefault()
    this.setState({ imageUrl: "", isChanged: true })
  }

  onSaveChange = () => {
    const { retailer } = this.props
    const { values, touched, errors } = this.state

    for (const key in values) {
      if (key === "shippingAddress") {
        this.validate(values[key].city, "city")
        this.validate(values[key].country, "country")
        touched.city = true
        touched.country = true
      }
      this.validate(values[key], key)
      touched[key] = true
    }
    this.setState({ touched })

    if (isEmpty(errors)) {
      this.props.onSaveChanges({
        ...retailer,
        ...values,
        storeAddress: { ...values.shippingAddress },
      })
      this.setState({ isChanged: false, savingRequest: true })
    }
  }

  isDisable = () => {
    const { savingRequest, isChanged } = this.state
    const { requestPending, requestError } = this.props

    if (savingRequest) {
      return !(!requestPending && requestError)
    }
    return !isChanged
  }

  render() {
    const { errors, values, touched, imageUrl } = this.state
    return (
      <PageContainer>
        <Div.Flex align="flex-start" justify="flex-start">
          <InputImageLabel noborder={imageUrl ? "transparent" : ""}>
            <span>Upload Image</span>
            <InputImage accept="image/*" type="file" onChange={this.addImage} />
            {imageUrl && (
              <ImageContainer>
                <Image src={imageUrl} />
              </ImageContainer>
            )}
            <ControlBlock
              className={
                imageUrl ? "control-container" : "control-container hidden"
              }
            >
              <ControlButton>Edit</ControlButton>
              <ControlButton onClick={this.removeImage}>Delete</ControlButton>
            </ControlBlock>
          </InputImageLabel>
          <div>
            <Form>
              <FormRow>
                <span>Company Name</span>
                <Input
                  errors={errors.name}
                  name="name"
                  touched={touched.name}
                  value={values.name}
                  width="100%"
                  onBlur={this.onBlur}
                  onChange={this.onInputChange}
                />
              </FormRow>
              <FormRow>
                <span>City, Country</span>
                <InputsContainer>
                  <Input
                    errors={errors.city}
                    name="city"
                    placeholder={"City"}
                    touched={touched.city}
                    value={values.shippingAddress.city}
                    width="48%"
                    onBlur={this.onBlur}
                    onChange={this.onInputChange}
                  />
                  <Multiselect
                    className="BuyerMultiselect"
                    errors={errors.country}
                    name="country"
                    options={countryOptions}
                    touched={touched.country}
                    value={countryOptions.filter(
                      ({ value }) => value === values.shippingAddress.country,
                    )}
                    width="48%"
                    onChange={this.onSelectChange}
                  />
                </InputsContainer>
              </FormRow>
              <FormRow>
                <span>Standart Phonenbr</span>
                <InputContainer>
                  <ReactPhoneInput
                    buttonStyle={
                      errors.phoneNumber &&
                      touched.phoneNumber && { borderColor: "red" }
                    }
                    country={"fr"}
                    inputExtraProps={{
                      name: "phoneNumber",
                    }}
                    inputStyle={
                      errors.phoneNumber &&
                      touched.phoneNumber && { borderColor: "red" }
                    }
                    enableSearch
                    disableSearchIcon
                    searchStyle={{ width: "95%" }}

                    value={values.phoneNumber}
                    onBlur={this.onBlur}
                    onChange={this.onInputChange}
                  />
                  {errors.phoneNumber && touched.phoneNumber && (
                    <ValidationError>{errors.phoneNumber}</ValidationError>
                  )}
                </InputContainer>
              </FormRow>
              <FormRow>
                <span>Store Type</span>
                <Multiselect
                  className="BuyerMultiselect"
                  errors={errors.type}
                  name="type"
                  options={storeTypeOptions}
                  touched={touched.type}
                  value={storeTypeOptions.filter(
                    ({ value }) => value === values.type,
                  )}
                  width={"100%"}
                  onChange={this.onSelectChange}
                />
              </FormRow>
            </Form>
          </div>
        </Div.Flex>
        <Button
          align="flex-end"
          disabled={this.isDisable()}
          margin="0 35px 35px 0"
          minWidth="150px"
          onClick={this.onSaveChange}
        >
          Save Changes
        </Button>
      </PageContainer>
    )
  }
}

CompanyGeneral.propTypes = {
  match: PropTypes.object,
  requestError: PropTypes.string,
  requestPending: PropTypes.bool,
  retailer: PropTypes.object,
  onSaveChanges: PropTypes.func,
}

export default CompanyGeneral
