import React, { memo } from "react"
import { SyncLoader } from "react-spinners"
import styled from "styled-components"

const MargedSpinner = styled(SyncLoader)`
  margin: 16px;
`

type Props = {
  size?: number,
  className: string,
}

const Loading = ({ size, className }: Props) => (
  <MargedSpinner className={className} color={"#a60c46"} loading size={size} sizeUnit={"px"} />
)

Loading.defaultProps = {
  size: 12,
}
export default memo(Loading)
