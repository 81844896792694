import styled from "styled-components"

export const TabsContainer = styled.div`
  width: 100%;
  height: 50px;
  min-height: 50px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  border-bottom: 4px solid ${props => props.theme.veryLightGray};
  list-style-type: none;
  padding-left: 30px;
`
const active = "tab-active"
export const Tab = styled.button`
  outline: none;
  border: none;
  min-width: 80px;
  margin: 0px 15px 0px 15px;
  font-size: 14px;
  line-height: 20px;
  color: ${props => (props.disabled ? "#ccc" : "#333")};
  cursor: ${props => (props.disabled ? "auto" : "pointer")};
  background-color: transparent;
  &.${active} {
    margin-bottom: -4px;
    border-bottom: 4px solid ${props => props.theme.primary};
  }

  & a {
    color: inherit;
    text-decoration: none;
  }
`
