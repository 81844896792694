import React, { Component } from "react"
import { Tab, TabsContainer } from "../../UI/Tabs"

import { Container } from "./styles"
import { General } from "./index"
import PropTypes from "prop-types"

class MyProfile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeSection: "general",
    }
  }

  onTabClick = tab => {
    this.setState({ activeSection: tab })
  }

  render() {
    const { activeSection } = this.state
    return (
      <Container>
        <TabsContainer>
          <React.Fragment>
            <Tab
              className={activeSection === "general" ? "tab-active" : ""}
              onClick={() => this.onTabClick("general")}
            >
              General
            </Tab>
          </React.Fragment>
        </TabsContainer>
        {activeSection === "general" && <General />}
      </Container>
    )
  }
}

MyProfile.propTypes = {
  user: PropTypes.object,
}

export default MyProfile
