import {
  Bold,
  ButtonsRow,
  CloseModal,
  Col,
  Icon,
  Loader,
  LoaderContainer,
  ModalBody,
  Row,
  Table,
  TableBody,
  TableHeader,
  Text,
} from "./styles"
import { Button, DeleteButton } from "../../UI"
import React, { Component } from "react"

import { InviteModal } from "../../InviteModal"
import Modal from "react-modal"
import PropTypes from "prop-types"
import { SyncLoader } from "react-spinners"
import closeIcon from "../../../static/cancel-music.svg"
import isEmpty from "lodash/isEmpty"

const getUrlParameter = (name, str = window.location.search) => {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]")
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)")
  const results = regex.exec(str)
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, ""))
}

const customStyles = {
  content: {
    width: "700px",
    height: "auto",
    maxHeight: "550px",
    minHeight: "200px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    padding: "40px",
  },
  overlay: {
    backgroundColor: "#d8d8d8BF",
    zIndex: 13,
  },
}

const deleteCustomStyles = {
  content: {
    width: "450px",
    height: "auto",
    maxHeight: "500px",
    minHeight: "200px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    overflow: "visible",
  },
  overlay: {
    backgroundColor: "#d8d8d8BF",
    zIndex: 21,
  },
}

export class TeamList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inviteModal: false,
      deleteModal: false,
      values: {
        email: "",
      },
      errors: {},
      touched: {},
      selectedColleagueId: null,
      isDelete: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { history, requestPending, requestError } = nextProps
    const { isDelete } = prevState
    const isInvite = getUrlParameter("isInvite")

    if (history.location.pathname === "/team/list/invite" && isInvite) {
      history.replace("/team/list/invite")
      return { inviteModal: true }
    }
    if (isDelete && !requestPending && !requestError) {
      return { isDelete: false, deleteModal: false }
    }
    return null
  }

  componentDidMount() {
    const { onFetchColleagues, user } = this.props

    onFetchColleagues(user._id)
  }

  validate = (value, name) => {
    const { values, errors } = this.state
    switch (name) {
      case "email":
        const reg = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        } else if (reg.test(value) === true) {
          delete errors[name]
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        errors[name] = `Invalid email address`
        return {
          values: { ...values, [name]: value },
          errors,
        }

      default:
        break
    }
  }

  onToggleModal = (id, name) => {
    const { history } = this.props
    this.setState(prevState => {
      if (prevState.inviteModal) {
        history.replace("/team/list")
      }
      return {
        [name]: !prevState[name],
        selectedColleagueId: id,
      }
    })
  }

  sendInvite = () => {
    const { values, touched, errors } = this.state
    for (const key in values) {
      this.validate(values[key], key)
      touched[key] = true
    }

    if (isEmpty(errors)) {
      this.setState({ isChanged: false, isRequest: true })
    }
    this.setState({ errors, touched })
  }

  onInputChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ ...this.validate(value, name), isChanged: true })
  }

  onSelectChange = (value, name) => {
    const { touched } = this.state
    touched[name] = true
    this.setState({
      ...this.validate(value.value, name),
      touched,
      isChanged: true,
    })
  }

  onBlur = e => {
    const { touched } = this.state
    const name = e.target.name
    touched[name] = true
    this.setState({ touched })
  }

  onDelete = id => {
    const { onDelete } = this.props
    this.setState({ isDelete: true })
    onDelete(id)
  }

  renderModal() {
    const { inviteModal, selectedColleagueId, deleteModal } = this.state
    const { colleagues, requestPending, allColleagues } = this.props

    if (inviteModal) {
      return (
        <Modal isOpen={inviteModal} style={customStyles}>
          <ModalBody className="modal">
            <InviteModal
              selectedColleagueId={selectedColleagueId}
              staffList={allColleagues.map(id => {
                const item = colleagues.byId[id]
                return {
                  value: item.email,
                  label: `${item.firstName} ${item.lastName} - ${item.email}`,
                  id: item._id,
                }
              })}
            />
            <CloseModal onClick={() => this.onToggleModal(null, "inviteModal")}>
              <Icon src={closeIcon} />
            </CloseModal>
          </ModalBody>
        </Modal>
      )
    }
    if (deleteModal) {
      return (
        <Modal isOpen={deleteModal} style={deleteCustomStyles}>
          {requestPending && (
            <LoaderContainer>
              <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
            </LoaderContainer>
          )}
          <div>
            <Bold style={{ fontSize: "16px" }}>{"Delete Colleague"}</Bold>
          </div>
          <Text>
            <p>{"Are you sure that you want to delete this colleague?"}</p>
          </Text>
          <ButtonsRow>
            <Button onClick={() => this.onDelete(selectedColleagueId)}>
              Confirm
            </Button>
          </ButtonsRow>
          <CloseModal
            style={{ width: "17px", top: "10px", right: "10px" }}
            onClick={() => this.onToggleModal(null, "deleteModal")}
          >
            <Icon height="17px" src={closeIcon} />
          </CloseModal>
        </Modal>
      )
    }
  }

  renderBody = () => {
    const { colleagues, retailer, user, allColleagues } = this.props
    return (
      allColleagues &&
      allColleagues.map((id, index) => {
        const data = colleagues.byId[id]
        const team = retailer.teams.find(team => team._id === data.team)
        return (
          <Row key={index}>
            <Col width="12%"> {retailer.name}</Col>
            <Col width="14%"> {`${data.firstName} ${data.lastName}`}</Col>
            <Col width="18%"> {data.email}</Col>
            <Col width="14%"> {data.position}</Col>
            <Col width="14%"> {team && team.name}</Col>
            <Col width="14%"> {data.phoneNumber}</Col>
            <Col style={{ minWidth: "150px" }} width="150px">
              {" "}
              {data.userId ? (
                <Button
                  bgcolor="#516CEF"
                  style={{ cursor: "initial" }}
                  type="button"
                >
                  Connected
                </Button>
              ) : (
                <Button
                  bgcolor="#ffcab1"
                  type="button"
                  onClick={() => this.onToggleModal(data._id, "inviteModal")}
                >
                  Invite to Connect
                </Button>
              )}
            </Col>
            <Col style={{ minWidth: "40px" }} width="40px">
              <DeleteButton
                disabled={id === user._id}
                onClick={() => {
                  this.onToggleModal(id, "deleteModal")
                }}
              />
            </Col>
          </Row>
        )
      })
    )
  }

  render() {
    const { requestColleaguesPending, allColleagues, retailer } = this.props
    if (!allColleagues || !retailer._id) return null
    return (
      <React.Fragment>
        {requestColleaguesPending && (
          <Loader style={{ zIndex: 2 }}>
            <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
          </Loader>
        )}
        {!requestColleaguesPending && (
          <Table>
            <TableHeader>
              <Col width="12%">Company</Col>
              <Col width="14%">Full Name</Col>
              <Col width="18%">Email</Col>
              <Col width="14%">Position</Col>
              <Col width="14%">Team</Col>
              <Col width="14%">Phone nbr.</Col>
              <Col style={{ minWidth: "150px" }} width="150px">
                Status
              </Col>
              <Col style={{ minWidth: "40px" }} width="40px" />
            </TableHeader>
            <TableBody>{this.renderBody()}</TableBody>
            {this.renderModal()}
          </Table>
        )}
      </React.Fragment>
    )
  }
}

TeamList.propTypes = {
  allColleagues: PropTypes.array,
  colleagues: PropTypes.object,
  history: PropTypes.object,

  requestColleaguesPending: PropTypes.bool,
  requestError: PropTypes.any,
  requestPending: PropTypes.bool,
  retailer: PropTypes.object,
  user: PropTypes.object,
  onDelete: PropTypes.func,
  onFetchColleagues: PropTypes.func,
}

export default TeamList
