import React, { memo, useCallback, useState } from "react"
import Modal from "../UI/Modal"

import { Body, ContainerCTA, H3, SelectContainer } from "./ModalSpreadTheWord.styles"
import { InputTags } from "../UI/InputTags"
import Button from "../UI/Button"
import Error from "../UI/Error"
import { useMutation } from "@apollo/client"
import { SPREAD_THE_WORD } from "../../graphql/mutations/script"
import { onToastError, onToastSuccess } from "../../helpers/toast"

type Props = {
  bookingId: string,
  onCloseModal: Function,
}

const ModalSpreadTheWord = ({ bookingId, onCloseModal }: Props) => {
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [emails, setEmails] = useState([])
  const [spreadTheWord] = useMutation(SPREAD_THE_WORD)

  const handleChangeTags = useCallback(tags => {
    if (tags.emails !== undefined) {
      setEmails(tags.emails)
    }
    if (tags.errors !== undefined) {
      setErrors({ ...tags.errors })
    }
  }, [])

  const handleClickSpreadTheWord = useCallback(async () => {
    setLoading(true)
    try {
      if (emails.length > 0) {
        await spreadTheWord({
          variables: {
            emails,
            bookingId,
          },
        })
        onToastSuccess("Thank you!")
      }
      onCloseModal()
    } catch (e) {
      console.error(e)
      onToastError("An error occured while spreading the word")
      setLoading(false)
    }
  }, [emails, bookingId, onCloseModal, spreadTheWord])
  return (
    <Modal showHeader title="" isOpen onClose={onCloseModal} width="600px">
      <Body>
        <H3>Did you love booking your appointment through ModaResa in a few clicks?</H3>
        <p>
          Do you want to have this seamless booking experience with other brands too?
          <br />
          <br />
          Insert below the email of other brands you think should benefit from ModaResa <b>now.</b>
          <br />
          <br />
        </p>
        <ContainerCTA>
          <SelectContainer>
            <InputTags
              errors={errors}
              newState={handleChangeTags}
              values={emails}
              placeholder="Enter your favourite brands' contact email"
            />
            {errors.emails && <Error>{errors.emails}</Error>}
          </SelectContainer>
          <Button
            bgcolor="#516bef"
            borderColor="#516bef"
            minWidth="100%"
            margin="24px 0 0 0"
            onClick={handleClickSpreadTheWord}
            loading={loading}
          >
            <b>Spread the word now</b>
          </Button>
        </ContainerCTA>
      </Body>
    </Modal>
  )
}

export default memo(ModalSpreadTheWord)
