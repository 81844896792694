import styled from "styled-components"

export const StepContainer = styled.div`
  display: flex;
  align-items: ${props => props.alignItems || "stretch"};
  flex-direction: column;

  min-height: 400px;
  justify-content: space-between;
  box-sizing: border-box;

  ${props => (props.isOverflow ? "max-height: 75vh; overflow-y: auto;" : "")}

  & .codeSelect__indicators {
    display: none;
  }

  & .codeSelect__control {
    width: 100px;
  }

  & .meetingSelect__control {
    width: 300px;
  }

  & .languageSelect__control {
    width: 300px;
  }

  &.last-step button {
    text-transform: initial;
    width: 50%;
  }
`

export const Text = styled.div`
  margin: ${props => props.margin};

  & p {
    margin: 0;
  }
`

export const SmallLogo = styled.img`
  display: block;
  width: 320px;
  height: auto;
`
export const SmallContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  width: 90%;
  margin: 25px auto;

  &.agreement {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  & a {
    color: #999;
    text-decoration: none;
  }
`

export const Services = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  & div {
    display: flex;
    width: 50%;
    height: 40px;
    justify-content: center;
    align-items: center;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    margin-bottom: 10px;
    cursor: pointer;

    & > a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    & img {
      height: auto;
      width: 60%;
      object-fit: contain;
    }
  }
`

export const MrLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  & img {
    width: auto;
    height: 30px;
  }
`
export const LinkRow = styled.div`
  color: #999;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;

  & a {
    color: #333;
    text-decoration: underline;
    cursor: pointer;
  }
`
export const SocialIcon = styled.img`
  width: 30px;
  height: 30px;
  padding-right: 10px;
  padding-left: 5px;
`
