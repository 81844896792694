import Modal from "react-modal"
import styled from "styled-components"
if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root")

export const BookingModalContainer = styled(Modal)`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  outline: none;
  justify-content: center;
  align-items: center;
`
export const BookingModalDialog = styled.div`
  background-color: #fff;
  position: relative;
  max-width: 600px;
  padding: 25px;
  width: 100%;
  border-radius: 4px;
  border: 2px solid ${props => props.theme.veryLightGray};
  max-height: 90vh;
  overflow: auto;
`
export const CloseIcon = styled.div`
  position: absolute;
  height: 1em;
  width: 1em;
  line-height: 1em;
  text-align: center;
  color: ${props => props.theme.berry};
  right: 10px;
  top: 10px;
  cursor: pointer;
`
export const BookingModalHeader = styled.div`
  padding: 20px;
  height: 78px;

  background-color: #ffcab1;
  color: #000;
  border-radius: 4px;
`

export const BookingModalDialogBody = styled.div`
  padding-top: 20px;
`
