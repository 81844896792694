import React, { memo } from "react"
import Modal from "react-modal"
import { Div } from "../UI"
import CheckLogo from "../../static/check-symbol.svg"
import { CloseModal, Icon, Text } from "../styles"
import modaResaIcon from "../../static/logo.png"
import CloseIcon from "../../static/cancel-music.svg"

const customStyles = {
  content: {
    width: "500px",
    height: "350px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    padding: "35px",
  },
  overlay: {
    backgroundColor: "rgba(216, 216, 216, 0)",
  },
}

type Props = {
  brand: {
    name: string,
  },
  onCloseModal: Function,
}

const ModalSpecialRequest = ({ brand, onCloseModal }: Props) => {
  return (
    <Modal isOpen style={customStyles}>
      <Div.Flex justify="center">
        <img
          alt="check"
          height="20px"
          width="20px"
          style={{ marginRight: "20px" }}
          src={CheckLogo}
        />{" "}
        <span>SPECIAL REQUEST SENT</span>
      </Div.Flex>
      <div>
        <p>Your special request has been sent to {brand.name}</p>
        <Text margin="20px 0 0 0">
          {`Shortly you will receive a reply if your request has been fulfilled or not by the brand.`}
        </Text>
      </div>
      <div style={{ margin: "0 auto" }}>
        <Icon height="30px" src={modaResaIcon} />
      </div>
      <CloseModal
        style={{ width: "17px", top: "20px", right: "20px" }}
        onClick={onCloseModal}
      >
        <Icon src={CloseIcon} />
      </CloseModal>
    </Modal>
  )
}

export default memo(ModalSpecialRequest)
