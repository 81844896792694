import React, { memo, useMemo } from "react"
import styled from "styled-components"
import ReactModal from "react-modal"

import { Button, ButtonSecondary } from "./Button"
import CloseIcon from "./icons/Close"
import Loading from "./Loading"
import Error from "./Error"
import Logo from "./Logo"

const H3 = styled.h3`
  margin: 0 0 16px;
  font-size: 16px;
`
const CloseCross = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 1em;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  color: #0a1069;
`
const ContainerButtons = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  min-height: 37px;
  justify-content: center;
  margin-top: 8px;
  & button {
    margin: 0 8px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`

const Body = styled.div`
  flex: 1;
`

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 120px;
  background-color: #ffe5e4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Spacer = styled.div`
  margin-top: 120px;
`

const defaultModalStyle = {
  content: {
    width: "400px",
    height: "auto",
    minHeight: "200px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    overflow: "visible",
  },
  overlay: {
    backgroundColor: "#d8d8d8BF",
    zIndex: 21,
  },
}

type Props = {
  showHeader: boolean,
  isOpen: boolean,
  loading: boolean,
  error: string,
  title?: string,
  onClose: Function,
  children: any,
  width: string,
  minHeight: string,
  actions: [
    {
      onClick: string,
      label: string,
      primary: boolean,
      disabled: boolean,
      loading: boolean,
    },
  ],
}
const Modal = ({ isOpen, loading, error, title, onClose, children, width, minHeight, actions, showHeader }: Props) => {
  const modalStyle = useMemo(() => {
    const customStyle = { ...defaultModalStyle }
    if (width) {
      customStyle.content.width = width
    }
    if (minHeight) {
      customStyle.content.minHeight = minHeight
    }
    return customStyle
  }, [width, minHeight])
  return (
    <ReactModal isOpen={isOpen} style={modalStyle} onRequestClose={onClose}>
      {loading && <Loading />}
      {error && <Error>{error}</Error>}
      {!loading && !error && (
        <>
          {showHeader && (
            <>
              <Header>
                <Logo />
              </Header>
              <Spacer />
            </>
          )}
          {onClose && (
            <CloseCross onClick={onClose}>
              <CloseIcon />
            </CloseCross>
          )}
          {title && <H3>{title}</H3>}
          <Body>{children}</Body>
          {actions && (
            <ContainerButtons>
              {actions.map(({ loading, disabled, label, onClick, primary }) => {
                const ComponentButton = primary ? Button : ButtonSecondary
                return (
                  <ComponentButton key={label} disabled={disabled} onClick={onClick} loading={loading}>
                    {label}
                  </ComponentButton>
                )
              })}
            </ContainerButtons>
          )}
        </>
      )}
    </ReactModal>
  )
}

Modal.defaultProps = {
  title: "Modal",
}
export default memo(Modal)
