import moment from "moment-timezone"

export const getHourList = (format = "24") => {
  const today = moment()
    .utc()
    .set({ hour: 0, minute: 0, second: 0 })
  const hoursList = []
  for (let i = 1; i <= 48; i++) {
    const value =
      format === "24"
        ? today.utc().format("HH:mm")
        : today.utc().format("hh:mm A")
    hoursList.push({ value, label: value, timestamp: today.unix() })
    today.add(30, "minutes")
  }
  return hoursList
}

export const getRangeHourList = (startStamp, endStamp) => {
  const start = moment.utc(startStamp, "X")
  const end = moment.utc(endStamp, "X")
  const hoursList = []
  const diff = end.diff(start, "hours", true)
  for (let i = 1; i <= diff * 2 + 1; i++) {
    const label = start.utc().format("HH:mm")
    hoursList.push({ value: start.unix(), label })
    start.add(30, "minutes")
  }
  return hoursList
}

export const getMinutesList = () => {
  let min = 15
  const today = moment()
    .utc()
    .set({ hour: 0, minute: 15, second: 0 })
  const minutesList = []

  for (let i = 1; i <= 20; i++) {
    const minutes = today.minute()
    const hour = today.hour()

    const getTimeString = () => {
      if (!hour) {
        return `${minutes} min`
      }
      if (hour > 1 && minutes > 0) {
        return `${hour} hours ${minutes} min`
      } else if (hour > 1) {
        return `${hour} hours`
      }

      if (hour === 1 && minutes > 0) {
        return `${hour} hour ${minutes} min`
      } else if (hour === 1) {
        return `${hour} hour`
      }
    }

    minutesList.push({ value: min, label: getTimeString() })
    today.add(15, "minutes")
    min += 15
  }
  return minutesList
}

export const getTime = time => {
  const [hours, minutes] = time.split(":")

  return { hours, minutes }
}

export const setTodayDate = timestamp => {
  const todayDate = moment().format("YYYY:MM:DD")
  const [year, month, date] = todayDate.split(":")
  return moment
    .utc(timestamp, "X")
    .set({ year, month: month - 1, date })
    .unix()
}

export const setToTimestamp = array => {
  const offset = moment().utcOffset()
  return array.map(event => ({
    ...event,
    startTime: moment
      .utc(event.startTime, "X")
      .add(offset, "minutes")
      .unix(),
    endTime: moment
      .utc(event.endTime, "X")
      .add(offset, "minutes")
      .unix(),
  }))
}

export const toMomentUTC = timestamp => moment.utc(timestamp, "X")
