import moment from "moment-timezone"

const meetingTypeLookup = {
  walkthrough: "Walkthrough",
  businessMeeting: "Business Meeting",
  buyingAppointment: "Buying Appointment",
}

const createIcsEvent = (
  startTime,
  endTime,
  timezone,
  meetingType,
  room,
  address,
  salesSessionName,
  retailer,
  brandName,
  buyerEmail,
  link,
  videoLink,
) => {
  const offset = moment
    .utc(startTime, "X")
    .tz(timezone, true)
    .utcOffset()
  let start = moment.utc(startTime, "X")
  let end = moment.utc(endTime, "X")
  if (offset > 0) {
    start = start.subtract(offset, "minutes")
    end = end.subtract(offset, "minutes")
  } else {
    start = start.add(offset, "minutes")
    end = end.add(offset, "minutes")
  }

  const eventStart = [
    "BEGIN:VEVENT",
    `DTSTART:${start.format("YYYYMMDDTHHmmss")}Z`,
    `DTEND:${end.format("YYYYMMDDTHHmmss")}Z`,
    `DTSTAMP:${moment(Date.now())
      .tz(timezone)
      .format("YYYYMMDDTHHmmss")}Z`,
    // `URL:${link}`,
  ]

  const attendees = []
  /*
  TODO: figure out why the clients are not properly added to the event
  slot.clients.forEach(clientId => {
    let email = colleagues[clientId].email
    attendees.push(`ATTENDEE;CUTYPE=INDIVIDUAL;ROLE=REQ-PARTICIPANT;PARTSTAT=NEEDS-ACTION;CN=${email};X-NUM-GUESTS=0:mailto:${email}`)
  })
  {}
  */

  let description = `${meetingTypeLookup[meetingType]} of the ${room} collection.`
  if (videoLink) {
    description += `\\n\\nJoin this meeting: ${videoLink}`
  }

  const eventEnd = [
    `CREATED:${moment(Date.now())
      .tz(timezone)
      .format("YYYYMMDDTHHmmss")}Z`,
    `DESCRIPTION:${description}\\n\\nEdit URL: ${link}`,
    `LAST-MODIFIED:${moment(Date.now())
      .tz(timezone)
      .format("YYYYMMDDTHHmmss")}Z`,
    `LOCATION:${address}`,
    "SEQUENCE:0",
    "STATUS:CONFIRMED",
    `SUMMARY:${salesSessionName} - ${retailer}`,
    "TRANSP:OPAQUE",
    "BEGIN:VALARM",
    "ACTION:EMAIL",
    `DESCRIPTION: Your meeting with ${brandName} is in 24 Hours`,
    "SUMMARY:Alarm notification",
    `ATTENDEE:mailto:${buyerEmail}`,
    "TRIGGER:-P1D",
    "END:VALARM",
    "BEGIN:VALARM",
    "ACTION:DISPLAY",
    `DESCRIPTION:You have a meeting with ${brandName} in 1 hour`,
    "TRIGGER:-P0DT1H0M0S",
    "END:VALARM",
    "END:VEVENT",
  ]
  const event = eventStart.concat(attendees)
  return event.concat(eventEnd).join("\n")
}

export const download = (filename, text) => {
  const element = document.createElement("a")
  element.setAttribute(
    "href",
    "data:text/calendar;charset=utf-8," + encodeURIComponent(text),
  )
  element.setAttribute("download", filename)

  element.style.display = "none"
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

export const createIcs = (
  arrayOfSlots,
  timezone,
  address,
  salesSessionName,
  retailer,
  brandName,
  buyerEmail,
  link,
) => {
  let ics = [
    "BEGIN:VCALENDAR",
    "PRODID:-//Google Inc//Google Calendar 70.9054//EN",
    "VERSION:2.0",
    "CALSCALE:GREGORIAN",
    `X-WR-TIMEZONE:${timezone}`,
  ]
  for (let i = 0; i < arrayOfSlots.length; i++) {
    const slot = arrayOfSlots[i]

    let videoLink
    if (slot.meetingApproach === "video-conference") {
      videoLink = `${window.location.origin}/video-call/${slot._id}`
    }

    const icsEvent = createIcsEvent(
      slot.startTime,
      slot.endTime,
      timezone,
      slot.meetingType,
      slot.room,
      address,
      salesSessionName,
      retailer,
      brandName,
      buyerEmail,
      link,
      videoLink,
    )
    ics = ics.concat(icsEvent)
  }
  ics.push("END:VCALENDAR")
  return ics.join("\n")
}
