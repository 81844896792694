import React, { Component } from "react"

import maxBy from "lodash/maxBy"
import isNull from "lodash/isNull"
import moment from "moment"
import { PropTypes } from "prop-types"
import Modal from "react-modal"
import Select from "react-select"
import { SyncLoader } from "react-spinners"
import { toMomentUTC } from "helpers/date"

import { Button, Spinner } from "./../UI"
import { ButtonsRow, Container, Flex, SelectOuter, Text } from "../styles"
import {
  Calendar,
  CalendarContentWrapper,
  CalendarGridColumnWidth,
  CalendarGridHourSize,
  DateColumn,
  DateColumnHeader,
  DateHourSidebar,
  DateHourSidebarLines,
  Header,
  LoaderContainer,
  Nav,
  NavigationButton,
  OptionsButton,
  SelectContainer,
  Suggestion,
  SuggestionItem,
  SuggestionList,
} from "./styles"
import { CreateDateOptions, getTimeOptions } from "../../config/date"
import BookedOptions from "./BookedOptions.js"
import TimeIcon from "../../static/clock.svg"
import { ValidationError } from "./../UI/Input"
import logo from "../../static/neutral-logo.svg"
import { mainTheme } from "./../../constants/themes"
import { RESTRICTION_TYPE } from "./../../constants/meetings"
import Suggestions from "./Suggestions"
import ModalConfirmationBooking from "./ModalConfirmationBooking"

function isEmpty(obj) {
  for (const key in obj) {
    return false
  }
  return true
}

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root")

const customStyles = {
  content: {
    width: "500px",
    height: "500px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.15)",
    padding: "15px",
  },
}

const confirmModalStyles = {
  ...customStyles,
  content: {
    ...customStyles.content,
    padding: "40px",
  },
}

const customStyles2 = {
  ...customStyles,
  content: {
    ...customStyles.content,
    height: "auto",
    padding: "30px",
  },
}

export const setTimeDate = (dayTimesteps, timestamp) => {
  const Date = moment.utc(dayTimesteps, "X").format("YYYY:MM:DD")
  const [year, month, date] = Date.split(":")
  return moment
    .utc(timestamp, "X")
    .set({ year, month: month - 1, date })
    .unix()
}

class Matches extends Component {
  constructor(props) {
    super(props)
    const { selectedRoom, session } = this.props

    const roomRequest = selectedRoom.map(room => ({
      hoursFrom: {},
      hoursTo: {},
      roomName: room.roomName,
      clients: room.clients,
    }))

    const Rooms = () => {
      let string = ""
      selectedRoom.forEach(elem => {
        if (elem.value) string += `${elem.label} & `
      })

      return string
    }

    const dateOption = CreateDateOptions(session.openingDay, session.closingDay)
    this.timeoutId = null
    this.state = {
      count: 1,
      isJokerModal: false,
      confirmModal: false,
      Rooms: Rooms().slice(0, -2),
      dateOptions: dateOption,
      joker: {
        date: "",
      },
      errors: {},
      bookings: [],
      pendingBookingConfirm: false,
      pendingJokerConfirm: false,
      showModal: false,
      roomRequest,
      isFetchingSuggestion: true,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      bookingRequestPending,
      onBookingConfirmed,
      bookingRequestError,
      jokerRequestStatus,
      jokerRequestError,
      isFetchingSuggestions,
      suggestions,
    } = nextProps
    if (
      prevState.pendingJokerUpdate &&
      !jokerRequestStatus &&
      !bookingRequestPending &&
      !jokerRequestError &&
      !bookingRequestError
    ) {
      onBookingConfirmed()
      return null
    }
    if (prevState.pendingBookingConfirm && !bookingRequestPending && !bookingRequestError) {
      onBookingConfirmed()
      return null
    }
    if (prevState.pendingJokerConfirm && !jokerRequestStatus && !jokerRequestError) {
      onBookingConfirmed()
      return null
    }

    if (prevState.isFetchingSuggestion && !isFetchingSuggestions && suggestions.length > 0) {
      const isEmpty = suggestions.filter(day => day.daySuggestions.length > 0 && day.daySuggestions[0].length > 0)
      if (isEmpty.length > 0) {
        return {
          isFetchingSuggestion: false,
        }
      }
      return {
        isFetchingSuggestion: false,
        showSpecialRequest: true,
        showNoSuggestionsModal: true,
      }
    }
    return null
  }

  componentDidUpdate(prevProps) {
    if (prevProps.suggestionsClientIndex !== this.props.suggestionsClientIndex) {
      this.setState({ oldSuggestions: [] })
    } else if (prevProps.suggestions && this.props && prevProps.suggestions !== this.props.suggestions) {
      this.setState({ oldSuggestions: prevProps.suggestions })
    }
  }

  componentDidMount() {
    const { onFetchSuggestions, session, selectedEvent, bookingsData } = this.props
    onFetchSuggestions({
      sessionId: session._id,
      bookingsData: {
        ...bookingsData,
        eventId: selectedEvent && selectedEvent._id,
      },
      part: 0,
    })
    this.setState({ isFetchingSuggestion: true })
  }

  componentWillUnmount() {
    this.props.onClearSuggestion()
    clearTimeout(this.timeoutId)
    this.timeoutId = null
  }

  showMore = () => {
    const { onFetchSuggestions, session, bookingsData, selectedEvent, suggestionsSlice } = this.props
    const { suggestionsClients: clients, suggestionsClientIndex: clientIndex } = this.props
    this.setState({ showGridTransitions: true })
    onFetchSuggestions({
      sessionId: session._id,
      clientId: clients[clientIndex],
      selectedBookings: this.state.bookings || [],
      bookingsData: {
        ...bookingsData,
        rooms: bookingsData.rooms.filter(room => room.roomName),
        eventId: selectedEvent && selectedEvent._id,
      },
      part: suggestionsSlice.slice + 1,
    })
  }

  showJokerModal = () => {
    this.setState({
      isJokerModal: true,
    })
  }

  closeModal = name => {
    if (name === "showModal") {
      this.setState({
        [name]: false,
      })
    } else {
      this.setState({
        [name]: false,
      })
    }
  }

  onSelectChange = (values, name, index) => {
    const { joker, errors, roomRequest } = this.state
    const newRoomsTime = [...roomRequest]
    if (name === "hoursTo") {
      newRoomsTime[index][name] = values
      if (errors[index]) {
        delete errors[index][name]
      }
      this.setState({
        errors,
        roomRequest: newRoomsTime,
      })
    } else if (name === "hoursFrom") {
      newRoomsTime[index][name] = values
      if (errors[index]) {
        delete errors[index][name]
      }
      this.setState({
        errors,
        roomRequest: newRoomsTime,
      })
    } else {
      delete errors[name]
      this.setState({
        errors,
        roomRequest: newRoomsTime.map(room => ({
          hoursFrom: {},
          hoursTo: {},
          roomName: room.roomName,
          clients: room.clients,
        })),
        joker: { ...joker, [name]: values },
      })
    }
  }

  onInputChange = e => {
    const value = e.target.value
    const name = e.target.name

    this.setState({
      [name]: value,
    })
  }

  sendRequest = () => {
    const { errors, joker, roomRequest } = this.state
    const {
      session,
      bookingsData,
      requestJoker,
      isEditing,
      onEditAutoJokerBookings,
      selectedEvent,
      onEditJokerRequest,
    } = this.props
    if (joker.date) {
      delete errors.date
    } else {
      errors.date = "Required"
    }
    roomRequest.forEach((roomTime, index) => {
      if (!roomTime.hoursFrom.timestamp) {
        if (!errors[index]) errors[index] = {}
        errors[index].hoursFrom = "Required"
      }
      if (!roomTime.hoursTo.timestamp) {
        if (!errors[index]) errors[index] = {}
        errors[index].hoursTo = "Required"
      }
    })

    if (isEmpty(errors)) {
      if (isEditing) {
        if (selectedEvent && selectedEvent._id && !selectedEvent.type) {
          onEditJokerRequest({
            requestData: roomRequest.map(roomRequest => ({
              bookingId: bookingsData._id,
              sessionId: session._id,
              brandId: session.brand,
              meetingType: bookingsData.meetingType,
              meetingApproach: bookingsData.meetingApproach,
              videoTool: bookingsData.videoTool,
              dayDate: joker.date.value,
              status: "pending",
              room: roomRequest.roomName,
              clients: roomRequest.clients,
              startTime: setTimeDate(joker.date.value, roomRequest.hoursFrom.timestamp),
              endTime: setTimeDate(joker.date.value, roomRequest.hoursTo.timestamp),
            })),
            sessionId: session._id,
            bookingData: bookingsData,
            eventId: selectedEvent && selectedEvent._id,
          })
        } else {
          onEditAutoJokerBookings({
            requestData: roomRequest.map(roomRequest => ({
              bookingId: bookingsData._id,
              sessionId: session._id,
              brandId: session.brand,
              meetingType: bookingsData.meetingType,
              meetingApproach: bookingsData.meetingApproach,
              videoTool: bookingsData.videoTool,
              dayDate: joker.date.value,
              status: "pending",
              room: roomRequest.roomName,
              clients: roomRequest.clients,
              startTime: setTimeDate(joker.date.value, roomRequest.hoursFrom.timestamp),
              endTime: setTimeDate(joker.date.value, roomRequest.hoursTo.timestamp),
            })),
            sessionId: session._id,
            bookingData: bookingsData,
            eventId: selectedEvent && selectedEvent._id,
          })
        }
      } else {
        const requests = roomRequest.map(roomRequest => ({
          bookingId: bookingsData._id,
          sessionId: session._id,
          brandId: session.brand,
          meetingType: bookingsData.meetingType,
          meetingApproach: bookingsData.meetingApproach,
          videoTool: bookingsData.videoTool,
          dayDate: joker.date.value,
          status: "pending",
          room: roomRequest.roomName,
          clients: roomRequest.clients,
          startTime: setTimeDate(joker.date.value, roomRequest.hoursFrom.timestamp),
          endTime: setTimeDate(joker.date.value, roomRequest.hoursTo.timestamp),
        }))
        requestJoker(requests)
      }
      this.setState({
        errors,
        pendingJokerConfirm: true,
      })
    } else {
      this.setState({
        errors,
      })
    }
  }

  onConfirm = (emails, messageToBrand) => {
    const { bookings } = this.state
    const {
      onConfirmAutoBookings,
      onEditAutoBookings,
      session,
      bookingsData,
      isEditing,
      selectedEvent,
      confirmBookind,
      onEditJokerRequest,
    } = this.props

    const allBookings = bookings.reduce((bookings, rooms) => bookings.concat(rooms), [])

    let requestData = {
      eventsData: allBookings.map(event => {
        const room = bookingsData.rooms.find(room => room.roomName === event.room)
        return {
          ...event,
          clients: room.clients,
          clientTimezone: moment.tz.guess(),
        }
      }),
      sessionId: session._id,
      bookingData: bookingsData,
    }

    if (isEditing && selectedEvent && selectedEvent._id && !selectedEvent.type) {
      onEditJokerRequest(requestData)
      this.setState({ pendingJokerConfirm: true })
    } else {
      requestData = {
        ...requestData,
        message: messageToBrand,
        emails,
      }

      if (isEditing) {
        onEditAutoBookings(requestData)
      } else {
        confirmBookind()
        onConfirmAutoBookings(requestData)
      }

      this.setState({ pendingBookingConfirm: true })
    }
  }

  onSelectSuggestion = daySuggestion => {
    const { suggestionsClients: clients, suggestionsClientIndex: clientIndex } = this.props

    const bookings = this.state.bookings || []
    bookings[clientIndex] = daySuggestion
    this.setState({
      ...this.state,
      bookings,
    })

    if (bookings.length === clients.length) {
      this.confirmBooking(daySuggestion)
    } else {
      this.selectClient(clientIndex + 1, bookings)
    }
  }

  confirmBooking = () => {
    this.setState({ confirmModal: true })
  }

  selectClient = (clientIndex, selectedBookings) => {
    const { suggestionsClients: clients } = this.props
    const { onFetchSuggestions, session, selectedEvent, bookingsData } = this.props
    this.setState({ isFetchingSuggestion: true, showGridTransitions: false })
    onFetchSuggestions({
      sessionId: session._id,
      clientId: clients[clientIndex],
      selectedBookings: selectedBookings || this.state.bookings || [],
      bookingsData: {
        ...bookingsData,
        eventId: selectedEvent && selectedEvent._id,
      },
      part: 0,
    })
  }

  renderConfirmModal = () => {
    const { confirmModal, bookings } = this.state
    const { session, brand, bookingRequestPending } = this.props

    if (bookingRequestPending) {
      return (
        <Modal isOpen={confirmModal} style={confirmModalStyles}>
          <LoaderContainer>
            <SyncLoader color={"#a60c46"} loading size={20} sizeUnit={"px"} />
          </LoaderContainer>
        </Modal>
      )
    }

    const timezone = session.timezone.split("/")[1]
    return (
      confirmModal && (
        <ModalConfirmationBooking
          timezone={timezone}
          bookings={bookings}
          brandName={brand.name}
          onConfirm={this.onConfirm}
          onClose={() => this.closeModal("confirmModal")}
        />
      )
    )
  }

  renderJokerModal = () => {
    const {
      isJokerModal,

      dateOptions,
      joker,
      errors,
      roomRequest,
    } = this.state
    const { jokerRequestStatus, session } = this.props
    if (jokerRequestStatus) {
      return (
        <Modal isOpen={isJokerModal} style={customStyles}>
          <LoaderContainer>
            <SyncLoader color={"#a60c46"} loading size={20} sizeUnit={"px"} />
          </LoaderContainer>
        </Modal>
      )
    }
    let timeOptions = []

    if (joker.date) {
      const sessionDay = session.openingHours.find(dayHours => dayHours.date === joker.date.value)
      const dayStartTime = sessionDay.from
      const dayEndTIme = sessionDay.to
      timeOptions = getTimeOptions(joker.date.value).filter(
        ({ timestamp }) => timestamp >= dayStartTime && timestamp <= dayEndTIme,
      )
    }

    return (
      <Modal isOpen={isJokerModal} style={customStyles}>
        <div>
          <div>APPOINTMENT REQUEST</div>
          <Text margin="20px 0 0 0">
            {`Please be sure that none of the previously listed options suit you.
          There is no guarantee that your request will be confirmed.
          `}
          </Text>
          <SelectOuter>
            <Text style={{ marginTop: "20px", marginBottom: "10px" }}>Selected date:</Text>
            <Select
              className="dateSelect"
              options={dateOptions}
              value={joker.date}
              onChange={value => this.onSelectChange(value, "date")}
            />
            {errors.date && <ValidationError>{errors.date}</ValidationError>}
          </SelectOuter>
          <Text style={{ marginTop: "20px", marginBottom: "10px" }}>Selected Room(s):</Text>
          {roomRequest.map((room, index) => (
            <React.Fragment key={index}>
              <p>{room.roomName}</p>
              <SelectContainer flexRow margin="10px 0px 10px 0px">
                <div>
                  <img alt="icon" src={TimeIcon} />
                </div>
                <div style={{ width: "100%" }}>
                  <Flex>
                    <SelectOuter width="45%">
                      <Select
                        className="timeSelect"
                        isDisabled={!joker.date}
                        isOptionDisabled={option => option.timestamp >= room.hoursTo.timestamp}
                        options={timeOptions}
                        value={timeOptions.filter(
                          ({ value }) => value === moment.utc(room.hoursFrom.timestamp, "X").format("HH:mm"),
                        )}
                        onChange={value => this.onSelectChange(value, "hoursFrom", index)}
                      />
                      {errors[index] && errors[index].hoursFrom && (
                        <ValidationError>{errors[index].hoursFrom}</ValidationError>
                      )}
                    </SelectOuter>
                    {" to "}{" "}
                    <SelectOuter width="45%">
                      <Select
                        className="timeSelect"
                        isDisabled={!joker.date}
                        isOptionDisabled={option => option.timestamp <= room.hoursFrom.timestamp}
                        options={timeOptions}
                        value={timeOptions.filter(
                          ({ value }) => value === moment.utc(room.hoursTo.timestamp, "X").format("HH:mm"),
                        )}
                        onChange={value => this.onSelectChange(value, "hoursTo", index)}
                      />
                      {errors[index] && errors[index].hoursTo && (
                        <ValidationError>{errors[index].hoursTo}</ValidationError>
                      )}
                    </SelectOuter>
                  </Flex>
                </div>
              </SelectContainer>
            </React.Fragment>
          ))}
        </div>
        <ButtonsRow style={{ marginTop: "30px" }}>
          <Button bgcolor={mainTheme.strawberry} margin="0 20px 0 0" onClick={() => this.closeModal("isJokerModal")}>
            Return to Options
          </Button>
          <Button onClick={this.sendRequest}>Send Request</Button>
        </ButtonsRow>
      </Modal>
    )
  }

  hasRestriction = (dayDate, timeFrame, type) => {
    let isRestrict = null
    const {
      invitedClient: { retailer },
      session: { restrictions },
    } = this.props

    if (restrictions.length <= 0) {
      return isRestrict
    }

    const currentDay = toMomentUTC(dayDate)
    const startDateTime = toMomentUTC(timeFrame.start)
    const endDateTime = toMomentUTC(timeFrame.end)

    const restrictionsByType = restrictions.filter(r => r.type === type)

    restrictionsByType.some(restriction => {
      const startDate = toMomentUTC(restriction.startDate)
      const endDate = toMomentUTC(restriction.endDate)

      // SAME DAY IN CALENDAR COLUMN
      if (currentDay.isBetween(startDate, endDate, undefined, "[]")) {
        const startTime = toMomentUTC(restriction.startTime).set({
          date: currentDay.get("date"),
          month: currentDay.get("month"),
          year: currentDay.get("year"),
        })
        const endTime = toMomentUTC(restriction.endTime).set({
          date: currentDay.get("date"),
          month: currentDay.get("month"),
          year: currentDay.get("year"),
        })

        // console.log("## restrictionsByType startTime-endTime", {
        //   //startTimeStamp: restriction.startTime,
        //   //endTimeStamp: restriction.endTime,
        //   start: startTime.format("DD.MM.YYYY - HH:mm"),
        //   end: endTime.format("DD.MM.YYYY - HH:mm"),
        // })

        const isStartTimeRestricted = startDateTime.isBetween(startTime, endTime, undefined, "[)")
        const isEndTimeRestricted = endDateTime.isBetween(startTime, endTime, undefined, "(]")

        //console.log("## hasRestriction", { isStartTimeRestricted, isEndTimeRestricted })

        // SAME TIMEFRAME IN CALENDAR COLUMN
        if (isStartTimeRestricted && isEndTimeRestricted) {
          const { areas, retailers } = restriction

          if (retailers && retailers.length > 0) {
            if (type === RESTRICTION_TYPE.CAN_BOOK) {
              isRestrict = !retailers.some(r => r.id === retailer._id)
            }
            if (type === RESTRICTION_TYPE.CAN_NOT_BOOK) {
              isRestrict = retailers.some(r => r.id === retailer._id)
            }
          }

          if (areas && areas.length > 0) {
            const { storeAddress } = retailer

            const isAreaMatch = areas.some(
              a =>
                (a.type === "continent" && a.value === storeAddress.continent) ||
                (a.type === "country" && a.value === storeAddress.country),
            )

            //console.log("storeAddress", { storeAddress, areas, type, isAreaMatch })

            if (type === RESTRICTION_TYPE.CAN_BOOK) {
              if (isAreaMatch && isRestrict) {
                isRestrict = false
              }
              if (!isAreaMatch && isRestrict === null) {
                isRestrict = true
              }
            }

            if (type === RESTRICTION_TYPE.CAN_NOT_BOOK && isAreaMatch) {
              isRestrict = true
            }
          }
        }
      }
      return isRestrict
    })

    return isRestrict
  }

  isRestrictByTime = (dayDate, start, end) => {
    // console.log("isRestrictByTime", {
    //   dayDate: toMomentUTC(dayDate).format("DD.MM.YYYY"),
    //   //startStamp: start,
    //   //endStamp: end,
    //   start: toMomentUTC(start).format("DD.MM.YYYY - HH:mm"),
    //   end: toMomentUTC(end).format("DD.MM.YYYY - HH:mm"),
    // })
    const hasRestrictionCanBook = this.hasRestriction(dayDate, { start, end }, RESTRICTION_TYPE.CAN_BOOK)
    const hasRestrictionCanNotBook = this.hasRestriction(dayDate, { start, end }, RESTRICTION_TYPE.CAN_NOT_BOOK)

    // console.log("isRestrictByTime", {
    //   hasRestrictionCanBook,
    //   hasRestrictionCanNotBook,
    // })

    const isRestricted =
      (!isNull(hasRestrictionCanBook) && hasRestrictionCanBook === true) ||
      (!isNull(hasRestrictionCanNotBook) && hasRestrictionCanNotBook === true)

    return isRestricted
  }

  renderColumns = (topOffset, withTransitions) => {
    const { session, suggestions } = this.props

    const selectedBookings = {}
    ;(this.state.bookings || []).forEach(booking => {
      booking.forEach(bookedRoom => {
        selectedBookings[bookedRoom.room] = bookedRoom
      })
    })

    const oldSuggestions = withTransitions ? this.state.oldSuggestions || [] : []

    const now = moment.utc()
    return suggestions.map((suggestion, dayIndex) => {
      if (moment.utc(suggestion.date, "X").isBefore(now, "day")) {
        return false
      }

      const widest = CalendarGridColumnWidth
      const width = Math.max(widest, widest * suggestion.daySuggestions.length)
      const marginTop = topOffset

      const timezone = session.timezone.split("/")[1]

      return (
        <DateColumn key={suggestion.date}>
          <DateColumnHeader>{moment.utc(suggestion.date, "X").format("dddd Do, MMM.")}</DateColumnHeader>
          <SuggestionList withTransitions={withTransitions} style={{ width, marginTop }}>
            {suggestion.daySuggestions.map((dayColumn, index) => {
              let dayTime = suggestion.date

              let oldDayColumn = []
              if (
                oldSuggestions &&
                oldSuggestions[dayIndex] &&
                oldSuggestions[dayIndex].daySuggestions &&
                oldSuggestions[dayIndex].daySuggestions[index]
              ) {
                oldDayColumn = oldSuggestions[dayIndex].daySuggestions[index]
              }

              const keys = {}

              return (
                <div key={index}>
                  {oldDayColumn.concat(dayColumn).map(daySuggestion => {
                    const isOld = oldDayColumn.indexOf(daySuggestion) > -1

                    // IF WE NEED TO CHECK INDIVIDUAL TIME
                    // const isRestrictList = daySuggestion.map(day =>
                    //   this.isRestrictByTime(day.dayDate, day.startTime, day.endTime),
                    // )
                    //const daySuggestion = suggestion.filter((day, index) => isRestrictList[index] === false && day)
                    const start = daySuggestion[0].startTime
                    const end = maxBy(daySuggestion, o => o.endTime).endTime

                    const isRestrict = this.isRestrictByTime(daySuggestion[0].dayDate, start, end)

                    if (isRestrict) {
                      return null
                    }

                    const key = `${isOld}-${start}-${end}`

                    if (keys[key]) {
                      return false
                    }
                    keys[key] = true

                    const height = ((end - start) / (60 * 60)) * CalendarGridHourSize
                    const marginTop = ((start - dayTime) / (60 * 60)) * CalendarGridHourSize

                    const style = { height, marginTop }
                    if (isOld) {
                      style.animation = "fadeOut .5s forwards"
                    }

                    let selected = true
                    for (let i = 0, l = daySuggestion.length; i < l; ++i) {
                      const s = daySuggestion[i]
                      const roomBooking = selectedBookings[s.room]
                      if (
                        !roomBooking ||
                        (roomBooking.startTime !== s.startTime && roomBooking.endTime !== s.endTime)
                      ) {
                        selected = false
                        break
                      }
                    }

                    dayTime = end

                    return (
                      <Suggestion style={style} key={key} onClick={() => this.onSelectSuggestion(daySuggestion)}>
                        {daySuggestion.length > 1 ? (
                          <SuggestionItem selected={selected}>
                            <span>
                              {`${moment.utc(start, "X").format("H:mm")} - ${moment.utc(end, "X").format("H:mm")}`}
                              <br />
                              <small>({`Timezone :  ${timezone}`})</small>
                            </span>
                            {daySuggestion.map(room => {
                              const start = moment.utc(room.startTime, "X").format("H:mm")
                              const end = moment.utc(room.endTime, "X").format("H:mm")
                              return <div key={room.room}>{`${room.room}: ${start} - ${end}`}</div>
                            })}
                          </SuggestionItem>
                        ) : (
                          <SuggestionItem selected={selected}>
                            {daySuggestion.map(room => {
                              const start = moment.utc(room.startTime, "X").format("H:mm")
                              const end = moment.utc(room.endTime, "X").format("H:mm")
                              return (
                                <span key={room.room}>
                                  {`${start} - ${end}`}
                                  <br />
                                  <small>({`Timezone :  ${timezone}`})</small>
                                </span>
                              )
                            })}
                          </SuggestionItem>
                        )}
                      </Suggestion>
                    )
                  })}
                </div>
              )
            })}
          </SuggestionList>
        </DateColumn>
      )
    })
  }

  renderEmptyStateModal = () => {
    const { goToStep } = this.props
    const { showNoSuggestionsModal } = this.state

    return (
      <Modal isOpen={showNoSuggestionsModal} style={customStyles2}>
        <div>
          <div style={{ fontWeight: "bold" }}>Darn, looks like you were late to the party!</div>
          <Text margin="20px 0">
            {`There are unfortunately no more available meeting slots with your applied filters.
              We advise you to simplify your filters, and if that still doesn’t work for you - send a special request to the brand.`}
          </Text>
        </div>
        <ButtonsRow>
          <Button
            style={{ textTransform: "initial" }}
            onClick={() => {
              this.closeModal("showNoSuggestionsModal")
              goToStep("meetingType")
            }}
          >
            Change filters
          </Button>
          <div style={{ width: "16px" }} />
          <Button
            style={{ textTransform: "initial" }}
            onClick={() => {
              this.closeModal("showNoSuggestionsModal")
              this.showJokerModal()
            }}
          >
            Special request
          </Button>
        </ButtonsRow>
      </Modal>
    )
  }

  getCalendarMinMax = () => {
    let startTime = 0
    this.props.suggestions.forEach(day => {
      if (!day.daySuggestions.length) {
        return
      }

      day.daySuggestions.forEach(suggestion => {
        const firstSuggestion = suggestion[0]
        const dayStart = firstSuggestion[0].startTime - day.date

        if (!startTime || startTime > dayStart) {
          startTime = dayStart
        }
      })
    })

    const calendarHours = []
    for (let i = 0, l = 24; i <= l; ++i) {
      calendarHours.push(i * 60 * 60)
    }

    return { startTime, calendarHours }
  }

  render() {
    const { goToStep, isVideoMeeting, isLanguageTab, isFetchingSuggestions, brand, session } = this.props
    const dateForm = moment.utc(session.openingDay, "X").format("dddd, Do MMM")
    const dateTo = moment.utc(session.closingDay, "X").format("dddd, Do MMM YYYY")
    const { startTime, calendarHours } = this.getCalendarMinMax()

    const timezone = session.timezone.split("/")[1]

    const suggestionsSlice = this.props.suggestionsSlice || {
      slice: 0,
      slices: 0,
    }

    const hourSidebarOffset =
      (parseInt(moment.utc(calendarHours[0], "X").format("m")) / 60 + startTime / 60 / 60) * CalendarGridHourSize

    const bookings = this.state.bookings || []
    const sidebarOpen = (this.props.suggestionsClients || []).length > 0

    const withTransitions = this.state.showGridTransitions

    return (
      <Container fullPage>
        <Header>
          <img alt="logo" src={logo} height={24} />
          <div>
            <div>{`${brand.name} - ${session.address}`}</div>
            <div>
              {`Open from ${dateForm} - ${dateTo}`}
              <strong>{` - Timezone : ${timezone}`}</strong>
            </div>
          </div>
        </Header>
        <Nav>
          <NavigationButton secondary onClick={() => goToStep("confirmInfos")}>
            General Info
          </NavigationButton>
          <NavigationButton secondary onClick={() => goToStep("meetingType")}>
            Meeting Type
          </NavigationButton>
          {isLanguageTab && (
            <NavigationButton secondary onClick={() => goToStep("languageRequest")}>
              Language
            </NavigationButton>
          )}
          {isVideoMeeting && (
            <NavigationButton secondary onClick={() => goToStep("videoTool")}>
              Virtual Meeting Type
            </NavigationButton>
          )}
          <NavigationButton secondary onClick={() => goToStep("rooms")}>
            Selected Rooms
          </NavigationButton>
          <NavigationButton secondary onClick={() => goToStep("meetingAssignation")}>
            Assigned Buyers
          </NavigationButton>
          {suggestionsSlice.slices > 1 && (
            <OptionsButton negative onClick={this.showMore}>
              {((suggestionsSlice.slice + 1) % suggestionsSlice.slices && "Give me other options") || "Start Over"}
            </OptionsButton>
          )}
          {(this.state.showSpecialRequest || suggestionsSlice.slices * 2 < suggestionsSlice.slice + 1) && (
            <OptionsButton negative onClick={this.showJokerModal}>
              Special Request
            </OptionsButton>
          )}
        </Nav>
        <Calendar>
          <CalendarContentWrapper>
            <BookedOptions
              visible={sidebarOpen}
              suggestionsClients={this.props.suggestionsClients}
              bookings={bookings}
              rooms={this.props.selectedRoom}
              clients={this.props.clients.byId}
              clientIndex={this.props.suggestionsClientIndex}
              onBookingClick={this.selectClient}
              onConfirmClick={this.confirmBooking}
            />
            <DateColumnHeader style={{ position: "absolute", width: "100%" }} />
            <DateHourSidebarLines withTransitions={withTransitions} style={{ top: -hourSidebarOffset - 1 }}>
              {calendarHours.map(hour => (
                <span key={hour}>
                  <span></span>
                </span>
              ))}
            </DateHourSidebarLines>
            <DateHourSidebar
              withTransitions={withTransitions}
              withSidebar={sidebarOpen}
              style={{ top: -hourSidebarOffset + 44 }}
            >
              {calendarHours.map(hour => (
                <span key={hour}>
                  {moment
                    .utc(hour, "X")
                    .format("h A")
                    .split(" ")
                    .join("\n")}
                </span>
              ))}
            </DateHourSidebar>
            <Suggestions withSidebar={sidebarOpen}>
              {this.renderColumns(-hourSidebarOffset, withTransitions)}
            </Suggestions>
            {isFetchingSuggestions && <Spinner isOpen />}
          </CalendarContentWrapper>
        </Calendar>
        {this.renderConfirmModal()}
        {this.renderJokerModal()}
        {this.renderEmptyStateModal()}
      </Container>
    )
  }
}

Matches.propTypes = {
  bookingRequestError: PropTypes.any,
  bookingRequestPending: PropTypes.bool,
  bookingsData: PropTypes.object,
  brand: PropTypes.object,
  confirmBookind: PropTypes.func,
  goToStep: PropTypes.func,
  isEditing: PropTypes.bool,
  isFetchingSuggestions: PropTypes.bool,
  isVideoMeeting: PropTypes.bool,
  isLanguageTab: PropTypes.bool,
  jokerRequestError: PropTypes.any,
  jokerRequestStatus: PropTypes.bool,
  requestJoker: PropTypes.func,
  selectedEvent: PropTypes.object,
  selectedRoom: PropTypes.array,
  session: PropTypes.object,
  suggestions: PropTypes.array,
  suggestionsClients: PropTypes.array,
  suggestionsClientIndex: PropTypes.number,
  suggestionsSlice: PropTypes.object,
  onBookingConfirmed: PropTypes.func,
  onClearSuggestion: PropTypes.func,
  onConfirmAutoBookings: PropTypes.func,
  onEditAutoBookings: PropTypes.func,
  onEditAutoJokerBookings: PropTypes.func,
  onEditJokerRequest: PropTypes.func,
  onFetchSuggestions: PropTypes.func,
}

export default Matches
