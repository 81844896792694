export const normalizeData = dataArray => {
  const allIds = []
  const byId = {}
  dataArray.forEach(item => {
    byId[item._id] = item
    allIds.push(item._id)
  })
  return { byId, allIds }
}

export const normalizeSchedules = schedules => {
  const allIds = []
  const byId = {}
  const idsBySession = {}
  schedules.forEach(item => {
    byId[item._id] = item
    allIds.push(item._id)
    idsBySession[item.sessionId] = item._id
  })
  return { byId, allIds }
}
