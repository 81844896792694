import {
  AutocompleteContainer,
  Bold,
  EventInput,
  Flex,
  Icons,
  LoaderContainer,
  MeetingClients,
  MeetingDate,
  MeetingInfo,
} from "../styles"
import { Button, Div } from "../../UI"
import { InputContainer, ValidationError } from "../../UI/Input"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"
import React, { Component } from "react"

import { BookingModalDialogBody } from "../../UI/BookingModal"
import { ReactComponent as CloseIcon } from "../../../static/cancel-music.svg"
import { ReactComponent as DeleteIcon } from "../../../static/waste-bin.svg"
import Modal from "react-modal"
import PropTypes from "prop-types"
import ReactPhoneInput from "react-phone-input-2"
import Select from "react-select"
import { SyncLoader } from "react-spinners"
import TimeIcon from "../../../static/clock.svg"
import { getTimeOptions } from "../../../config/date"
import isEmpty from "lodash/isEmpty"
import moment from "moment-timezone"

const timeOptions = {}

const control = base => ({
  ...base,
  borderColor: "#f8263f",
})

export const setTimeToDate = (dateStamp, timestamp) => {
  const todayDate = moment.utc(dateStamp, "X").format("YYYY:MM:DD")
  const DateHour = moment.utc(timestamp, "X").format("HH:mm")
  const [year, month, date] = todayDate.split(":")
  const [hours, minutes] = DateHour.split(":")
  return moment
    .utc()
    .set({ year, month: month - 1, date, hours, minutes })
    .unix()
}

const customEventStyles = {
  content: {
    width: "510px",
    maxHeight: "80vh",
    overflowY: "auto",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    padding: "25px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 21,
  },
}

export const meetingTypes = [
  { value: "businessMeeting", label: "Business Meeting" },
  { value: "walkthrough", label: "Walkthrough" },
  { value: "buyingAppointment", label: "Buying Appointment" },
  { value: "photoshoot", label: "Photoshoot" },
  { value: "other", label: "Other" },
]

export class MREvent extends Component {
  constructor(props) {
    super(props)
    const { eventData, calendarOwner, colleagues, allColleagues } = this.props
    const colleaguesOptions = []
    allColleagues.forEach(id => {
      if (id === (calendarOwner && calendarOwner._id)) {
        colleaguesOptions.push({
          value: calendarOwner._id,
          label: `${calendarOwner.firstName} ${calendarOwner.lastName}, ${calendarOwner.position} - ${calendarOwner.email} (me)`,
        })
      } else {
        const colleague = colleagues.byId[id]
        colleaguesOptions.push({
          value: colleague._id,
          label: `${colleague.firstName} ${colleague.lastName}, ${colleague.position} - ${colleague.email}`,
        })
      }
    })

    this.state = {
      eventData,
      colleaguesOptions,
      errors: {},
      touched: {},
      isChanged: false,
      gmapsLoaded: false,
      address: "",
    }
  }

  componentDidMount() {
    const { onFetchTimezone, eventData } = this.props
    if (!document.getElementById("gmaps-api")) {
      window.initMap = this.initMap
      const gmapScriptEl = document.createElement(`script`)
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC3boOLqNgMBUDAzCemBK_B0-yO8u7VfmU&libraries=places&callback=initMap`
      gmapScriptEl.id = "gmaps-api"
      document
        .querySelector(`body`)
        .insertAdjacentElement(`beforeend`, gmapScriptEl)
    } else this.initMap()
    if (eventData.location) {
      this.setState({
        address: eventData.location,
      })
      geocodeByAddress(eventData.location)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
          onFetchTimezone(latLng)
        })
        .catch(error => console.error("Error", error))
    }
  }

  initMap = () => {
    this.setState({
      gmapsLoaded: true,
    })
  }

  handleChange = value => {
    this.setState({
      address: value,
    })
  }

  handleSelect = address => {
    const { onFetchTimezone } = this.props
    this.setState({
      address,
      ...this.validate(address, "location"),
      isChanged: true,
    })
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        onFetchTimezone(latLng)
      })
      .catch(error => console.error("Error", error))
  }

  onBlur = e => {
    const { touched } = this.state
    const name = e.target.name
    touched[name] = true
    this.setState({ touched })
  }

  onInputChange = event => {
    if (typeof event === "object") {
      const name = event.target.name
      const value = event.target.value
      this.setState({ ...this.validate(value, name), isChanged: true })
    } else {
      this.setState({ ...this.validate(event, "phoneNumber"), isChanged: true })
    }
  }

  onChangeColleages = values => {
    this.setState(prevState => {
      const errors = { ...prevState.errors }
      delete errors.attendees
      return {
        touched: {
          ...prevState.touched,
          attendees: true,
        },
        eventData: {
          ...prevState.eventData,
          attendees: values,
        },
        errors,
        isChanged: true,
      }
    })
  }

  onChangeType = values => {
    this.setState(prevState => {
      const errors = { ...prevState.errors }
      delete errors.meetingType
      return {
        touched: {
          ...prevState.touched,
          meetingType: true,
        },
        eventData: {
          ...prevState.eventData,
          meetingType: values.value,
        },
        errors,
        isChanged: true,
      }
    })
  }

  onChangeTime = (value, name) => {
    this.setState(prevState => ({
      eventData: {
        ...prevState.eventData,
        [name]: value.timestamp,
      },
      isChanged: true,
    }))
    this.props.onChangeTime(value.timestamp, name)
  }

  validate = (value, name) => {
    const { eventData, errors } = this.state
    switch (name) {
      case "title":
      case "location":
      case "meetingType":
        if (value.trim().length > 0) {
          delete errors[name]
          return {
            eventData: { ...eventData, [name]: value },
            errors,
          }
        }
        errors[name] = `Required`
        return {
          eventData: { ...eventData, [name]: value },
          errors,
        }

      case "fullName":
        if (value.trim().length > 0) {
          delete errors[name]
          return {
            eventData: {
              ...eventData,
              host: {
                ...eventData.host,
                [name]: value,
              },
            },
            errors,
          }
        }
        errors[name] = `Required`
        return {
          eventData: {
            ...eventData,
            host: {
              ...eventData.host,
              [name]: value,
            },
          },
          errors,
        }

      case "email": {
        const reg = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            eventData: {
              ...eventData,
              host: {
                ...eventData.host,
                [name]: value,
              },
            },
            errors,
          }
        } else if (reg.test(value) === true) {
          delete errors[name]
          return {
            eventData: {
              ...eventData,
              host: {
                ...eventData.host,
                [name]: value,
              },
            },
            errors,
          }
        }
        errors[name] = `Invalid email address`
        return {
          eventData: {
            ...eventData,
            host: {
              ...eventData.host,
              [name]: value,
            },
          },
          errors,
        }
      }
      case "phoneNumber":
      case "role":
        delete errors[name]
        return {
          eventData: {
            ...eventData,
            host: {
              ...eventData.host,
              [name]: value,
            },
          },
          errors,
        }
      case `attendees`:
        if (value.length === 0) {
          errors[name] = `Required`
          return {
            errors,
          }
        }
        break
      default:
        break
    }
  }

  onSave = () => {
    const { eventData, errors, touched } = this.state
    for (const key in eventData) {
      this.validate(eventData[key], key)
      touched[key] = true
    }
    for (const key in eventData.host) {
      this.validate(eventData.host[key], key)
      touched[key] = true
    }

    if (isEmpty(errors)) {
      this.props.onSaveEvent(eventData)
    } else {
      this.setState({ errors, touched })
    }
  }

  render() {
    const {
      newEventModal,
      onCloseModal,
      onDelete,
      requestMREventPending,
      calendarOwner,
    } = this.props
    const {
      eventData,
      colleaguesOptions,
      errors,
      touched,
      isChanged,
    } = this.state
    if (!timeOptions[eventData.dayDate]) {
      timeOptions[eventData.dayDate] = getTimeOptions(eventData.dayDate)
    }

    let colleaguesValue = []
    if (eventData.attendees) {
      if (typeof eventData.attendees[0] === "string") {
        colleaguesValue = colleaguesOptions.filter(colleague =>
          eventData.attendees.includes(colleague.value),
        )
      } else {
        colleaguesValue = eventData.attendees
      }
    }

    return (
      <Modal isOpen={newEventModal} style={customEventStyles}>
        <BookingModalDialogBody style={{ padding: 0 }}>
          {requestMREventPending && (
            <LoaderContainer style={{ zIndex: 15 }}>
              <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
            </LoaderContainer>
          )}
          <Icons>
            {calendarOwner && eventData && eventData._id && (
              <div onClick={() => onDelete(eventData._id, "mrevent")}>
                <DeleteIcon fill="#000" height="15px" width="100%" />
              </div>
            )}
            <div>
              <CloseIcon
                fill="#000"
                height="12px"
                width="100%"
                onClick={() => onCloseModal(null, "newEventModal")}
              />
            </div>
          </Icons>
          <InputContainer style={{ marginBottom: "17px" }}>
            <EventInput
              error={errors.title && touched.title}
              name="title"
              placeholder="Add meeting title..."
              type="text"
              value={eventData.title || ""}
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
            {errors.title && touched.title && (
              <ValidationError>{errors.title}</ValidationError>
            )}
          </InputContainer>
          <InputContainer
            style={{ marginBottom: "17px", position: "relative", zIndex: 14 }}
          >
            <Select
              options={meetingTypes}
              placeholder="Select meeting type"
              styles={{
                control:
                  errors.meetingType && touched.meetingType ? control : "",
              }}
              value={meetingTypes.filter(
                opt => opt.value === eventData.meetingType,
              )}
              onChange={this.onChangeType}
            />
            {errors.meetingType && touched.meetingType && (
              <ValidationError>{errors.meetingType}</ValidationError>
            )}
          </InputContainer>
          <InputContainer style={{ marginBottom: "17px" }}>
            <EventInput
              error={errors.fullName && touched.fullName}
              name="fullName"
              placeholder="Enter name of host"
              type="text"
              value={(eventData.host && eventData.host.fullName) || ""}
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
            {errors.fullName && touched.fullName && (
              <ValidationError>{errors.fullName}</ValidationError>
            )}
          </InputContainer>
          <InputContainer style={{ marginBottom: "17px" }}>
            <EventInput
              name="role"
              placeholder="Enter role of host"
              type="text"
              value={(eventData.host && eventData.host.role) || ""}
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
          </InputContainer>
          <InputContainer style={{ marginBottom: "17px" }}>
            <EventInput
              error={errors.email && touched.email}
              name="email"
              placeholder="Enter email"
              type="text"
              value={(eventData.host && eventData.host.email) || ""}
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
            {errors.email && touched.email && (
              <ValidationError>{errors.email}</ValidationError>
            )}
          </InputContainer>
          <InputContainer style={{ marginBottom: "10px" }}>
            <ReactPhoneInput
              country={"fr"}
              inputExtraProps={{
                name: "phoneNumber",
              }}
              enableSearch
              disableSearchIcon
              searchStyle={{ width: "95%" }}
              value={eventData.host && eventData.host.phoneNumber}
              onChange={this.onInputChange}
            />
          </InputContainer>
          <InputContainer style={{ marginBottom: "17px" }}>
            {}
            {}
            <PlacesAutocomplete
              name="location"
              value={this.state.address}
              onChange={this.handleChange}
              onBlur={this.onBlur}
              onError={() => {}}
              onSelect={address => {
                this.handleSelect(address)
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <EventInput
                    {...getInputProps({
                      placeholder: "Location",
                      className: "location-search-input",
                    })}
                  />
                  {}
                  <AutocompleteContainer
                    border={
                      suggestions && suggestions.length > 0 ? null : "none"
                    }
                  >
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item"

                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                            borderBottom: "1px solid gray",
                            paddingBottom: "3px",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                            borderBottom: "1px solid gray",
                            paddingBottom: "3px",
                          }
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      )
                    })}
                  </AutocompleteContainer>
                </div>
              )}
            </PlacesAutocomplete>
          </InputContainer>
          <MeetingClients style={{ borderWidth: "0.5px" }}>
            <Div.Flex
              align="flex-start"
              column
              justify="flex-start"
              style={{ marginBottom: "5px" }}
            >
              <Bold>Meeting Attendees:</Bold>
              <div
                style={{
                  width: "100%",
                  marginTop: "5px",
                  position: "relative",
                }}
              >
                <Select
                  classNamePrefix={"colleagues"}
                  closeOnSelect={false}
                  isMulti
                  maxMenuHeight={150}
                  options={colleaguesOptions}
                  placeholder="Clients"
                  styles={{
                    control:
                      errors.attendees && touched.attendees ? control : "",
                  }}
                  value={colleaguesValue}
                  onChange={this.onChangeColleages}
                />
                {errors.attendees && touched.attendees && (
                  <ValidationError>{errors.attendees}</ValidationError>
                )}
              </div>
            </Div.Flex>
          </MeetingClients>
          <MeetingInfo style={{ borderWidth: "0.5px", borderRadius: "3px" }}>
            <div style={{ width: "80%" }}>
              <MeetingDate>
                <div>
                  <img alt="icon" src={TimeIcon} />
                </div>
                <div style={{ width: "100%" }}>
                  <div style={{ marginBottom: "5px" }}>
                    {moment
                      .utc(eventData.dayDate, "X")
                      .format("dddd, MMMM Do Y")}
                  </div>
                  <Flex>
                    <div style={{ width: "45%" }}>
                      <Select
                        isOptionDisabled={option =>
                          option.timestamp >= eventData.endTime
                        }
                        key={eventData.endTime}
                        options={timeOptions[eventData.dayDate]}
                        value={timeOptions[eventData.dayDate].filter(
                          ({ timestamp }) => timestamp === eventData.startTime,
                        )}
                        onChange={value =>
                          this.onChangeTime(value, "startTime")
                        }
                      />
                    </div>
                    {" to "}
                    <div style={{ width: "45%" }}>
                      <Select
                        isOptionDisabled={option =>
                          option.timestamp <= eventData.startTime
                        }
                        key={eventData.startTime}
                        options={timeOptions[eventData.dayDate]}
                        value={timeOptions[eventData.dayDate].filter(
                          ({ timestamp }) => timestamp === eventData.endTime,
                        )}
                        onChange={value => this.onChangeTime(value, "endTime")}
                      />
                    </div>
                  </Flex>
                </div>
              </MeetingDate>
            </div>
          </MeetingInfo>
          <Flex column style={{ minHeight: "30px" }}>
            {calendarOwner && (
              <Button
                align="center"
                disabled={!isChanged}
                type="button"
                onClick={this.onSave}
              >
                Save
              </Button>
            )}
          </Flex>
        </BookingModalDialogBody>
      </Modal>
    )
  }
}

MREvent.propTypes = {
  allColleagues: PropTypes.array,
  calendarOwner: PropTypes.object,
  colleagues: PropTypes.object,
  eventData: PropTypes.object,
  newEventModal: PropTypes.bool,
  requestMREventPending: PropTypes.bool,
  onChangeTime: PropTypes.func,
  onCloseModal: PropTypes.func,
  onDelete: PropTypes.func,
  onSaveEvent: PropTypes.func,
}

export default MREvent
