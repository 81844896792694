import { createAsyncAction } from "../../helpers/redux"

export const FETCH_SALE_SESSIONS = createAsyncAction("FETCH_SALE_SESSIONS")
export const fetchSaleSassions = brandId => ({
  type: FETCH_SALE_SESSIONS.REQUEST,
  payload: {
    brandId,
  },
})

export const FETCH_SALE_SESSION = createAsyncAction("FETCH_SALE_SESSION")
export const fetchSaleSession = id => ({
  type: FETCH_SALE_SESSION.REQUEST,
  payload: {
    id,
  },
})

export const FETCH_LANGUAGES = createAsyncAction("FETCH_LANGUAGES")
export const fetchLanguages = data => ({
  type: FETCH_LANGUAGES.REQUEST,
  payload: data,
})

export const FETCH_TIMEZONE = createAsyncAction("FETCH_TIMEZONE")
export const fetchTimezone = latLng => ({
  type: FETCH_TIMEZONE.REQUEST,
  payload: latLng,
})
