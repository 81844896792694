import { ButtonsRow, RoomContainer, StepContainer, Text, Title } from "../styles"
import React, { Component } from "react"

import { Button } from "../UI"

import { PropTypes } from "prop-types"
import { Room } from "./components/Roomassign"
import isEmpty from "lodash/isEmpty"
import { ButtonSecondary } from "../UI/Button"

export class MeetingAssignation extends Component {
  constructor(props) {
    super(props)
    const {
      bookingsData: { rooms },
      selectedEvent,
      session,
    } = this.props
    this.clientLength = {}
    let newRooms
    if (selectedEvent && selectedEvent.room) {
      newRooms = [
        {
          roomName: selectedEvent.room,
          clients: selectedEvent.clients,
          length: session.customBuyingAppointment ? (selectedEvent.endTime - selectedEvent.startTime) / 60 : 0,
        },
      ]
      this.clientLength[selectedEvent.room] = {}
    } else {
      newRooms = rooms.map(room => {
        this.clientLength[room.roomName] = {}
        return {
          ...room,
          length: session.customBuyingAppointment ? room.length : 0,
        }
      })
    }
    this.state = {
      rooms: newRooms,
      modalIsOpened: false,
      errors: {},
    }
  }

  onSelectClient = (value, roomName, index) => {
    this.setState(state => {
      const newErrors = { ...state.errors }
      delete newErrors[roomName]
      this.clientLength[roomName][index] = true
      return {
        rooms: state.rooms.map(room => {
          if (room.roomName === roomName) {
            const { clients } = room
            clients[index] = value
            return { ...room, clients }
          }
          return room
        }),
        errors: newErrors,
      }
    })
  }

  onChangeLength = (value, roomName) => {
    this.setState(state => ({
      rooms: state.rooms.map(room => {
        if (room.roomName === roomName) {
          return { ...room, length: value }
        }
        return room
      }),
    }))
  }

  onAddColleague = roomIndex => {
    this.setState(state => {
      const { rooms } = state
      const room = rooms[roomIndex]
      rooms[roomIndex] = { ...room, clients: [...room.clients, null] }
      return {
        rooms,
      }
    })
  }

  onDeleteColleague = (roomName, clientIndex) => {
    this.setState(state => {
      const { rooms } = state
      const roomIndex = rooms.findIndex(room => room.roomName === roomName)
      rooms[roomIndex] = {
        ...rooms[roomIndex],
        clients: rooms[roomIndex].clients.filter((client, index) => index !== clientIndex),
      }
      return {
        rooms,
      }
    })
  }

  onShowModal = () => {
    const { rooms, errors } = this.state
    const newError = { ...errors }
    const newRooms = []
    rooms.forEach(room => {
      const clients = room.clients.filter(id => id !== null)
      if (clients.length > 0) {
        newRooms.push({ ...room, clients })
      } else {
        newError[room.roomName] = "Required"
      }
    })
    if (isEmpty(newError)) {
      this.setState({ rooms: newRooms })
      this.props.onNextClick({ rooms: newRooms })
    } else {
      this.setState({ errors: newError })
    }
  }

  render() {
    const { onPrevClick, clients, collegues, visibleTimeSelect, selectedEvent } = this.props
    const { rooms, errors } = this.state
    const Rooms = rooms.map((room, index) => (
      <Room
        allClients={clients}
        clientLength={this.clientLength}
        clients={room.clients}
        errors={errors[room.roomName]}
        key={index}
        meetingLength={room.length}
        options={collegues}
        roomName={room.roomName}
        visibleTimeSelect={visibleTimeSelect}
        onAddColleague={() => this.onAddColleague(index)}
        onChange={this.onSelectClient}
        onChangeLength={this.onChangeLength}
        onDeleteColleague={this.onDeleteColleague}
      />
    ))
    return (
      <StepContainer isOverflow>
        <Title>4. Meeting Assignation</Title>
        <Text margin="20px 0 20px 0">
          Please confirm who will attend:
          <p style={{ marginTop: 20 }}>
            {`If a colleague is missing from the list, please `}
            <a href="https://stephaniesmith855628.typeform.com/to/PZJryy" rel="noopener noreferrer" target="_blank">
              click here
            </a>{" "}
            and fill the form for us to add them.
          </p>
          <p style={{ marginTop: 20 }}>NB! You will have to come back here to finish your booking.</p>
        </Text>
        <RoomContainer>{Rooms}</RoomContainer>
        <ButtonsRow>
          {(!selectedEvent || (selectedEvent && !selectedEvent.room)) && (
            <ButtonSecondary margin="0 20px 0 0" onClick={onPrevClick}>
              Previous
            </ButtonSecondary>
          )}
          <Button onClick={this.onShowModal}>See Matches</Button>
        </ButtonsRow>
      </StepContainer>
    )
  }
}

MeetingAssignation.propTypes = {
  bookingsData: PropTypes.object,
  clients: PropTypes.object,
  collegues: PropTypes.array,
  selectedEvent: PropTypes.object,
  session: PropTypes.object,
  visibleTimeSelect: PropTypes.bool,
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
}
