import "react-toastify/dist/ReactToastify.css"
import "react-phone-input-2/lib/style.css"

import React, { Component } from "react"
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom"

import { Calendar } from "../Calendar"
import EditPage from "../EditPage"
import InviteBooking from "../InviteBooking"
import { InviteModal } from "../InviteModal"
import Login from "../Login"
import { Logout } from "./Logout"
import NoMatch from "../NoMatch"
import PropTypes from "prop-types"
import { ResetPassword } from "../ResetPassword/ResetPasswordForm"
import { ResetPasswordEmail } from "../ResetPassword/EmailForm"
import Settings from "../Settings/Settings"
import Sync from "../Sync"
import Team from "../Team/Team"
import { ToastContainer } from "react-toastify"
import { Topbar } from "../Topbar"
import VideoCall from "../VideoCall"
import VideoPresentation from "../VideoPresentation"
import { connect } from "react-redux"
import { createGlobalStyle } from "styled-components"
import { fetchDBData } from "../../store/actions/user"
import loading from "./loading.svg"
import { logout } from "store/actions/auth"
import ConnectAs from "../ConnectAs"
import ButtonStopConnectAs from "./ButtonStopConnectAs"

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 14px;
  }

  html, body {
    height: 100%;
    margin: 0;
  }

  #root {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  *, *:before, *:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
  }
`

const TopbarRoute = ({ component: Component, isAuthed, ...rest }) => (
  <Route
    {...rest}
    render={props => isAuthed && <Component {...props} {...rest} />}
  />
)

TopbarRoute.propTypes = {
  component: PropTypes.func,
  isAuthed: PropTypes.bool,
  location: PropTypes.object,
}

const ProctectedRoute = ({ component: Component, isAuthed, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthed ? (
        <Component {...props} {...rest} />
      ) : !isAuthed ? (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      ) : (
        <img alt="loading" className="loading-circle" src={loading} />
      )
    }
  />
)
ProctectedRoute.propTypes = {
  component: PropTypes.func,
  isAuthed: PropTypes.bool,
  location: PropTypes.object,
}
class App extends Component {
  logout = () => {
    this.props.onLogout()

    window.$crisp.push(["do", "session:reset", [false]])
  }

  componentDidMount() {
    const { onFetchDBData, isAuthed, isDataLoaded } = this.props
    const isLogoutRoute = window.location.pathname === "/logout"
    if (isAuthed && !isDataLoaded && !isLogoutRoute) onFetchDBData()
    window.$crisp = []
    window.CRISP_WEBSITE_ID = "c6a02827-001f-4560-ad18-062fc2956ad0"
    ;(function() {
      const d = document
      const s = d.createElement("script")

      s.src = "https://client.crisp.chat/l.js"
      s.async = 1
      d.getElementsByTagName("head")[0].appendChild(s)
    })()
  }

  render() {
    const { isAuthed, isDataLoaded } = this.props

    const path = "/(/|calendars|team|settings)?/"
    return (
      <React.Fragment>
        <Router>
          <React.Fragment>
            <GlobalStyle />
            {}
            <TopbarRoute
              component={Topbar}
              isAuthed={isAuthed}
              logout={this.logout}
              path={path}
            />
            {}
            <Switch>
              <Route component={ConnectAs} path="/connect-as/:token?" />
              <ProctectedRoute
                component={Calendar}
                isAuthed={isAuthed}
                loaded={isDataLoaded}
                path="/calendars"
              />
              <ProctectedRoute
                component={Settings}
                isAuthed={isAuthed}
                loaded={isDataLoaded}
                path="/settings"
              />
              <ProctectedRoute
                component={Team}
                isAuthed={isAuthed}
                loaded={isDataLoaded}
                path="/team"
              />
              <ProctectedRoute
                component={Logout}
                isAuthed={isAuthed}
                loaded
                logout={this.props.onLogout}
                path="/logout"
              />
              <Route
                path="/sync"
                render={props =>
                  isAuthed ? <Redirect to="/calendars" /> : <Sync {...props} />
                }
              />
              <Route
                exact
                path="/user/resetpassword"
                render={props =>
                  isAuthed ? (
                    <Redirect to="/calendars" />
                  ) : (
                    <ResetPassword {...props} />
                  )
                }
              />
              <Route
                exact
                path="/user/email"
                render={props =>
                  isAuthed ? (
                    <Redirect to="/calendars" />
                  ) : (
                    <ResetPasswordEmail {...props} />
                  )
                }
              />
              <Route
                exact
                path="/login"
                render={props =>
                  isAuthed ? <Redirect to="/calendars" /> : <Login {...props} />
                }
              />
              {}
              <Route component={InviteModal} exact path="team/list/invite" />
              <Route component={EditPage} exact path="/booking/:id/edit" />
              <Route component={InviteBooking} exact path="/booking/:id" />
              <Route component={VideoCall} exact path="/video-call/:id" />
              <Route
                component={VideoPresentation}
                exact
                path="/video-presentation/:id"
              />
              <Redirect to={"/calendars"} />
              <Route component={NoMatch} />
            </Switch>
            <ButtonStopConnectAs />
          </React.Fragment>
        </Router>
        <ToastContainer />
      </React.Fragment>
    )
  }
}

App.propTypes = {
  isAuthed: PropTypes.bool,
  isDataLoaded: PropTypes.bool,
  user: PropTypes.object,
  onFetchDBData: PropTypes.func,
  onLogout: PropTypes.func,
}

const mapStateToProps = ({ auth, user }) => ({
  isDataLoaded: user.dataLoaded,
  isAuthed: auth.isAuthed,
  user: user.user,
})

const actions = {
  onLogout: logout,
  onFetchDBData: fetchDBData,
}

export default connect(mapStateToProps, actions)(App)
