import * as R from "ramda"

export const getSaleSessions = R.path(["salesSessions", "salesSessions"])
export const getSelectedSessionId = R.path([
  "salesSessions",
  "selectedSessionId",
])

export const getSessionRequestStatus = R.path([
  "salesSessions",
  "requestPending",
])
export const getSessionRequestError = R.path(["salesSessions", "requestError"])

export const getSelectedSession = R.path(["salesSessions", "selectedSession"])

export const getLangFetchStatus = R.path([
  "salesSessions",
  "langRequestPending",
])
export const getAvailableLanguages = R.path([
  "salesSessions",
  "availableLanguages",
])
export const getFetchedTimezone = R.path(["salesSessions", "timezone"])
