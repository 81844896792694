import InfoToast from "../components/UI/Toast"
import React from "react"
import { toast } from "react-toastify"

export const onToastError = error => {
  toast.error(`ERROR ${error || "Notification !"}`, {
    position: toast.POSITION.TOP_RIGHT,
  })
}

export const onToastSuccess = message => {
  toast.success(`${message || ""}`, {
    position: toast.POSITION.TOP_RIGHT,
  })
}

export const onToastInfo = data => {
  toast(<InfoToast {...data} />, {
    hideProgressBar: true,
    className: "def-toast",
    autoClose: 7000,
  })
}
