import { createAsyncAction } from "../../helpers/redux"

export const FETCH_SCHEDULES = createAsyncAction("FETCH_SCHEDULES")
export const fetchSchedules = brandId => ({
  type: FETCH_SCHEDULES.REQUEST,
  payload: {
    brandId,
  },
})

export const FETCH_SCHEDULE = createAsyncAction("FETCH_SCHEDULE")
export const fetchSchedule = sessionId => ({
  type: FETCH_SCHEDULE.REQUEST,
  payload: {
    sessionId,
  },
})

export const CANCEL_BOOKING = createAsyncAction("CANCEL_BOOKING")

export const cancelBooking = (bookingId, eventIds) => ({
  type: CANCEL_BOOKING.REQUEST,
  payload: { bookingId, eventIds },
})

export const CANCEL_BOOKING_BUYER = createAsyncAction("CANCEL_BOOKING_BUYER")
export const cancelBuyerBooking = (bookingId, eventId) => ({
  type: CANCEL_BOOKING_BUYER.REQUEST,
  payload: { bookingId, eventId },
})

export const CONFIRM_BOOKING = createAsyncAction("CONFIRM_BOOKING")
export const confirmBooking = data => ({
  type: CONFIRM_BOOKING.REQUEST,
  payload: data,
})

export const CONFIRM_AUTO_BOOKINGS = createAsyncAction("CONFIRM_AUTO_BOOKINGS")
export const confirmAutoBookings = data => ({
  type: CONFIRM_AUTO_BOOKINGS.REQUEST,
  payload: data,
})

export const EDIT_AUTO_BOOKINGS = createAsyncAction("EDIT_AUTO_BOOKINGS")
export const editAutoBookings = data => ({
  type: EDIT_AUTO_BOOKINGS.REQUEST,
  payload: data,
})

export const EDIT_AUTO_BOOKINGS_JOKER = createAsyncAction("EDIT_AUTO_BOOKINGS_JOKER")
export const editAutoBookingsJoker = data => ({
  type: EDIT_AUTO_BOOKINGS_JOKER.REQUEST,
  payload: data,
})

export const FETCH_SUGGESTIONS = createAsyncAction("FETCH_SUGGESTIONS")
export const fetchSuggestions = data => ({
  type: FETCH_SUGGESTIONS.REQUEST,
  payload: data,
})

export const FETCH_VIDEO_CALL_EVENT = createAsyncAction("FETCH_VIDEO_CALL_EVENT")
export const fetchVideoCallEvent = data => ({
  type: FETCH_VIDEO_CALL_EVENT.REQUEST,
  payload: data,
})

export const REQUEST_JOKER = createAsyncAction("REQUEST_JOKER")
export const requestJoker = data => ({
  type: REQUEST_JOKER.REQUEST,
  payload: data,
})

export const FETCH_EVENTS = createAsyncAction("FETCH_EVENTS")

export const EDIT_APPOINTMENT = "EDIT_APPOINTMENT"
export const editAppoinment = data => ({
  type: EDIT_APPOINTMENT,
  payload: data,
})
export const DELAY_APPOINTMENT = createAsyncAction("DELAY_APPOINTMENT")
export const delayAppoinment = data => ({
  type: DELAY_APPOINTMENT.REQUEST,
  payload: data,
})

export const EDIT_JOKER_REQUEST = createAsyncAction("EDIT_JOKER_REQUEST")
export const editJokerRequest = data => ({
  type: EDIT_JOKER_REQUEST.REQUEST,
  payload: data,
})

export const FETCH_BOOKING_ID = createAsyncAction("FETCH_BOOKING_ID")
export const fetchBookingId = data => ({
  type: FETCH_BOOKING_ID.REQUEST,
  payload: data,
})

export const CLEAR_SUGGESTIONS = "CLEAR_SUGGESTIONS"
export const clearSuggestions = () => ({
  type: CLEAR_SUGGESTIONS,
})

export const DELETE_FASHION_EVENT = "DELETE_FASHION_EVENT"
