import { MenuItem as MI, MenuItemText as MIT } from "../../UI/SideMenu"

import styled from "styled-components"

const activeClassName = "menuitem-active"
export const MenuItem = styled(MI)`
  background-color: #fff;
  &:hover {
    text-decoration: none;
    padding-left: 14px;
    border-left: 6px solid ${props => props.theme.primary};
  }
  &.${activeClassName} {
    background-color: ${props => props.theme.gray75};
    padding-left: 14px;
    border-left: 6px solid ${props => props.theme.primary};
  }
`
export const MenuItemText = styled(MIT)``
