import {
  getRegisterData,
  getRequestInviteDataError,
  getRequestInviteDataPending,
} from "../../store/selectors"

import Component from "./Invite"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { fetchTokenData } from "../../store/actions/invites"

const selector = createStructuredSelector({
  inviteData: getRegisterData,
  requestPending: getRequestInviteDataPending,
  requestError: getRequestInviteDataError,
})

const actions = {
  onFetchData: fetchTokenData,
}

export const Invite = connect(selector, actions)(Component)
