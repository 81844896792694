import { AppContainer, Container } from "../styles"
import {
  LinkRow,
  Services,
  SmallContainer,
  SmallLogo,
  StepContainer,
  Text,
} from "./styles"
import React, { Component } from "react"

import { Button } from "./../UI"
import GoogleLogo from "../../static/google.jpg"
import { Link } from "react-router-dom"
import Logo from "../../static/PinkLogo.png"
import { PropTypes } from "prop-types"
import { REST_API_URL } from "../../config"
import logo from "../../static/smallOutlook.png"
import queryString from "query-string"

class Connect extends Component {
  componentDidMount() {
    const { client, history, inviteData } = this.props
    if (!((client && client._id) || inviteData)) {
      history.push("/login")
    }
  }

  render() {
    const { client, inviteData } = this.props
    const queryParams = {}
    if (client && client._id) {
      queryParams.clientId = client._id
    }
    if (inviteData) {
      if (inviteData.retailerId) {
        queryParams.retailerId = inviteData.retailerId
        queryParams.isShared = inviteData.isShared
      }
      if (inviteData.clientId) {
        queryParams.clientId = inviteData.clientId
        queryParams.isShared = inviteData.isShared
      }
    }
    return (
      <Container>
        <AppContainer>
          {}
          <StepContainer
            className="last-step"
            flexRow="flex-start"
            style={{ fontSize: 16, fontFamily: "Avenir Next" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "120px",
                backgroundColor: "rgb(255,229,228)",
              }}
            >
              <SmallLogo
                alt="logo"
                src={Logo}
                style={{ height: "60px", width: "auto" }}
              />
            </div>
            <SmallContainer>
              <Text margin="20px 0px 20px 0px">
                {`Sign up with your Gmail and Outlook, and synchronise your current calendar to your ModaResa calendar, so`}
              </Text>
              <div style={{ paddingLeft: "15px" }}>
                <ul>
                  <li>
                    We can know when you are not available when booking with
                    brands
                  </li>
                  <li>
                    View your existing appointments around the official show
                    calendars
                  </li>
                  <li>Avoid any double meetings within your team</li>
                  {}
                </ul>
              </div>
              <Services>
                <div style={{ backgroundColor: "#244AFB", padding: "10px" }}>
                  <a
                    href={`${REST_API_URL}/auth/google/signup?${queryString.stringify(
                      queryParams,
                    )}`}
                  >
                    <img
                      alt="google logo"
                      src={GoogleLogo}
                      style={{
                        width: "30px",
                        height: "30px",
                        paddingRight: "10px",
                      }}
                    />
                    <span style={{ color: "white" }}>Sign up with Google</span>
                  </a>
                </div>
                <div style={{ padding: "10px" }}>
                  <a
                    href={`${REST_API_URL}/auth/outlook/signup?${queryString.stringify(
                      queryParams,
                    )}`}
                  >
                    <img
                      alt="logo"
                      src={logo}
                      style={{
                        width: "30px",
                        height: "30px",
                        paddingLeft: "5px",
                        paddingRight: "10px",
                      }}
                    />
                    <span style={{ color: "#2271B7", fontWeight: "500" }}>
                      Sign up with Outlook
                    </span>
                  </a>
                </div>
                {}
                <div style={{ border: "none" }}>
                  <Link to={`${this.props.match.path}signup`}>
                    <Button
                      bgcolor="rgb(255,229,228)"
                      style={{ fontSize: 16, width: "100%", color: "#333" }}
                      type="button"
                    >
                      Sign up with email
                    </Button>
                  </Link>
                </div>
              </Services>
              <LinkRow style={{ marginBottom: "50px" }}>
                <p>
                  <span>Already have a profile</span>
                </p>
                <p>
                  <Link to={`/login`}>Login</Link>
                  <span> here</span>
                </p>
              </LinkRow>
            </SmallContainer>
            {}
          </StepContainer>
        </AppContainer>
      </Container>
    )
  }
}

Connect.propTypes = {
  client: PropTypes.object,
  history: PropTypes.object,
  inviteData: PropTypes.object,
  match: PropTypes.object,
}

export default Connect
