import { REST_API_URL } from "../../config"
import axios from "axios"

export const fetchClients = async params => {
  const config = {
    params,
  }
  const response = await axios.get(`${REST_API_URL}/buyers`, config)

  return response.data
}

export const fetchClient = async ({ id }) => {
  const response = await axios.get(`${REST_API_URL}/buyers/${id}`)

  return response.data
}

export const fetchSharedBuyerByUserId = async ({ id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(
    `${REST_API_URL}/buyers/userId/${id}`,
    config,
  )
  return response.data
}

export const fetchColleagues = async ({ buyerId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(
    `${REST_API_URL}/sharedBuyers/${buyerId}/colleagues`,
    config,
  )

  return response.data
}

export const fetchBuyerSchedule = async ({ buyerId, token, params }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params,
  }
  const response = await axios.get(
    `${REST_API_URL}/sharedBuyers/${buyerId}/schedule`,
    config,
  )

  return response.data
}

export const fetchColleaguesCalendar = async ({ buyerId, token, params }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params,
  }
  const response = await axios.get(
    `${REST_API_URL}/sharedBuyers/${buyerId}/schedule/all`,
    config,
  )

  return response.data
}

export const updateClient = async ({ clientId, token, data }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.patch(
    `${REST_API_URL}/sharedBuyers/${clientId}`,
    data,
    config,
  )

  return response.data
}

export const addEvent = async ({ buyerId, eventId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.post(
    `${REST_API_URL}/sharedBuyers/${buyerId}/addevent/${eventId}`,
    config,
  )

  return response.data
}

export const removeEvent = async ({ buyerId, eventId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }

  const response = await axios.delete(
    `${REST_API_URL}/sharedBuyers/${buyerId}/removeEvent/${eventId}`,
    config,
  )

  return response.data
}

export const createMrEvent = async ({ data }) => {
  const response = await axios.post(`${REST_API_URL}/events/mrevent`, data)

  return response.data
}

export const deleteMrEvent = async ({ eventId }) => {
  const response = await axios.delete(
    `${REST_API_URL}/events/mrevent/${eventId}`,
  )

  return response.data
}

export const updateMrEvent = async ({ data }) => {
  const response = await axios.patch(`${REST_API_URL}/events/mrevent`, data)

  return response.data
}

export const updateClientPhoneNumber = async ({ clientId, data, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }

  const response = await axios.patch(
    `${REST_API_URL}/buyers/${clientId}`,
    data,
    config,
  )

  return response.data
}

export const verifyEmail = async ({ id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(
    `${REST_API_URL}/sharedBuyers/${id}/email/verify`,
    config,
  )

  return response.data
}

export const nudgeEmail = async ({ eventId }) => {
  const response = await axios.post(
    `${REST_API_URL}/sharedBuyers/nudge/${eventId}`,
  )

  return response.data
}

export const fetchBuyer = async ({ id, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(
    `${REST_API_URL}/sharedBuyers/${id}/`,
    config,
  )

  return response.data
}

export const deleteClient = async ({ clientId }) => {
  const response = await axios.delete(
    `${REST_API_URL}/sharedBuyers/${clientId}`,
  )

  return response.data
}
