import styled, { css } from "styled-components"

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
  ${props =>
    props.fullPage
      ? `align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;`
      : ""}
`
export const TextSection = styled.div`
  background: #f7f7f2;
  padding: 30px;
  font-weight: bold;
  font-size: 16px;
  margin-top: 30px;
`
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

export const AddMeetingButton = styled.div`
  background: #f7f7f2;
  width: 250px;
  height: 37px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 20px;
`

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  border: 1px solid ${props => props.theme.veryLightGray};
  position: relative;
`
export const StepContainer = styled.div`
  display: flex;
  align-items: ${props => props.alignItems || "stretch"};
  flex-direction: column;
  padding: 20px;
  min-height: 400px;
  justify-content: space-between;
  box-sizing: border-box;

  ${props => (props.isOverflow ? "max-height: 75vh; overflow-y: auto;" : "")}

  & .codeSelect__indicators {
    display: none;
  }

  & .codeSelect__control {
    width: 100px;
  }

  & .meetingSelect__control {
    width: 300px;
  }

  & .languageSelect__control {
    width: 300px;
  }

  &.last-step {
    padding: 40px 0;
    height: 100%;
    width: 100%;
    justify-content: flex-start;

    font-size: 18px;

    & button {
      text-transform: initial;
    }
  }
`

export const StepContainerAbsolute = styled(StepContainer)``

export const Title = styled.div`
  text-transform: uppercase;
`

export const Text = styled.div`
  margin: ${props => props.margin};

  & p {
    margin: 0;
  }
`
export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`
export const ButtonsRow = styled(InputRow)`
  width: 100%;
  min-height: 37px;
  justify-content: center;
  margin: ${props => props.margin || "0px"};
`
export const SelectContainer = styled(Text)`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => (props.flexRow ? `justify-content: space-between` : "")}

  & .languageSelect {
    width: 70%;
  }

  & img {
    display: block;
    min-height: 50px;
    width: auto;
    margin: 20px;
  }

  & .dateSelect {
    margin-bottom: 20px;
  }
`
export const TabRow = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 55px;
`
export const RoomContainer = styled.div`
  margin-bottom: 10px;
`
export const Error = styled.div`
  position: absolute;
  color: ${props => props.theme.primary};
  font-size: 12px;
  bottom: 0;
  transform: translateY(100%);
  left: 0px;
  z-index: 0;
`

export const Label = styled.div`
  font-weight: 600;
  min-width: 30%;
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SelectOuter = styled.div`
  width: ${props => props.width};
  position: relative;
`
export const CloseModal = styled.span`
  position: absolute;
  color: ${props => props.theme.strawberry};
  right: 5px;
  top: 5px;
  width: 1em;
  text-align: center;
  cursor: pointer;
`

export const Infocontainer = styled.div`
  position: relative;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  margin: 20px 0;
  width: ${({ count }) =>
    count > 1 ? `calc((100% - ${(count - 1) * 20}px)/${count})` : "400px"};
  min-width: 300px;

  &:not(:last-child) {
    margin-right: 20px;
  }

  & p {
    margin: 0;
  }

  & img {
    display: block;
    height: 25px;
    width: auto;
    margin-right: 25px;
  }
`

export const InfoDate = styled.div`
  padding: 15px 30px;
  display: flex;
  & p {
    margin-bottom: 5px;
  }
`
export const InfoRoom = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% + 2px);
  transform: translate(-1px, -1px);
  background: #f7f7f2;
  height: 40px;
  font-size: 16px;
  padding-left: 30px;
`

export const InfoAddress = styled.div`
  padding: 15px 30px;
  display: flex;
  & span {
    font-weight: 600;
  }

  & p {
    margin-bottom: 5px;
  }
`

export const PersonInfo = styled.div`
  background: rgb(10, 16, 105);
  min-height: 40px;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  line-height: 40px;
`
export const ButtonString = styled.span`
  color: #45cea2;
  cursor: pointer;
  margin-right: 20px;
  font-weight: 400 !important;
`
export const Bold = styled.span`
  font-weight: 600;
`
export const Link = styled.a`
  text-decoration: none;
  color: #fff;
`
export const LinkRow = styled.div`
  color: #b72158;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;

  & a {
    color: inherit;
  }

  & span {
    cursor: pointer;
  }
`
export const SmallLogo = styled.img`
  display: block;
  width: 100px;
  margin: 15px auto;
`
export const SmallContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  width: 90%;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  margin: 25px auto;

  &.agreement {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  & a {
    color: #999;
    text-decoration: none;
  }
`
export const Services = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;

  & div {
    display: flex;
    width: 35%;
    height: 40px;
    justify-content: center;
    align-items: center;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    margin-bottom: 10px;
    cursor: pointer;

    & img {
      height: auto;
      width: 60%;
      object-fit: contain;
    }
  }
`

export const MrLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  & img {
    width: auto;
    height: 30px;
  }
`
export const InviteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 60px;
  padding-bottom: 60px;
  background: #f4f4f4;
  height: 100%;

  & ${StepContainer} {
    width: 520px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 30px;
  }

  & ${MrLogo} {
    flex-direction: column;
    align-items: center;

    p {
      margin-bottom: 5px;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 0.05em;
    }
  }
`
export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 25px;
  margin-bottom: 35px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 175px;
    height: 140px;
    border-radius: 5px;
    padding: 15px 0;

    & ${Bold} {
      font-size: 16px;
      display: block;
      margin-top: 15px;
      margin-bottom: 10px;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      border-radius: 5px;
    }
  }

  & > div.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    box-shadow: none;
  }

  & img {
    width: 80%;
    height: auto;
    object-fit: cover;
  }
`
export const MiddleLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;

  & img {
    width: 150px;
    height: auto;
    object-fit: contain;
  }
`
export const ModalArea = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  margin-bottom: 5px;
  min-height: 140px;

  & > p {
    margin-bottom: 15px;
  }
`
export const ModalInputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: center;

  & > span {
    font-weight: bold;
  }
`
export const InviteModalContainer = styled.div`
  width: 700px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  margin: auto;
  padding: 40px;

  & button {
    text-transform: initial;
  }

  & ${MrLogo} {
    margin-top: 30px;
  }

  .modal & {
    border: initial;
    width: initial;
    margin: initial;
    padding: initial;

    & ${MrLogo} {
      display: none;
    }
  }
`

export const ModalBody = styled.div`
  & .spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255);
  }
`
export const TextWrap = styled.div`
  margin-bottom: 25px;
  & p {
    margin: 0;
    margin-top: 15px;
  }
`
export const ButtonsRowModal = styled(InputRow)`
  position: relative;
  width: 100%;
  min-height: 37px;
  justify-content: space-around;
  margin: ${props => props.margin || "0px"};
`

export const Form = styled.form`
  width: 60%;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 10px;
  margin: 0 auto;
  margin-bottom: 20px;

  & div {
    min-height: 63px;
    display: flex;
    align-items: center;
    position: relative;

    & > span {
      color: ${({ theme }) => theme.primary};
      display: block;
      position: absolute;
      bottom: 5px;
      left: 15px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
  }

  & input {
    background-color: #f5f5f5;
    border: none;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    outline: none;
    color: #333;
  }
`
export const Icon = styled.img`
  display: block;
  height: ${({ height }) => height || "17px"};
  width: auto;
  cursor: pointer;
`
export const ButtonLoader = styled.div`
  background: #a60c46;
  align-self: center;
  height: 37px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
`
export const Message = styled.div`
  position: absolute;
  bottom: 60px;
  left: 30px;
  color: #a60c46;
`
export const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255);
`

export const Button = styled.button`
  cursor: pointer;
  background: transparent;

  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.text};

  color: ${({ theme }) => theme.text};
  font-weight: 500;
  font-size: 15px;

  height: 40px;
  margin: 4px;
  padding: 0.25em 0.5em;

  ${props =>
    props.primary &&
    css`
      font-weight: 600;
      background: ${({ theme }) => theme.brandSecondary};
      border-color: ${({ theme }) => theme.brandSecondary};
      color: white;
    `}

  ${props =>
    props.secondary &&
    css`
      font-weight: 600;
      background: ${({ theme }) => theme.text};
      border-color: ${({ theme }) => theme.text};
      color: white;
    `}

  ${props =>
    props.negative &&
    css`
      font-weight: 600;
      background: ${({ theme }) => theme.negative};
      border-color: ${({ theme }) => theme.negative};
      color: white;
    `}

  ${props =>
    props.plain &&
    css`
      border-color: transparent;
      background: transparent;
    `}

  ${props =>
    props.large &&
    css`
      font-size: 18px;
      width: 300px;
      height: 56px;
    `}

  > img {
    display: inline;
    margin: 0 8px;
    vertical-align: middle;
  }
`

export const H1 = styled.h1`
  font-weight: 500;
  font-family: Futura;
  font-size: 24px;
  color: ${({ theme }) => theme.text};
  text-align: center;
  margin-bottom: 20px;
`

export const BulletPoint = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.text};
  text-align: center;
  margin-bottom: 24px;

  > img {
    display: inline;
    margin-right: 32px;
    margin-bottom: -5px;
  }
`
