export const mainTheme = {
  primary: "#a60c46",
  secondary: "#233044",
  teal: "#7BDCB5",
  gray: "#efeaea",
  gray25: "#D1D1D6",
  gray50: "#F1F3F5",
  gray75: "#F8F9FA",
  gray100: "#495057",
  gray200: "#868E96",
  gray300: "#343A40",

  veryLightGray: "#d8d8d8",
  berry: "#a60c46",
  macaroniAndCheese: "#f5a728",
  offWhite: "#f7f7f2",
  darkGreyBlue: "#3747d",
  aquaMarine: "#45cea2",
  white: "#f7f7f7",
  strawberry: "#f8263f",
  textBlack: "#4a4a4a",
  tabBorder: "#ef3c52",
  black: "#000",

  mainFont: "avenir",
}

export const palette = [
  "#f9dbbd",
  "#ef3c52",
  "#a60c46",
  "#fca17d",
  "#394648",
  "#37475d",
  "#577287",
  "#afd5aa",
  "#ddf8e8",
  "#d93b18",
  "#ed6a5a",
  "#45cea2",
  "#6d98ba",
  "#785964",
  "#82a7a6",
]
