import {
  Bold,
  ButtonsRow,
  InviteModalContainer,
  ModalArea,
  ModalInputRow,
  MrLogo,
} from "../styles"
import { Button, Div, Input, Multiselect } from "../UI"
import React, { Component } from "react"

import { PropTypes } from "prop-types"
import SmllLogo from "../../static/mR2.png"
import { SyncLoader } from "react-spinners"
import isEmpty from "lodash/isEmpty"

class InviteModal extends Component {
  constructor(props) {
    super(props)
    const { clients, selectedColleagueId, staffList } = this.props
    const clientsList =
      clients &&
      clients.allIds &&
      clients.allIds.map(clientId => {
        const client = clients.byId[clientId]
        return {
          value: client.email,
          label: `${client.firstName} ${client.lastName} - ${client.email}`,
          id: client._id,
        }
      })

    const list = staffList || clientsList
    const colleague =
      selectedColleagueId && list.find(elem => elem.id === selectedColleagueId)
    this.state = {
      clientsList,
      modalIsOpen: false,
      values: {
        email: (colleague && colleague.value) || "",
      },
      errors: {},
      touched: {},
      selectedColleague: colleague || null,
      newLink: false,
    }
  }

  validate = (value, name) => {
    const { values, errors } = this.state
    switch (name) {
      case "email":
        const reg = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        } else if (reg.test(value) === true) {
          delete errors[name]
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        errors[name] = `Invalid email address`
        return {
          values: { ...values, [name]: value },
          errors,
        }

      default:
        break
    }
  }

  sendInvite = () => {
    const {
      onSendInvite,
      user,
      retailer,
      sharedRetailer,
      buyer,
      invitedClient,
    } = this.props
    const { values, touched, errors, selectedColleague } = this.state
    for (const key in values) {
      this.validate(values[key], key)
      touched[key] = true
    }

    if (isEmpty(errors)) {
      if (user) {
        if (selectedColleague) {
          onSendInvite({
            clientId: selectedColleague.id,
            isShared: true,
            email: selectedColleague.value,
            inviterId: buyer._id,
          })
        } else {
          onSendInvite({
            email: values.email,
            retailerId: sharedRetailer._id,
            isShared: true,
            inviterId: buyer._id,
          })
        }
      } else if (!user) {
        if (selectedColleague) {
          onSendInvite({
            clientId: selectedColleague.id,
            isShared: false,
            email: selectedColleague.value,
            inviterId: invitedClient._id,
          })
        } else {
          onSendInvite({
            email: values.email,
            retailerId: retailer._id,
            isShared: false,
            inviterId: invitedClient._id,
          })
        }
      }
      this.setState({ isChanged: false, isRequest: true })
    }
    this.setState({ errors, touched })
  }

  onCopy = () => {
    const { inviteLink } = this.props
    const el = document.createElement("textarea")
    el.value = inviteLink
    el.setAttribute("readonly", "")
    el.style.position = "absolute"
    el.style.left = "-9999px"
    document.body.appendChild(el)
    el.select()
    document.execCommand("copy")
    document.body.removeChild(el)
  }

  onGenerateLink = () => {
    const { onFetchLink, user, retailer, sharedRetailer } = this.props
    const { selectedColleague } = this.state
    this.setState({ newLink: true })
    if (user) {
      if (selectedColleague) {
        onFetchLink({ clientId: selectedColleague.id, isShared: true })
      } else {
        onFetchLink({ retailerId: sharedRetailer._id, isShared: true })
      }
    } else if (!user) {
      if (selectedColleague) {
        onFetchLink({ clientId: selectedColleague.id, isShared: false })
      } else {
        onFetchLink({ retailerId: retailer._id, isShared: false })
      }
    }
  }

  onInputChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({
      ...this.validate(value, name),
      isChanged: true,
      selectedColleague: null,
      newLink: false,
    })
  }

  onSelectChange = (value, name) => {
    const { touched } = this.state
    touched[name] = true
    this.setState({
      ...this.validate(value.value, name),
      selectedColleague: value,
      touched,
      isChanged: true,
      newLink: false,
    })
  }

  onBlur = e => {
    const { touched } = this.state
    const name = e.target.name
    touched[name] = true
    this.setState({ touched })
  }

  render() {
    const { staffList, requestInviteLinkPending, inviteLink } = this.props
    const {
      clientsList,
      errors,
      touched,
      values,
      selectedColleague,
      newLink,
    } = this.state
    const list = staffList || clientsList
    return (
      <InviteModalContainer>
        <div style={{ marginBottom: "15px" }}>
          <Bold>Invite colleague to connect their calendar to ModaResa</Bold>
        </div>
        <ModalArea>
          <p>Invite you colleague to create their own ModaResa calendar.</p>
          <ModalInputRow>
            <span>Choose a colleague from list</span>
            <Multiselect
              name="email"
              options={list}
              value={selectedColleague}
              width="300px"
              onChange={this.onSelectChange}
            />
          </ModalInputRow>
          <ModalInputRow>
            <span>OR insert email address</span>
            <Input
              errors={errors.email}
              name="email"
              style={{ backgroundColor: "#fff", border: "1px solid #e2e2e2" }}
              touched={touched.email}
              value={values.email}
              width="300px"
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
          </ModalInputRow>
        </ModalArea>
        <ModalArea>
          {requestInviteLinkPending && (
            <Div.Flex justify="center" style={{ minHeight: "100px" }}>
              <SyncLoader color={"#a60c46"} loading size={20} sizeUnit={"px"} />
            </Div.Flex>
          )}
          {!requestInviteLinkPending && (
            <React.Fragment>
              <p>Share Link for colleague to connect</p>
              <ModalInputRow>
                {newLink && inviteLink && (
                  <React.Fragment>
                    <Input
                      disabled
                      name="link"
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #e2e2e2",
                      }}
                      value={inviteLink || ""}
                    />
                    <Button
                      bgcolor="#ffcab1"
                      style={{ marginLeft: "20px" }}
                      onClick={this.onCopy}
                    >
                      Copy Link
                    </Button>
                  </React.Fragment>
                )}
                {(!newLink || !inviteLink) && (
                  <Button
                    disabled={
                      !(selectedColleague || (values.email && !errors.email))
                    }
                    style={{ marginLeft: "20px" }}
                    type="button"
                    onClick={this.onGenerateLink}
                  >
                    Generate Link
                  </Button>
                )}
              </ModalInputRow>
            </React.Fragment>
          )}
        </ModalArea>
        <ButtonsRow style={{ marginTop: "25px" }}>
          <Button
            disabled={!(values.email && !errors.email)}
            onClick={this.sendInvite}
          >
            Send Invite
          </Button>
        </ButtonsRow>
        <MrLogo>
          <img alt="modaresa logo" src={SmllLogo} />
        </MrLogo>
      </InviteModalContainer>
    )
  }
}

InviteModal.propTypes = {
  buyer: PropTypes.object,
  clients: PropTypes.object,
  inviteLink: PropTypes.string,
  invitedClient: PropTypes.object,
  requestInviteLinkPending: PropTypes.bool,
  retailer: PropTypes.object,
  selectedColleagueId: PropTypes.string,
  sharedRetailer: PropTypes.object,
  staffList: PropTypes.array,
  user: PropTypes.object,
  onFetchLink: PropTypes.func,
  onSendInvite: PropTypes.func,
}

export { InviteModal }
export default InviteModal
