import {
  Bold,
  Booking,
  CalendarEvent,
  EventContainer,
  FashionEvent,
  Icon,
} from "./styles.js"
import React, { Component } from "react"
import { eventType, seasonsKey } from "../../config/session"

import { Div } from "../UI"
import MRIcon from "../../static/mR2.png"
import { ReactComponent as MinuseIcon } from "../../static/Minus.svg"
import { ReactComponent as PlusIcon } from "../../static/plus.svg"
import PropTypes from "prop-types"

export class Event extends Component {
  render() {
    const {
      data,
      eventHeight,
      eventLeft,
      eventTop,
      eventWidth,
      type,
      onEventClick,
      onAddEvent,
      onDelete,
      addedEvent,
      showEventControl,
    } = this.props

    return (
      <EventContainer
        eventHeight={eventHeight}
        eventLeft={eventLeft}
        eventTop={eventTop}
        eventWidth={eventWidth}
      >
        {type === "custom" && (
          <Booking onClick={() => onEventClick(data, "mrevent")}>
            {data._id && (
              <React.Fragment>
                <div>
                  <Bold>{(data && data.title) || "Event"}</Bold>
                </div>
                <div>{data.location}</div>
              </React.Fragment>
            )}
            {!data._id && <div>New Event</div>}
          </Booking>
        )}
        {type === "booking" && (
          <Booking onClick={() => onEventClick(data, "booking")}>
            <Div.Flex align="flex-start" justify="space-between">
              <div>
                <div>
                  <span style={{ fontWeight: "bold" }}>{data.brand}</span> -{" "}
                  {seasonsKey[data.session.season]}
                </div>
                <div>{data.session.name}</div>
                <div>{data.room}</div>
              </div>
              <Icon src={MRIcon} style={{ marginRight: "0px" }} />
            </Div.Flex>
          </Booking>
        )}
        {type === "fashionEvent" && (
          <FashionEvent added onClick={() => onEventClick(data, "fashion")}>
            <Div.Flex justify="flex-start" style={{ flexWrap: "wrap" }}>
              {showEventControl ? (
                addedEvent ? (
                  <div
                    style={{
                      height: "18px",
                      width: "18px",
                      margin: "0 5px 0 0",
                    }}
                  >
                    <MinuseIcon
                      className="icon"
                      style={{ height: "100%", width: "100%", margin: 0 }}
                      onClick={e => onDelete(data._id, "fashion", e)}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      height: "18px",
                      width: "18px",
                      margin: "0 5px 0 0",
                    }}
                  >
                    <PlusIcon
                      className="icon"
                      style={{ height: "100%", width: "100%", margin: 0 }}
                      onClick={e => onAddEvent(data._id, e)}
                    />
                  </div>
                )
              ) : null}
              <p>
                <span>{data.companyName}</span> - {eventType[data.eventType]}
              </p>
            </Div.Flex>
          </FashionEvent>
        )}
        {type === "calendar" && (
          <CalendarEvent>
            <div>
              <span style={{ fontWeight: "bold" }}>{data.name}</span>
            </div>
            <div>{data.location}</div>
            <div>
              {data.attendees &&
                data.attendees.map((email, index) => (
                  <p key={index}>{email}</p>
                ))}
            </div>
          </CalendarEvent>
        )}
      </EventContainer>
    )
  }
}

Event.propTypes = {
  addedEvent: PropTypes.bool,
  data: PropTypes.object,
  eventHeight: PropTypes.string,
  eventLeft: PropTypes.string,
  eventTop: PropTypes.string,
  eventWidth: PropTypes.string,
  showEventControl: PropTypes.bool,
  type: PropTypes.string,
  onAddEvent: PropTypes.func,
  onDelete: PropTypes.func,
  onEventClick: PropTypes.func,
}

export default Event
