import { fetchSuggestions, requestJoker } from "../../store/actions/schedules"
import { fetchInviteData, updateInviteData } from "../../store/actions/invites"
import {
  getAvailableLanguages,
  getBookingStatus,
  getBrand,
  getClients,
  getInviteBookingsData,
  getInvitedClient,
  getLangFetchStatus,
  getRequestUpdatePhoneError,
  getRequestUpdatePhonePending,
  getRetailer,
  getSelectedSession,
  isEdit,
  selectedEvent,
} from "../../store/selectors"

import Component from "./InviteBooking"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { fetchLanguages } from "../../store/actions/salesSessions"
import { updateClientPhoneNumber } from "../../store/actions/clients"

const selector = createStructuredSelector({
  clients: getClients,
  brand: getBrand,
  retailer: getRetailer,
  bookingsData: getInviteBookingsData,

  invitedClient: getInvitedClient,
  session: getSelectedSession,
  availableLanguages: getAvailableLanguages,
  languageRequestPending: getLangFetchStatus,
  requestUpdatePhonePending: getRequestUpdatePhonePending,
  requestUpdatePhoneError: getRequestUpdatePhoneError,
  bookingRequestPending: getBookingStatus,
  isEdit,
  selectedEvent,
})

const actions = {
  onFetchLanguages: fetchLanguages,
  onFetchSuggestions: fetchSuggestions,
  onFetchInviteData: fetchInviteData,
  onUpdateInviteData: updateInviteData,
  onUpdatePhoneNumber: updateClientPhoneNumber,
  onRequest: requestJoker,
}

export default connect(selector, actions)(Component)
