import { AppContainer, Container } from "../styles"
import { Categories, ConfirmInformation, LanguageRequest, MeetingAssignation, MeetingType, VideoTool } from "../Steps"
import React, { Component } from "react"
import { createIcs, download } from "../../helpers/ics"

import Matches from "../MeetingMatches"
import NewFinalStep from "../NewFinalstep"
import { Progressbar } from "../UI"
import PropTypes from "prop-types"
import isEmpty from "lodash/isEmpty"

const getUrlParameter = (name, str = window.location.search) => {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")
  const regex = new RegExp("[\\?&]" + name)
  const results = regex.exec(str)
  return results !== null
}

const stepsSections = [
  "confirmInfos",
  "meetingType",
  "rooms",
  "languageRequest",
  "videoTool",
  "meetingAssignation",
  "matches",
]

class InviteBooking extends Component {
  constructor(props) {
    super(props)
    const { isEdit, selectedEvent } = this.props
    let initialStep = "confirmInfos"
    if (selectedEvent !== null) {
      if (isEmpty(selectedEvent)) {
        initialStep = "meetingType"
      } else {
        initialStep = "rooms"
      }
    }

    this.state = {
      customBuyingAppointment: true,
      customLanguage: true,
      activeStep: initialStep,
      connectFlowStep: null,
      errors: {},
      touched: {},
      timeStart: +new Date(),
      timeEnd: 0,
      isFinished: false,
      isEditing: isEdit,
      showTime: false,
      service: null,
      jokerRequest: false,
      openEditPage: false,
      showSpreadTheWordModal: false,
    }
  }

  componentDidMount() {
    const { match, session } = this.props
    const download = getUrlParameter("download")
    if (match.params && match.params.id && !session) {
      this.props.onFetchInviteData(match.params.id, download)
      this.props.history.replace(`/booking/${match.params.id}`)
    }
  }

  validate = (value, name) => {
    const { values, errors } = this.state
    switch (name) {
      case "phoneNumber":
        if (value.trim().length < 10) {
          errors[name] = `Invalid phone number`
          this.setState({
            values: { ...values, [name]: value },
            errors,
          })
        } else {
          delete errors[name]
          this.setState({
            values: { ...values, [name]: value },
            errors,
          })
        }
        break
      default:
        break
    }
  }

  onInputChange = (e, nam) => {
    if (typeof e === "string") {
      this.validate(e, nam)
    } else {
      const name = e.target.name
      const value = e.target.value
      this.validate(value, name)
    }
  }

  onSelectChange = (value, name) => {
    const { values } = this.state
    this.setState({
      values: { ...values, [name]: value.value },
    })
  }

  onCheckboxChange = id => {
    const { values } = this.state
    const { categories } = values
    categories[id].value = !categories[id].value

    const isValueCategories = categories.filter(({ value }) => value === true)

    if (isValueCategories.length === 0) {
      this.setState({
        values: { ...values, categories },
        errors: { categories: "Required" },
      })
    } else {
      this.setState({
        values: { ...values, categories },
        errors: {},
      })
    }
  }

  prevStep = () => {
    const { activeStep } = this.state
    const prevStep = this.getNextOrPrevValidStep(stepsSections.indexOf(activeStep) - 1, "-")
    this.setState({
      activeStep: stepsSections[prevStep],
    })
  }

  getNextOrPrevValidStep = (step, modifier) => {
    const { bookingsData, session } = this.props

    let currentStep = step
    const modifierFunction =
      modifier === "-"
        ? () => {
            currentStep--
          }
        : () => {
            currentStep++
          }

    let stepIsValid = false
    while (!stepIsValid) {
      const stepName = stepsSections[currentStep]
      switch (stepName) {
        case "languageRequest":
          const customLanguage = session ? session.algorithms.lang : false
          if (!customLanguage) {
            modifierFunction()
          } else {
            stepIsValid = true
          }
          break
        case "videoTool":
          const isVideoMeeting = !(!session.videoMeetings || bookingsData.meetingApproach !== "video-conference")
          if (!isVideoMeeting) {
            modifierFunction()
          } else {
            stepIsValid = true
          }
          break
        default:
          stepIsValid = true
      }
    }
    return currentStep
  }

  nextStep = props => {
    const { activeStep } = this.state
    const { onUpdateInviteData } = this.props

    switch (activeStep) {
      case "confirmInfos":
        const { phoneNumber, changed } = props
        if (changed) {
          onUpdateInviteData({ phoneNumber })
        }
        break
      case "meetingType":
        const { meetingType, meetingApproach } = props
        onUpdateInviteData({ meetingType, meetingApproach })
        break
      case "rooms":
        const { selectedRooms } = props
        onUpdateInviteData({ rooms: selectedRooms }, true)
        break
      case "languageRequest":
        const { languages } = props
        onUpdateInviteData({ languages })
        break
      case "videoTool":
        const { videoTool } = props
        onUpdateInviteData({ videoTool })
        break
      case "meetingAssignation":
        const { rooms } = props
        onUpdateInviteData({ rooms }, true)
        this.setState({ MeetingMatches: true })
        break
      default:
        break
    }

    const nextStepNb = this.getNextOrPrevValidStep(stepsSections.indexOf(activeStep) + 1, "+")
    const nextStepName = stepsSections[nextStepNb]
    this.setState({
      activeStep: nextStepName,
    })
  }

  goToStep = value => {
    this.setState({
      activeStep: value,
    })
  }

  onBlur = e => {
    const { touched } = this.state
    const name = e.target.name
    touched[name] = true
    this.setState({ touched })
  }

  confirmBookind = () => {
    this.setState({ isEditing: true })
  }

  finishBooking = () => {
    this.setState({
      showSpreadTheWordModal: true,
      isEditing: false,
      timeEnd: +new Date(),
      showTime: true,
    })
  }

  requestJoker = requests => {
    this.setState({ isEditing: false, jokerRequest: true })
    this.props.onRequest(requests)
  }

  onDownloadPress = () => {
    const { brand, bookingsData, session, clients, retailer } = this.props
    const { timezone, name, address } = session
    const { name: brandName } = brand
    const link = `${window.location.origin}/${bookingsData._id}/edit`
    const client = clients.byId[bookingsData.clientIds[0]]
    const ics = createIcs(bookingsData.events, timezone, address, name, retailer.name, brandName, client.email, link)
    download(`${retailer.name}-${brand.name}`, ics)
  }

  renderSteps = () => {
    const { activeStep, optionListCollegues, isEditing } = this.state
    const {
      invitedClient,
      session,
      brand,
      bookingsData,
      clients,
      requestUpdatePhonePending,
      onUpdatePhoneNumber,
      selectedEvent,
    } = this.props
    const activeStepIndex = stepsSections.indexOf(activeStep)

    if (activeStepIndex < 6) {
      return (
        <AppContainer>
          <Progressbar progress={activeStepIndex} />
          {activeStep === "confirmInfos" && (
            <ConfirmInformation
              brandName={brand.name}
              invitedClient={invitedClient}
              requestPending={requestUpdatePhonePending}
              session={session}
              onNextClick={this.nextStep}
              onUpdateNumber={onUpdatePhoneNumber}
            />
          )}
          {activeStep === "meetingType" && (
            <MeetingType
              bookingsData={bookingsData}
              session={session}
              onNextClick={this.nextStep}
              onPrevClick={this.prevStep}
              onSelectChange={this.onSelectChange}
            />
          )}
          {activeStep === "rooms" && (
            <Categories
              bookingsData={bookingsData}
              session={session}
              onNextClick={this.nextStep}
              onPrevClick={this.prevStep}
            />
          )}
          {activeStep === "languageRequest" && (
            <LanguageRequest
              bookingId={bookingsData._id}
              languages={bookingsData.languages}
              rooms={bookingsData.rooms}
              onNextClick={this.nextStep}
              onPrevClick={this.prevStep}
            />
          )}
          {activeStep === "videoTool" && (
            <VideoTool
              bookingsData={bookingsData}
              session={session}
              onNextClick={this.nextStep}
              onPrevClick={this.prevStep}
              onSelectChange={this.onSelectChange}
            />
          )}
          {activeStep === "meetingAssignation" && (
            <MeetingAssignation
              bookingsData={bookingsData}
              clients={clients}
              collegues={optionListCollegues}
              selectedEvent={selectedEvent}
              session={session}
              visibleTimeSelect={bookingsData.meetingType === "buyingAppointment" && session.customBuyingAppointment}
              onNextClick={this.nextStep}
              onPrevClick={this.prevStep}
            />
          )}
        </AppContainer>
      )
    }

    const customLanguage = session ? session.algorithms.lang : false
    const isVideoMeeting = bookingsData.meetingApproach === "video-conference"
    return (
      <Matches
        confirmBookind={this.confirmBookind}
        goToStep={this.goToStep}
        isEditing={isEditing}
        isVideoMeeting={isVideoMeeting}
        isLanguageTab={customLanguage}
        requestJoker={this.requestJoker}
        selectedRoom={bookingsData && bookingsData.rooms}
        onBookingConfirmed={this.finishBooking}
      />
    )
  }

  onChangeStep = (step, service) => {
    if (service) {
      this.setState({ connectFlowStep: step, service })
    } else {
      this.setState({ connectFlowStep: step })
    }
  }

  render() {
    const { isEditing, jokerRequest } = this.state
    const { session, brand, bookingsData, match } = this.props
    const { showSpreadTheWordModal } = this.state

    if (isEmpty(bookingsData)) return null
    const eventsArray = [...bookingsData.events, ...bookingsData.jokerRequests]
    const isFinalStep = !isEditing && eventsArray.length > 0
    if (isEmpty(bookingsData) || !session) return null
    return (
      <div className="App">
        <Container>
          {isFinalStep ? (
            <React.Fragment>
              <NewFinalStep
                brand={brand}
                bookingId={match.params.id}
                isJokerRequest={jokerRequest}
                onDownloadPress={this.onDownloadPress}
                defaultShowSpreadTheWordModal={showSpreadTheWordModal}
              />
            </React.Fragment>
          ) : (
            this.renderSteps()
          )}
        </Container>
      </div>
    )
  }
}

InviteBooking.propTypes = {
  availableLanguages: PropTypes.array,
  bookingRequestPending: PropTypes.bool,
  bookingsData: PropTypes.object,
  brand: PropTypes.object,
  clients: PropTypes.object,
  history: PropTypes.object,
  invitedClient: PropTypes.object,
  isEdit: PropTypes.bool,
  languageRequestPending: PropTypes.bool,
  match: PropTypes.object,
  requestUpdatePhonePending: PropTypes.bool,
  retailer: PropTypes.object,
  selectedEvent: PropTypes.object,
  session: PropTypes.object,

  onFetchInviteData: PropTypes.func,
  onFetchLanguages: PropTypes.func,

  onRequest: PropTypes.func,
  onUpdateInviteData: PropTypes.func,
  onUpdatePhoneNumber: PropTypes.func,
}

export default InviteBooking
