import {
  FETCH_RETAILER,
  FETCH_SHARED_RETAILER,
  UPDATE_RETAILER,
} from "../actions/retailers"

import { createReducer } from "../..//helpers/redux"

const initialState = {
  retailer: {},
  sharedRetailer: {},
  requestSavePending: false,
  requestSaveError: null,
}

const handlers = {
  [FETCH_RETAILER.SUCCESS]: (state, { payload }) => ({
    ...state,
    retailer: payload,
  }),
  [FETCH_SHARED_RETAILER.SUCCESS]: (state, { payload }) => ({
    ...state,
    sharedRetailer: payload,
  }),
  [UPDATE_RETAILER.REQUEST]: state => ({
    ...state,
    requestSavePending: true,
    requestSaveError: null,
  }),
  [UPDATE_RETAILER.SUCCESS]: (state, { payload }) => ({
    ...state,
    sharedRetailer: payload,
    requestSavePending: false,
  }),
  [UPDATE_RETAILER.FAILURE]: (state, { payload }) => ({
    ...state,
    requestSavePending: false,
    requestSaveError: payload,
  }),
}

export default createReducer(initialState, handlers)
