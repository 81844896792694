import {
  getBuyerSchedule,
  getInvitedClient,
  getRegisterData,
  getRequestScheduleError,
  getRequestSchedulePending,
} from "../../store/selectors"

import Component from "./Agreement"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { signup } from "../../store/actions/auth"

const selector = createStructuredSelector({
  schedule: getBuyerSchedule,
  inviteData: getRegisterData,
  requestScheduleError: getRequestScheduleError,
  requestSchedulePending: getRequestSchedulePending,
  invitedClient: getInvitedClient,
})

const actions = {
  onSignUp: signup,
}

export const Agreement = connect(selector, actions)(Component)
