import * as Api from "../../helpers/api"
import * as socketEvents from "../sockets/messageTypes"

import {
  CANCEL_BOOKING,
  CANCEL_BOOKING_BUYER,
  CONFIRM_AUTO_BOOKINGS,
  DELAY_APPOINTMENT,
  EDIT_AUTO_BOOKINGS,
  EDIT_AUTO_BOOKINGS_JOKER,
  EDIT_JOKER_REQUEST,
  FETCH_BOOKING_ID,
  FETCH_SUGGESTIONS,
  FETCH_VIDEO_CALL_EVENT,
  REQUEST_JOKER,
} from "../actions/schedules"
import { call, put, select } from "redux-saga/effects"

import { onToastError } from "./../../helpers/toast"
import { takeLatest } from "../../helpers/saga"

function* delayAppointment({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { bookingId, sessionId, delayTime } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.DELAY_APPOINTMENT,
        body: { bookingId, sessionId, token: accessToken, delayTime },
      },
    })
  } catch (error) {
    onToastError()
  }
}

function* delayAppointmentFlow() {
  yield takeLatest(DELAY_APPOINTMENT.REQUEST, delayAppointment)
}

function* fetchVideoCallEvent({ payload: { eventId, markAsViewed } }) {
  try {
    const response = yield call(Api.fetchEvent, { eventId, markAsViewed })
    yield put({ type: FETCH_VIDEO_CALL_EVENT.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_VIDEO_CALL_EVENT.FAILURE,
      payload: errosMsg,
    })
  }
}
function* fetchVideoCallEventFlow() {
  yield takeLatest(FETCH_VIDEO_CALL_EVENT.REQUEST, fetchVideoCallEvent)
}

function* fetchSuggestions({ payload }) {
  const { sessionId, clientId, selectedBookings, bookingsData, part } = payload
  try {
    const state = yield select()
    const response = yield call(Api.fetchSuggestions, {
      sessionId,
      clientId,
      selectedBookings,
      bookingsData,
      selectedEvent: state.schedules.selectedEvent,
      part,
    })

    yield put({ type: FETCH_SUGGESTIONS.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_SUGGESTIONS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchSuggestionsFlow() {
  yield takeLatest(FETCH_SUGGESTIONS.REQUEST, fetchSuggestions)
}

function* confirmAutoBookings({ payload }) {
  const { eventsData, sessionId, bookingData, emails, message } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.BOOK_BUYER_EVENTS,
        body: {
          id: bookingData._id,
          eventsData,
          sessionId,
          bookingData,
          emails,
          message,
        },
      },
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: CONFIRM_AUTO_BOOKINGS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* confirmAutoBookingsFlow() {
  yield takeLatest(CONFIRM_AUTO_BOOKINGS.REQUEST, confirmAutoBookings)
}

function* editAutoBookings({ payload }) {
  const { eventsData, eventId, bookingData, message, emails } = payload
  try {
    if (eventId) {
      yield put({
        type: "SEND_WEBSOCKET_MESSAGE",
        payload: {
          eventType: socketEvents.UPDATE_BUYER_EVENT,
          body: {
            id: bookingData._id,
            eventData: eventsData[0],
            eventId,
            bookingData,
            message,
            emails,
          },
        },
      })
    } else {
      yield put({
        type: "SEND_WEBSOCKET_MESSAGE",
        payload: {
          eventType: socketEvents.UPDATE_BUYER_FULL_BOOKING,
          body: {
            id: bookingData._id,
            eventsData,
            bookingData,
            message,
            emails,
          },
        },
      })
    }
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: EDIT_AUTO_BOOKINGS.FAILURE,
      payload: errosMsg,
    })
  }
}

function* editAutoBookingsFlow() {
  yield takeLatest(EDIT_AUTO_BOOKINGS.REQUEST, editAutoBookings)
}

function* editAutoBookingsJoker({ payload }) {
  const { eventId, bookingData, requestData } = payload
  try {
    if (eventId) {
      yield put({
        type: "SEND_WEBSOCKET_MESSAGE",
        payload: {
          eventType: socketEvents.UPDATE_BUYER_EVENT_WITH_JOKER,
          body: { id: bookingData._id, requestData: requestData[0], eventId },
        },
      })
    } else {
      yield put({
        type: "SEND_WEBSOCKET_MESSAGE",
        payload: {
          eventType: socketEvents.UPDATE_BUYER_EVENTS_WITH_JOKER,
          body: { id: bookingData._id, requests: requestData },
        },
      })
    }
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: EDIT_AUTO_BOOKINGS_JOKER.FAILURE,
      payload: errosMsg,
    })
  }
}

function* editAutoBookingsJokerFlow() {
  yield takeLatest(EDIT_AUTO_BOOKINGS_JOKER.REQUEST, editAutoBookingsJoker)
}

function* cancelAutoBookings({ payload }) {
  const { bookingId, eventIds } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.DELETE_BUYER_EVENT,
        body: { id: bookingId, eventIds },
      },
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: CANCEL_BOOKING.FAILURE,
      payload: errosMsg,
    })
  }
}

function* cancelAutoBookingsFlow() {
  yield takeLatest(CANCEL_BOOKING.REQUEST, cancelAutoBookings)
}

function* cancelBookings({ payload }) {
  const { bookingId, eventId } = payload
  try {
    const response = yield call(Api.cancelAutoBookings, { bookingId, eventId })

    yield put({ type: CANCEL_BOOKING_BUYER.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: CANCEL_BOOKING_BUYER.FAILURE,
      payload: errosMsg,
    })
  }
}

function* cancelBookingsFlow() {
  yield takeLatest(CANCEL_BOOKING_BUYER.REQUEST, cancelBookings)
}

function* requestJoker({ payload }) {
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.REQUEST_BUYER_JOKER,
        body: { id: payload[0].bookingId, requests: payload },
      },
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: REQUEST_JOKER.FAILURE,
      payload: errosMsg,
    })
  }
}

function* requestJokerFlow() {
  yield takeLatest(REQUEST_JOKER.REQUEST, requestJoker)
}

function* editJokerRequest({ payload }) {
  const { eventId, bookingData, requestData, eventsData } = payload
  try {
    const socketBody = {
      id: bookingData._id,
      jokerId: eventId,
    }
    if (eventsData) {
      socketBody.eventData = eventsData[0]
    } else if (requestData) {
      socketBody.requestData = requestData[0]
    }
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.REPLACE_DECLINED_BUYER_JOKER,
        body: socketBody,
      },
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: EDIT_JOKER_REQUEST.FAILURE,
      payload: errosMsg,
    })
  }
}

function* editJokerRequestFlow() {
  yield takeLatest(EDIT_JOKER_REQUEST.REQUEST, editJokerRequest)
}

function* fetchBookignId({ payload }) {
  try {
    const response = yield call(Api.fetchBookingId, payload)

    yield put({ type: FETCH_BOOKING_ID.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_BOOKING_ID.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchBookignIdFlow() {
  yield takeLatest(FETCH_BOOKING_ID.REQUEST, fetchBookignId)
}

export default [
  fetchSuggestionsFlow,
  fetchVideoCallEventFlow,
  confirmAutoBookingsFlow,
  editAutoBookingsFlow,
  cancelAutoBookingsFlow,
  requestJokerFlow,
  editAutoBookingsJokerFlow,
  editJokerRequestFlow,
  fetchBookignIdFlow,
  cancelBookingsFlow,
  delayAppointmentFlow,
]
