import { ReactComponent as DeleteIcon } from "../../../static/waste-bin.svg"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const Button = styled.div`
  border-radius: 50%;
  min-width: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  height: 40px;
  transition: background-color 0.6s ease;

  ${({ disabled }) => {
    if (disabled) {
      return `opacity: 0.1;
      pointer-events: none;
      `
    }
  }}

  & svg {
    pointer-events: none;
    transition: fill 0.6s ease;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.5);
    & svg {
      fill: #fff !important;
    }
  }
`

const onClick = (e, callBack) => {
  e.stopPropagation()
  callBack()
}

export const DeleteButton = props => (
  <Button {...props} onClick={e => onClick(e, props.onClick)}>
    <DeleteIcon fill="#000" height="20px" width="100%" />
  </Button>
)

DeleteButton.propTypes = {
  onClick: PropTypes.func,
}
