import { FETCH_BRAND } from "../actions/brand"
import { createReducer } from "../..//helpers/redux"

const initialState = {
  brand: {},
  requestPending: false,
  requestError: null,
}

const handlers = {
  [FETCH_BRAND.SUCCESS]: (state, { payload }) => ({
    ...state,
    brand: payload,
  }),
}

export default createReducer(initialState, handlers)
