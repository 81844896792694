import React, { Component } from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { Container } from "./styles"

import { InviteModal } from "../InviteModal"
import MyProfile from "./MyProfile/MyProfile"
import PropTypes from "prop-types"
import { SideMenu } from "./SideMenu"
import { TeamList } from "./TeamList"

class Team extends Component {
  render() {
    const { match } = this.props
    return (
      <React.Fragment>
        <Container>
          <SideMenu match={match} />
          <Switch>
            <Route
              component={MyProfile}
              exact
              path={`${match.path}/myprofile`}
            />
            <Route component={TeamList} path={`${match.path}/list`} />
            <Route component={InviteModal} path={`${match.path}/list/invite`} />
            <Redirect to={`${match.path}/list`} />
          </Switch>
        </Container>
      </React.Fragment>
    )
  }
}

Team.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
}

export default Team
