import { createAsyncAction } from "../../helpers/redux"

export const FETCH_RETAILERS = createAsyncAction("FETCH_RETAILERS")
export const fetchRetailers = brandId => ({
  type: FETCH_RETAILERS.REQUEST,
  payload: { brandId },
})

export const FETCH_RETAILER = createAsyncAction("FETCH_RETAILER")

export const FETCH_SHARED_RETAILER = createAsyncAction("FETCH_SHARED_RETAILER")

export const UPDATE_RETAILER = createAsyncAction("UPDATE_RETAILER")
export const updateRetailer = (data, retailerId) => ({
  type: UPDATE_RETAILER.REQUEST,
  payload: { data, retailerId },
})
