import * as R from "ramda"

import { createSelector } from "reselect"
import { normalizeData } from "./../../helpers/normalize"

export const getSuggestionFetchStatus = R.path(["schedules", "requestPending"])
export const getSuggestionFetchError = R.path(["schedules", "requestError"])
export const getSuggestions = R.path(["schedules", "suggestions"])
export const getSuggestionsClients = R.path(["schedules", "suggestionsClients"])
export const getSuggestionsClientIndex = R.path([
  "schedules",
  "suggestionsClientIndex",
])
export const getSuggestionsSlice = R.path(["schedules", "suggestionsSlice"])
export const getBookingStatus = R.path(["schedules", "bookingRequestPending"])
export const getBookingError = R.path(["schedules", "bookingRequestError"])
export const getAllEvents = R.path(["schedules", "fashionEvents"])
export const getVideoCallEvent = R.path(["schedules", "videoCallEvent"])

export const getAppointmentRequestStatus = R.path([
  "schedules",
  "appointmentRequestPending",
])
export const getAppointmentRequestError = R.path([
  "schedules",
  "appointmentRequestError",
])

export const getCancelBookingPending = R.path([
  "schedules",
  "requestCancelBookingPending",
])
export const getCancelBookingError = R.path([
  "schedules",
  "requestCancelBookingError",
])

export const isEdit = R.path(["schedules", "isEdit"])
export const selectedEvent = R.path(["schedules", "selectedEvent"])

export const getRequestBookingIdPending = R.path([
  "schedules",
  "requestBookingIdPending",
])
export const getRequestBookingIdError = R.path([
  "schedules",
  "requestBookingIdError",
])
export const getSelectedEventId = R.path(["schedules", "selectedEventId"])

export const getNormalizedEvents = createSelector([getAllEvents], events => {
  if (!events) return null
  return normalizeData(events)
})

export const getScheduleEvents = createSelector([getAllEvents], events => {
  if (!events) return []
  const newSchedule = []
  const dateSchedule = {}
  events.forEach(event => {
    if (dateSchedule[event.dayDate]) {
      dateSchedule[event.dayDate].events.push(event)
    } else {
      dateSchedule[event.dayDate] = { events: [event] }
    }
  })
  for (const key in dateSchedule) {
    newSchedule.push({ date: key, events: dateSchedule[key].events })
  }
  return newSchedule
})
