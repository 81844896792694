import { Button, Div } from "../../UI"
import {
  ButtonsRow,
  Form,
  Loader,
  MrLogo,
  SmallContainer,
  Text,
} from "../styles"
import React, { Component } from "react"

import PropTypes from "prop-types"
import SmllLogo from "../../../static/mR2.png"
import { SyncLoader } from "react-spinners"
import isEmpty from "lodash/isEmpty"

const getUrlParameter = (name, str = window.location.search) => {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]")
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)")
  const results = regex.exec(str)
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, ""))
}

class ResetPassword extends Component {
  state = {
    values: {
      password: "",
      confirmPassword: "",
    },
    resetToken: "",
    errors: {},
    touched: {},
  }

  componentDidMount() {
    const { history, onVerifyToken } = this.props
    const resetToken = getUrlParameter("token")
    if (!(resetToken.length > 0)) {
      history.push("/login")
    } else {
      onVerifyToken({ resetToken }, history)
      this.setState({ resetToken })
      history.replace("/user/resetpassword")
    }
  }

  validate = (value, name) => {
    const { values, errors } = this.state
    switch (name) {
      case "password":
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        delete errors[name]
        return {
          values: { ...values, [name]: value },
          errors,
        }

      case "confirmPassword":
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        } else if (values.password !== value) {
          errors[name] = `Passwords need to match`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        delete errors[name]
        return {
          values: { ...values, [name]: value },
          errors,
        }

      default:
        break
    }
  }

  onInputChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ ...this.validate(value, name), isChanged: true })
  }

  onBlur = e => {
    const { touched } = this.state
    const name = e.target.name
    touched[name] = true
    this.setState({ touched })
  }

  onSubmit = () => {
    const { values, touched, errors, resetToken } = this.state
    const { onResetPassword, history } = this.props

    for (const key in values) {
      this.validate(values[key], key)
      touched[key] = true
    }

    if (isEmpty(errors)) {
      onResetPassword({ password: values.password, resetToken }, history)
    } else {
      this.setState({ errors, touched })
    }
  }

  render() {
    const { errors, touched, values } = this.state
    const {
      requestPending,
      requestVerificationPendeing,
      isTokenVerified,
    } = this.props
    if (!isTokenVerified && !requestVerificationPendeing) return null
    return (
      <Div.Flex style={{ minHeight: "100vh" }}>
        <SmallContainer className="agreement">
          {(requestPending || requestVerificationPendeing) && (
            <Loader style={{ zIndex: 3 }}>
              <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
            </Loader>
          )}
          <MrLogo style={{ marginBottom: "20px" }}>
            <img alt="modaresa logo" src={SmllLogo} />
          </MrLogo>
          <Text margin="0px 0px 20px 0px" style={{ textAlign: "center" }}>
            {`Choose a New Password.`}
          </Text>
          <Form>
            <div>
              <input
                name="password"
                placeholder="New Password"
                type="password"
                value={values.password}
                onBlur={this.onBlur}
                onChange={this.onInputChange}
              />
              {errors.password && touched.password && (
                <span>{errors.password}</span>
              )}
            </div>
            <div>
              <input
                name="confirmPassword"
                placeholder="Confirm Password"
                type="password"
                value={values.confirmPassword}
                onBlur={this.onBlur}
                onChange={this.onInputChange}
              />
              {errors.confirmPassword && touched.confirmPassword && (
                <span>{errors.confirmPassword}</span>
              )}
            </div>
          </Form>
          <ButtonsRow style={{ marginTop: "25px" }}>
            <Button onClick={this.onSubmit}>Reset Password</Button>
          </ButtonsRow>
        </SmallContainer>
      </Div.Flex>
    )
  }
}

ResetPassword.propTypes = {
  history: PropTypes.object,

  isTokenVerified: PropTypes.bool,
  requestPending: PropTypes.bool,
  requestVerificationPendeing: PropTypes.bool,
  onResetPassword: PropTypes.func,
  onVerifyToken: PropTypes.func,
}

export default ResetPassword
