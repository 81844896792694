import PropTypes from "prop-types"
import React from "react"
import Select from "react-select"
import { mainTheme } from "../../../constants/themes"
import styled from "styled-components"

const SelectContainer = styled.div`
position: relative
color: ${props => props.theme.textBlack};
width: ${props => props.width || "100%"};
`

const ValidationError = styled.div`
  position: absolute;
  color: ${props => props.theme.primary}
  font-size: 12px;
  bottom: 0;
  transform: translateY(100%);
  left: 0px;
`

const control = base => ({
  ...base,
  borderColor: mainTheme.strawberry,
})

const menuList = base => ({
  ...base,
  marginTop: "0px",
})

const Multiselect = props => {
  const { onChange, options, value, name, errors, touched, width } = props
  return (
    <SelectContainer width={width}>
      <Select
        {...props}
        options={options}
        styles={{
          control: errors ? control : "",
          menu: menuList,
        }}
        value={value}
        onChange={value => onChange(value, name)}
      />
      {errors && touched && <ValidationError>{errors}</ValidationError>}
    </SelectContainer>
  )
}
Multiselect.propTypes = {
  errors: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  touched: PropTypes.bool,
  value: PropTypes.any,
  width: PropTypes.string,
  onChange: PropTypes.func,
}

export { Multiselect }
