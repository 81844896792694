import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const InputText = styled.input`
  background-color: ${props => props.theme.white};
  height: 40px;
  min-height: 40px;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 3px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  border: 1px solid ${props => (props.isError ? props.theme.strawberry : "transparent")} &[disabled] {
    color: ${props => props.theme.gray300};
  }
`

export const InputContainer = styled.div`
  position: relative;
  color: ${props => props.theme.textBlack};
  width: ${props => props.width || "100%"};

  & .react-tel-input > input {
    width: 100% !important;
    min-height: 40px;
  }
`

export const ValidationError = styled.div`
  position: absolute;
  color: ${props => props.theme.primary};
  font-size: 12px;
  bottom: 0;
  transform: translateY(100%);
  left: 0px;
  z-index: 0;

  &.SelectValidation {
    transform: none;
    left: unset;
    right: 0px;
    text-align: right;
    bottom: 36px;
    right: 20px;
  }
`

export const Input = props => (
  <InputContainer width={props.width}>
    <InputText type={props.type} isError={props.errors && props.touched} {...props} />
    {props.errors && props.touched && <ValidationError>{props.errors}</ValidationError>}
  </InputContainer>
)

Input.propTypes = {
  errors: PropTypes.string,
  touched: PropTypes.bool,
  width: PropTypes.string,
}
