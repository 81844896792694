import { createAsyncAction } from "../../helpers/redux"

export const FETCH_CLIENTS = createAsyncAction("FETCH_CLIENTS")
export const fetchClients = brandId => ({
  type: FETCH_CLIENTS.REQUEST,
  payload: { brandId },
})

export const FETCH_CLIENT = createAsyncAction("FETCH_CLIENT")
export const fetchClient = id => ({
  type: FETCH_CLIENTS.REQUEST,
  payload: { id },
})

export const FETCH_COLLEAGUES = createAsyncAction("FETCH_COLLEAGUES")
export const fetchColleagues = buyerId => ({
  type: FETCH_COLLEAGUES.REQUEST,
  payload: { buyerId },
})

export const FETCH_BUYER_SCHEDULE = createAsyncAction("FETCH_BUYER_SCHEDULE")
export const fetchBuyerSchegule = (buyerId, ignoreOffset) => ({
  type: FETCH_BUYER_SCHEDULE.REQUEST,
  payload: { buyerId, ignoreOffset },
})

export const FETCH_COLLEAGUES_CALENDAR = createAsyncAction(
  "FETCH_COLLEAGUES_CALENDAR",
)
export const fetchColleaguesCalendar = (buyerId, ignoreOffset) => ({
  type: FETCH_COLLEAGUES_CALENDAR.REQUEST,
  payload: { buyerId, ignoreOffset },
})

export const UPDATE_CLIENT = createAsyncAction("UPDATE_CLIENT")
export const updateClient = (data, clientId) => ({
  type: UPDATE_CLIENT.REQUEST,
  payload: { data, clientId },
})

export const ADD_EVENT = createAsyncAction("ADD_EVENT")
export const addEvent = (eventId, buyerId) => ({
  type: ADD_EVENT.REQUEST,
  payload: { eventId, buyerId },
})

export const CREATE_MR_EVENT = createAsyncAction("CREATE_MR_EVENT")
export const createMREvent = event => ({
  type: CREATE_MR_EVENT.REQUEST,
  payload: event,
})

export const UPDATE_MR_EVENT = createAsyncAction("UPDATE_MR_EVENT")
export const updateMREvent = event => ({
  type: UPDATE_MR_EVENT.REQUEST,
  payload: event,
})

export const DELETE_MR_EVENT = createAsyncAction("DELETE_MR_EVENT")
export const deleteMREvent = eventId => ({
  type: DELETE_MR_EVENT.REQUEST,
  payload: { eventId },
})

export const REMOVE_EVENT = createAsyncAction("REMOVE_EVENT")
export const removeEvent = (eventId, buyerId) => ({
  type: REMOVE_EVENT.REQUEST,
  payload: { eventId, buyerId },
})

export const UPDATE_CLIENT_PHONE_NUMBER = createAsyncAction(
  "UPDATE_CLIENT_PHONE_NUMBER",
)
export const updateClientPhoneNumber = (data, clientId) => ({
  type: UPDATE_CLIENT_PHONE_NUMBER.REQUEST,
  payload: { data, clientId },
})

export const DELETE_CLIENT = createAsyncAction("DELETE_CLIENT")
export const deleteClient = clientId => ({
  type: DELETE_CLIENT.REQUEST,
  payload: {
    clientId,
  },
})

export const NUDGE_BRAND = createAsyncAction("NUDGE_BRAND")
export const nudgeBrand = () => ({
  type: NUDGE_BRAND.REQUEST,
})
