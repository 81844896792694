import { Button, Div } from "../UI"
import {
  ButtonString,
  ButtonsRowModal,
  CloseModal,
  Icon,
  InfoAddress,
  InfoDate,
  InfoRoom,
  Infocontainer,
  Loader,
  LoaderContainer,
  Message,
  ModalBody,
  PersonInfo,
  StepContainer,
  TextSection,
  TextWrap,
} from "../styles"
import React, { Component } from "react"
import { createIcs, download } from "../../helpers/ics"

import Modal from "react-modal"
import PinIcon from "../../static/pin.svg"
import { PropTypes } from "prop-types"
import Select from "react-select"
import { SyncLoader } from "react-spinners"
import TimeIcon from "../../static/time.svg"
import { getMinutesList } from "../../helpers/date"
import isEmpty from "lodash/isEmpty"
import modaResaIcon from "../../static/PinkLogo.png"
import moment from "moment-timezone"

const timeOptions = getMinutesList()

const customStyles = {
  content: {
    width: "450px",
    height: "auto",
    maxHeight: "500px",
    minHeight: "200px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    overflow: "visible",
  },
  overlay: {
    backgroundColor: "#d8d8d8BF",
    zIndex: 13,
  },
}

class EditPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      delayedModal: false,
      delayTimeValue: { value: 15, label: "15 min" },
      seletedAppointmentId: null,
      seletedAppointment: null,
      cancelModal: false,
      errorModal: false,
      hostModal: false,
      isRequesting: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { requestPending, requestError } = nextProps
    const { isRequesting } = prevState
    if (isRequesting && !requestPending && !requestError) {
      return {
        seletedAppointmentId: null,
        seletedAppointment: null,
        cancelModal: false,

        delayedModal: false,
        errorModal: false,
        hostModal: false,
        isRequesting: false,
      }
    }
    if (isRequesting && !requestPending && requestError === "Member is busy") {
      return {
        seletedAppointmentId: null,
        cancelModal: false,

        delayedModal: false,
        errorModal: true,
        hostModal: false,
        isRequesting: false,
      }
    }
    return null
  }

  componentDidMount() {
    const { match, session } = this.props
    if (match.params && match.params.id && !session) {
      this.props.onFetchInviteData(match.params.id)
    }
  }

  onCancelFullBooking = () => {
    const { bookingsData, onCancelBooking } = this.props
    const eventsArray = [...bookingsData.events, ...bookingsData.jokerRequests]
    onCancelBooking(
      bookingsData._id,
      eventsArray.map(event => event._id),
    )
  }

  onEditPress = booking => {
    const { onEdit, match, history } = this.props
    onEdit(booking)
    history.replace(`/booking/${match.params.id}`)
  }

  onSelectChange = value => {
    this.setState({ delayTimeValue: value })
  }

  onDelay = appointmentId => {
    this.setState({
      delayedModal: true,
      seletedAppointmentId: appointmentId,
    })
  }

  onDelayed = () => {
    const { onDelayAppointment, selectedSession, bookingsData } = this.props
    const { delayTimeValue } = this.state
    this.setState({ isRequesting: true })
    // select only first event because the delay of whole booking is realized on back-end

    onDelayAppointment({
      bookingId: bookingsData.events[0]._id,
      sessionId: selectedSession._id,
      delayTime: delayTimeValue.value,
    })
  }

  onCancelPress = item => {
    this.setState({
      cancelModal: true,
      seletedAppointment: item,
    })
  }

  onCancelApt = (bookingDataId, appointmentId) => {
    this.setState({ isRequesting: true })
    this.props.onCancelBooking(bookingDataId, [appointmentId])
  }

  onToggleModal = (name, seletedAppointmentId) => {
    if (!name) {
      this.setState({
        cancelModal: false,
        delayedModal: false,
      })
    } else {
      this.setState({ [name]: true, seletedAppointmentId })
    }
  }

  onDownloadPress = id => {
    const { brand, bookingsData, session, clients, retailer } = this.props
    const { timezone, name, address } = session
    const { name: brandName } = brand
    const client = clients.byId[bookingsData.clientIds[0]]
    const events = [...bookingsData.events]
    const link = `${window.location.origin}/booking/${bookingsData._id}/edit`
    const ics = createIcs(
      events.filter(event => event._id === id),
      timezone,
      address,
      name,
      retailer.name,
      brandName,
      client.email,
      link,
    )
    download(`${retailer.name}-${brand.name}`, ics)
  }

  componentDidUpdate() {
    const { bookingsData, history } = this.props
    if (!isEmpty(bookingsData)) {
      const eventsArray = [...bookingsData.events, ...bookingsData.jokerRequests]
      if (eventsArray.length === 0) {
        history.replace(`/booking/${bookingsData._id}`)
        return null
      }
    }
  }

  renderBookingInfo = () => {
    const { session, brand, bookingsData, clients } = this.props
    const eventsArray = [...bookingsData.events, ...bookingsData.jokerRequests]
    return eventsArray.map(booking => {
      const { startTime, endTime, _id, room, clients: clientIds } = booking
      const dayDate = moment.utc(booking.dayDate, "X").format("dddd, MMMM D, YYYY")
      const clientsList = clientIds.map(id => {
        const client = clients.byId[id]
        if (client) {
          return <p key={id}>{`${client.firstName} ${client.lastName}, ${client.position}`}</p>
        }
        return null
      })
      return (
        <Infocontainer count={eventsArray.length} key={booking._id}>
          {!booking.type && (
            <InfoRoom>
              <p>
                {room} -{" "}
                <span style={{ color: "#a60c46" }}>
                  {booking.status === "declined" ? "Declined" : "Special Request Sent"}
                </span>
              </p>
            </InfoRoom>
          )}
          {booking.type === "joker" && (
            <InfoRoom>
              <p>
                {room} - <span style={{ color: "#a60c46" }}>Joker</span>
              </p>
            </InfoRoom>
          )}
          {booking.type === "main" && (
            <InfoRoom>
              <p>{room}</p>
            </InfoRoom>
          )}
          {!booking.type && booking.status === "pending" && (
            <Message style={{ bottom: "90px", left: "45px" }}>*Cannot be edited. Special request pending.</Message>
          )}
          {!booking.type && booking.status === "declined" && (
            <Message>
              <ButtonString onClick={() => this.onEditPress(booking)}>Edit Appt.</ButtonString>
            </Message>
          )}
          <InfoDate>
            <div>
              <img alt="TimeIcon" src={TimeIcon} />
            </div>
            <div>
              <p>{dayDate}</p>
              <p key={_id}>
                {moment.utc(startTime, "X").format("HH:mm")} to {moment.utc(endTime, "X").format("HH:mm")}
              </p>
              <p>
                <ButtonString
                  style={{
                    color: "rgb(81, 107, 239)",
                    opacity: `${booking.type ? 1 : 0}`,
                    pointerEvents: `${booking.type ? "initial" : "none"}`,
                  }}
                  onClick={() => this.onDownloadPress(booking._id)}
                >
                  Add to calendar
                </ButtonString>
              </p>
            </div>
          </InfoDate>
          <InfoAddress
            style={{
              opacity: `${booking.type ? 1 : 0}`,
              pointerEvents: `${booking.type ? "initial" : "none"}`,
            }}
          >
            <div>
              <img alt="PinIcon" src={PinIcon} />
            </div>
            <div>
              <p>
                <span>{session.name}</span>
              </p>
              <p>{session.address}</p>
              <p>
                <span>How to find us</span>
              </p>
              <p>{brand.directions}</p>
              {}
            </div>
          </InfoAddress>
          <p
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginBottom: "10px",
            }}
          >
            {booking.type === "main" && (
              <ButtonString style={{ marginRight: 0 }} onClick={() => this.onEditPress(booking)}>
                <Button bgcolor={"rgb(81, 107, 239)"} borderColor={"rgb(81, 107, 239)"} style={{ width: "60px" }}>
                  Edit meeting
                </Button>
              </ButtonString>
            )}
            <ButtonString style={{ marginRight: 0 }} onClick={() => this.onCancelPress(booking)}>
              <Button
                color={"rgb(81, 107, 239)"}
                borderColor={"rgb(81, 107, 239)"}
                bgcolor={"transparent"}
                style={{
                  width: "60px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                {" "}
                <p style={{ transform: "rotate(45deg)", fontSize: "20px" }}> + </p> Cancel Appt.
              </Button>
            </ButtonString>
          </p>

          <PersonInfo>{clientsList}</PersonInfo>
        </Infocontainer>
      )
    })
  }

  render() {
    const { delayedModal, delayTimeValue, cancelModal, seletedAppointment } = this.state

    const { bookingsData, session, cancelPendeing, requestPending } = this.props
    if (isEmpty(bookingsData) || !session) return null
    const eventsArray = [...bookingsData.events, ...bookingsData.jokerRequests]
    return (
      <React.Fragment>
        <Modal isOpen={cancelModal || delayedModal} style={customStyles}>
          <ModalBody />
          {requestPending && (
            <LoaderContainer>
              <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
            </LoaderContainer>
          )}
          {delayedModal && (
            <React.Fragment>
              <TextWrap>
                <span style={{ fontSize: "16px" }}>Confirm Arrival Check-in</span>
              </TextWrap>
              <div style={{ marginBottom: "10px" }}>
                <p>
                  Please list the number of minutes the client is delayed{" "}
                  <span style={{ textDecoration: "underline", fontWeight: "bold" }}>from the original start time</span>{" "}
                  of booked appointment.
                </p>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <Select options={timeOptions} value={delayTimeValue} onChange={this.onSelectChange} />
              </div>
              <ButtonsRowModal>
                <Button onClick={this.onDelayed}>Notify Host</Button>
              </ButtonsRowModal>
            </React.Fragment>
          )}

          {cancelModal && (
            <React.Fragment>
              <TextWrap>
                <span style={{ fontSize: "16px" }}>Cancellation of booking</span>
              </TextWrap>
              <div style={{ marginBottom: "10px" }}>
                <p>Are you sure you want to cancel your appointment?</p>
                <p>You can also click ‘Edit Appointment’ to find another suitable slot.</p>
              </div>

              <ButtonsRowModal>
                <Button bgcolor={"rgb(81, 107, 239)"} onClick={() => this.onEditPress(seletedAppointment)}>
                  Edit Appointment
                </Button>
                {}
                <Button
                  bgcolor={"rgb(10, 16, 105)"}
                  onClick={() => this.onCancelApt(bookingsData._id, seletedAppointment._id)}
                >
                  Yes, cancel.
                </Button>
              </ButtonsRowModal>
            </React.Fragment>
          )}
          <CloseModal onClick={() => this.onToggleModal(null)}>X</CloseModal>
        </Modal>

        <StepContainer className="last-step" flexRow="flex-start">
          <div style={{ width: "50%", margin: "0 auto" }}>
            <Icon height="70px" src={modaResaIcon} />
          </div>
          <div>
            <TextSection>
              <div style={{ maxWidth: "760px", margin: "0 auto" }}>
                <p>{`NB! For group bookings, we always find parallel slots for you in order to make the journey time the most time efficient for your team.`}</p>
                <br />
                <p>{`Should you choose to edit parts of a group booking, the slot that is changed will no longer be in tune with the rest of the group booking.`}</p>
                <Div.Flex
                  style={{
                    marginTop: "20px",
                    fontWeight: "bold",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    bgcolor="rgb(81, 107, 239)"
                    borderColor="rgb(81, 107, 239)"
                    margin="10px auto"
                    onClick={() => this.onEditPress({})}
                  >
                    Edit Full Booking
                  </Button>
                  <Button
                    onClick={this.onDelay}
                    bgcolor="rgb(246,131,112)"
                    borderColor="rgb(246,131,112)"
                    margin="10px auto"
                  >
                    I'm Late - Notify brand
                  </Button>
                  <ButtonString onClick={this.onCancelFullBooking}>
                    <Button
                      color={"rgb(81, 107, 239)"}
                      borderColor={"rgb(81, 107, 239)"}
                      bgcolor={"transparent"}
                      style={{
                        width: "170px",
                        margin: "10px auto",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ transform: "rotate(45deg)", fontSize: "20px" }}> + </p> Cancel Full Booking
                    </Button>
                  </ButtonString>
                </Div.Flex>
              </div>
            </TextSection>
          </div>
          <div style={{ height: "100%", position: "relative" }}>
            {cancelPendeing && (
              <Loader style={{ zIndex: 3 }}>
                <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
              </Loader>
            )}
            <Div.Flex
              align="flex-start"
              justify={eventsArray.length > 1 ? "flex-start" : "center"}
              margin="0 auto 20px auto"
              style={{
                width: "100%",
                maxWidth: 1100,
                flexWrap: "wrap",
                padding: "0 50px",
              }}
            >
              {this.renderBookingInfo()}
            </Div.Flex>
          </div>
        </StepContainer>
      </React.Fragment>
    )
  }
}

EditPage.propTypes = {
  bookingsData: PropTypes.object,
  brand: PropTypes.object,
  cancelPendeing: PropTypes.bool,
  clients: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  retailer: PropTypes.object,
  session: PropTypes.object,
  onCancelBooking: PropTypes.func,
  onEdit: PropTypes.func,
  onFetchInviteData: PropTypes.func,
}

export default EditPage
