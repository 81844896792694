import React, { Component } from "react"

import PropTypes from "prop-types"

export class Logout extends Component {
  componentDidMount() {
    this.props.logout()
  }

  render() {
    return <div>Redirecting</div>
  }
}

Logout.propTypes = {
  logout: PropTypes.func,
}

export default Logout
