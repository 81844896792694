import {
  Col,
  DropdownContainer,
  ErrorText,
  Icon,
  InputContainer,
  TeamColor,
  TeamContainer,
} from "./styles"
import Dropdown, {
  DropdownContent,
  DropdownTrigger,
} from "react-simple-dropdown"
import React, { PureComponent } from "react"

import BucketIcon from "../../../../static/noun_Trash_839970.svg"
import { Div } from "../../../UI"
import PropTypes from "prop-types"
import { TwitterPicker } from "react-color"
import { palette } from "../../../../constants/constants/themes"

class Team extends PureComponent {
  handleTeamChange = (type, value) => {
    const { teamIndex, onTeamChange } = this.props
    onTeamChange(type, value, teamIndex)
  }

  onDeleteTeam = teamIndex => {
    this.props.onDeleteTeam(teamIndex)
  }

  render() {
    const { team, errors, teamIndex } = this.props
    return (
      <TeamContainer>
        <Div.Flex
          align="flex-start"
          justify="flex-start"
          style={{ width: "90%" }}
        >
          <InputContainer>
            <input
              placeholder="Enter Team Name"
              type="text"
              value={team.name}
              onChange={e => this.handleTeamChange("name", e.target.value)}
            />
            <ErrorText>{errors[`teams.${teamIndex}.name`]}</ErrorText>
          </InputContainer>
          <Col>
            <DropdownContainer>
              <Dropdown>
                <DropdownTrigger>
                  <TeamColor bgColor={team.color}>Team Color</TeamColor>
                </DropdownTrigger>
                <DropdownContent>
                  <TwitterPicker
                    color={team.color}
                    colors={palette}
                    triangle="top-right"
                    onChangeComplete={color =>
                      this.handleTeamChange("color", color.hex)
                    }
                  />
                </DropdownContent>
              </Dropdown>
            </DropdownContainer>
          </Col>
        </Div.Flex>
        <Div.Flex
          align="flex-start"
          justify="center"
          style={{ marginTop: "10px", marginLeft: "20px" }}
        >
          <Icon
            height="20px"
            src={BucketIcon}
            style={{ padding: "0 10px" }}
            onClick={() => this.onDeleteTeam(teamIndex)}
          />
        </Div.Flex>
      </TeamContainer>
    )
  }
}

Team.propTypes = {
  errors: PropTypes.object,
  team: PropTypes.object,
  teamIndex: PropTypes.number,
  onDeleteTeam: PropTypes.func,
  onTeamChange: PropTypes.func,
}

export default Team
