import { gql } from "@apollo/client"

export const GET_AVAILABLE_LANGUAGES_FOR_BOOKING = gql`
  query GetAvailableLanguagesForBooking($bookingId: ID!, $roomNames: [String!]!) {
    getAvailableLanguagesForBooking(bookingId: $bookingId, roomNames: $roomNames) {
      name
      isoCode
    }
  }
`

export const GET_AVAILABLE_ROOMS_FOR_BOOKING = gql`
  query GetAvailableLanguagesForBooking($bookingId: ID!) {
    getAvailableRoomsForBooking(bookingId: $bookingId) {
      id
      name
    }
  }
`
