export const mainTheme = {
  primary: "#a60c46",
  secondary: "#233044",
  teal: "#7BDCB5",
  gray: "#efeaea",
  red: "#EF3C52",
  gray25: "#D1D1D6",
  gray50: "#F1F3F5",
  gray75: "#F8F9FA",
  gray100: "#495057",
  gray200: "#868E96",
  gray300: "#343A40",
  black: "#333",

  veryLightGray: "#d8d8d8",
  berry: "#a60c46",
  macaroniAndCheese: "#f5a728",
  offWhite: "#f7f7f2",
  darkGreyBlue: "#3747d",
  aquaMarine: "#45cea2",
  white: "#f7f7f7",
  strawberry: "#f8263f",
  textBlack: "#4a4a4a",
  tabBorder: "#ef3c52",

  mainFont: "avenir",

  text: "#001069",
  backgroundLight: "#f9f9f9",
  brandSecondary: "#516bef",
  negative: "#f68470",

  header: "#ffe5e4",
  calendarSidebar: "#8c97b2",

  danger: "#FF3232",
}
