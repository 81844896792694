import { fetchInviteLink, sendInviteEmail } from "../../store/actions/invites"
import {
  getBuyer,
  getClients,
  getInviteLink,
  getInvitedClient,
  getRequestEmailLinkError,
  getRequestEmailLinkStatus,
  getRequestInviteLinkError,
  getRequestInviteLinkStatus,
  getRetailer,
  getSharedRetailer,
  getUser,
} from "../../store/selectors"

import Component from "./InviteModal"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

const selector = createStructuredSelector({
  clients: getClients,
  inviteLink: getInviteLink,
  user: getUser,
  buyer: getBuyer,
  invitedClient: getInvitedClient,
  retailer: getRetailer,
  sharedRetailer: getSharedRetailer,
  requestInviteLinkPending: getRequestInviteLinkStatus,
  requestInviteLinkError: getRequestInviteLinkError,
  requestEmailLinkPending: getRequestEmailLinkStatus,
  requestEmailLinkError: getRequestEmailLinkError,
})

const actions = {
  onFetchLink: fetchInviteLink,
  onSendInvite: sendInviteEmail,
}

export const InviteModal = connect(selector, actions)(Component)
