import { createAsyncAction } from "../../helpers/redux"

export const FETCH_DB_DATA = createAsyncAction("FETCH_DB_DATA")
export const fetchDBData = () => ({
  type: FETCH_DB_DATA.REQUEST,
})
export const DATA_LOADED = "DATA_LOADED"

export const FETCH_USER = createAsyncAction("FETCH_USER")
export const fetchSaleSassions = id => ({
  type: FETCH_USER.REQUEST,
  payload: {
    id,
  },
})

export const UPDATE_USER = "UPDATE_USER"

export const VERIFY_EMAIL = createAsyncAction("VERIFY_EMAIL")
export const verifyEmail = () => ({
  type: VERIFY_EMAIL.REQUEST,
})

export const FETCH_BUYER = createAsyncAction("FETCH_BUYER")
export const fetchBuyer = id => ({
  type: FETCH_BUYER.REQUEST,
  payload: {
    id,
  },
})
