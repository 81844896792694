import * as Api from "../../helpers/api"

import { call, put, select } from "redux-saga/effects"

import { UPDATE_RETAILER } from "../actions/retailers"
import { onToastError } from "./../../helpers/toast"
import { takeLatest } from "../../helpers/saga"

function* updateRetailer({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { data, retailerId } = payload
  try {
    const response = yield call(Api.updateRetailer, {
      retailerId,
      data,
      token: accessToken,
    })

    yield put({ type: UPDATE_RETAILER.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message ||
      (error.response &&
        error.response.data.errors &&
        error.response.data.errors.message)
    yield put({
      type: UPDATE_RETAILER.FAILURE,
      payload: errosMsg,
    })
  }
}

function* updateRetailerFlow() {
  yield takeLatest(UPDATE_RETAILER.REQUEST, updateRetailer)
}

export default [updateRetailerFlow]
