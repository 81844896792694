import * as events from "./messageTypes"

import { ADD_SOCKET_ROOM, REMOVE_SOCKET_ROOM } from "../actions/sockets"
import {
  CANCEL_BOOKING,
  CONFIRM_AUTO_BOOKINGS,
  DELAY_APPOINTMENT,
  EDIT_AUTO_BOOKINGS,
  EDIT_AUTO_BOOKINGS_JOKER,
  EDIT_JOKER_REQUEST,
  REQUEST_JOKER,
} from "../actions/schedules"
import { DELETE_CLIENT, UPDATE_CLIENT } from "../actions/clients"
import { onToastError, onToastSuccess } from "./../../helpers/toast"

import { SOCKET_URL } from "../../config"
import forIn from "lodash/forIn"
import io from "socket.io-client"

const successActionTypeBySocketEvent = {
  [events.BUYER_EVENT_DELETED]: CANCEL_BOOKING.SUCCESS,
  [events.BUYER_JOKER_REQUESTED]: REQUEST_JOKER.SUCCESS,
  [events.APPOINTMENT_DELAYED]: DELAY_APPOINTMENT.SUCCESS,
  [events.SHARED_BUYER_UPDATED]: UPDATE_CLIENT.SUCCESS,
}

const successEditActionTypeBySocketEvent = {
  [events.BUYER_EVENTS_BOOKED]: CONFIRM_AUTO_BOOKINGS.SUCCESS,
  [events.BUYER_EVENT_UPDATED_WITH_JOKER]: EDIT_AUTO_BOOKINGS_JOKER.SUCCESS,
  [events.DECLINED_BUYER_JOKER_REPLACED]: EDIT_JOKER_REQUEST.SUCCESS,
  [events.BUYER_FULL_BOOKING_UPDATED]: EDIT_AUTO_BOOKINGS.SUCCESS,
  [events.BUYER_EVENT_UPDATED]: EDIT_AUTO_BOOKINGS.SUCCESS,
  [events.BUYER_EVENTS_UPDATED_WITH_JOKER]: EDIT_AUTO_BOOKINGS_JOKER.SUCCESS,
}

const failureActionTypeBySocketEvent = {
  [events.BOOK_BUYER_EVENTS]: CONFIRM_AUTO_BOOKINGS.FAILURE,
  [events.DELETE_BUYER_EVENT]: CANCEL_BOOKING.FAILURE,
  [events.UPDATE_BUYER_FULL_BOOKING]: EDIT_AUTO_BOOKINGS.FAILURE,
  [events.UPDATE_BUYER_EVENT]: EDIT_AUTO_BOOKINGS.FAILURE,
  [events.DELAY_APPOINTMENT]: DELAY_APPOINTMENT.FAILURE,
  [events.UPDATE_BUYER_EVENTS_WITH_JOKER]: EDIT_AUTO_BOOKINGS_JOKER.FAILURE,
  [events.UPDATE_BUYER_EVENT_WITH_JOKER]: EDIT_AUTO_BOOKINGS_JOKER.FAILURE,
  [events.REQUEST_BUYER_JOKER]: REQUEST_JOKER.FAILURE,
  [events.UPDATE_SHARED_BUYER]: UPDATE_CLIENT.FAILURE,
  [events.DELETE_SHARED_BUYER]: DELETE_CLIENT.FAILURE,
}

const updateRooms = ({ storeAPI, payload }) => {
  const {
    salesSessions: { selectedSession },
  } = storeAPI.getState()
  const eventsArray = [...payload.events, ...payload.jokerRequests]
  const rooms = selectedSession.rooms.map(room => {
    const event = eventsArray.find(event => event.room === room.name)
    if (event) {
      return {
        roomName: event.room,
        clients: event.clients,
        length: (event.endTime - event.startTime) / 60,
      }
    }
    return {
      room: room.name,
      clients: [null],
      length: 0,
    }
  })
  return rooms
}

const createMySocketMiddleware = () => storeAPI => {
  const socket = io(SOCKET_URL, {
    transports: ["websocket"],
  })
  const createSocketEventHandler = (socket, socketEvent, actionType, options = {}) => {
    socket.on(socketEvent, payload => {
      if (options.successMessage) {
        onToastSuccess(options.successMessage)
      }
      storeAPI.dispatch({
        type: actionType,
        payload,
      })
    })
  }

  const createSocketEditEventHandler = (socket, socketEvent, actionType, options = {}) => {
    socket.on(socketEvent, payload => {
      const rooms = updateRooms({ storeAPI, payload })
      if (options.successMessage) {
        onToastSuccess(options.successMessage)
      }
      storeAPI.dispatch({
        type: actionType,
        payload: { bookingsData: { ...payload, rooms } },
      })
    })
  }

  forIn(successActionTypeBySocketEvent, (action, event) => {
    createSocketEventHandler(socket, event, action)
  })

  forIn(successEditActionTypeBySocketEvent, (action, event) => {
    createSocketEditEventHandler(socket, event, action)
  })

  socket.on(events.API_ERROR, error => {
    const { eventType, message } = error
    console.log(eventType)
    console.error(message)
    let customError = "Oops, something went wrong"
    if (error && error.errorType === "custom") {
      customError = error.message
    }
    onToastError(customError)

    const actionType = failureActionTypeBySocketEvent[eventType]

    storeAPI.dispatch({
      type: actionType,
      payload: message,
    })
  })

  return next => action => {
    if (action.type === "SEND_WEBSOCKET_MESSAGE") {
      const { eventType, body } = action.payload

      if (eventType === events.JOIN_SOCKET_ROOM) {
        storeAPI.dispatch({
          type: ADD_SOCKET_ROOM,
          payload: body,
        })
      } else if (eventType === events.LEAVE_SOCKET_ROOM) {
        storeAPI.dispatch({
          type: REMOVE_SOCKET_ROOM,
          payload: body,
        })
      }
      socket.emit(eventType, body)
      return
    }

    return next(action)
  }
}

export default createMySocketMiddleware
