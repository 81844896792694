import {
  Bold,
  ButtonsRow,
  CalendarContent,
  CalendarDay,
  CalendarDays,
  CalendarHeader,
  Calendars,
  ClientItem,
  Clients,
  ClientsList,
  CloseModal,
  Container,
  Date,
  DateContainer,
  Day,
  Dots,
  Flex,
  Hour,
  Icon,
  Icons,
  Loader,
  LoaderContainer,
  MeetingDate,
  MeetingInfo,
  NotFound,
  StaffCalendars,
  StaffColumn,
  StaffColumnHeader,
  StaffTableHeader,
  Text,
  Timeline,
  Timezone,
  TodayButton,
} from "./styles"
import { BookingModalDialogBody, BookingModalHeader } from "../UI/BookingModal"
import { Button, Div } from "../UI"
import React, { Component } from "react"
import { marketsByValue, seasonsKey } from "../../config/session"

import Airplane from "../../static/Airplane.svg"
import AnnNoSm from "../../static/Anotsmiling.png"
import AnnSm from "../../static/asmiling.png"
import ArrowIcon from "../../static/back.svg"
import { CAR } from "../../config/images"
import { ReactComponent as CloseIcon } from "../../static/cancel-music.svg"
import { DayGrid } from "./Grid"
import { ReactComponent as DeleteIcon } from "../../static/waste-bin.svg"
import { Event } from "./Event"
import { Filter } from "./CalendarFilter"
import { Link } from "react-router-dom"
import { MREvent } from "./MREvent"
import MRLogo from "../../static/Logo2.png"
import Modal from "react-modal"
import PropTypes from "prop-types"
import ReactTooltip from "react-tooltip"
import Select from "react-select"
import Switch from "react-switch"
import { SyncLoader } from "react-spinners"
import TimeIcon from "../../static/clock.svg"
import TooltipIcon from "../../static/info.svg"
import ViewIcon from "../../static/view.svg"
import { modaResaRoles } from "../../config/settings"
import moment from "moment-timezone"
import sortBy from "lodash/sortBy"
import times from "lodash/times"
import { toast } from "react-toastify"
import { videoTools } from "../../constants/meetings"

Modal.setAppElement("body")
const customStyles = {
  content: {
    width: "400px",
    height: "350px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    padding: "15px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    zIndex: 5,
  },
}

const customEventStyles = {
  content: {
    width: "510px",
    maxHeight: "80vh",
    overflowY: "auto",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    padding: "25px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 21,
  },
}

const timeZoneModalStyles = {
  content: {
    width: "700px",
    height: "430px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    padding: "35px",
    position: "relative",
    fontSize: 16,
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    zIndex: 5,
  },
}

const customStyles2 = {
  content: {
    width: "300px",
    height: "230px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    padding: "15px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    zIndex: 5,
  },
}

const nurgeModalStyles = {
  content: {
    width: "510px",
    maxHeight: "80vh",
    overflowY: "auto",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    padding: "25px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 21,
  },
}

const eventType = {
  show: "Show",
  presentation: "Presentation",
}

const confirmationModalStyles = {
  content: {
    width: "500px",
    height: "250px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    padding: "30px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 21,
  },
}

const HALFHOUR = 1800
let COLUMN_WIDTH = 95
const getType = data => {
  if (data.isExternal === true) {
    return "calendar"
  }
  if (data.type === "custom" || data.type === "new") {
    return "custom"
  }
  if (data.eventType === "show" || data.eventType === "presentation") {
    return "fashionEvent"
  }
  return "booking"
}

function onSameRow(a, b, minimumStartDifference) {
  return (
    Math.abs(b.startTime - a.startTime) < minimumStartDifference ||
    (b.startTime > a.startTime && b.startTime < a.endTime)
  )
}

const _width = event => {
  if (event.rows) {
    const columns =
      event.rows.reduce((max, row) => Math.max(max, row.leaves.length + 1), 0) +
      1
    return COLUMN_WIDTH / columns
  }

  const availableWidth = COLUMN_WIDTH - _width(event.container)
  if (event.leaves) {
    return availableWidth / (event.leaves.length + 1)
  }
  return _width(event.row)
}

const xOffset = event => {
  if (event.rows) return 0
  const leavesWidth = _width(event)
  if (event.leaves) return leavesWidth
  const leaves = event.row.leaves
  const x = xOffset(event.row)
  const width = _width(event.row)
  const index = leaves.indexOf(event) + 1
  return x + index * width
}

const width = event => {
  const noOverlap = _width(event)
  const overlap = Math.min(COLUMN_WIDTH, _width(event) * 1.7)
  if (event.rows) {
    return overlap
  }
  if (event.leaves) {
    return event.leaves.length > 0 ? overlap : noOverlap
  }
  const { leaves } = event.row
  const index = leaves.indexOf(event)
  return index === leaves.length - 1 ? noOverlap : overlap
}

export class Calendar extends Component {
  constructor(props) {
    super(props)
    const today = moment()
      .utc()
      .set({ hours: "00", minute: "00", seconds: "00" })
    const { buyer } = this.props
    const timeZonesModal =
      window.localStorage.getItem("timeZoneModal") !== "true"
    const firstTime = window.localStorage.getItem("firstTime") !== "true"
    this.state = {
      currentWeek: today.isoWeek(),
      currentYear: today.year(),
      currentDay: today.unix(),
      events: [],
      showPresentation: false,
      showShows: false,
      fashionModalIsOpened: false,
      bookingModalIsOpened: false,
      confirmationModalIsOpened: false,
      typeOfConfirmationModal: null,
      selectedEvent: null,
      firstTime,
      timeZonesModal,
      timeZonesModalStep: 0,
      firstTimeModal: false,
      isAddingRequest: false,
      isRemovingRequest: false,
      selectedColleague: {
        value: buyer._id,
        label: `${buyer.firstName} ${buyer.lastName}`,
      },
      selectedTeam: { value: "all", label: "All Teams" },
      MREventModal: false,
      newEventModalCords: {},
      MREventRequest: false,
      isCreating: false,
      ignoreOffset: false,
      isCancellingRequest: false,
      nudgeModal: false,
      isNudgeEmailPengind: false,
      verifyEmailRequest: false,
      gmapsLoaded: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      requestAddEventPending,
      requestAddEventError,
      requestRemoveEventPending,
      requestRemoveEventError,
      requestMREventPending,
      requestMREventError,
      cancelPendingError,
      cancelPendeing,
      requestVerifyEmailPending,
      requestVerifyEmailError,
      requestNudgeEmailError,
      requestNudgeEmailPending,
    } = nextProps
    const {
      firstTime,
      isAddingRequest,
      isRemovingRequest,
      MREventRequest,
      isCancellingRequest,
      isCreating,
      verifyEmailRequest,
      timeZonesModalStep,
      isNudgeEmailPengind,
    } = prevState
    if (
      isAddingRequest &&
      !requestAddEventPending &&
      firstTime &&
      !requestAddEventError
    ) {
      return { firstTime: false, firstTimeModal: true, isAddingRequest: false }
    }
    if (
      isAddingRequest &&
      !requestAddEventPending &&
      !firstTime &&
      !requestAddEventError
    ) {
      toast("The event has been add to your schedule!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        className: "def-toast",
      })
      return { isAddingRequest: false }
    }
    if (
      isRemovingRequest &&
      !requestRemoveEventPending &&
      !requestRemoveEventError
    ) {
      toast("The event has been removed from your schedule!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        className: "def-toast",
      })
      return { isRemovingRequest: false }
    }
    if (MREventRequest && !requestMREventPending && !requestMREventError) {
      if (isCreating) {
        return {
          MREventRequest: false,
          MREventModal: false,
          selectedEvent: null,
          nudgeModal: true,
          isCreating: false,
        }
      }
      return {
        MREventRequest: false,
        MREventModal: false,
        selectedEvent: null,
      }
    }
    if (isCancellingRequest && !cancelPendeing && !cancelPendingError) {
      return {
        isCancellingRequest: false,
        confirmationModalIsOpened: false,
        selectedEvent: null,
        typeOfConfirmationModal: null,
      }
    }
    if (
      verifyEmailRequest &&
      !requestVerifyEmailPending &&
      !requestVerifyEmailError
    ) {
      return {
        verifyEmailRequest: false,
        timeZonesModalStep: timeZonesModalStep + 1,
      }
    }
    if (
      isNudgeEmailPengind &&
      !requestNudgeEmailPending &&
      !requestNudgeEmailError
    ) {
      return { isNudgeEmailPengind: false, nudgeModal: false }
    }
    return null
  }

  componentDidMount() {
    const { onFetchSchedule, buyer } = this.props
    const startTimeElem = document.getElementById(8)
    const scrollTo = startTimeElem.getBoundingClientRect()
    const calendar = document.getElementById("calendar")
    calendar.scrollTop = scrollTo.top - 148
    if (!document.getElementById("gmaps-api")) {
      window.initMap = this.initMap
      const gmapScriptEl = document.createElement(`script`)
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC3boOLqNgMBUDAzCemBK_B0-yO8u7VfmU&libraries=places&callback=initMap`
      gmapScriptEl.id = "gmaps-api"
      document
        .querySelector(`body`)
        .insertAdjacentElement(`beforeend`, gmapScriptEl)
    } else this.initMap()

    onFetchSchedule(buyer._id)
  }

  initMap = () => {
    this.setState({
      gmapsLoaded: true,
    })
  }

  onAddEvent = (id, e) => {
    const { onAddEvent } = this.props
    e.stopPropagation()
    const { selectedColleague } = this.state
    this.setState({ isAddingRequest: true })
    onAddEvent(id, selectedColleague.value)
  }

  onEventClick = (event, type) => {
    const { onFetchBookingId } = this.props
    if (type === "fashion") {
      this.setState({ fashionModalIsOpened: true, selectedEvent: event })
    } else if (type === "booking") {
      this.setState({ bookingModalIsOpened: true, selectedEvent: event })
      onFetchBookingId(event._id)
    } else if (type === "mrevent") {
      this.setState({ MREventModal: true, selectedEvent: event })
    }
  }

  onSwitchDate = (direction, type) => {
    const { currentWeek, currentYear, currentDay } = this.state
    if (type === "week") {
      if (direction === "next") {
        if (currentWeek + 1 > 52) {
          if (
            moment
              .utc()
              .year(currentYear)
              .isoWeeksInYear() === 52
          ) {
            this.setState({ currentWeek: 1, currentYear: currentYear + 1 })
          } else if (currentWeek + 1 > 53) {
            this.setState({ currentWeek: 1, currentYear: currentYear + 1 })
          } else {
            this.setState({ currentWeek: currentWeek + 1 })
          }
        } else {
          this.setState({ currentWeek: currentWeek + 1 })
        }
      } else {
        if (currentWeek - 1 > 0) {
          this.setState({ currentWeek: currentWeek - 1 })
        } else {
          this.setState({
            currentWeek: moment
              .utc()
              .year(currentYear - 1)
              .isoWeeksInYear(),
            currentYear: currentYear - 1,
          })
        }
      }
    } else if (type === "day") {
      if (direction === "next") {
        this.setState({ currentDay: currentDay + 86400 })
      } else {
        this.setState({ currentDay: currentDay - 86400 })
      }
    }
  }

  onSwitch = (cheked, name) => {
    const { selectedColleague } = this.state
    const { buyer, onFetchSchedule, onFetchColleaguesCalendar } = this.props
    this.setState({ [name]: cheked })
    if (name === "ignoreOffset") {
      if (selectedColleague.value !== "all") {
        onFetchSchedule(selectedColleague.value, cheked)
      } else {
        onFetchColleaguesCalendar(buyer._id, cheked)
      }
    }
  }

  onSlotClick = (e, dayDate, timestamp) => {
    const { selectedColleague } = this.state
    const { colleagues } = this.props
    const meObj = colleagues.byId[selectedColleague.value]
    const me = {
      value: meObj._id,
      label: `${meObj.firstName} ${meObj.lastName}, ${meObj.position} - ${meObj.email} (me)`,
    }
    const event = {
      dayDate,
      startTime: timestamp,
      endTime: timestamp + 3600,
      type: "new",
      attendees: [me],
      meetingType: "",
      location: "",
      title: "",
      host: {
        role: "",
        fullName: "",
        phoneNumber: "",
        email: "",
      },
    }
    this.setState({ selectedEvent: event, MREventModal: true })
  }

  onChangeSelect = (name, value) => {
    const { ignoreOffset } = this.state
    const { onFetchSchedule, onFetchColleaguesCalendar, buyer } = this.props
    this.setState({ [name]: value })
    if (name === "selectedColleague") {
      if (value.value !== "all") {
        onFetchSchedule(value.value, ignoreOffset)
      } else {
        onFetchColleaguesCalendar(buyer._id, ignoreOffset)
      }
    }
  }

  onDelete = (id, type, e) => {
    const {
      onRemoveEvent,
      onRemoveMREvent,
      selectedBookingId,
      onCancelBooking,
    } = this.props
    const { selectedColleague } = this.state
    if (type === "fashion") {
      e.stopPropagation()
      this.setState({ isRemovingRequest: true })
      onRemoveEvent(id, selectedColleague.value)
    } else if (type === "mrevent") {
      this.setState({ MREventRequest: true })
      onRemoveMREvent(id)
    } else if (type === "booking") {
      this.setState({ isCancellingRequest: true })
      onCancelBooking(selectedBookingId, id)
    }
  }

  closeModal = (withDelete, name, turnOff) => {
    const { selectedEvent, events } = this.state
    if (withDelete) {
      const index = events.findIndex(event => event._id === selectedEvent._id)
      events.splice(index, 1)
      this.setState({ [name]: false, events })
    } else if (turnOff) {
      this.setState({
        [name]: false,
        showPresentation: false,
        showShows: false,
      })
    } else if (name === "MREventModal") {
      this.setState({ [name]: false, selectedEvent: null })
    } else if (name === "firstTimeModal") {
      window.localStorage.setItem("firstTime", true)
      this.setState({ [name]: false })
    } else if (name === "timeZonesModal") {
      window.localStorage.setItem("timeZoneModal", true)
      this.setState({ [name]: false })
    } else if (name === "confirmationModalIsOpened") {
      this.setState({ [name]: false, typeOfConfirmationModal: null })
    } else {
      this.setState({ [name]: false, selectedEvent: null })
    }
  }

  onChangeTime = (value, name) => {
    this.setState(prevState => ({
      selectedEvent: {
        ...prevState.selectedEvent,
        [name]: value,
      },
    }))
  }

  onOpenConfirmationModal = type => {
    this.setState({
      confirmationModalIsOpened: true,
      typeOfConfirmationModal: type,
    })
  }

  onTodayPress = () => {
    const today = moment()
      .utc()
      .set({ hours: "00", minute: "00", seconds: "00" })
    this.setState({
      currentWeek: today.isoWeek(),
      currentYear: today.year(),
      currentDay: today.unix(),
    })
  }

  onSaveEvent = ({ ...event }) => {
    const { selectedColleague } = this.state
    const {
      colleagues,
      onUpdateMREvent,
      onSaveEvent,

      fetchedTimezone,
    } = this.props

    const offset = moment
      .utc()
      .tz(fetchedTimezone, true)
      .utcOffset()
    const calendarOwner = colleagues.byId[selectedColleague.value]
    this.setState({ MREventRequest: true })
    let newEvent
    if (event._id) {
      newEvent = {
        ...event,
        offset,
        attendees:
          event.attendees &&
          event.attendees.map(collegue => {
            if (typeof collegue === "string") return collegue
            return collegue.value
          }),
        startTime: event.startTime - offset * 60,
        endTime: event.endTime - offset * 60,
      }
      onUpdateMREvent(newEvent)
    } else {
      newEvent = {
        ...event,
        attendees:
          event.attendees && event.attendees.map(collegue => collegue.value),
        retailerId: calendarOwner.retailerId,
        startTime: event.startTime - offset * 60,
        endTime: event.endTime - offset * 60,
        createdBy: colleagues.byId[selectedColleague.value]._id,
        type: "custom",
        offset,
      }
      onSaveEvent(newEvent)
    }
    this.setState({ isCreating: true, nudgeEmail: newEvent.host.email })
  }

  onNextClick = () => {
    this.setState(prevState => ({
      timeZonesModalStep: prevState.timeZonesModalStep + 1,
    }))
  }

  onNudgeBrand = () => {
    this.setState({ isNudgeEmailPengind: true })
    this.props.onNudgeBrand()
  }

  onVerifyEmail = () => {
    this.setState({ verifyEmailRequest: true })
    this.props.onVerify()
  }

  renderConfirmationModal = () => {
    const {
      confirmationModalIsOpened,
      typeOfConfirmationModal,
      selectedEvent,
    } = this.state
    const { selectedBookingId, cancelPendeing } = this.props
    if (typeOfConfirmationModal === "delete") {
      return (
        <Modal
          isOpen={confirmationModalIsOpened}
          style={confirmationModalStyles}
          onRequestClose={() =>
            this.closeModal(null, "confirmationModalIsOpened")
          }
        >
          {cancelPendeing && (
            <LoaderContainer>
              <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
            </LoaderContainer>
          )}
          <div>
            <div style={{ fontWeight: "bold" }}>
              Confirm Booking Cancellation
            </div>
            <Text margin="20px 0 30px 0">
              {`Are you sure you want to delete this booking? If you want to change your appointment choose 'Edit Appointment' instead,
              to see the brand's other live availabilities`}
            </Text>
          </div>
          <ButtonsRow>
            <Button
              bgcolor="#4b4b4b"
              style={{ textTransform: "initial" }}
              onClick={() => this.onDelete(selectedEvent._id, "booking")}
            >
              Cancel Appointment
            </Button>
          </ButtonsRow>
          <CloseModal
            style={{ color: "#4b4b4b", width: "19px" }}
            onClick={() => this.closeModal(null, "confirmationModalIsOpened")}
          >
            &#10006;
          </CloseModal>
        </Modal>
      )
    } else if (typeOfConfirmationModal === "edit") {
      return (
        <Modal
          isOpen={confirmationModalIsOpened}
          style={confirmationModalStyles}
          onRequestClose={() =>
            this.closeModal(null, "confirmationModalIsOpened")
          }
        >
          <div>
            <div style={{ fontWeight: "bold" }}>Changing appointment</div>
            <Text margin="20px 0 30px 0">
              {`Do you want to see the updated availabilities of the brand? We'll take you through the same booking process and show you the new and real-time availabilities.
              Your new booking will then automatically update here.`}
            </Text>
          </div>
          <ButtonsRow>
            <Link to={`/booking/${selectedBookingId}/edit`}>
              <Button style={{ textTransform: "initial" }}>
                Edit Appointment
              </Button>
            </Link>
          </ButtonsRow>
          <CloseModal
            style={{ color: "#4b4b4b", width: "19px" }}
            onClick={() => this.closeModal(null, "confirmationModalIsOpened")}
          >
            &#10006;
          </CloseModal>
        </Modal>
      )
    }
  }

  renderModal = () => {
    const {
      fashionModalIsOpened,
      selectedEvent,
      bookingModalIsOpened,
      MREventModal,

      selectedColleague,
      confirmationModalIsOpened,
      nudgeModal,
    } = this.state
    const {
      requestBookingIdPending,
      allColleagues,
      requestMREventError,
      colleagues,
      requestMREventPending,
      requestNudgeEmailPending,
      onFetchTimezone,
      fetchedTimezone,
    } = this.props

    if ((!selectedEvent || confirmationModalIsOpened) && !nudgeModal)
      return null
    let href
    if (selectedEvent && selectedEvent.contactEmail) {
      href = "mailto:" + selectedEvent.contactEmail
    }
    if (selectedEvent && fashionModalIsOpened) {
      return (
        <Modal
          isOpen={fashionModalIsOpened}
          style={customStyles2}
          onRequestClose={() => this.closeModal(null, "fashionModalIsOpened")}
        >
          <div>
            <div>
              <span style={{ fontWeight: "bold" }}>
                {selectedEvent.companyName}{" "}
              </span>
              {eventType[selectedEvent.eventType]}
            </div>
            <div>
              {moment
                .utc(selectedEvent.startTime, "X")
                .format("DD.MM.YYYY - HH:mm")}{" "}
              - {moment.utc(selectedEvent.endTime, "X").format("HH:mm")}
            </div>
            {selectedEvent.address && (
              <div style={{ marginTop: "15px" }}>{selectedEvent.address}</div>
            )}
            <div style={{ marginTop: "15px" }}>
              {selectedEvent.contactName && (
                <div>Contact: {selectedEvent.contactName}</div>
              )}
              {selectedEvent.contactEmail && (
                <div>
                  Email: <a href={href}>{selectedEvent.contactEmail}</a>
                </div>
              )}
              {selectedEvent.url && (
                <div>
                  <a
                    href={selectedEvent.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    See more
                  </a>
                </div>
              )}
            </div>
          </div>
          <CloseModal
            style={{ color: "#516BEF" }}
            onClick={() => this.closeModal(null, "fashionModalIsOpened")}
          >
            &#10006;
          </CloseModal>
        </Modal>
      )
    } else if (selectedEvent && bookingModalIsOpened) {
      const { meetingResp } = selectedEvent
      return (
        <Modal
          isOpen={bookingModalIsOpened}
          style={customEventStyles}
          onRequestClose={() => this.closeModal(null, "bookingModalIsOpened")}
        >
          {requestBookingIdPending && (
            <LoaderContainer>
              <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
            </LoaderContainer>
          )}
          <Icons>
            <div>
              <DeleteIcon
                fill="#000"
                height="15px"
                width="100%"
                onClick={() => this.onOpenConfirmationModal("delete")}
              />
            </div>
            <div>
              <CloseIcon
                fill="#000"
                height="12px"
                width="100%"
                onClick={() => this.closeModal(null, "bookingModalIsOpened")}
              />
            </div>
          </Icons>
          <BookingModalHeader light>
            <Flex align="flex-start" column>
              <div>
                <Bold>
                  {selectedEvent.brand} -{" "}
                  {marketsByValue[selectedEvent.session.market]} -{" "}
                  {seasonsKey[selectedEvent.session.season]}
                </Bold>
              </div>
              <div>{selectedEvent.session.address}</div>
            </Flex>
          </BookingModalHeader>
          <BookingModalDialogBody>
            <Clients>
              <p>Attendees:</p>
              <ClientsList>
                {selectedEvent.clients.map((client, index) => (
                  <ClientItem key={index}>
                    <p>{`${client.name}, ${client.position} - ${client.email}`}</p>
                  </ClientItem>
                ))}
              </ClientsList>
            </Clients>
            <MeetingInfo style={{ flexDirection: "column" }}>
              <div style={{ width: "60%" }}>
                <MeetingDate margin="0px">
                  <div>
                    <img alt={"icon"} src={TimeIcon} />
                  </div>
                  <div style={{ width: "100%" }}>
                    <div style={{ marginBottom: "5px" }}>
                      {moment
                        .utc(selectedEvent.dayDate, "X")
                        .format("dddd, MMMM Do Y")}
                    </div>
                    <div>
                      {moment.utc(selectedEvent.startTime, "X").format("HH:mm")}{" "}
                      - {moment.utc(selectedEvent.endTime, "X").format("HH:mm")}
                    </div>
                  </div>
                </MeetingDate>
              </div>
              <div style={{ width: "60%" }}>
                <MeetingDate margin="0px" style={{ alignItems: "center" }}>
                  <div>
                    <img alt={"icon"} src={ViewIcon} />
                  </div>
                  <div style={{ width: "100%" }}>
                    <div style={{ marginBottom: "5px" }}>
                      {selectedEvent.room}
                    </div>
                  </div>
                </MeetingDate>
              </div>
              {selectedEvent.meetingApproach === "video-conference" && (
                <div>
                  <MeetingDate
                    margin="0px"
                    style={{ alignItems: "center", marginLeft: "45px" }}
                  >
                    <div style={{ width: "100%" }}>
                      <div style={{ marginBottom: "5px" }}>
                        <Link to={`/video-call/${selectedEvent._id}`}>
                          Click here to join this meeting on{" "}
                          <b>
                            {
                              videoTools.find(
                                t => t.value === selectedEvent.videoTool,
                              ).label
                            }
                          </b>
                        </Link>
                      </div>
                    </div>
                  </MeetingDate>
                </div>
              )}
            </MeetingInfo>
            <Div.Flex align="center">
              <span>Brand Contact</span>
              <ClientItem style={{ minWidth: "80%", margin: "0" }}>
                <span>{`${meetingResp.name}, ${
                  modaResaRoles[meetingResp.position]
                }`}</span>
                <span>{meetingResp.phoneNumber}</span>
              </ClientItem>
            </Div.Flex>
            <Div.Flex justify="center" style={{ marginTop: "20px" }}>
              <Button
                style={{ textTransform: "initial" }}
                type="button"
                onClick={() => this.onOpenConfirmationModal("edit")}
              >
                <span>Edit Appointment</span>
              </Button>
            </Div.Flex>
          </BookingModalDialogBody>
        </Modal>
      )
    } else if (selectedEvent && MREventModal) {
      const calendarOwner = colleagues.byId[selectedColleague.value]
      return (
        <MREvent
          allColleagues={allColleagues}
          calendarOwner={calendarOwner}
          colleagues={colleagues}
          eventData={selectedEvent}
          newEventModal={MREventModal}
          requestMREventError={requestMREventError}
          requestMREventPending={requestMREventPending}
          onChangeTime={this.onChangeTime}
          onCloseModal={this.closeModal}
          onDelete={this.onDelete}
          onSaveEvent={this.onSaveEvent}
          onFetchTimezone={onFetchTimezone}
          fetchedTimezone={fetchedTimezone}
        />
      )
    } else if (nudgeModal) {
      return (
        <Modal
          isOpen={nudgeModal}
          style={nurgeModalStyles}
          onRequestClose={() => this.closeModal(null, "nudgeModal")}
        >
          {requestNudgeEmailPending && (
            <LoaderContainer>
              <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
            </LoaderContainer>
          )}
          <div>
            <div
              style={{
                textAlign: "center",
                margin: "20px 0",
                fontSize: "16px",
              }}
            >
              <Bold>The more, the merrier!</Bold>
            </div>
            <Text margin="0 0 30px 0">
              {`If you just inputted the email of a brand, you can now encourage them to start using ModaResa to further reduce your ping pong emailing and
              book more meetings instantaneously.`}
            </Text>
            <Text margin="0 0 30px 0">
              {`We need visionary buyers like you - let's lead this revolution together!`}
            </Text>
          </div>
          <CloseModal
            style={{ color: "#a60c46" }}
            onClick={() => this.closeModal(null, "nudgeModal")}
          >
            &#10006;
          </CloseModal>
          <Flex column style={{ minHeight: "30px" }}>
            <Button align="center" type="button" onClick={this.onNudgeBrand}>
              FRIENDLY NUDGE
            </Button>
          </Flex>
          <Div.Flex justify="center" style={{ marginTop: "20px" }}>
            <img alt="logo" src={MRLogo} style={{ height: "30px" }} />
          </Div.Flex>
        </Modal>
      )
    }
  }

  renderfirstTimeModal = () => {
    const { firstTimeModal } = this.state
    return (
      <Modal
        isOpen={firstTimeModal}
        style={customStyles}
        onRequestClose={() => this.closeModal(false, "firstTimeModal")}
      >
        <div>
          <Text margin="0 0 30px 0">
            {`The event has been added to your schedule. Continue adding shows and presentations to your schedule or turn off the buttons to clear your schedule for events you won't attend.`}
          </Text>
          <Text margin="20px 0 0 0">
            {`To remove an event you just added, press the minus-sign.`}
          </Text>
          <Text margin="20px 0 0 0" style={{ fontWeight: "bold" }}>
            {`NB! Adding an event to your calendar does not notify the brand. You must be personally invited to actually attend.`}
          </Text>
        </div>
        <ButtonsRow>
          <Button
            style={{ textTransform: "initial" }}
            onClick={() => this.closeModal(false, "firstTimeModal", true)}
          >
            Turn off shows
          </Button>
          <Button
            bgcolor="#6ea6bb"
            style={{ textTransform: "initial", marginLeft: "20px" }}
            onClick={() => this.closeModal(false, "firstTimeModal")}
          >
            Continue Adding
          </Button>
        </ButtonsRow>
        {}
      </Modal>
    )
  }

  renderTimeZonesModal = () => {
    const { timeZonesModal, timeZonesModalStep } = this.state
    const { requestVerifyEmailPending, buyer } = this.props
    const isVerified = buyer.emailVerified
    return (
      <Modal isOpen={timeZonesModal} style={timeZoneModalStyles}>
        {requestVerifyEmailPending && (
          <LoaderContainer>
            <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
          </LoaderContainer>
        )}
        {timeZonesModalStep === 0 && (
          <React.Fragment>
            <div>
              <Div.Flex justify="flex-start">
                <Icon
                  src={TimeIcon}
                  style={{ height: "25px", marginLeft: "0" }}
                />
                <Bold>Timezone Information</Bold>
              </Div.Flex>
              <Text margin="15px 0 15px 0">
                {`The timezone listed in the navbar is based on your current location, but we store your bookings in the timezone of where the sale session/market will happen.`}
              </Text>
              <Text margin="0 0 15px 0">
                <span style={{ fontStyle: "italic" }}>{`Example: `}</span>
                {`if you are currently in NY and booked a future appointment that will be in  Paris,
              it will appear converted into your timezone, as we speak. Once you actually get to Paris, it will appear in the right TimeZone.`}
              </Text>
              <Text margin="0 0 15px 0">
                {`To simulate your future calendar in the future timezone you can play around with our Jetsetter (Airplane) toggle button at the upper right.`}
              </Text>
            </div>
            <Div.Flex justify="space-between">
              <Button
                style={{ textTransform: "initial", fontSize: 16 }}
                onClick={
                  isVerified
                    ? () => this.closeModal(false, "timeZonesModal", false)
                    : this.onNextClick
                }
              >
                {`You've got me all covered, MR!`}
              </Button>
              <div style={{ width: "350px" }}>
                <img
                  alt="car"
                  src={CAR}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </Div.Flex>
          </React.Fragment>
        )}
        {timeZonesModalStep === 1 && (
          <React.Fragment>
            <Div.Flex align="flex-start">
              <div>
                <div>
                  <div>
                    <Bold style={{ fontSize: "16px" }}>
                      Anna wants you to verify your email
                    </Bold>
                  </div>
                  <Text margin="45px 0 25px 0">
                    <p>
                      Be a visionary like Anna, and{" "}
                      <Bold>verify your email</Bold> so you can have access to
                      features such as notifying brands of your potential delay
                      to meetings in one-click.
                    </p>
                  </Text>
                  <Text margin="0 0 65px 0">
                    {`To resume, this will allow us to send emails on your behalf in relation to your activity through ModaResa!`}
                  </Text>
                </div>
                <div>
                  <Button
                    style={{ textTransform: "initial", fontSize: 16 }}
                    onClick={this.onVerifyEmail}
                  >
                    {`Verify my email`}
                  </Button>
                </div>
              </div>
              <div style={{ paddingTop: "50px" }}>
                <img
                  alt="Anna"
                  src={AnnNoSm}
                  style={{ width: "250px", height: "auto" }}
                />
              </div>
            </Div.Flex>
          </React.Fragment>
        )}
        {timeZonesModalStep === 2 && (
          <React.Fragment>
            <Div.Flex align="flex-start">
              <div>
                <div>
                  <div>
                    <Bold style={{ fontSize: "16px" }}>
                      Great, now check your email!
                    </Bold>
                  </div>
                  <Text margin="45px 0 25px 0">
                    {`Click on the link from Amazon Web Services, and you're good to go.`}
                  </Text>
                  <Text margin="0 0 105px 0">{`Happy ModaResa-journey!`}</Text>
                </div>
                <div>
                  <Button
                    style={{ textTransform: "initial", fontSize: 16 }}
                    onClick={() =>
                      this.closeModal(false, "timeZonesModal", false)
                    }
                  >
                    {`Toodeloo!`}
                  </Button>
                </div>
              </div>
              <div style={{ paddingTop: "50px" }}>
                <img
                  alt="Anna"
                  src={AnnSm}
                  style={{ width: "250px", height: "auto" }}
                />
              </div>
            </Div.Flex>
          </React.Fragment>
        )}
        {!isVerified && (
          <Dots index={timeZonesModalStep + 1}>
            <span />
            <span />
            <span />
          </Dots>
        )}
        {timeZonesModalStep === 1 && (
          <CloseModal
            style={{ width: "12px", top: "20px", right: "20px" }}
            onClick={() => this.closeModal(false, "timeZonesModal", false)}
          >
            <CloseIcon
              fill="#000"
              height="12px"
              style={{ display: "block" }}
              width="100%"
            />
          </CloseModal>
        )}
      </Modal>
    )
  }

  renderRange = () => {
    const { currentWeek, currentYear } = this.state
    let firstDay
    if (currentWeek === 53) {
      firstDay = moment
        .utc()
        .year(currentYear)
        .endOf("year")
        .startOf("isoWeek")
    } else if (currentWeek === 1) {
      firstDay = moment
        .utc()
        .year(currentYear - 1)
        .endOf("year")
        .startOf("isoWeek")
    } else {
      firstDay = moment
        .utc()
        .isoWeek(currentWeek)
        .year(currentYear)
        .startOf("isoWeek")
    }
    const lastDay = firstDay.clone().add(6, "d")
    let rangeDays
    const diffYears = lastDay.year() - firstDay.year()
    if (diffYears) {
      rangeDays = `${firstDay.format("ddd, Do MMM YYYY")} - ${lastDay.format(
        "ddd, Do, MMM YYYY",
      )}`
    } else {
      rangeDays = `${firstDay.format("ddd, Do MMM")} - ${lastDay.format(
        "ddd, Do MMM YYYY",
      )}`
    }
    return <div>{`Week ${currentWeek}, ${rangeDays} `}</div>
  }

  renderHours = () => {
    const start = moment.utc().set({ hours: "00", minute: "00" })
    let startTime = -1
    const end = moment.utc().set({ hours: "23", minute: "30" })

    const duration = moment.duration(end.diff(start))
    const diffHour = (duration._data.hours * 60 + duration._data.minutes) / 60
    const diff = Math.ceil(diffHour)
    const isHalfHour = !((diffHour ^ 0) === diffHour)
    return times(diff * 2, idx => {
      if (isHalfHour && idx === diff * 2) {
        return null
      }
      if ((idx + 1) % 2 !== 0) {
        startTime++
        return <Hour id={startTime} key={idx}>{`${startTime}:00`}</Hour>
      }
      return <Hour key={idx} />
    })
  }

  renderEvents = (dayDate, staffId) => {
    const {
      showPresentation,
      showShows,
      selectedEvent,
      currentDay,
      selectedColleague,
    } = this.state
    const {
      schedule,
      fashionEvents,
      buyerEvents,
      allColleaguesCalendar,
    } = this.props
    let showEvents = []
    let fshnEvnts = []

    if (!staffId) {
      const customEvent = selectedEvent &&
        selectedEvent.type === "new" &&
        selectedEvent.dayDate === dayDate && { ...selectedEvent }
      const modaresaBooking = schedule.find(day => +day.date === dayDate)
      const fashionEvnts = fashionEvents.find(day => +day.date === dayDate)

      if (!modaresaBooking && !fashionEvnts && !customEvent) {
        return null
      }

      if (customEvent) {
        showEvents = [...showEvents, { ...customEvent }]
      }

      if (modaresaBooking) {
        showEvents = [...showEvents, ...modaresaBooking.events]
      }

      if (fashionEvnts) {
        fshnEvnts = fashionEvnts.events.filter(
          event => !buyerEvents.includes(event._id),
        )
      }
    }

    if (staffId) {
      if (!allColleaguesCalendar) {
        showEvents = []
      } else {
        showEvents =
          (allColleaguesCalendar[staffId] &&
            allColleaguesCalendar[staffId][currentDay]) ||
          []
      }
    }

    if (fshnEvnts && (showPresentation || showShows)) {
      if (showPresentation && showShows) {
        showEvents = [...showEvents, ...fshnEvnts]
      } else if (showPresentation) {
        const presentation = fshnEvnts.filter(
          event => event.eventType === "presentation",
        )
        showEvents = [...showEvents, ...presentation]
      } else if (showShows) {
        const show = fshnEvnts.filter(event => event.eventType === "show")
        showEvents = [...showEvents, ...show]
      }
    }

    if (showEvents.length > 0) {
      const sortedByTime = sortBy(showEvents, ["startTime", e => -e.endTime])

      const sorted = []
      while (sortedByTime.length > 0) {
        const event = sortedByTime.shift()
        sorted.push({ ...event })

        for (let i = 0; i < sortedByTime.length; i++) {
          const test = sortedByTime[i]
          if (event.endTime > test.startTime) continue
          if (i > 0) {
            const event = sortedByTime.splice(i, 1)[0]
            sorted.push({ ...event })
          }
          break
        }
      }

      const containerEvents = []
      for (let i = 0; i < sorted.length; i++) {
        const event = sorted[i]
        const container = containerEvents.find(
          c =>
            c.endTime > event.startTime ||
            Math.abs(event.startTime - c.startTime) < 30,
        )
        if (!container) {
          event.rows = []
          containerEvents.push(event)
          continue
        }
        event.container = container
        let row = null
        for (let j = container.rows.length - 1; !row && j >= 0; j--) {
          if (onSameRow(container.rows[j], event, 1800)) {
            row = container.rows[j]
          }
        }

        if (row) {
          row.leaves.push(event)
          event.row = row
        } else {
          event.leaves = []
          container.rows.push(event)
        }
      }

      return sorted.map(event => {
        const top = `${((event.startTime - dayDate) / HALFHOUR) * 50}px`
        const height = `${((event.endTime - event.startTime) / HALFHOUR) *
          50}px`
        const eventWidth = `${width(event)}%`
        const eventXOffset = `${xOffset(event)}%`
        const type = getType(event)
        let showConrol = false
        let addedEvent = false
        if (type === "fashionEvent") {
          if (
            (showPresentation && event.eventType === "presentation") ||
            (showShows && event.eventType === "show")
          ) {
            showConrol = true
          }
          if (buyerEvents.includes(event._id)) {
            addedEvent = true
          }
          if (selectedColleague.value === "all") {
            addedEvent = true
          }
        }
        return (
          <Event
            addedEvent={addedEvent}
            data={event}
            eventHeight={height}
            eventLeft={eventXOffset}
            eventTop={top}
            eventWidth={eventWidth}
            key={event._id || event.eventId || event.dayDate}
            showEventControl={showConrol}
            type={type}
            onAddEvent={this.onAddEvent}
            onDelete={this.onDelete}
            onEventClick={this.onEventClick}
          />
        )
      })
    }
    return null
  }

  renderCalendar = () => {
    const { currentWeek } = this.state
    COLUMN_WIDTH = 95
    const firstDay = moment
      .utc()
      .set({ hours: "00", minutes: "00", seconds: "00" })
      .isoWeek(currentWeek)
      .startOf("isoWeek")
    if (this.state.currentYear - firstDay.year() >= 1) firstDay.add(1, "y")
    const Days = []
    for (let i = 0; i < 7; i++) {
      const dayTimeStamp = firstDay.unix()
      Days.push(
        <CalendarDay key={i}>
          <DayGrid dayTimeStamp={dayTimeStamp} onSlotClick={this.onSlotClick} />
          {this.renderEvents(firstDay.unix())}
        </CalendarDay>,
      )
      firstDay.add(1, "d")
    }
    return Days
  }

  renderStaffCalendar = allColleagues => {
    const { currentDay } = this.state
    COLUMN_WIDTH = 100
    return allColleagues.map((member, idx, arr) => (
      <StaffColumn key={idx} last={idx === arr.length - 1}>
        <DayGrid dayTimeStamp={currentDay} isStaff={member._id} />
        {this.renderEvents(currentDay, member._id)}
      </StaffColumn>
    ))
  }

  renderStaffCalendarHeader = allColleagues =>
    allColleagues.map((member, idx) => (
      <StaffColumnHeader key={idx}>
        <span>
          {member.firstName} {member.lastName}
        </span>
      </StaffColumnHeader>
    ))

  renderCalendarHeader = () => {
    const { currentWeek } = this.state
    const firstDay = moment
      .utc()
      .isoWeek(currentWeek)
      .startOf("isoWeek")
    const Days = []

    for (let i = 0; i < 7; i++) {
      Days.push(<Day key={i}>{firstDay.format("ddd, Do MMM")}</Day>)
      firstDay.add(1, "d")
    }
    return Days
  }

  render() {
    const {
      showPresentation,
      showShows,
      selectedColleague,
      currentDay,
      selectedTeam,
      timeZonesModal,
      ignoreOffset,
    } = this.state
    const {
      colleagues,
      requestSchedulePending,
      retailer,
      allColleagues: allClgs,
    } = this.props
    const colleaguesOptions = []
    let allColleagues = []
    allClgs.forEach(member => {
      const staffMember = colleagues.byId[member]
      if (staffMember.userId) {
        allColleagues.push(staffMember)
        colleaguesOptions.push({
          value: staffMember._id,
          label: `${staffMember.firstName} ${staffMember.lastName}`,
        })
      }
    })

    if (selectedTeam.value !== "all") {
      allColleagues = allColleagues.filter(
        member => member.team === selectedTeam.value,
      )
    }
    const isStaffCalendar =
      selectedColleague.value === "all" &&
      allColleagues &&
      allColleagues.length > 0
    const isRenderCalendar =
      selectedColleague.value === "all"
        ? allColleagues && allColleagues.length > 0
        : true
    return (
      <React.Fragment>
        {this.renderfirstTimeModal()}
        {this.renderConfirmationModal()}
        {this.renderModal()}
        {timeZonesModal && this.renderTimeZonesModal()}
        <Container>
          {requestSchedulePending && (
            <Loader style={{ zIndex: 4 }}>
              <SyncLoader color={"#a60c46"} loading size={25} sizeUnit={"px"} />
            </Loader>
          )}
          <CalendarHeader>
            <Div.Flex justify="flex-start">
              <React.Fragment>
                <div style={{ width: 200, marginRight: "10px" }}>
                  <Select
                    options={[
                      { value: "all", label: "All Colleagues" },
                      ...colleaguesOptions,
                    ]}
                    placeholder="Select Staff"
                    value={selectedColleague}
                    onChange={value =>
                      this.onChangeSelect("selectedColleague", value)
                    }
                  />
                </div>
                {selectedColleague.value === "all" && (
                  <div style={{ width: 200, marginRight: "10px" }}>
                    <Select
                      options={
                        retailer.teams &&
                        [{ value: "all", label: "All Teams" }].concat(
                          retailer.teams.map(team => ({
                            value: team._id,
                            label: team.name,
                          })),
                        )
                      }
                      value={selectedTeam}
                      onChange={value =>
                        this.onChangeSelect("selectedTeam", value)
                      }
                    />
                  </div>
                )}
                <TodayButton onClick={this.onTodayPress}>Today</TodayButton>
                {!ignoreOffset && (
                  <Div.Flex>
                    <Timezone>
                      GMT
                      {`${moment().format("Z")} (${
                        moment.tz.guess().split("/")[1]
                      })`}
                    </Timezone>{" "}
                    <Icon
                      data-effect="solid"
                      data-html
                      data-place="bottom"
                      data-tip={`<p>The timezone listed in the navbar is based on your current location. However, ModaResa bookings made through our app are stored in the timezone of the location of where the sale session will happen.</p>
                      <br>
                      <p><span style='font-style: italic;'>Example:</span>If you are currently in NY and booked a future appointment that will be in  Paris,
                      it will appear converted into your timezone, so at NY-time. Once you actually get to Paris, it will appear in the right timezone, and the time you booked it for.</p>
                      <br>
                      <p>To simulate your future calendar in the future timezone play around with our Jetsetter button in the upper right.</p>`}
                      height="15px"
                      src={TooltipIcon}
                      width="15px"
                    />
                    <ReactTooltip className="custom-tooltip toast" />
                  </Div.Flex>
                )}
              </React.Fragment>
            </Div.Flex>
            {selectedColleague.value !== "all" && (
              <DateContainer>
                <Icon
                  src={ArrowIcon}
                  onClick={() => this.onSwitchDate("prev", "week")}
                />
                <Date>{this.renderRange()}</Date>
                <Icon
                  rotate="true"
                  src={ArrowIcon}
                  onClick={() => this.onSwitchDate("next", "week")}
                />
              </DateContainer>
            )}
            {selectedColleague.value === "all" && (
              <DateContainer>
                <Icon
                  src={ArrowIcon}
                  onClick={() => this.onSwitchDate("prev", "day")}
                />
                <Date>
                  {moment.utc(currentDay, "X").format("dddd DD MMMM YYYY")}
                </Date>
                <Icon
                  rotate="true"
                  src={ArrowIcon}
                  onClick={() => this.onSwitchDate("next", "day")}
                />
              </DateContainer>
            )}
            <Div.Flex justify="flex-end">
              <Div.Flex style={{ marginRight: "20px" }}>
                <Switch
                  checked={ignoreOffset}
                  checkedIcon={false}
                  handleDiameter={24}
                  height={30}
                  offColor="#37475d"
                  onChange={cheked => {
                    this.onSwitch(cheked, "ignoreOffset")
                  }}
                  onColor="#ffcab1"
                  uncheckedIcon={false}
                  width={60}
                />
                <Icon
                  src={Airplane}
                  style={{
                    height: "30px",
                    marginRight: "0px",
                    cursor: "initial",
                  }}
                />
              </Div.Flex>
              {selectedColleague.value !== "all" && (
                <React.Fragment>
                  <Div.Flex style={{ marginRight: "20px" }}>
                    <Switch
                      checked={showShows}
                      checkedIcon={false}
                      handleDiameter={24}
                      height={30}
                      offColor="#37475d"
                      onChange={cheked => {
                        this.onSwitch(cheked, "showShows")
                      }}
                      onColor="#ffcab1"
                      uncheckedIcon={false}
                      width={60}
                    />
                    <div style={{ paddingLeft: "20px" }}>Shows</div>
                  </Div.Flex>
                  <Div.Flex>
                    <Switch
                      checked={showPresentation}
                      checkedIcon={false}
                      handleDiameter={24}
                      height={30}
                      offColor="#37475d"
                      onChange={cheked => {
                        this.onSwitch(cheked, "showPresentation")
                      }}
                      onColor="#ffcab1"
                      uncheckedIcon={false}
                      width={60}
                    />
                    <div style={{ paddingLeft: "20px" }}>Presentation</div>
                  </Div.Flex>
                </React.Fragment>
              )}
            </Div.Flex>
            {selectedColleague.value === "all" && (
              <Div.Flex justify="flex-end">
                <Filter />
              </Div.Flex>
            )}
          </CalendarHeader>
          <CalendarContent
            allColleagues={selectedColleague.value === "all"}
            id="calendar"
          >
            {!isRenderCalendar && (
              <NotFound>
                <span>No colleagues in this team</span>
              </NotFound>
            )}
            {}
            {selectedColleague.value !== "all" && (
              <CalendarDays>{this.renderCalendarHeader()}</CalendarDays>
            )}
            {isStaffCalendar && (
              <StaffTableHeader>
                {this.renderStaffCalendarHeader(allColleagues)}
              </StaffTableHeader>
            )}
            {isRenderCalendar && (
              <Div.Flex
                align="flex-start"
                justify="flex-start"
                style={{
                  display: "inline-flex",
                  width: "auto",
                  minWidth: "100%",
                }}
              >
                <Timeline>{this.renderHours()}</Timeline>
                {selectedColleague.value !== "all" && (
                  <Calendars>{this.renderCalendar()}</Calendars>
                )}
                {selectedColleague.value === "all" && (
                  <StaffCalendars>
                    {this.renderStaffCalendar(allColleagues)}
                  </StaffCalendars>
                )}
              </Div.Flex>
            )}
          </CalendarContent>
        </Container>
      </React.Fragment>
    )
  }
}

Calendar.propTypes = {
  allColleagues: PropTypes.array,
  allColleaguesCalendar: PropTypes.object,
  buyer: PropTypes.object,
  buyerEvents: PropTypes.array,
  cancelPendeing: PropTypes.bool,
  cancelPendingError: PropTypes.any,
  colleagues: PropTypes.object,

  fashionEvents: PropTypes.array,
  requestAddEventError: PropTypes.any,
  requestAddEventPending: PropTypes.bool,
  requestBookingIdPending: PropTypes.bool,
  requestMREventError: PropTypes.any,
  requestMREventPending: PropTypes.bool,
  requestNudgeEmailError: PropTypes.bool,
  requestNudgeEmailPending: PropTypes.bool,
  requestRemoveEventError: PropTypes.any,
  requestRemoveEventPending: PropTypes.bool,
  requestSchedulePending: PropTypes.bool,
  requestVerifyEmailError: PropTypes.any,
  requestVerifyEmailPending: PropTypes.bool,
  retailer: PropTypes.object,
  schedule: PropTypes.array,
  selectedBookingId: PropTypes.any,
  onAddEvent: PropTypes.func,
  onCancelBooking: PropTypes.func,
  onFetchBookingId: PropTypes.func,
  onFetchColleaguesCalendar: PropTypes.func,
  onFetchSchedule: PropTypes.func,
  onNudgeBrand: PropTypes.func,
  onRemoveEvent: PropTypes.func,
  onRemoveMREvent: PropTypes.func,
  onSaveEvent: PropTypes.func,
  onUpdateMREvent: PropTypes.func,
  onVerify: PropTypes.func,
}

export default Calendar
