import {
  CANCEL_BOOKING,
  CANCEL_BOOKING_BUYER,
  CLEAR_SUGGESTIONS,
  CONFIRM_AUTO_BOOKINGS,
  DELAY_APPOINTMENT,
  DELETE_FASHION_EVENT,
  EDIT_APPOINTMENT,
  EDIT_AUTO_BOOKINGS,
  FETCH_BOOKING_ID,
  FETCH_EVENTS,
  FETCH_SUGGESTIONS,
  FETCH_VIDEO_CALL_EVENT,
} from "../actions/schedules"

import { FETCH_BUYER_SCHEDULE } from "../actions/clients"
import { createReducer } from "../..//helpers/redux"

const initialState = {
  selectedBooking: null,
  requestPending: false,
  requestError: null,
  bookingRequestPending: false,
  bookingRequestError: null,
  suggestions: [],
  fashionEvents: null,
  selectedEvent: null,
  isEdit: false,
  requestCancelBookingPending: false,
  requestCancelBookingError: null,
  requestBookingIdPending: false,
  requestBookingIdError: null,
  selectedEventId: null,
  appointmentRequestPending: false,
  appointmentRequestError: null,
}

const getBookingIndexes = (schedule, booking) => {
  const scheduleType = booking.type === "joker" ? "jokerSchedule" : "schedule"
  const dayIndex = schedule[scheduleType].findIndex(daySchedule => daySchedule.date === booking.dayDate)
  const roomIndex = schedule[scheduleType][dayIndex].daySchedule.findIndex(room => room.roomName === booking.room)
  const bookingIndex = schedule[scheduleType][dayIndex].daySchedule[roomIndex].tables[booking.tableIndex].findIndex(
    bkng => bkng._id === booking._id,
  )
  return { dayIndex, roomIndex, bookingIndex, scheduleType }
}

const replaceEvent = (schedule, event) => {
  const { dayIndex, roomIndex, bookingIndex, scheduleType } = getBookingIndexes(schedule, event)

  schedule[scheduleType][dayIndex].daySchedule[roomIndex].tables[event.tableIndex][bookingIndex] = event
}

const replaceEvents = (oldSchedule, events) => {
  const newSchedule = { ...oldSchedule }
  events.forEach(event => {
    replaceEvent(newSchedule, event)
  })
  return newSchedule
}

const handlers = {
  [FETCH_VIDEO_CALL_EVENT.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [FETCH_VIDEO_CALL_EVENT.SUCCESS]: (state, { payload }) => ({
    ...state,
    videoCallEvent: payload,
    requestPending: false,
  }),
  [FETCH_VIDEO_CALL_EVENT.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [CANCEL_BOOKING.REQUEST]: state => ({
    ...state,
    requestCancelBookingPending: true,
    requestCancelBookingError: null,
  }),
  [CANCEL_BOOKING.SUCCESS]: state => ({
    ...state,
    requestCancelBookingPending: false,
    selectedEvent: null,
    isEdit: false,
  }),
  [CANCEL_BOOKING.FAILURE]: (state, { payload }) => ({
    ...state,
    requestCancelBookingPending: false,
    requestCancelBookingError: payload,
  }),
  [CANCEL_BOOKING_BUYER.REQUEST]: state => ({
    ...state,
    requestCancelBookingPending: true,
    requestCancelBookingError: null,
  }),
  [CANCEL_BOOKING_BUYER.SUCCESS]: state => ({
    ...state,
    requestCancelBookingPending: false,
    selectedEvent: null,
    isEdit: false,
  }),
  [CANCEL_BOOKING_BUYER.FAILURE]: (state, { payload }) => ({
    ...state,
    requestCancelBookingPending: false,
    requestCancelBookingError: payload,
  }),
  [CONFIRM_AUTO_BOOKINGS.REQUEST]: state => ({
    ...state,
    bookingRequestPending: true,
    bookingRequestError: null,
  }),
  [CONFIRM_AUTO_BOOKINGS.SUCCESS]: state => ({
    ...state,
    bookingRequestPending: false,
    bookingRequestError: null,
  }),
  [CONFIRM_AUTO_BOOKINGS.FAILURE]: (state, { payload }) => ({
    ...state,
    bookingRequestPending: false,
    bookingRequestError: payload,
  }),
  [EDIT_AUTO_BOOKINGS.REQUEST]: state => ({
    ...state,
    bookingRequestPending: true,
    bookingRequestError: null,
  }),
  [EDIT_AUTO_BOOKINGS.SUCCESS]: state => ({
    ...state,
    bookingRequestPending: false,
    bookingRequestError: null,
  }),
  [EDIT_AUTO_BOOKINGS.FAILURE]: (state, { payload }) => ({
    ...state,
    bookingRequestPending: false,
    bookingRequestError: payload,
  }),
  [DELAY_APPOINTMENT.REQUEST]: state => ({
    ...state,
    appointmentRequestPending: true,
    appointmentRequestError: null,
  }),
  [DELAY_APPOINTMENT.SUCCESS]: (state, { payload }) => {
    const booking = payload.updatedEvents[0]
    let updatedSchedule = state.selectedSchedule
    if (updatedSchedule && booking.scheduleId === updatedSchedule._id) {
      updatedSchedule = replaceEvents(state.selectedSchedule, payload.updatedEvents)
    }
    return {
      ...state,
      appointmentRequestPending: false,
      selectedSchedule: updatedSchedule,
    }
  },
  [DELAY_APPOINTMENT.FAILURE]: (state, { payload }) => ({
    ...state,
    appointmentRequestPending: false,
    appointmentRequestError: payload,
  }),
  [FETCH_SUGGESTIONS.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [FETCH_SUGGESTIONS.SUCCESS]: (state, { payload }) => ({
    ...state,
    suggestions: payload.suggestions,
    suggestionsClients: payload.clients,
    suggestionsClientIndex: payload.clientIndex,
    suggestionsSlice: payload.slice,
    requestPending: false,
  }),
  [FETCH_SUGGESTIONS.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [FETCH_EVENTS.SUCCESS]: (state, { payload }) => ({
    ...state,
    fashionEvents: payload,
  }),
  [FETCH_BUYER_SCHEDULE.REQUEST]: state => ({
    ...state,
    fashionEvents: null,
  }),
  [EDIT_APPOINTMENT]: (state, { payload }) => ({
    ...state,
    selectedEvent: payload,
    isEdit: true,
  }),
  [FETCH_BOOKING_ID.REQUEST]: state => ({
    ...state,
    requestBookingIdPending: true,
    requestBookingIdError: null,
    selectedEventId: null,
  }),
  [FETCH_BOOKING_ID.SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedEventId: payload._id,
    requestBookingIdPending: false,
  }),
  [FETCH_BOOKING_ID.FAILURE]: (state, { payload }) => ({
    ...state,
    requestBookingIdPending: false,
    requestBookingIdError: payload,
  }),
  [CLEAR_SUGGESTIONS]: state => ({
    ...state,
    suggestions: [],
  }),
  [DELETE_FASHION_EVENT]: (state, { payload }) => {
    const newFashionEvents = [...state.fashionEvents]
    const deletedFashionEventIndex = newFashionEvents.findIndex(event => event._id === payload)
    newFashionEvents.splice(deletedFashionEventIndex, 1)
    return {
      ...state,
      fashionEvents: newFashionEvents,
    }
  },
}

export default createReducer(initialState, handlers)
