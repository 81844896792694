import { createAsyncAction } from "../../helpers/redux"

export const FETCH_BRAND = createAsyncAction("FETCH_BRAND")
export const fetchBrand = id => ({
  type: FETCH_BRAND.REQUEST,
  payload: { id },
})

export const UPDATE_BRAND = createAsyncAction("UPDATE_BRAND")
export const updateBrand = (data, id) => ({
  type: UPDATE_BRAND.REQUEST,
  payload: { data, id },
})
