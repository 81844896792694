import { deleteClient, fetchColleagues } from "../../../store/actions/clients"
import {
  getAllBuyerColleagues,
  getBuyer,
  getBuyerColleagues,
  getDeleteRequestError,
  getDeleteRequestPending,
  getRequestColleaguesError,
  getRequestColleaguesPending,
  getSharedRetailer,
} from "../../../store/selectors"

import Component from "./TeamList"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

const selector = createStructuredSelector({
  allColleagues: getAllBuyerColleagues,
  colleagues: getBuyerColleagues,
  retailer: getSharedRetailer,
  user: getBuyer,
  requestColleaguesError: getRequestColleaguesError,
  requestColleaguesPending: getRequestColleaguesPending,
  requestPending: getDeleteRequestPending,
  requestError: getDeleteRequestError,
})

const actions = {
  onDelete: deleteClient,
  onFetchColleagues: fetchColleagues,
}

export const TeamList = connect(selector, actions)(Component)
