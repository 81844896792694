import {
  Bg,
  CloseModal,
  Containr,
  ContentContainer,
  Flex,
  FormRow,
  Icon,
  InputModal,
  Modal,
  ModalContainer,
} from "./styles"
import { Button, Input, Multiselect } from "../../UI"
import { InputContainer, ValidationError } from "../../UI/Input"
import React, { Component } from "react"

import PropTypes from "prop-types"
import ReactPhoneInput from "react-phone-input-2"
import ReactTooltip from "react-tooltip"
import TooltipIcon from "../../../static/info.svg"
import isEmpty from "lodash/isEmpty"
import { toast } from "react-toastify"
class General extends Component {
  constructor(props) {
    super(props)
    const { user } = this.props
    this.state = {
      errors: {},
      values: {
        email: (user && user.email) || "",
        firstName: (user && user.firstName) || "",
        lastName: (user && user.lastName) || "",
        phoneNumber:
          user && user.phoneNumber
            ? user.phoneNumber.charAt(0) === "+"
              ? user.phoneNumber
              : "+" + user.phoneNumber
            : "",
        position: (user && user.position) || "",
        team: (user && user.team) || "",
      },
      valuesPass: {
        currentPassword: "",
        newPassword: "",
      },
      touched: {},
      isChanged: false,
      savingRequest: false,
      isVerifyEmail: false,
      isChangePassword: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { savingRequest, isVerifyEmail } = prevState
    const {
      requestPending,
      requestError,
      requestVerifyEmailPending,
      requestVerifyEmailError,
    } = nextProps

    if (savingRequest && !requestPending && !requestError) {
      return { savingRequest: false }
    }

    if (
      isVerifyEmail &&
      !requestVerifyEmailPending &&
      !requestVerifyEmailError
    ) {
      toast("Email is sent successfully", {
        hideProgressBar: true,
        className: "def-toast",
        autoClose: 1000,
      })
      return { isVerifyEmail: false }
    }
    return null
  }

  onSubmit = () => {
    const { values, touched, errors } = this.state
    const { user } = this.props
    for (const key in values) {
      this.validate(values[key], key)
      touched[key] = true
    }

    if (isEmpty(errors)) {
      const updatedMember = {
        ...user,
        ...values,
      }
      this.props.onSaveChanges(updatedMember, user._id)
      this.setState({ isChanged: false, savingRequest: true })
    }
    this.setState({ errors, touched })
  }

  onChangePassword = () => {
    const { isChangePassword } = this.state
    this.setState({
      isChangePassword: !isChangePassword,
    })
  }

  confirmNewPass = async () => {
    const { valuesPass, touched, errors } = this.state
    const { user, onChangePass } = this.props
    for (const key in valuesPass) {
      this.validatePass(valuesPass[key], key)
      touched[key] = true
    }
    if (isEmpty(errors)) {
      const updatePass = {
        userId: user._id,
        values: valuesPass,
      }
      if (valuesPass.currentPassword !== valuesPass.newPassword) {
        await onChangePass(updatePass)
        this.onChangePassword()
        valuesPass.currentPassword = ""
        valuesPass.newPassword = ""
      } else {
        toast("The current and new passwords are the same", {
          hideProgressBar: true,
          className: "def-toast",
          autoClose: 1500,
        })
        valuesPass.currentPassword = ""
        valuesPass.newPassword = ""
      }
    }
    this.setState({ errors, touched })
  }

  validatePass = (value, name) => {
    const { valuesPass, errors } = this.state
    switch (name) {
      case "currentPassword":
      case "newPassword":
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            valuesPass: { ...valuesPass, [name]: value },
            errors,
          }
        }
        delete errors[name]
        return {
          valuesPass: { ...valuesPass, [name]: value },
          errors,
        }

      default:
        break
    }
  }

  validate = (value, name) => {
    const { values, errors } = this.state
    switch (name) {
      case "firstName":
      case "lastName":
      case "position":
        if (value.trim().length > 0) {
          delete errors[name]
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        errors[name] = `Required`
        return {
          values: { ...values, [name]: value },
          errors,
        }

      case "email":
        const reg = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        } else if (reg.test(value) === true) {
          delete errors[name]
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        errors[name] = `Invalid email address`
        return {
          values: { ...values, [name]: value },
          errors,
        }

      case "phoneNumber":
        if (value.trim().length < 3) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        } else if (value.trim().length < 8) {
          errors[name] = `Invalid phone number`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        delete errors[name]
        return {
          values: { ...values, [name]: value },
          errors,
        }

      default:
        break
    }
  }

  onInputChange = e => {
    if (typeof e === "string") {
      this.setState({ ...this.validate(e, "phoneNumber"), isChanged: true })
    } else {
      const name = e.target.name
      const value = e.target.value
      this.setState({ ...this.validate(value, name), isChanged: true })
    }
  }

  onInputChangePass = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ ...this.validatePass(value, name), isChanged: true })
  }

  onBlur = e => {
    const { touched } = this.state
    const name = e.target.name
    touched[name] = true
    this.setState({ touched })
  }

  onSelectChange = (value, name) => {
    const { touched, values, errors } = this.state
    touched[name] = true
    delete errors[name]
    this.setState({
      values: { ...values, [name]: value.value },
      touched,
      errors,
      isChanged: true,
    })
  }

  isDisable = () => {
    const { savingRequest, isChanged } = this.state
    const { requestPending, requestError } = this.props

    if (savingRequest) {
      return !(!requestPending && requestError)
    }
    return !isChanged
  }

  verifyEmail = () => {
    this.setState({ isVerifyEmail: true })
    this.props.onVerifyEmail()
  }

  render() {
    const { values, valuesPass, errors, touched, isChangePassword } = this.state
    const { retailer, user, requestVerifyEmailPending } = this.props
    const teamOptions =
      retailer.teams &&
      retailer.teams.map(team => ({ value: team._id, label: team.name }))
    const isVerified = user && user.emailVerified
    const verifyText = isVerified ? "Verified" : "Verify Email"
    return (
      <ContentContainer>
        {isChangePassword && (
          <div>
            <Bg />
            <Modal>
              <ModalContainer>
                <CloseModal onClick={this.onChangePassword}>X</CloseModal>
                <InputModal>
                  <span>Current password</span>
                  <Input
                    type="password"
                    errors={errors.currentPassword}
                    touched={touched.currentPassword}
                    name="currentPassword"
                    value={valuesPass.currentPassword}
                    width="100%"
                    onBlur={this.onBlur}
                    onChange={this.onInputChangePass}
                  />
                </InputModal>
                <InputModal>
                  <span>New password</span>
                  <Input
                    type="password"
                    errors={errors.newPassword}
                    touched={touched.newPassword}
                    name="newPassword"
                    value={valuesPass.newPassword}
                    width="100%"
                    onBlur={this.onBlur}
                    onChange={this.onInputChangePass}
                  />
                </InputModal>
                <Button
                  disabled={
                    valuesPass.currentPassword === "" ||
                    valuesPass.newPassword === "" ||
                    errors.currentPassword ||
                    errors.newPassword
                  }
                  onClick={this.confirmNewPass}
                >
                  Confirm
                </Button>
              </ModalContainer>
            </Modal>
          </div>
        )}
        <Containr>
          <FormRow>
            <span>First Name</span>
            <Input
              errors={errors.firstName}
              name="firstName"
              touched={touched.firstName}
              value={values.firstName}
              width="100%"
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
          </FormRow>
          <FormRow>
            <span>Last Name</span>
            <Input
              errors={errors.lastName}
              name="lastName"
              touched={touched.lastName}
              value={values.lastName}
              width="100%"
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
          </FormRow>
          <FormRow>
            <span>Phone Number</span>
            <InputContainer>
              <ReactPhoneInput
                buttonStyle={
                  errors.phoneNumber &&
                  touched.phoneNumber && { borderColor: "red" }
                }
                country={"fr"}
                inputExtraProps={{
                  name: "phoneNumber",
                }}
                inputStyle={
                  errors.phoneNumber &&
                  touched.phoneNumber && { borderColor: "red" }
                }
                enableSearch
                disableSearchIcon
                searchStyle={{ width: "95%" }}
                value={values.phoneNumber}
                onBlur={this.onBlur}
                onChange={this.onInputChange}
              />
              {errors.phoneNumber && touched.phoneNumber && (
                <ValidationError>{errors.phoneNumber}</ValidationError>
              )}
            </InputContainer>
          </FormRow>
          <FormRow>
            <span>Email</span>
            <Input
              errors={errors.email}
              name="email"
              touched={touched.email}
              value={values.email}
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
          </FormRow>
          <FormRow>
            <span>Retailer</span>
            <Input
              disabled
              type="text"
              value={retailer.name || ""}
              onChange={this.onInputChange}
            />
          </FormRow>
          <FormRow>
            <span>Company Position</span>
            <Input
              errors={errors.position}
              name="position"
              touched={touched.position}
              value={values.position}
              width="100%"
              onBlur={this.onBlur}
              onChange={this.onInputChange}
            />
          </FormRow>
          <FormRow>
            <span>
              Team{" "}
              <Icon
                data-effect="solid"
                data-place="right"
                data-tip={`Teams can be created under 'Company Settings', and applies if you have multiple buyers belonging to the same buyer team.`}
                height="15px"
                src={TooltipIcon}
                width="15px"
              />
              <ReactTooltip className="custom-tooltip" />
            </span>
            <InputContainer>
              <Multiselect
                errors={errors.team}
                name="team"
                options={teamOptions}
                placeholder="Choose Team"
                touched={touched.team}
                value={
                  teamOptions &&
                  teamOptions.filter(option => option.value === values.team)
                }
                onChange={this.onSelectChange}
              />
            </InputContainer>
          </FormRow>
          <FormRow>
            <span />
            <Flex>
              <Button
                disabled={true}
                minWidth="49%"
                type="button"
                onClick={this.onChangePassword}
              >
                {}
                Change Password
              </Button>
              <Button
                disabled={this.isDisable()}
                minWidth="49%"
                type="button"
                onClick={this.onSubmit}
              >
                Save Changes
              </Button>
            </Flex>
          </FormRow>
          <FormRow>
            <span />
            <Flex style={{ justifyContent: "flex-end" }}>
              <Button
                disabled={isVerified || requestVerifyEmailPending}
                minWidth="49%"
                type="button"
                onClick={this.verifyEmail}
              >
                {verifyText}
              </Button>
            </Flex>
          </FormRow>
        </Containr>
      </ContentContainer>
    )
  }
}

General.propTypes = {
  requestError: PropTypes.string,
  requestPending: PropTypes.bool,
  requestVerifyEmailError: PropTypes.any,
  requestVerifyEmailPending: PropTypes.bool,
  retailer: PropTypes.object,
  user: PropTypes.object,
  onSaveChanges: PropTypes.func,
  onVerifyEmail: PropTypes.func,
}

export default General
