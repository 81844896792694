import * as R from "ramda"

export const getInviteRequestPending = R.path(["invite", "requestPending"])
export const getInviteBookingsData = R.path(["invite", "bookingsData"])

export const getRequestInviteLinkStatus = R.path([
  "invite",
  "requestInviteLinkPending",
])
export const getRequestInviteLinkError = R.path([
  "invite",
  "requestInviteLinkError",
])
export const getInviteLink = R.path(["invite", "inviteLink"])
export const getRequestEmailLinkStatus = R.path([
  "invite",
  "requestEmailPending",
])
export const getRequestEmailLinkError = R.path(["invite", "requestEmailError"])

export const getRegisterData = R.path(["invite", "registerData"])
export const getRequestInviteDataPending = R.path([
  "invite",
  "requestInviteDataPending",
])
export const getRequestInviteDataError = R.path([
  "invite",
  "requestInviteDataError",
])

export const getRequestJokerPending = R.path(["invite", "requestJokerPending"])
export const getRequestJokerError = R.path(["invite", "requestJokerError"])
