import React from "react"
import { mainTheme as theme } from "../../../constants/themes"
import { rgba } from "../../../helpers/styles"
import styled from "styled-components"
import LoadingIcon from "../icons/Loading"

const ButtonStyle = styled.button`
  min-width: ${props => props.minWidth || "100px"};
  align-self: ${props => props.align};
  margin: ${props => props.margin};
  height: 37px;
  outline: none;
  border: ${p => `1px solid ${p.borderColor}`};
  border-radius: 3px;
  font-family: ${props => props.theme.mainFont};
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: ${p => p.bgcolor};
  color: ${p => p.color};
  padding: 5px 10px 5px 10px;
  ${props =>
    props.icon &&
    `background-color:transparent;
     padding:0; 
     ${props.disabled ? "opacity: 0.3" : ""}
     `};
  &:disabled {
    cursor: initial;
    background-color: ${p => rgba(p.bgcolor, 0.4)};
    border-color: ${p => rgba(p.borderColor, 0.4)};
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`
ButtonStyle.defaultProps = {
  color: "white",
  bgcolor: theme.primary,
  borderColor: theme.primary,
}

export const SpinningLoading = styled(LoadingIcon)`
  margin-right: 12px;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  font-size: 0.85em;
`

type Props = {
  loading: boolean,
  loadingMessage: string,
  children: any,
  bgcolor: string,
  color: string,
  minWidth: string,
  align: string,
  margin: string,
  icon: boolean,
}
export const Button = ({ loading, children, disabled, ...props }: Props) => (
  <ButtonStyle disabled={loading || disabled} {...props}>
    {loading && <SpinningLoading />}
    {children}
  </ButtonStyle>
)
export const ButtonSecondary = styled(Button).attrs(props => ({
  bgcolor: "white",
  color: props.theme.black,
  borderColor: props.theme.black,
  ...props,
}))``

export default Button
