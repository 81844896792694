import styled from "styled-components"

export const Text = styled.div`
  margin: ${props => props.margin};

  & p {
    margin: 0;
  }
`

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`

export const ButtonsRow = styled(InputRow)`
  width: 100%
  min-height: 37px;
  justify-content: center;
  margin: ${props => props.margin || "0px"};
`

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0 10px;
  margin: 0 auto;
  margin-bottom: 20px;

  & div {
    min-height: 63px;
    display: flex;
    align-items: center;
    position: relative;

    & > span {
      color: ${({ theme }) => theme.primary};
      display: block;
      position: absolute;
      bottom: 5px;
      left: 15px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
  }

  & input {
    background-color: #f5f5f5;
    border: none;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    outline: none;
    color: #333;
  }
`

export const SmallContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  margin: 25px auto;

  &.agreement {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  & a {
    color: #999;
    text-decoration: none;
  }
`

export const MrLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  & img {
    width: auto;
    height: 30px;
  }
`
export const Services = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > a > div {
    width: 200px !important;
  }

  & .icon {
    width: 48px;
    min-width: 48px;
    height: 48px;
    background: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > div {
    width: 200px;
    height: 50px;
    cursor: pointer;
    background: #1871b7;
    margin-top: 20px;
    padding-left: 1px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px 0px;

    & > a {
      display: flex;
      height: 100%;
      width: auto;
      justify-content: flex-start;
      align-items: center;

      & span {
        font-size: 16px;
        color: rgb(255, 255, 255);
        font-family: Roboto, arial, sans-serif;
      }
    }

    & img {
      height: auto;
      width: 50%;
      object-fit: contain;
    }
  }
`
