import React, { Component } from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { Agreement } from "../Agreement"
import { Connect } from "../Connect"
import { Invite } from "../Invite"
import PropTypes from "prop-types"

class Sync extends Component {
  render() {
    const { match } = this.props
    return (
      <Switch>
        <Route component={Connect} exact path={`${match.path}/`} />
        <Route
          exact
          path={`${match.path}/signup`}
          render={props => <Agreement service="signup" {...props} />}
        />
        <Route component={Invite} exact path={`${match.path}/invite/:token`} />
        <Redirect to={`${match.path}/`} />
      </Switch>
    )
  }
}

Sync.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
}

export default Sync
