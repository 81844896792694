import {
  getAppointmentRequestError,
  getInviteRequestPending,
  getSuggestionFetchStatus,
  getVideoCallEvent,
} from "../../store/selectors"

import Component from "./VideoPresentation"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { fetchVideoCallEvent } from "../../store/actions/schedules"

const selector = state => ({
  ...createStructuredSelector({
    loading: getSuggestionFetchStatus || getInviteRequestPending || getAppointmentRequestError,
    event: getVideoCallEvent,
  })(state),
})

const actions = {
  onFetchVideoCallEvent: fetchVideoCallEvent,
}

export default connect(selector, actions)(Component)
