import {
  FETCH_LANGUAGES,
  FETCH_SALE_SESSION,
  FETCH_TIMEZONE,
} from "../actions/salesSessions"

import { createReducer } from "../..//helpers/redux"

const initialState = {
  selectedSession: null,
  availableLanguages: [],
  requestPending: false,
  requestError: null,
  timezone: null,
}

const handlers = {
  [FETCH_SALE_SESSION.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [FETCH_SALE_SESSION.SUCCESS]: (state, { payload }) => ({
    ...state,
    selectedSession: payload,
  }),
  [FETCH_SALE_SESSION.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [FETCH_LANGUAGES.REQUEST]: state => ({
    ...state,
    langRequestPending: true,
    langRequestError: null,
  }),
  [FETCH_LANGUAGES.SUCCESS]: (state, { payload }) => ({
    ...state,
    langRequestPending: false,
    availableLanguages: payload,
  }),
  [FETCH_LANGUAGES.FAILURE]: (state, { payload }) => ({
    ...state,
    langRequestPending: false,
    langRequestError: payload,
  }),
  [FETCH_TIMEZONE.REQUEST]: state => ({
    ...state,
    timezoneFetchPending: true,
  }),
  [FETCH_TIMEZONE.SUCCESS]: (state, { payload }) => {
    const { timeZoneId } = payload
    return {
      ...state,
      timezone: timeZoneId,
      timezoneFetchPending: false,
    }
  },
  [FETCH_TIMEZONE.FAILURE]: state => ({
    ...state,
    timezoneFetchPending: false,
  }),
}

export default createReducer(initialState, handlers)
