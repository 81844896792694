import * as R from "ramda"

export const getUser = R.path(["auth", "user"])
export const getBuyer = R.path(["auth", "buyer"])

export const getRequestSavePending = R.path(["auth", "requestSavePending"])
export const getRequestSaveError = R.path(["auth", "requestSaveError"])

export const getRequestPasswordPending = R.path([
  "auth",
  "requestResetPasswordPending",
])
export const getRequestPasswordError = R.path([
  "auth",
  "requestResetPasswordError",
])

export const getRequestVerificationStatus = R.path([
  "auth",
  "requestVerifyTokenPending",
])
export const getRequestVerificationError = R.path([
  "auth",
  "requestVerifyTokenError",
])
export const getTokenIsVerified = R.path(["auth", "tokenIsVerified"])

export const getRequestResetPasswordEmailStatus = R.path([
  "auth",
  "requestResetPasswordEmailPending",
])
export const getRequestResetPasswordEmailError = R.path([
  "auth",
  "requestResetPasswordEmailError",
])
export const getEmailSent = R.path(["auth", "emailSent"])

export const getRequestVerifyEmailPending = R.path([
  "user",
  "requestVerifyEmailPending",
])
export const getRequestVerifyEmailError = R.path([
  "user",
  "requestVerifyEmailError",
])
