import {
  getRequestPasswordError,
  getRequestPasswordPending,
  getRequestVerificationError,
  getRequestVerificationStatus,
  getTokenIsVerified,
} from "../../../store/selectors"
import { resetPassword, verifyToken } from "../../../store/actions/auth"

import Component from "./ResetPasswordForm"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

const selector = createStructuredSelector({
  requestError: getRequestPasswordError,
  requestPending: getRequestPasswordPending,
  requestVerificationPendeing: getRequestVerificationStatus,
  requestVerificationError: getRequestVerificationError,
  isTokenVerified: getTokenIsVerified,
})

const actions = {
  onResetPassword: resetPassword,
  onVerifyToken: verifyToken,
}

export const ResetPassword = connect(selector, actions)(Component)
