import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  padding-top: 54px;
  display: flex;
  flex-direction: column;
`
export const Containr = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`
export const Modal = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 14;
`
export const Bg = styled(Modal)`
  background-color: gray;
  opacity: 0.8;
`
export const ModalContainer = styled.div`
  width: 50%;
  background-color: white;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 10px gray;
`
export const InputModal = styled.div`
  width: 80%;
  margin-bottom: 10px;
  color: black;
`
export const CloseModal = styled.div`
  position: absolute;
  width: 45px;
  height: 45px;
  right: 0;
  top: 0;
  color: black;
  font-size: 20px;
  cursor: pointer;
`

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px;
  padding-bottom: 60px;
`
export const Button = styled.button`
  background-color: ${props => props.theme.primary};
  color: #fff;
  padding: 5px 10px 5px 10px;
`

export const ErrorText = styled.div`
  color: ${props => props.theme.primary};
`
export const Row = styled.div`
  display: flex;
`

export const InputContainer = styled.div`
  width: 100%;
  max-width: 400px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-left: 45px;
  padding-right: 25px;
`

export const FormRow = styled.div`
  display: flex;
  width: 50%;
  max-width: 600px;
  min-width: 500px;
  align-items: center;
  margin-bottom: 17px;
  font-size: 15px;
  min-height: 40px;
  color: ${props => props.theme.textBlack};

  & > span {
    min-width: 140px;
    max-width: 140px;
  }

  & .form-control {
    width: 100% !important;
    height: 40px !important;
  }

  & .react-tel-input .selected-flag .arrow {
    display: none;
  }

  & .PhoneError {
    border-color: ${props => props.theme.strawberry};
  }

  & .custom-tooltip {
    display: inline-block !important;
    max-width: 350px;
    padding: 15px;
    background-color: #d8d8d8 !important;
    color: #4a4a4a !important;
    opacity: 0.97 !important;
    font-size: 14px;
  }

  & .custom-tooltip p {
    margin: 0;
  }

  & .custom-tooltip__calendar {
    display: inline-block !important;
    max-width: 450px;
    padding: 15px;
    background-color: #d8d8d8 !important;
    color: #4a4a4a !important;
    opacity: 0.97 !important;
    font-size: 14px;
  }

  & .custom-tooltip__calendar::after {
    border-right-color: #d8d8d8 !important;
  }

  & .custom-tooltip::after {
    border-right-color: #d8d8d8 !important;
  }
`

export const Flex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Icon = styled.img`
  display: inline-block;
  height: ${({ height }) => height || "17px"};
  width: auto;
  cursor: pointer;
`
