import { fetchInviteLink, sendInviteEmail } from "../../store/actions/invites"
import { getInvitedClient, getRegisterData } from "../../store/selectors"

import Component from "./Connect"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"

const selector = createStructuredSelector({
  inviteData: getRegisterData,
  client: getInvitedClient,
})

const actions = {
  onFetchLink: fetchInviteLink,
  onSendInvite: sendInviteEmail,
}

export const Connect = connect(selector, actions)(Component)
