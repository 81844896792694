import React, { Component } from "react"

import { PropTypes } from "prop-types"
import TagsInput from "react-tagsinput"

class InputTags extends Component {
  state = {
    inputValue: "",
  }

  defaultRenderInput = options => {
    const { placeholder } = this.props
    const { onChange, value, ...other } = options
    return (
      <input
        type="text"
        value={value}
        {...other}
        placeholder={placeholder}
        style={{ width: "100%" }}
        onChange={onChange}
      />
    )
  }

  handleInputChange = inputValue => {
    const { errors } = this.props
    if (inputValue.length === 0) {
      const newError = { ...errors }
      delete newError.emails
      this.props.newState({
        errors: newError,
      })
    }
    this.setState({ inputValue })
  }

  handleChange = value => {
    const { errors } = this.props
    const newError = { ...errors }
    delete newError.emails
    this.props.newState({ emails: value, errors: newError })
  }

  onError = values => {
    const { errors } = this.props
    if (values[0]) {
      errors.emails = "Invalid email address"
    }
    this.props.newState({
      errors,
    })
  }

  render() {
    const { inputValue } = this.state
    const { values } = this.props
    return (
      <TagsInput
        addOnBlur
        inputValue={inputValue}
        onlyUnique
        renderInput={this.defaultRenderInput}
        validationRegex={/^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_-])+\.([A-Za-z]{2,})$/}
        value={values}
        onChange={this.handleChange}
        onChangeInput={this.handleInputChange}
        onValidationReject={this.onError}
      />
    )
  }
}

InputTags.propTypes = {
  errors: PropTypes.object,
  newState: PropTypes.func,
  values: PropTypes.any,
}

export { InputTags }
