import styled from "styled-components"
import { BulletPoint, Button, H1 } from "../styles"
import Bg from "../../static/BackgroundConfirmationpage.jpg"

export const ActionButton = styled(Button)`
  width: 405px;
  font-size: 22px;
  font-weight: 600;
  height: 62px;
`

export const ActionButtonChangeAppointment = styled(ActionButton)`
  font-weight: 500;
`

export const ContainerBackground = styled.div`
  margin-left: 120px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const BackgroundModaresaCalendar = styled.img.attrs(() => ({
  alt: 'background',
  src: Bg,
}))`
  width: auto;
  height: 100vh;
  opacity: 0.6;
  position: absolute;
  top: 0;
  right: 0;
`;

export const ConfirmationStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 74%;
  background-color: rgb(255, 251, 242);
  box-shadow: 3px 5px 4px 0 #d8d8d8;
    border-bottom-right-radius: 100px; 
  padding: 0;
  overflow: hidden;
`
export const ConfirmationContainerBody = styled.div`
  flex: 1;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const ConfirmationContainerFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 229, 228);
  height: 96px;
`;

export const Signature = styled.p`
  text-align: center;
  width: 100%;
  color: rgb(10, 16, 105);
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 400;
`;

export const BodyContent = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 900px;
  margin: auto;
  width: 100%;
`

export const BodyContentText = styled.p`
   font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.01px;
  color: #0a1069;
  margin-bottom: 40px;
`;

export const Title = styled(H1)`
  margin-bottom: 40px;
`;

export const MargedBulletPoint = styled(BulletPoint)`
  margin-bottom: 40px;
`
