import {
  ADD_EVENT,
  CREATE_MR_EVENT,
  DELETE_CLIENT,
  DELETE_MR_EVENT,
  FETCH_BUYER_SCHEDULE,
  FETCH_CLIENT,
  FETCH_CLIENTS,
  FETCH_COLLEAGUES,
  FETCH_COLLEAGUES_CALENDAR,
  NUDGE_BRAND,
  REMOVE_EVENT,
  UPDATE_CLIENT,
  UPDATE_CLIENT_PHONE_NUMBER,
  UPDATE_MR_EVENT,
} from "../actions/clients"

import { CANCEL_BOOKING_BUYER } from "../actions/schedules"
import { createReducer } from "../../helpers/redux"

const initialState = {
  clients: {},
  invitedClient: {},
  requestSchedulePending: false,
  requestScheduleError: null,
  buyerSchedule: null,
  colleaguesCalendar: null,
  buyerEventsIds: [],
  requestColleaguesPending: false,
  requestColleaguesError: null,
  buyerColleagues: {},
  requestAddEventPending: false,
  requestAddEventError: null,
  requestRemoveEventPending: false,
  requestRemoveEventError: null,
  requestMREventPending: false,
  requestMREventError: null,
  requestUpdatePhonePending: false,
  requestUpdatePhoneError: null,
  requestPending: false,
  requestError: null,
  MREventId: null,
  requestNudgeEmailPending: false,
  requestNudgeEmailError: null,
}

const handlers = {
  [FETCH_CLIENTS.SUCCESS]: (state, { payload }) => ({
    ...state,
    clients: payload,
  }),
  [FETCH_CLIENT.SUCCESS]: (state, { payload }) => ({
    ...state,
    invitedClient: payload,
  }),
  [FETCH_BUYER_SCHEDULE.REQUEST]: state => ({
    ...state,
    requestSchedulePending: true,
    requestScheduleError: null,
    buyerSchedule: null,
    buyerEventsIds: [],
  }),
  [FETCH_BUYER_SCHEDULE.SUCCESS]: (state, { payload }) => ({
    ...state,
    buyerSchedule: payload.meetings,
    buyerEventsIds: payload.events,
    requestSchedulePending: false,
  }),
  [FETCH_BUYER_SCHEDULE.FAILURE]: (state, { payload }) => ({
    ...state,
    requestSchedulePending: false,
    requestScheduleError: payload,
  }),
  [ADD_EVENT.REQUEST]: state => ({
    ...state,
    requestAddEventPending: true,
    requestAddEventError: null,
  }),
  [ADD_EVENT.SUCCESS]: (state, { payload }) => ({
    ...state,
    buyerEventsIds: payload,
    requestAddEventPending: false,
  }),
  [ADD_EVENT.FAILURE]: (state, { payload }) => ({
    ...state,
    requestAddEventPending: false,
    requestAddEventError: payload,
  }),
  [REMOVE_EVENT.REQUEST]: state => ({
    ...state,
    requestRemoveEventPending: true,
    requestRemoveEventError: null,
  }),
  [REMOVE_EVENT.SUCCESS]: (state, { payload }) => ({
    ...state,
    buyerEventsIds: payload,
    requestRemoveEventPending: false,
  }),
  [REMOVE_EVENT.FAILURE]: (state, { payload }) => ({
    ...state,
    requestRemoveEventPending: false,
    requestRemoveEventError: payload,
  }),
  [FETCH_COLLEAGUES.REQUEST]: state => ({
    ...state,
    requestColleaguesPending: true,
    requestColleaguesError: null,
  }),
  [FETCH_COLLEAGUES.SUCCESS]: (state, { payload }) => ({
    ...state,
    buyerColleagues: payload,
    requestColleaguesPending: false,
  }),
  [FETCH_COLLEAGUES.FAILURE]: (state, { payload }) => ({
    ...state,
    requestColleaguesPending: false,
    requestColleaguesError: payload,
  }),
  [CREATE_MR_EVENT.REQUEST]: state => ({
    ...state,
    requestMREventPending: true,
    requestMREventError: null,
    MREventId: null,
  }),
  [CREATE_MR_EVENT.SUCCESS]: (state, { payload }) => ({
    ...state,
    buyerSchedule: [...state.buyerSchedule, { ...payload }],
    MREventId: payload._id,
    requestMREventPending: false,
  }),
  [CREATE_MR_EVENT.FAILURE]: (state, { payload }) => ({
    ...state,
    requestMREventPending: false,
    requestMREventError: payload,
  }),
  [DELETE_MR_EVENT.REQUEST]: state => ({
    ...state,
    requestMREventPending: true,
    requestMREventError: null,
  }),
  [DELETE_MR_EVENT.SUCCESS]: (state, { payload }) => {
    const newBuyerSchedule = [...state.buyerSchedule]
    const deleteItemIndex = newBuyerSchedule.findIndex(event => event._id === payload)
    newBuyerSchedule.splice(deleteItemIndex, 1)
    return {
      ...state,
      buyerSchedule: newBuyerSchedule,
      requestMREventPending: false,
    }
  },
  [DELETE_MR_EVENT.FAILURE]: (state, { payload }) => ({
    ...state,
    requestMREventPending: false,
    requestMREventError: payload,
  }),
  [UPDATE_MR_EVENT.REQUEST]: state => ({
    ...state,
    requestMREventPending: true,
    requestMREventError: null,
    MREventId: null,
  }),
  [UPDATE_MR_EVENT.SUCCESS]: (state, { payload }) => {
    const newBuyerSchedule = [...state.buyerSchedule]
    const deleteItemIndex = newBuyerSchedule.findIndex(event => event._id === payload._id)
    newBuyerSchedule[deleteItemIndex] = { ...payload }
    return {
      ...state,
      buyerSchedule: newBuyerSchedule,
      requestMREventPending: false,
      MREventId: payload._id,
    }
  },
  [UPDATE_MR_EVENT.FAILURE]: (state, { payload }) => ({
    ...state,
    requestMREventPending: false,
    requestMREventError: payload,
  }),
  [FETCH_COLLEAGUES_CALENDAR.REQUEST]: state => ({
    ...state,
    requestSchedulePending: true,
    requestScheduleError: null,
  }),
  [FETCH_COLLEAGUES_CALENDAR.SUCCESS]: (state, { payload }) => ({
    ...state,
    colleaguesCalendar: payload,
    requestSchedulePending: false,
  }),
  [FETCH_COLLEAGUES_CALENDAR.FAILURE]: (state, { payload }) => ({
    ...state,
    requestSchedulePending: false,
    requestScheduleError: payload,
  }),
  [UPDATE_CLIENT_PHONE_NUMBER.REQUEST]: state => ({
    ...state,
    requestUpdatePhonePending: true,
    requestUpdatePhoneError: null,
  }),
  [UPDATE_CLIENT_PHONE_NUMBER.SUCCESS]: (state, { payload }) => ({
    ...state,
    invitedClient: payload,
    requestUpdatePhonePending: false,
  }),
  [UPDATE_CLIENT_PHONE_NUMBER.FAILURE]: (state, { payload }) => ({
    ...state,
    requestUpdatePhonePending: false,
    requestUpdatePhoneError: payload,
  }),
  [CANCEL_BOOKING_BUYER.SUCCESS]: (state, { payload }) => {
    const newBuyerSchedule = [...state.buyerSchedule]
    const deleteItemIndex = newBuyerSchedule.findIndex(event => event._id === payload._id)

    newBuyerSchedule.splice(deleteItemIndex, 1)
    return {
      ...state,
      buyerSchedule: newBuyerSchedule,
    }
  },
  [UPDATE_CLIENT.SUCCESS]: (state, { payload }) => {
    const { updatedBuyer } = payload
    return {
      ...state,
      buyerColleagues: {
        ...state.buyerColleagues,
        byId: {
          ...state.buyerColleagues.byId,
          [updatedBuyer._id]: {
            ...updatedBuyer,
          },
        },
      },
    }
  },
  [DELETE_CLIENT.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [DELETE_CLIENT.SUCCESS]: (state, { payload }) => {
    const { updatedBuyer } = payload
    return {
      ...state,
      buyerColleagues: {
        ...state.buyerColleagues,
        byId: {
          ...state.buyerColleagues.byId,
          [updatedBuyer._id]: {
            ...updatedBuyer,
          },
        },
      },
      requestPending: false,
    }
  },
  [DELETE_CLIENT.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [NUDGE_BRAND.REQUEST]: state => ({
    ...state,
    requestNudgeEmailPending: true,
    requestNudgeEmailError: null,
  }),
  [NUDGE_BRAND.SUCCESS]: state => ({
    ...state,
    requestNudgeEmailPending: false,
  }),
  [NUDGE_BRAND.FAILURE]: (state, { payload }) => ({
    ...state,
    requestNudgeEmailPending: false,
    requestNudgeEmailError: payload,
  }),
}

export default createReducer(initialState, handlers)
