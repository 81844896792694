import * as Api from "../../helpers/api"

import { call, put } from "redux-saga/effects"

import { FETCH_TIMEZONE } from "../actions/salesSessions"
import moment from "moment-timezone"
import { onToastError } from "./../../helpers/toast"
import { takeLatest } from "../../helpers/saga"

function* fetchTimezone({ payload }) {
  const latLng = payload

  try {
    const timestamp = moment().unix()
    const { lat, lng } = latLng
    const response = yield call(Api.fetchTimezone, { timestamp, lat, lng })

    yield put({ type: FETCH_TIMEZONE.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message ||
      (error.response &&
        error.response.data.errors &&
        error.response.data.errors.message)
    yield put({
      type: FETCH_TIMEZONE.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchTimezoneFlow() {
  yield takeLatest(FETCH_TIMEZONE.REQUEST, fetchTimezone)
}
export default [fetchTimezoneFlow]
