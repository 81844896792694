import { createAsyncAction } from "../../helpers/redux"

export const FETCH_INVITE_DATA = createAsyncAction("FETCH_INVITE_DATA")
export const fetchInviteData = (id, download) => ({
  type: FETCH_INVITE_DATA.REQUEST,
  payload: { id, download },
})

export const UPDATE_INVITE_DATA = "UPDATE_INVITE_DATA"
export const updateInviteData = (data, replace) => ({
  type: UPDATE_INVITE_DATA,
  payload: { data, replace },
})

export const FETCH_INVITE_LINK = createAsyncAction("FETCH_INVITE_LINK")
export const fetchInviteLink = payload => ({
  type: FETCH_INVITE_LINK.REQUEST,
  payload,
})

export const SEND_INVITE_EMAIL = createAsyncAction("SEND_INVITE_EMAIL")
export const sendInviteEmail = payload => ({
  type: SEND_INVITE_EMAIL.REQUEST,
  payload,
})

export const FETCH_TOKEN_DATA = createAsyncAction("FETCH_TOKEN_DATA")
export const fetchTokenData = token => ({
  type: FETCH_TOKEN_DATA.REQUEST,
  payload: { token },
})
