import styled from "styled-components"

export const RoomoOuter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.veryLightGray};
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;

  &:last-child {
    border-bottom: none;
  }

  & > div {
    width: 70%;
  }

  & span {
    padding-top: 6px;
  }
`

export const SelectRow = styled.div`
  display: flex;
  justify-content: ${props =>
    !props.visibleTimeSelect ? "flex-end" : "space-between"};
  position: relative;
  margin-right: -20px;
  padding-right: 40px;
  box-sizing: border-box;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  & .peopleSelect {
    width: 100%;
    max-width: 230px;
  }

  & .timeSelect {
    width: 35%;
  }

  & .lengthSelect {
    width: 45%;
  }

  &:hover .deleteButton {
    visibility: visible;
  }
`

export const ClientsList = styled.div`
  width: 100%;
`

export const ClientRow = styled.div`
  display: flex;
  flex: 1;
  margin-top: ${props => (!props.first ? "30px" : "0")};
  justify-content: flex-end;
  position: relative;
`

export const AddButton = styled.div`
  text-align: right;
  margin-top: 7px;
  margin-bottom: 7px;
  padding-right: 20px;

  & span {
    cursor: pointer;
  }
`
export const DeleteButton = styled.div`
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  color: #a60c46;
  cursor: pointer;
`
export const Message = styled.div`
  left: -200px;
  bottom: -20px;
  position: absolute;
  color: rgb(69, 206, 162);
  right: 0;
  font-size: 13px;
  text-align: right;
`
