import { Button, Input } from "../UI"
import { ButtonLoader, InputRow, StepContainer, Text, Title } from "../styles"
import { InputContainer, ValidationError } from "../UI/Input"
import React, { Component } from "react"

import { PropTypes } from "prop-types"
import { PulseLoader } from "react-spinners"
import ReactPhoneInput from "react-phone-input-2"
import isEmpty from "lodash/isEmpty"
import { markets } from "../../config/settings"
import moment from "moment-timezone"

class ConfirmInformation extends Component {
  constructor(props) {
    super(props)
    const { invitedClient } = this.props
    this.state = {
      errors: {},
      touched: {},
      changed: false,
      isUpdating: false,
      phoneNumber: invitedClient.phoneNumber || "",
    }
  }

  onInputChange = value => {
    if (value.trim().length < 10) {
      this.setState(state => ({
        errors: {
          ...state.errors,
          phoneNumber: `Invalid phone number`,
        },
        phoneNumber: value,
        changed: true,
      }))
    } else {
      this.setState(() => ({
        errors: {},
        phoneNumber: value,
        changed: true,
      }))
    }
  }

  onBlur = () => {
    this.setState(state => ({
      touched: { ...state.touched, phoneNumber: true },
    }))
  }

  componentDidUpdate(prevProps) {
    const { onNextClick } = this.props
    const { phoneNumber, changed, isUpdating } = this.state
    if (isUpdating && !this.props.requestPending && prevProps.requestPending) {
      onNextClick({ phoneNumber, changed })
    }
  }

  onNextClick = () => {
    const { onNextClick, onUpdateNumber, invitedClient } = this.props
    const { phoneNumber, changed, errors } = this.state
    if (isEmpty(errors)) {
      if (changed) {
        this.setState({ isUpdating: true })
        onUpdateNumber({ phoneNumber }, invitedClient._id)
      } else {
        onNextClick({ phoneNumber, changed })
      }
    }
  }

  render() {
    const { invitedClient, brandName, session, requestPending } = this.props
    const { errors, touched, phoneNumber } = this.state
    return (
      <StepContainer>
        <Title>1. Confirm information</Title>
        <Text margin="30px 0px 50px 0px">
          <strong>{brandName}</strong> would like you to book your upcoming
          appointment on their live calendar for <strong>{session.name}</strong>{" "}
          for <strong>{`${markets[session.market]}'s`}</strong>, from{" "}
          {`${moment
            .utc(session.openingDay, "X")
            .tz(session.timezone)
            .format("DD.MM.YY")}`}{" "}
          to{" "}
          {`${moment
            .utc(session.closingDay, "X")
            .tz(session.timezone)
            .format("DD.MM.YY")}`}
          .
        </Text>
        <div>
          <InputRow>
            <Input disabled value={invitedClient.email} data-hj-whitelist />
          </InputRow>
          <InputRow>
            <Input
              disabled
              value={invitedClient.firstName}
              width="48%"
              data-hj-whitelist
            />
            <Input
              disabled
              value={invitedClient.lastName}
              width="48%"
              data-hj-whitelist
            />
          </InputRow>
          <InputRow>
            <Input disabled value={invitedClient.position} data-hj-whitelist />
          </InputRow>
          <InputRow>
            <InputContainer>
              <ReactPhoneInput
                country={"fr"}
                inputExtraProps={{
                  name: "phoneNumber",
                }}
                value={phoneNumber}
                enableSearch
                disableSearchIcon
                searchStyle={{ width: "95%" }}
                onBlur={this.onBlur}
                onChange={this.onInputChange}
              />
              {errors.phoneNumber && touched.phoneNumber && (
                <ValidationError>{errors.phoneNumber}</ValidationError>
              )}
            </InputContainer>
          </InputRow>
        </div>
        {requestPending ? (
          <ButtonLoader>
            <PulseLoader color={"#fff"} loading size={10} sizeUnit={"px"} />
          </ButtonLoader>
        ) : (
          <Button align="center" onClick={this.onNextClick}>
            Next
          </Button>
        )}
      </StepContainer>
    )
  }
}

ConfirmInformation.propTypes = {
  brandName: PropTypes.string,
  invitedClient: PropTypes.object,
  requestPending: PropTypes.bool,
  session: PropTypes.object,
  onNextClick: PropTypes.func,
  onUpdateNumber: PropTypes.func,
}
export { ConfirmInformation }
