import {
  getBuyer,
  getRequestSaveError,
  getRequestSavePending,
  getRequestVerifyEmailError,
  getRequestVerifyEmailPending,
  getSharedRetailer,
} from "../../../store/selectors"

import Component from "./General"
import { changePass } from "../../../store/actions/auth"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { updateClient } from "../../../store/actions/clients"
import { verifyEmail } from "../../../store/actions/user"

const selector = createStructuredSelector({
  retailer: getSharedRetailer,
  user: getBuyer,
  requestPending: getRequestSavePending,
  requestError: getRequestSaveError,
  requestVerifyEmailPending: getRequestVerifyEmailPending,
  requestVerifyEmailError: getRequestVerifyEmailError,
})

const actions = {
  onSaveChanges: updateClient,
  onChangePass: changePass,
  onVerifyEmail: verifyEmail,
}

export const General = connect(selector, actions)(Component)
