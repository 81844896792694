import styled from "styled-components"

export const Container = styled.div`
  padding-top: 54px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const TableContainer = styled.div`
  height: 100%;
`

export const DropdownContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`
export const DropdownMenuItem = styled.div`
  padding: 8px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.gray75};
    text-decoration: none;
  }

  & a {
    color: ${props => props.theme.black};
  }
`
export const DropdownMenu = styled.div`
  background-color: #fff;
  min-width: 200px;
  text-decoration: none;
  ${({ border, theme }) =>
    border &&
    `border: 2px solid ${theme.veryLightGray};
  border-radius: 3px;

  & ${DropdownMenuItem}:not(:last-child) {
    border-bottom: 1px solid ${theme.veryLightGray}
  }`}
`
export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin: 10px;
`
export const Icon = styled.img`
  display: block;
  height: ${({ height }) => height || "17px"};
  width: auto;
  cursor: pointer;
`
