import * as R from "ramda"

import { createSelector } from "reselect"
import { getNormalizedEvents } from "./schedule"
export const getClients = R.path(["clients", "clients"])
export const getInvitedClient = R.path(["clients", "invitedClient"])

export const getBuyerSchedule = R.path(["clients", "buyerSchedule"])
export const getRequestSchedulePending = R.path([
  "clients",
  "requestSchedulePending",
])
export const getRequestScheduleError = R.path([
  "clients",
  "requestScheduleError",
])
export const getBuyerColleagues = R.path(["clients", "buyerColleagues"])
export const getRequestColleaguesError = R.path([
  "clients",
  "requestColleaguesError",
])
export const getRequestColleaguesPending = R.path([
  "clients",
  "requestColleaguesPending",
])
export const getBuyerEventsIds = R.path(["clients", "buyerEventsIds"])

export const getRequestAddEventPending = R.path([
  "clients",
  "requestAddEventPending",
])
export const getRequestAddEventError = R.path([
  "clients",
  "requestAddEventError",
])

export const getRequestRemoveEventPending = R.path([
  "clients",
  "requestRemoveEventPending",
])
export const getRequestRemoveEventError = R.path([
  "clients",
  "requestRemoveEventError",
])

export const getRequestMREventPending = R.path([
  "clients",
  "requestMREventPending",
])
export const getRequestMREventError = R.path(["clients", "requestMREventError"])

export const getRequestUpdatePhonePending = R.path([
  "clients",
  "requestUpdatePhonePending",
])
export const getRequestUpdatePhoneError = R.path([
  "clients",
  "requestUpdatePhoneError",
])

export const getAllColleguesCallendar = R.path([
  "clients",
  "colleaguesCalendar",
])

export const getDeleteRequestPending = R.path(["clients", "requestPending"])
export const getDeleteRequestError = R.path(["clients", "requestError"])

export const getRequestNudgeEmailPending = R.path([
  "clients",
  "requestNudgeEmailPending",
])
export const getRequestNudgeEmailError = R.path([
  "clients",
  "requestNudgeEmailError",
])

export const getAllBuyerColleagues = createSelector(
  [getBuyerColleagues],
  colleagues => {
    if (!colleagues.allIds) return []
    const allColleagues = colleagues.allIds.filter(
      id => colleagues.byId[id].dbStatus !== "archived",
    )
    return allColleagues
  },
)

export const getSchedule = createSelector(
  [getBuyerSchedule, getBuyerEventsIds, getNormalizedEvents],
  (schedule, eventsIds, getNormalizedEvents) => {
    if (!schedule || !getNormalizedEvents) return []
    const buyerFashionEvents = eventsIds.map(
      eventId => getNormalizedEvents.byId[eventId],
    )
    const allEvents = [...schedule, ...buyerFashionEvents]
    const newSchedule = []
    const dateSchedule = {}
    allEvents.forEach(meeting => {
      if (meeting) {
        if (dateSchedule[meeting.dayDate]) {
          dateSchedule[meeting.dayDate].events.push(meeting)
        } else {
          dateSchedule[meeting.dayDate] = { events: [meeting] }
        }
      }
    })
    for (const key in dateSchedule) {
      newSchedule.push({ date: key, events: dateSchedule[key].events })
    }
    return newSchedule
  },
)

export const getCollegeauesCalendar = createSelector(
  [getAllColleguesCallendar, getNormalizedEvents],
  (colleaguesCalendar, getNormalizedEvents) => {
    if (!colleaguesCalendar || !getNormalizedEvents) return null
    const allColleaguesCalendar = {}
    for (const key in colleaguesCalendar) {
      allColleaguesCalendar[key] = {}
      const fashionEvents = colleaguesCalendar[key].fashionEvents.map(
        eventId => getNormalizedEvents.byId[eventId],
      )
      const events = [...colleaguesCalendar[key].meetings, ...fashionEvents]
      events.forEach(meeting => {
        if (!allColleaguesCalendar[key][meeting.dayDate]) {
          allColleaguesCalendar[key][meeting.dayDate] = []
          allColleaguesCalendar[key][meeting.dayDate].push(meeting)
        } else {
          allColleaguesCalendar[key][meeting.dayDate].push(meeting)
        }
      })
    }
    return allColleaguesCalendar
  },
)
