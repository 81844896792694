import "react-tagsinput/react-tagsinput.css"
import "./index.css"

import * as serviceWorker from "./serviceWorker"

import App from "./components/App"
import { PersistGate } from "redux-persist/integration/react"
import { Provider } from "react-redux"
import Raven from "raven-js"
import React from "react"
import { ThemeProvider } from "styled-components"
import configureStore from "./store/configureStore"
import { mainTheme } from "./constants/themes"
import { render } from "react-dom"
import rootReducer from "./store/reducers"
import rootSaga from "./store/sagas"
import { ApolloProvider } from "@apollo/client"
import client from "./graphql/graphqlClient"

const { store, persistor } = configureStore(rootReducer, rootSaga)
if (process.env.NODE_ENV === "production") {
  Raven.config("https://d176c5997ba941e7b5125afe4ab1846d@sentry.io/1267540").install()
}

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={mainTheme}>
          <App />
        </ThemeProvider>
      </ApolloProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root"),
)
serviceWorker.unregister()
