import styled from "styled-components"

export const Container = styled.div`
  & .react-tagsinput-tag {
    color: #fff;
    background-color: #516bf0;
    border-color: #516bf0;
  }

  & .react-tagsinput-remove {
    color: #fff;
  }
`

export const Row = styled.div`
  padding: 20px 40px 20px 40px;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent || "space-between"};
  align-items: center;
`
export const ToogleDescription = styled.p`
  margin-right: 25px;
`

export const Section = styled.div`
  padding: 20px 40px 20px 40px;
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
  background-color: ${props => props.theme.gray75};
`

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
`
export const SectionHeaderTitle = styled.h3`
  color: #333333;
`
export const HeaderText = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;

  & .italic {
    font-style: italic;
  }
`

export const SectionContent = styled.div`
  margin-top: 10px;
`

export const TeamContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 15px;

  &:not(:last-child) {
    margin-bottom: 15px;
    border-bottom: 1px solid #e3e3e3;
  }
`

export const AddButton = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${props => props.theme.primary};
  margin-left: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  border-radius: 50%;

  & span {
    height: 1em;
    width: 1em;
    text-align: center;
    line-height: 0.9em;
  }
`
export const SelectContainer = styled.div`
  width: 100%;

  & .areaSelect__multi-value {
    font-size: 16px;
    min-height: 35px;
    display: flex;
    align-items: center;
  }

  & .areaSelect__multi-value__remove {
    min-height: 35px;
  }
`

export const DropdownContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;

  & .dropdown__content {
    margin-top: 10px;
  }
`
export const DropdownMenu = styled.div`
  background-color: #fff;
  min-width: 200px;
  text-decoration: none;
`
export const DropdownMenuItem = styled.div`
  padding: 8px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.gray75};
    text-decoration: none;
  }

  & a {
    color: ${props => props.theme.black};
  }
`

export const Icon = styled.img`
  display: block;
  height: ${({ height }) => height || "17px"};
  width: auto;
  cursor: pointer;
`

export const Col = styled.div`
  width: 100%;
  max-width: 250px;
`

export const TeamColor = styled.div`
  display: block;
  max-width: 250px;
  min-height: 40px;
  line-height: 40px;
  border-radius: 3px;
  text-align: center;
  color: #333;
  background-color: ${props => props.bgColor || props.theme.teal};
  &:hover {
    text-decoration: none;
  }
`
export const ErrorText = styled.div`
  color: ${props => props.theme.primary};
`
export const InputContainer = styled.div`
  padding-right: 15px;
  & > input {
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 40px;
    padding-left: 15px;
    padding-right: 10px;
  }
`
