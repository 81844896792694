import { REST_API_URL } from "../../config"
import axios from "axios"

export const fetchRetailer = async ({ retailerId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(
    `${REST_API_URL}/retailers/${retailerId}`,
    config,
  )

  return response.data
}

export const fetchSharedRetailer = async ({ retailerId, token }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.get(
    `${REST_API_URL}/sharedRetailers/${retailerId}`,
    config,
  )

  return response.data
}

export const updateRetailer = async ({ retailerId, token, data }) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }
  const response = await axios.patch(
    `${REST_API_URL}/sharedRetailers/${retailerId}`,
    data,
    config,
  )

  return response.data
}
