import {
  CANCEL_BOOKING,
  CONFIRM_AUTO_BOOKINGS,
  EDIT_AUTO_BOOKINGS,
  EDIT_AUTO_BOOKINGS_JOKER,
  EDIT_JOKER_REQUEST,
  REQUEST_JOKER,
} from "../actions/schedules"
import {
  FETCH_INVITE_DATA,
  FETCH_INVITE_LINK,
  FETCH_TOKEN_DATA,
  SEND_INVITE_EMAIL,
  UPDATE_INVITE_DATA,
} from "../actions/invites"

import { createReducer } from "../../helpers/redux"

const initialState = {
  bookingsData: {},
  bookings: [],
  requestPending: false,
  requestError: null,
  requestInviteLinkPending: false,
  requestInviteLinkError: null,
  inviteLink: null,
  requestEmailPending: false,
  requestEmailError: null,
  requestInviteDataPending: false,
  requestInviteDataError: null,
  registerData: null,
  requestJokerPending: false,
  requestJokerError: null,
}

const removeEvent = (oldBookingData, cancelledEvent) => {
  const roomIndex = oldBookingData.rooms.findIndex(room => room.roomName === cancelledEvent.room)
  const newBookingData = { ...oldBookingData }
  newBookingData.rooms[roomIndex] = {
    room: cancelledEvent.room,
    clients: [null],
  }

  const eventIndex = oldBookingData.events.findIndex(event => event.room === cancelledEvent.room)
  newBookingData.events.splice(eventIndex, 1)
  return newBookingData
}

const handlers = {
  [FETCH_INVITE_DATA.REQUEST]: state => ({
    ...state,
    requestPending: true,
    requestError: null,
  }),
  [FETCH_INVITE_DATA.SUCCESS]: (state, { payload }) => {
    const { bookingsData } = payload
    return {
      ...state,
      bookingsData,
      requestPending: false,
    }
  },
  [FETCH_INVITE_DATA.FAILURE]: (state, { payload }) => ({
    ...state,
    requestPending: false,
    requestError: payload,
  }),
  [FETCH_INVITE_LINK.REQUEST]: state => ({
    ...state,
    requestInviteLinkPending: true,
    requestInviteLinkError: null,
  }),
  [FETCH_INVITE_LINK.SUCCESS]: (state, { payload }) => ({
    ...state,
    inviteLink: payload,
    requestInviteLinkPending: false,
  }),
  [FETCH_INVITE_LINK.FAILURE]: (state, { payload }) => ({
    ...state,
    requestInviteLinkPending: false,
    requestInviteLinkError: payload,
  }),
  [UPDATE_INVITE_DATA]: (state, { payload }) => {
    const { data } = payload
    return {
      ...state,
      bookingsData: {
        ...state.bookingsData,
        ...data,
      },
    }
  },
  [CONFIRM_AUTO_BOOKINGS.SUCCESS]: (state, { payload }) => {
    const { bookingsData } = payload
    return {
      ...state,
      bookingsData,
    }
  },
  [EDIT_AUTO_BOOKINGS.SUCCESS]: (state, { payload }) => {
    const { bookingsData } = payload
    return {
      ...state,
      bookingsData,
    }
  },
  [CANCEL_BOOKING.SUCCESS]: (state, { payload }) => ({
    ...state,
    bookingsData: removeEvent(state.bookingsData, payload),
  }),
  [SEND_INVITE_EMAIL.REQUEST]: state => ({
    ...state,
    requestEmailPending: true,
    requestEmailError: null,
  }),
  [SEND_INVITE_EMAIL.SUCCESS]: state => ({
    ...state,
    requestEmailPending: false,
  }),
  [SEND_INVITE_EMAIL.FAILURE]: (state, { payload }) => ({
    ...state,
    requestEmailPending: false,
    requestEmailError: payload,
  }),
  [FETCH_TOKEN_DATA.REQUEST]: state => ({
    ...state,
    requestInviteDataPending: true,
    requestInviteDataError: null,
  }),
  [FETCH_TOKEN_DATA.SUCCESS]: (state, { payload }) => ({
    ...state,
    registerData: payload,
    requestInviteDataPending: false,
  }),
  [FETCH_TOKEN_DATA.FAILURE]: (state, { payload }) => ({
    ...state,
    requestInviteDataPending: false,
    requestInviteDataError: payload,
  }),
  [REQUEST_JOKER.REQUEST]: state => ({
    ...state,
    requestJokerPending: true,
    requestJokerError: null,
  }),
  [REQUEST_JOKER.SUCCESS]: (state, { payload }) => {
    const newBookingData = { ...state.bookingsData }

    newBookingData.jokerRequests = payload
    return {
      ...state,
      requestJokerPending: false,
      bookingsData: newBookingData,
    }
  },
  [REQUEST_JOKER.FAILURE]: (state, { payload }) => ({
    ...state,
    requestJokerPending: false,
    requestJokerError: payload,
  }),
  [EDIT_AUTO_BOOKINGS_JOKER.REQUEST]: state => ({
    ...state,
    requestJokerPending: true,
    requestJokerError: null,
  }),
  [EDIT_AUTO_BOOKINGS_JOKER.SUCCESS]: (state, { payload }) => {
    const { bookingsData } = payload
    return {
      ...state,
      requestJokerPending: false,
      bookingsData,
    }
  },
  [EDIT_AUTO_BOOKINGS_JOKER.FAILURE]: (state, { payload }) => ({
    ...state,
    requestJokerPending: false,
    requestJokerError: payload,
  }),
  [EDIT_JOKER_REQUEST.REQUEST]: state => ({
    ...state,
    requestJokerPending: true,
    requestJokerError: null,
  }),
  [EDIT_JOKER_REQUEST.SUCCESS]: (state, { payload }) => {
    const { bookingsData } = payload
    return {
      ...state,
      requestJokerPending: false,
      bookingsData,
    }
  },
  [EDIT_JOKER_REQUEST.FAILURE]: (state, { payload }) => ({
    ...state,
    requestJokerPending: false,
    requestJokerError: payload,
  }),
}

export default createReducer(initialState, handlers)
