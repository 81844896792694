import styled, { css } from "styled-components"

import { Button } from "../styles"

export const CalendarGridHourSize = 100
export const CalendarGridColumnWidth = 230
export const CalendarColumnSpacing = 1
export const CalendarTransitionDuration = 0.4

export const Header = styled.div`
  width: 100%;
  min-height: 100px;

  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.header};
  font-size: 15px;
  line-height: 24px;

  display: flex;
  flex-direction: row;
  align-items: center;

  > img {
    padding: 0 40px;
  }

  > div {
    flex: 1;
    text-align: center;
    padding: 20px;

    > div:first-child {
      font-weight: 600;
    }
  }
`

export const Calendar = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
`
export const CalendarHeader = styled.div`
  width: 100%;
`
export const CalendarContentWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: stretch;

  div > {
    height: 100%;
  }
`
export const Suggestions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  overflow-x: scroll;
  padding-left: 44px;

  margin-left: 0px;
  transition: margin-left 0.3s;
  ${props =>
    props.withSidebar &&
    css`
      margin-left: 240px;
    `}
`

export const DateColumn = styled.div`
  display: flex;
  flex-direction: column;
`
export const DateColumnHeader = styled.div`
  width: 100%;
  height: 44px;
  z-index: 4;
  padding-right: ${CalendarColumnSpacing}px;

  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.backgroundLight};
  border-bottom: ${({ theme }) => theme.veryLightGray} 0.5px solid;

  font-size: 15px;
  font-weight: 500;
  line-height: 44px;
  text-align: center;
  white-space: nowrap;
`
export const SuggestionList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: hidden;

  margin-top: 0;
  width: ${CalendarGridColumnWidth}px;
  margin-right: ${CalendarColumnSpacing}px;
  padding-top: 1px;

  background-color: rgba(251, 251, 251, 0.5);
  border-left: #d8d8d8 0.5px solid;
  border-right: #d8d8d8 0.5px solid;

  > div {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: ${CalendarGridColumnWidth}px;
  }

  ${props =>
    props.withTransitions &&
    css`
      transition: ${CalendarTransitionDuration}s width, ${CalendarTransitionDuration}s margin-top;
    `}
`
export const Suggestion = styled.div`
  animation: fadeIn 0.3s forwards;

  width: 100%;
  padding: 1px;
  z-index: 1;

  > div {
    width: 100%;
    height: 100%;
  }

  > div > span {
    display: block;
    text-align: center;
    margin: 4px auto;
    padding: 0 4px;
  }

  > div > div {
    font-size: 13px;
    font-weight: 450;
    line-height: 21px;
    letter-spacing: -0.5px;
  }
`

export const SuggestionItem = styled.div`
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #fff;

  border: 1px solid #f1f1f1;
  border-radius: 3px;
  border-color: ${({ theme }) => theme.text};
  color: ${({ theme }) => theme.text};

  font-size: 15px;
  font-weight: 550;

  transition: ${CalendarTransitionDuration}s color, ${CalendarTransitionDuration}s border-color;

  ${props =>
    props.selected &&
    css`
      border-color: ${({ theme }) => theme.brandSecondary};
      color: ${({ theme }) => theme.brandSecondary};
    `}

  ${props =>
    props.disabled &&
    css`
      border-color: ${({ theme }) => theme.veryLightGray};
      color: ${({ theme }) => theme.veryLightGray};
    `}
`

export const DateHourSidebar = styled.div`
  position: absolute;
  overflow: hidden;

  width: 39px;
  top: 44px;
  bottom: 0;
  left: 0;
  z-index: 3;

  background: #fff;
  box-shadow: 3px 0px 4px 0px #fff;

  > span {
    display: block;
    float: left;
    clear: both;
    height: ${CalendarGridHourSize + 9}px;
    width: 44px;
    color: ${({ theme }) => theme.calendarSidebar};
    font-size: 14px;
    padding-right: 16px;
    text-align: right;
    overflow: hidden;
    text-transform: uppercase;
    margin-top: -9px;
  }

  transition: left 0.3s;
  ${props =>
    props.withSidebar &&
    css`
      left: 240px;
    `}
  ${props =>
    props.withTransitions &&
    css`
      transition: left 0.3s, ${CalendarTransitionDuration}s top;
    `}
`
export const DateHourSidebarLines = styled.div`
  pointer-events: none;
  position: absolute;
  overflow: hidden;

  width: 100%;
  height: ${CalendarGridHourSize * 24}px;
  padding-top: 45px;
  z-index: -1;

  > span {
    display: block;
    float: left;
    height: ${CalendarGridHourSize}px;
    width: 100%;
    border-top: 1px solid #dadfea;

    > span {
      display: block;
      margin-top: ${Math.floor(CalendarGridHourSize / 2)}px;
      width: 100%;
      border-top: 1px dashed #dadfea;
    }
  }

  > span:first-child {
    border-top-color: transparent;
  }

  ${props =>
    props.withTransitions &&
    css`
      transition: ${CalendarTransitionDuration}s top;
    `}
`

export const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`
export const Text = styled.div`
  margin: ${props => props.margin};

  & p {
    margin: 0;
  }
`

export const SelectContainer = styled(Text)`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => (props.flexRow ? `justify-content: space-between` : "")}

  & .languageSelect {
    width: 70%;
  }

  & img {
    display: block;
    min-height: 30px;
    width: auto;
    margin: 0 10px;
  }

  & .dateSelect {
    margin-bottom: 20px;
  }

  & .react-tagsinput--focused {
    border-color: #0a1069;
  }

  & .react-tagsinput {
    border: 1px solid #0a1069;
    border-radius: 2px;
    color: #0a1069;
  }

  & .react-tagsinput-tag {
    color: #fff;
    background-color: #516bf0;
    border-color: #516bf0;
  }

  & .react-tagsinput-remove {
    color: #fff;
  }
`
export const SmallLogo = styled.img`
  display: block;
  width: 100px;
  margin: 15px auto;
`

export const Nav = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.backgroundLight};
  padding: 25px 5px 0 25px;
`

export const NavigationButton = styled(Button)`
  display: block;
  float: left;
  text-align: center;

  width: 100%;
  max-width: 200px;

  padding: 0 15px;

  margin: 0 20px 8px 0;
`

export const OptionsButton = styled(NavigationButton)`
  float: right;
  width: auto;
`

export const Sidebar = styled.div`
  display: block;
  position: absolute;
  overflow: hidden;

  top: 44px;
  bottom: 0;
  left: 0;
  z-index: 4;

  width: 240px;
  transition: width 0.3s;

  background-color: ${({ theme }) => theme.backgroundLight};
  border-right: ${({ theme }) => theme.veryLightGray} 0.5px solid;
`

export const SidebarHeader = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 240px;

  height: 44px;
  line-height: 44px;

  text-align: center;
  font-size: 15px;
  font-weight: 550;

  color: ${({ theme }) => theme.text};
  border-bottom: ${({ theme }) => theme.text} 0.5px solid;
`

export const SidebarBookingDate = styled.div`
  display: block;
  font-weight: 600;
  margin-top: 0 !important;
  margin-bottom: 10px;
`

export const SidebarContent = styled.div`
  position: absolute;
  top: 44px;
  bottom: 0;
  right: 0;
  width: 240px;
  padding: 25px;
  overflow: scroll;

  button {
    margin: 0;
    width: 100%;
  }
`

export const SidebarBooking = styled(SuggestionItem)`
  margin-bottom: 10px;

  font-size: 16px;
  font-weight: 500;
  text-align: center;

  hr {
    border: none;
    margin: 16px auto;
    width: 56px;
    height: 1px;
    background-color: ${({ theme }) => theme.veryLightGray};
  }

  > div {
    width: 100%;
    padding: 16px;
    border-bottom: 1px solid ${({ theme }) => theme.text};
    line-height: 22px;

    &:last-child {
      border-bottom: none;
    }

    > span {
      display: block;
    }

    > div {
      margin-top: 16px;

      > span {
        display: block;
        font-weight: 600;
      }

      > small {
        display: block;
      }
    }
  }
`
