import "./styles.css"

import Dropdown, {
  DropdownContent,
  DropdownTrigger,
} from "react-simple-dropdown"
import { DropdownMenu, DropdownMenuItem, Icon } from "./styles"
import { Link, NavLink } from "react-router-dom"
import React, { Component } from "react"

import PropTypes from "prop-types"
import mR from "../../static/mR.png"
import settingsIcon from "../../static/settings-work-tool.svg"

export class Topbar extends Component {
  static propTypes = {
    logout: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = {
      showFeedback: false,
      feedBackType: "",
      partOfApp: "",
      freetextFeedback: "",
    }
  }

  feedBackTypeChanged(event) {
    this.setState({
      feedBackType: event.target.value,
    })
  }

  partOfAppChanged(event) {
    this.setState({
      partOfApp: event.target.value,
    })
  }

  freeTextChanged(event) {
    this.setState({
      freetextFeedback: event.target.value,
    })
  }

  handleLinkClick = () => {
    this.dropdown.hide()
  }

  render() {
    const userRole = "admin"
    if (this.props.location.pathname.indexOf("booking") !== -1) return null
    if (this.props.location.pathname.indexOf("video-call") !== -1) return null
    if (this.props.location.pathname.indexOf("video-presentation") !== -1)
      return null
    return (
      <div className="top-bar">
        <div className="logo">
          <Link to={userRole !== "freelancer" ? "/" : "/calendars"}>
            <img alt="logo" src={mR} />
          </Link>
        </div>
        <div className="menu-tabs">
          <React.Fragment>
            <NavLink
              activeClassName="route-active"
              className="route tab"
              to="/calendars"
            >
              CALENDARS
            </NavLink>
            <NavLink
              activeClassName="route-active"
              className="route tab"
              to="/team"
            >
              TEAM
            </NavLink>
          </React.Fragment>
        </div>
        <div className="right-menu-options">
          <Dropdown ref={ref => (this.dropdown = ref)}>
            <DropdownTrigger>
              <Icon
                height="25px"
                src={settingsIcon}
                style={{ padding: "0 5px", minWidth: "30px" }}
              />
            </DropdownTrigger>
            <DropdownContent>
              <DropdownMenu
                border
                style={{ minWidth: "300px" }}
                onClick={this.handleLinkClick}
              >
                <Link to={"/team/myprofile"}>
                  <DropdownMenuItem
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    My Profile
                  </DropdownMenuItem>
                </Link>
                <Link to={`/settings/company`}>
                  <DropdownMenuItem
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Company Settings
                  </DropdownMenuItem>
                </Link>
                <Link to={` `}>
                  <DropdownMenuItem>Sync calendar</DropdownMenuItem>
                </Link>
                <DropdownMenuItem onClick={this.props.logout}>
                  <div>Log Out</div>
                </DropdownMenuItem>
              </DropdownMenu>
            </DropdownContent>
          </Dropdown>
        </div>
      </div>
    )
  }
}
