import * as Api from "../../helpers/api"

import { FETCH_BUYER, FETCH_DB_DATA, VERIFY_EMAIL } from "../actions/user"
import { all, call, put, select } from "redux-saga/effects"

import { FETCH_COLLEAGUES } from "../actions/clients"
import { FETCH_SHARED_RETAILER } from "../actions/retailers"
import { LOGOUT } from "../actions/auth"
import { normalizeData } from "../../helpers/normalize"
import { onToastError } from "../../helpers/toast"
import { takeLatest } from "../../helpers/saga"

function* fetchDBData() {
  try {
    const state = yield select()
    const { accessToken, buyer } = state.auth
    const newBuyer = yield call(Api.fetchBuyer, {
      token: accessToken,
      id: buyer._id,
    })
    const dbData = yield all([
      call(Api.fetchSharedRetailer, {
        token: accessToken,
        retailerId: buyer.retailerId,
      }),
      call(Api.fetchColleagues, { token: accessToken, buyerId: buyer._id }),
      call(Api.fetchBuyer, { token: accessToken, id: buyer._id }),
    ])
    const colleagues = normalizeData(dbData[1].colleagues)
    const sharedRetailer = dbData[0]

    yield put({ type: FETCH_COLLEAGUES.SUCCESS, payload: colleagues })
    yield put({ type: FETCH_SHARED_RETAILER.SUCCESS, payload: sharedRetailer })
    yield put({ type: FETCH_BUYER.SUCCESS, payload: newBuyer })
    yield put({ type: FETCH_DB_DATA.SUCCESS })
  } catch (error) {
    if (error.response.data.errorType === "archived") {
      onToastError(error.response.data.message)
      return yield put({ type: LOGOUT })
    }
    let customError = "Oops, something went wrong"
    if (
      error.response &&
      error.response.data.message &&
      error.response.data.errorType === "auth"
    ) {
      customError = error.response.data.message
    }
    onToastError(customError)

    const errosMsg =
      error.message ||
      (error.response &&
        error.response.data.errors &&
        error.response.data.errors.message)
    yield put({ type: FETCH_DB_DATA.FAILURE, payload: errosMsg })
  }
}

function* loginFlow() {
  yield takeLatest(FETCH_DB_DATA.REQUEST, fetchDBData)
}

function* verifyEmail() {
  const state = yield select()
  const { accessToken } = state.auth
  const id = state.auth.buyer._id

  try {
    const response = yield call(Api.verifyEmail, { token: accessToken, id })

    yield put({ type: VERIFY_EMAIL.SUCCESS, payload: response })
  } catch (error) {
    onToastError()
    const errosMsg =
      error.message ||
      (error.response &&
        error.response.data.errors &&
        error.response.data.errors.message)
    yield put({
      type: VERIFY_EMAIL.FAILURE,
      payload: errosMsg,
    })
  }
}

function* verifyEmailFlow() {
  yield takeLatest(VERIFY_EMAIL.REQUEST, verifyEmail)
}

export default [loginFlow, verifyEmailFlow]
