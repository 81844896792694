export const seasons = {
  summerSpring: "Summer Spring",
  preSummerSpring: "Pre-Summer Spring",
  autumnWinter: "Autumn Winter",
  preAutumnWinter: "Pre-Autumn Winter",
}
export const markets = {
  women: "Women",
  men: "Men",
  womenMen: "Women & Men",
}

export const modaResaRoles = {
  agent: "Agent",
  manager: "Manager",
  freelancer: "Freelancer",
  receptionist: "Receptionist",
  nonExecAreaManager: "Non executive Area Manager",
  areaManager: "Area Manager",
}
