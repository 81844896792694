import styled from "styled-components"

export const Body = styled.div`
  color: #0a1069;
  padding: 0 32px 16px 32px;
`

export const H3 = styled.h3`
  margin: 0 0 16px;
  font-size: 16px;
`

export const SelectContainer = styled.div`
  width: 100%;

  & .react-tagsinput--focused {
    border-color: #0a1069;
  }

  & .react-tagsinput {
    border: 1px solid #0a1069;
    border-radius: 2px;
    color: #0a1069;
  }

  & .react-tagsinput-tag {
    color: #fff;
    background-color: #516bf0;
    border-color: #516bf0;
  }

  & .react-tagsinput-remove {
    color: #fff;
  }
`

export const ContainerCTA = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 32px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`
