import {
  AppContainer,
  Bold,
  ButtonsRow,
  Container,
  Form,
  MiddleLogo,
  MrLogo,
  SmallContainer,
  SmallLogo,
  StepContainer,
  Text,
} from "../styles"
import { Button, Progressbar } from "../UI"
import React, { Component } from "react"

import GoogleLogo from "../../static/google.png"
import Logo from "../../static/logo.png"
import Outlook from "../../static/Outlook.svg"
import { PropTypes } from "prop-types"
import SmllLogo from "../../static/mR2.png"
import isEmpty from "lodash/isEmpty"

const services = {
  google: { logo: GoogleLogo, label: "Link Google Account" },
  outlook: { logo: Outlook, label: "Link Outlook Account" },
}

class Agreement extends Component {
  constructor(props) {
    super(props)
    const { invitedClient, inviteData } = props
    let defaultEmail
    defaultEmail = invitedClient ? invitedClient.email : ""
    defaultEmail = inviteData ? inviteData.email : ""
    this.state = {
      isAgreed: false,
      values: {
        firstName: "",
        lastName: "",
        email: defaultEmail || "",
        password: "",
        confirmPassword: "",
      },
      errors: {},
      touched: {},
    }
  }

  validate = (value, name) => {
    const { values, errors } = this.state
    const { inviteData } = this.props
    const reg = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/
    switch (name) {
      case "email":
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        } else if (reg.test(value) === true) {
          delete errors[name]
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        errors[name] = `Invalid email address`
        return {
          values: { ...values, [name]: value },
          errors,
        }
      case "password":
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        delete errors[name]
        return {
          values: { ...values, [name]: value },
          errors,
        }

      case "confirmPassword":
        if (value.trim().length === 0) {
          errors[name] = `Required`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        } else if (values.password !== value) {
          errors[name] = `Passwords need to match`
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        delete errors[name]
        return {
          values: { ...values, [name]: value },
          errors,
        }

      case "lastName":
      case "firstName":
        if (inviteData && inviteData.retailerId) {
          if (value.trim().length === 0) {
            errors[name] = `Required`
            return {
              values: { ...values, [name]: value },
              errors,
            }
          }
          delete errors[name]
          return {
            values: { ...values, [name]: value },
            errors,
          }
        }
        break

      default:
        break
    }
  }

  onInputChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({ ...this.validate(value, name), isChanged: true })
  }

  onBlur = e => {
    const { touched } = this.state
    const name = e.target.name
    touched[name] = true
    this.setState({ touched })
  }

  onAgree = () => {
    this.setState(prevState => ({ isAgreed: !prevState.isAgreed }))
  }

  onSubmit = () => {
    const { history, inviteData, invitedClient, onSignUp } = this.props
    const { values, touched, errors } = this.state
    for (const key in values) {
      this.validate(values[key], key)
      touched[key] = true
    }
    if (isEmpty(errors)) {
      if (invitedClient && invitedClient._id) {
        onSignUp(
          {
            email: values.email,
            password: values.password,
            clientId: invitedClient._id,
            isShared: false,
          },
          history,
        )
      }
      if (inviteData) {
        if (inviteData.retailerId) {
          onSignUp(
            {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              password: values.password,
              isShared: inviteData.isShared,
              retailerId: inviteData.retailerId,
            },
            history,
          )
        } else if (inviteData.clientId) {
          onSignUp(
            {
              email: values.email,
              password: values.password,
              isShared: inviteData.isShared,
              clientId: inviteData.clientId,
            },
            history,
          )
        }
      }
    }
    this.setState({ errors, touched })
  }

  renderSingUp = () => {
    const { isAgreed, errors, touched, values } = this.state
    const { inviteData, invitedClient } = this.props
    const { email, password, confirmPassword, firstName, lastName } = values
    let isActive
    if (invitedClient) {
      isActive =
        isEmpty(errors) && isAgreed && password && confirmPassword && email
    } else if (inviteData && inviteData.retailerId) {
      isActive =
        isEmpty(errors) &&
        isAgreed &&
        password &&
        confirmPassword &&
        email &&
        firstName &&
        lastName
    }
    return (
      <React.Fragment>
        <SmallContainer className="agreement">
          <MrLogo style={{ marginBottom: "20px" }}>
            <img alt="modaresa logo" src={SmllLogo} />
          </MrLogo>
          <Text
            margin="0px auto 20px auto"
            style={{ textAlign: "center", width: "60%" }}
          >
            {`Welcome onboard ModaResa, scheduling will never be the same again.`}
          </Text>
          <Text
            margin="0px auto 20px auto"
            style={{ textAlign: "center", width: "60%" }}
          >
            {`Please create your password below and you're ready to start`}
          </Text>
          <Form>
            {inviteData && inviteData.retailerId && (
              <React.Fragment>
                <div>
                  <input
                    name="firstName"
                    placeholder="First Name"
                    type="text"
                    value={values.firstName}
                    onBlur={this.onBlur}
                    onChange={this.onInputChange}
                  />
                  {errors.firstName && touched.firstName && (
                    <span>{errors.firstName}</span>
                  )}
                </div>
                <div>
                  <input
                    name="lastName"
                    placeholder="Last Name"
                    type="text"
                    value={values.lastName}
                    onBlur={this.onBlur}
                    onChange={this.onInputChange}
                  />
                  {errors.lastName && touched.lastName && (
                    <span>{errors.lastName}</span>
                  )}
                </div>
              </React.Fragment>
            )}
            <div>
              <input
                name="email"
                placeholder="Email"
                type="text"
                value={values.email}
                onBlur={this.onBlur}
                onChange={this.onInputChange}
              />
              {errors.email && touched.email && <span>{errors.email}</span>}
            </div>
            <div>
              <input
                name="password"
                placeholder="Create Password"
                type="password"
                value={values.password}
                onBlur={this.onBlur}
                onChange={this.onInputChange}
              />
              {errors.password && touched.password && (
                <span>{errors.password}</span>
              )}
            </div>
            <div>
              <input
                name="confirmPassword"
                placeholder="Confirm Password"
                type="password"
                value={values.confirmPassword}
                onBlur={this.onBlur}
                onChange={this.onInputChange}
              />
              {errors.confirmPassword && touched.confirmPassword && (
                <span>{errors.confirmPassword}</span>
              )}
            </div>
          </Form>
          <div style={{ margin: "0 auto", width: "70%" }}>
            <p>
              <input
                id="agree"
                style={{ marginRight: "10px" }}
                type="checkbox"
                onChange={this.onAgree}
              />
              <Bold>
                <label htmlFor="agree">I agree to the ModaResa</label>
                <a
                  href="http://modaresa.com/terms-and-conditions/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {" "}
                  Term of Service
                </a>{" "}
                and{" "}
                <a
                  href="http://modaresa.com/privacy-policy/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </Bold>
            </p>
          </div>
          <ButtonsRow style={{ marginTop: "25px" }}>
            <Button disabled={!isActive} onClick={this.onSubmit}>
              Create Account
            </Button>
          </ButtonsRow>
        </SmallContainer>
      </React.Fragment>
    )
  }

  renderLogin = () => {
    const { errors, touched, values } = this.state
    return (
      <React.Fragment>
        <SmallContainer className="agreement">
          <MrLogo style={{ marginBottom: "20px" }}>
            <img alt="modaresa logo" src={SmllLogo} />
          </MrLogo>
          <Text margin="0px 0px 20px 0px" style={{ textAlign: "center" }}>
            {`Welcome onboard ModaResa, scheduling will never be the same again.`}
          </Text>
          <Text margin="0px 0px 20px 0px" style={{ textAlign: "center" }}>
            {`Please create your password below and you're ready to start`}
          </Text>
          <Form>
            <div>
              <input
                name="email"
                placeholder="Email"
                type="text"
                value={values.email}
                onBlur={this.onBlur}
                onChange={this.onInputChange}
              />
              {errors.email && touched.email && <span>{errors.email}</span>}
            </div>
            <div>
              <input
                name="password"
                placeholder="Password"
                type="password"
                value={values.password}
                onBlur={this.onBlur}
                onChange={this.onInputChange}
              />
              {errors.password && touched.password && (
                <span>{errors.password}</span>
              )}
            </div>
          </Form>
          <ButtonsRow style={{ marginTop: "25px" }}>
            <Button onClick={this.onSubmit}>Login</Button>
          </ButtonsRow>
        </SmallContainer>
      </React.Fragment>
    )
  }

  renderService = () => {
    const { service } = this.props
    const { isAgreed } = this.state

    return (
      <React.Fragment>
        <SmallLogo alt="logo" src={Logo} />
        <SmallContainer className="agreement">
          <Text margin="0px 0px 20px 0px">
            {`ModaResa will  securely access your calendar so it can`}
          </Text>
          <div style={{ paddingLeft: "15px" }}>
            <ul>
              <li>Create calendar events</li>
              <li>Delete events that this app creates</li>
              <li>Update your events</li>
              <li>Read events in your calendars</li>
            </ul>
          </div>
          <MiddleLogo>
            <img alt="google logo" src={services[service].logo} />
          </MiddleLogo>
          <div>
            <p>
              <input id="agree" type="checkbox" onChange={this.onAgree} />
              <Bold>
                <label htmlFor="agree">I agree to the ModaResa</label>
                <a
                  href="http://modaresa.com/terms-and-conditions/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {" "}
                  Term of Service
                </a>{" "}
                and{" "}
                <a
                  href="http://modaresa.com/privacy-policy/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </Bold>
            </p>
          </div>
          <ButtonsRow style={{ marginTop: "30px", marginBottom: "50px" }}>
            <Button disabled={!isAgreed}>{services[service].label}</Button>
          </ButtonsRow>
        </SmallContainer>
        <MrLogo>
          <img alt="modaresa logo" src={SmllLogo} />
        </MrLogo>
      </React.Fragment>
    )
  }

  render() {
    const { service } = this.props
    return (
      <Container>
        <AppContainer>
          <Progressbar progress={6} />
          <StepContainer className="last-step" flexRow="flex-start">
            {(service === "google" || service === "outlook") &&
              this.renderService()}
            {service === "login" && this.renderLogin()}
            {service === "signup" && this.renderSingUp()}
          </StepContainer>
        </AppContainer>
      </Container>
    )
  }
}

Agreement.propTypes = {
  history: PropTypes.object,
  inviteData: PropTypes.object,
  invitedClient: PropTypes.object,
  service: PropTypes.string,
  onSignUp: PropTypes.func,
}

export default Agreement
