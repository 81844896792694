import * as Api from "../../helpers/api"
import * as socketEvents from "../sockets/messageTypes"

import {
  ADD_EVENT,
  CREATE_MR_EVENT,
  DELETE_CLIENT,
  DELETE_MR_EVENT,
  FETCH_BUYER_SCHEDULE,
  FETCH_COLLEAGUES,
  FETCH_COLLEAGUES_CALENDAR,
  NUDGE_BRAND,
  REMOVE_EVENT,
  UPDATE_CLIENT,
  UPDATE_CLIENT_PHONE_NUMBER,
  UPDATE_MR_EVENT,
} from "../actions/clients"
import { DELETE_FASHION_EVENT, FETCH_EVENTS } from "../actions/schedules"
import { all, call, put, select } from "redux-saga/effects"

import moment from "moment-timezone"
import { normalizeData } from "../../helpers/normalize"
import { onToastError } from "./../../helpers/toast"
import { takeLatest } from "../../helpers/saga"

function* fetchColleagues({ payload }) {
  const { buyerId } = payload
  try {
    const response = yield call(Api.fetchColleagues, { buyerId })

    yield put({
      type: FETCH_COLLEAGUES.SUCCESS,
      payload: normalizeData(response.colleagues),
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_COLLEAGUES.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchColleaguesFlow() {
  yield takeLatest(FETCH_COLLEAGUES.REQUEST, fetchColleagues)
}

function* fetchBuyerSchedule({ payload }) {
  const { buyerId, ignoreOffset } = payload
  try {
    const offset = moment().utcOffset()
    const params = {
      offset,
    }
    if (ignoreOffset) {
      params.ignoreOffset = true
    }

    const dbData = yield all([call(Api.fetchBuyerSchedule, { buyerId, params }), call(Api.fetchEvents, { params })])
    const response = dbData[0]
    const events = dbData[1]

    yield put({ type: FETCH_EVENTS.SUCCESS, payload: events })
    yield put({
      type: FETCH_BUYER_SCHEDULE.SUCCESS,
      payload: { meetings: response.meetings, events: response.fashionEvents },
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_BUYER_SCHEDULE.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchBuyerScheduleFlow() {
  yield takeLatest(FETCH_BUYER_SCHEDULE.REQUEST, fetchBuyerSchedule)
}

function* fetchColleaguesCalendar({ payload }) {
  const { buyerId, ignoreOffset } = payload

  try {
    const offset = moment().utcOffset()
    const params = {
      offset,
    }
    if (ignoreOffset) {
      params.ignoreOffset = true
    }
    const dbData = yield all([
      call(Api.fetchColleaguesCalendar, { buyerId, params }),
      call(Api.fetchEvents, { params }),
    ])
    const response = dbData[0]
    const events = dbData[1]

    yield put({ type: FETCH_EVENTS.SUCCESS, payload: events })
    yield put({
      type: FETCH_COLLEAGUES_CALENDAR.SUCCESS,
      payload: response.colleaguesSchedules,
    })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: FETCH_COLLEAGUES_CALENDAR.FAILURE,
      payload: errosMsg,
    })
  }
}

function* fetchColleaguesCalendarFlow() {
  yield takeLatest(FETCH_COLLEAGUES_CALENDAR.REQUEST, fetchColleaguesCalendar)
}

function* updateClient({ payload }) {
  const { data, clientId } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.UPDATE_SHARED_BUYER,
        body: { data, id: clientId },
      },
    })
  } catch (error) {
    onToastError()
  }
}

function* updateClientFlow() {
  yield takeLatest(UPDATE_CLIENT.REQUEST, updateClient)
}

function* addEvent({ payload }) {
  const { buyerId, eventId } = payload
  try {
    const response = yield call(Api.addEvent, { buyerId, eventId })

    yield put({ type: ADD_EVENT.SUCCESS, payload: response })
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errorType === "eventRemoved") {
      onToastError(error.response.data.message)
      yield put({ type: DELETE_FASHION_EVENT, payload: eventId })
    } else {
      onToastError()
    }
    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: ADD_EVENT.FAILURE,
      payload: errosMsg,
    })
  }
}

function* addEventFlow() {
  yield takeLatest(ADD_EVENT.REQUEST, addEvent)
}

function* removeEvent({ payload }) {
  const { buyerId, eventId } = payload
  try {
    const response = yield call(Api.removeEvent, { buyerId, eventId })

    yield put({ type: REMOVE_EVENT.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: REMOVE_EVENT.FAILURE,
      payload: errosMsg,
    })
  }
}

function* removeEventFlow() {
  yield takeLatest(REMOVE_EVENT.REQUEST, removeEvent)
}

function* createMREvent({ payload }) {
  try {
    const response = yield call(Api.createMrEvent, { data: payload })

    yield put({ type: CREATE_MR_EVENT.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: CREATE_MR_EVENT.FAILURE,
      payload: errosMsg,
    })
  }
}

function* createMREventFlow() {
  yield takeLatest(CREATE_MR_EVENT.REQUEST, createMREvent)
}

function* deleteMREvent({ payload }) {
  try {
    const { eventId } = payload
    const response = yield call(Api.deleteMrEvent, { eventId })

    yield put({ type: DELETE_MR_EVENT.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: DELETE_MR_EVENT.FAILURE,
      payload: errosMsg,
    })
  }
}

function* deleteMREventFlow() {
  yield takeLatest(DELETE_MR_EVENT.REQUEST, deleteMREvent)
}

function* updateMREvent({ payload }) {
  try {
    const response = yield call(Api.updateMrEvent, { data: payload })

    yield put({ type: UPDATE_MR_EVENT.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: UPDATE_MR_EVENT.FAILURE,
      payload: errosMsg,
    })
  }
}

function* updateMREventFlow() {
  yield takeLatest(UPDATE_MR_EVENT.REQUEST, updateMREvent)
}

function* updateClientPhoneNumber({ payload }) {
  const state = yield select()
  const { accessToken } = state.auth
  const { data, clientId } = payload
  try {
    const response = yield call(Api.updateClientPhoneNumber, {
      clientId,
      data,
      token: accessToken,
    })

    yield put({ type: UPDATE_CLIENT_PHONE_NUMBER.SUCCESS, payload: response })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: UPDATE_CLIENT_PHONE_NUMBER.FAILURE,
      payload: errosMsg,
    })
  }
}

function* updateClientPhoneNumberFlow() {
  yield takeLatest(UPDATE_CLIENT_PHONE_NUMBER.REQUEST, updateClientPhoneNumber)
}

function* deleteClient({ payload }) {
  const { clientId } = payload
  try {
    yield put({
      type: "SEND_WEBSOCKET_MESSAGE",
      payload: {
        eventType: socketEvents.DELETE_SHARED_BUYER,
        body: { id: clientId },
      },
    })
  } catch (error) {
    onToastError()
  }
}

function* deleteClientFlow() {
  yield takeLatest(DELETE_CLIENT.REQUEST, deleteClient)
}

function* nudgeBrand() {
  try {
    const state = yield select()
    const { MREventId } = state.clients
    yield call(Api.nudgeEmail, { eventId: MREventId })

    yield put({ type: NUDGE_BRAND.SUCCESS })
  } catch (error) {
    onToastError()

    const errosMsg =
      error.message || (error.response && error.response.data.errors && error.response.data.errors.message)
    yield put({
      type: NUDGE_BRAND.FAILURE,
      payload: errosMsg,
    })
  }
}

function* nudgeBrandFlow() {
  yield takeLatest(NUDGE_BRAND.REQUEST, nudgeBrand)
}

export default [
  fetchColleaguesFlow,
  fetchBuyerScheduleFlow,
  updateClientFlow,
  addEventFlow,
  removeEventFlow,
  createMREventFlow,
  deleteMREventFlow,
  updateMREventFlow,
  updateClientPhoneNumberFlow,
  nudgeBrandFlow,
  fetchColleaguesCalendarFlow,
  deleteClientFlow,
]
