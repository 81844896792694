import React, { Component } from "react"
import { Sidebar, SidebarBooking, SidebarBookingDate, SidebarContent, SidebarHeader } from "./styles"

import { Button } from "../styles"
import moment from "moment"

class BookedOptions extends Component {
  onBookingClick = bookingIndex => {
    if (typeof this.props.onBookingClick === "function") {
      this.props.onBookingClick(bookingIndex)
    }
  }

  render() {
    const styles = {}
    if (!this.props.visible) {
      styles.width = 0
    }

    const clientById = this.props.clients
    const selectedRooms = this.props.rooms

    if (!this.props.suggestionsClients) {
      return false
    }
    return (
      <Sidebar style={styles}>
        <SidebarHeader>Selected appointment(s)</SidebarHeader>
        <SidebarContent>
          {this.props.suggestionsClients.map((clientId, index) => {
            const bookingRooms = this.props.bookings[index]
            const selected = this.props.clientIndex === index

            /**
             * Display a placeholder
             */
            if (!bookingRooms || !bookingRooms.length) {
              const client = clientById[clientId]
              const rooms = selectedRooms.filter(room => room.clients.indexOf(clientId) > -1)
              return (
                <SidebarBooking selected={selected} key={index} onClick={() => this.onBookingClick(index)}>
                  <div>
                    <span>{`${client.firstName} ${client.lastName}`}</span>
                    <hr />
                    {rooms.map(room => (
                      <div key={`${index}_${room.roomName}`}>{room.roomName}</div>
                    ))}
                  </div>
                </SidebarBooking>
              )
            }

            const booking = bookingRooms.reduce(
              (booking, room) => {
                const roomData = selectedRooms.find(roomData => roomData.roomName === room.room)

                if (!booking.clients) {
                  booking.clients = {}
                  roomData.clients.forEach(clientId => {
                    booking.clients[clientId] = clientById[clientId]
                  })
                } else {
                  Object.keys(booking.clients).forEach(clientId => {
                    if (roomData.clients.indexOf(clientId) < 0) {
                      delete booking.clients[clientId]
                    }
                  })
                }

                booking.rooms.push(room)

                return booking
              },
              {
                clients: null,
                rooms: [],
              },
            )

            return (
              <SidebarBooking selected={selected} key={index} onClick={() => this.onBookingClick(index)}>
                <div>
                  <SidebarBookingDate>{`${moment
                    .utc(booking.rooms[0].startTime, "X")
                    .format("dddd Do, MMM.")}`}</SidebarBookingDate>
                  {Object.values(booking.clients).map(client => (
                    <span key={client._id}>{`${client.firstName} ${client.lastName}`}</span>
                  ))}
                  <hr />
                  {booking.rooms.map(room => {
                    const roomData = selectedRooms.find(roomData => roomData.roomName === room.room)
                    const extras = roomData.clients
                      .filter(clientId => !booking.clients[clientId])
                      .map(clientId => `${clientById[clientId].firstName} ${clientById[clientId].lastName}`)
                    return (
                      <div key={room.room}>
                        {room.room}
                        {extras.length ? <small>(with {extras.join(", ")})</small> : false}
                        <span>{`${moment.utc(room.startTime, "X").format("H:mm")} - ${moment
                          .utc(room.endTime, "X")
                          .format("H:mm")}`}</span>
                      </div>
                    )
                  })}
                </div>
              </SidebarBooking>
            )
          })}
          {this.props.suggestionsClients && this.props.suggestionsClients.length === this.props.bookings.length ? (
            <Button primary onClick={this.props.onConfirmClick}>
              Confirm
            </Button>
          ) : (
            false
          )}
        </SidebarContent>
      </Sidebar>
    )
  }
}

export default BookedOptions
