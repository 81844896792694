import { MenuItem, MenuItemText } from "./styles"
import React, { Component } from "react"

import { Container } from "../../../UI/SideMenu/index"
import PropTypes from "prop-types"

class Sidemenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      draft: false,
      archived: false,
    }
  }

  render() {
    const { match } = this.props

    return (
      <Container>
        <MenuItem to={`${match.url}/company`}>
          <MenuItemText>Company Settings</MenuItemText>
        </MenuItem>
      </Container>
    )
  }
}

Sidemenu.propTypes = {
  match: PropTypes.object,
}

export default Sidemenu
