import styled from "styled-components"

export const Page = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  height: 100%;

  padding: 20px;

  background-color: #fff4f3;

  color: ${props => props.theme.text};
  font-size: 20px;
  letter-spacing: 1px;

  strong {
    font-weight: 550;
  }
`

export const LoaderContainer = styled.div`
  text-align: center;
`

export const LogoContainer = styled.img`
  height: 36px;
  margin-top: 58px;
`

export const PageContentBlock = styled.div`
  width: 100%;
  max-width: 900px;
`

export const Content = styled(PageContentBlock)`
  margin-top: 130px;
  margin-bottom: 80px;
`

export const Footer = styled(PageContentBlock)`
  > div {
    margin-top: 60px;
  }
`
