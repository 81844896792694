import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const CheckboxOuter = styled.label`
  cursor: pointer;
  display: block;
  width: 32%;
  margin-bottom: 2%;

  &:not(:nth-child(3n)) {
    margin-right: 2%;
  }

  & input:checked ~ div {
    border: 1px solid ${props => props.theme.primary};
    background-color: ${props => props.theme.primary};
    color: #fff;
  }
`

const CheckboxLabel = styled.div`
  border: 1px solid ${props => props.theme.veryLightGray};
  height: 32px;
  line-height: 30px;
  width: 100%;
  text-align: center;
`

export const Checkbox = props => {
  const { onChange, name, label, checked, id } = props
  return (
    <CheckboxOuter htmlFor={name}>
      <input
        defaultChecked={checked}
        id={name}
        name={name}
        style={{ display: "none" }}
        type="checkbox"
        onChange={() => onChange(id)}
      />
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxOuter>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
}
