import React, { memo, useCallback, useState } from "react"
import NewFinalStepView from "./NewFinalStepView"

type Props = {
  brand: {},
  bookingId: string,
  isJokerRequest: boolean,
  onDownloadPress: Function,
  defaultShowSpreadTheWordModal: boolean,
}
const NewFinalStep = ({ bookingId, isJokerRequest, brand, onDownloadPress, defaultShowSpreadTheWordModal }: Props) => {
  const [showSpecialRequestModal, setShowSpecialRequestModal] = useState(isJokerRequest)
  const [showSpreadTheWordModal, setShowSpreadTheWordModal] = useState(defaultShowSpreadTheWordModal)
  const handleCloseSpecialRequestModal = useCallback(() => setShowSpecialRequestModal(false), [])
  const handleCloseSpreadTheWordModal = useCallback(() => setShowSpreadTheWordModal(false), [])
  return (
    <NewFinalStepView
      brand={brand}
      bookingId={bookingId}
      showSpecialRequestModal={showSpecialRequestModal}
      onCloseSpecialRequestModal={handleCloseSpecialRequestModal}
      showSpreadTheWordModal={showSpreadTheWordModal}
      onCloseSpreadTheWordModal={handleCloseSpreadTheWordModal}
      onDownloadPress={onDownloadPress}
    />
  )
}

NewFinalStep.defaultProps = {
  showTime: false,
}

export default memo(NewFinalStep)
