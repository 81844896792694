import "react-tagsinput/react-tagsinput.css"

import { AddButton, Container, HeaderText, Section, SectionContent, SectionHeader, SectionHeaderTitle } from "./styles"
import { Button, Div } from "../../../UI"
import React, { Component } from "react"

import PropTypes from "prop-types"
import Team from "./Team"
import { generateId } from "../../../../helpers/idGenerator"
import isEmpty from "lodash/isEmpty"
import { mainTheme } from "../../../../constants/themes"

class CompanyStructure extends Component {
  constructor(props) {
    super(props)
    const { retailer } = this.props

    this.state = {
      teams: (retailer && retailer.teams) || [],
      errors: {},
      isChanged: false,
      isRequest: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isRequest } = prevState
    const { requestPending, requestError } = nextProps

    if (isRequest && !requestPending && !requestError) {
      return { isRequest: false }
    }
    return null
  }

  handleTeamChange = (type, value, teamIndex) => {
    this.setState(state => {
      const teams = state.teams.map((team, index) => (teamIndex === index ? { ...team, [type]: value } : team))
      return {
        teams,
        isChanged: true,
      }
    })
  }

  validateTeams = values => {
    const errors = {}
    const { teams } = values
    teams.forEach((area, index) => {
      if (!area.name) errors[`teams.${index}.name`] = "Name is Required"
      if (!area.color) errors[`teams.${index}.color`] = "Color is Required"
    })
    return errors
  }

  onSubmit = () => {
    const { teams } = this.state
    const { retailer } = this.props
    const errors = this.validateTeams(this.state)
    if (isEmpty(errors)) {
      this.props.onSaveChanges({ ...retailer, teams })

      this.setState({ isChanged: false, isRequest: true })
    }
    this.setState({ errors })
  }

  onDeleteTeam = teamIndex => {
    this.setState(state => {
      const newTeams = state.teams
      newTeams.splice(teamIndex, 1)
      return {
        teams: newTeams,
        isChanged: true,
      }
    })
  }

  onAddEmptyTeam = () => {
    this.setState(state => ({
      teams: [
        ...state.teams,
        {
          name: "",
          color: mainTheme.teal,
          _id: generateId(),
        },
      ],
      isChanged: true,
    }))
  }

  render() {
    const { requestError, requestPending } = this.props
    const { errors, isChanged, isRequest } = this.state
    return (
      <Container>
        <Section>
          <SectionHeader>
            <Div.Flex justify="flex-start">
              <SectionHeaderTitle>{`Create your teams`}</SectionHeaderTitle>
              <AddButton onClick={this.onAddEmptyTeam}>
                <span>+</span>
              </AddButton>
            </Div.Flex>
            <HeaderText>
              <p>Create your buying teams and allocate a colour to each one.</p>
              <p className="italic">Example: International Designers, Contemporary, Men, Women</p>
            </HeaderText>
          </SectionHeader>
          <SectionContent>
            {this.state.teams.map((team, teamIndex) => (
              <Team
                errors={errors}
                key={team._id}
                team={team}
                teamIndex={teamIndex}
                onDeleteTeam={this.onDeleteTeam}
                onTeamChange={this.handleTeamChange}
              />
            ))}
          </SectionContent>
        </Section>
        <Div.Flex align="flex-end" column style={{ paddingTop: "20px", paddingRight: "20px" }}>
          <Button
            disabled={isRequest ? (!requestPending && requestError ? false : !isChanged) : !isChanged}
            type="button"
            onClick={this.onSubmit}
          >
            Save Changes
          </Button>
        </Div.Flex>
      </Container>
    )
  }
}

CompanyStructure.propTypes = {
  requestError: PropTypes.string,
  requestPending: PropTypes.bool,
  retailer: PropTypes.object,
  onSaveChanges: PropTypes.func,
}

export default CompanyStructure
