import {
  ButtonsRow,
  SelectContainer,
  StepContainer,
  Text,
  Title,
} from "../styles"
import React, { Component } from "react"

import { Button } from "../UI"
import { PropTypes } from "prop-types"
import Select from "react-select"
import { ButtonSecondary } from "../UI/Button"

export const meetingTypes = [
  { value: "businessMeeting", label: "Business Meeting" },
  { value: "walkthrough", label: "Walkthrough" },
  { value: "buyingAppointment", label: "Buying Appointment" },
]

export class MeetingType extends Component {
  constructor(props) {
    super(props)
    const { bookingsData, session } = this.props

    let meetingApproach = "face-to-face"
    if (session.videoMeetings && session.disableFaceToFaceMeetings) {
      meetingApproach = "video-conference"
    } else if (!session.videoMeetings && !session.disableFaceToFaceMeetings) {
      meetingApproach = "face-to-face"
    } else if (bookingsData.meetingApproach) {
      meetingApproach = bookingsData.meetingApproach
    }

    this.state = {
      meetingType: bookingsData.meetingType
        ? meetingTypes.find(type => type.value === bookingsData.meetingType)
        : null,
      meetingApproach,
    }
  }

  onNextClick = () => {
    const { onNextClick } = this.props
    const { meetingType, meetingApproach } = this.state
    onNextClick({ meetingType: meetingType.value, meetingApproach })
  }

  onSelect = option => {
    this.setState({ meetingType: option })
  }

  onMeetingApproachChange = e => {
    this.setState({ meetingApproach: e.currentTarget.value })
  }

  render() {
    const { onPrevClick, session } = this.props
    const { meetingType, meetingApproach } = this.state
    const meetingOptions = [meetingTypes[2]]
    if (session.businessMeeting.available) {
      meetingOptions.push(meetingTypes[0])
    }
    if (session.walkthrough.available) {
      meetingOptions.push(meetingTypes[1])
    }

    return (
      <StepContainer>
        <Title>2. Meeting Type</Title>
        <div>Please tick the type of meeting you want to book</div>
        <SelectContainer
          margin="30px 0px 40px 0px"
          style={{ flexDirection: "column" }}
        >
          <Select
            classNamePrefix="meetingSelect"
            options={meetingOptions}
            value={meetingType}
            onChange={this.onSelect}
          />
          {session.videoMeetings && !session.disableFaceToFaceMeetings && (
            <>
              <Text margin="20px 0 0 0" style={{ width: "300px" }}>
                <input
                  type="radio"
                  id="approach-face-to-face"
                  value="face-to-face"
                  checked={meetingApproach === "face-to-face"}
                  onChange={this.onMeetingApproachChange}
                />{" "}
                <label htmlFor="approach-face-to-face">Face-to-Face</label>
              </Text>
              <Text margin="20px 0 0 0" style={{ width: "300px" }}>
                <input
                  type="radio"
                  id="approach-video-conference"
                  value="video-conference"
                  checked={meetingApproach === "video-conference"}
                  onChange={this.onMeetingApproachChange}
                />{" "}
                <label htmlFor="approach-video-conference">
                  Virtual Meeting
                </label>
              </Text>
            </>
          )}
        </SelectContainer>
        <ButtonsRow>
          <ButtonSecondary
            margin="0 20px 0 0"
            onClick={onPrevClick}
          >
            Previous
          </ButtonSecondary>
          <Button onClick={this.onNextClick}>Next</Button>
        </ButtonsRow>
      </StepContainer>
    )
  }
}

MeetingType.propTypes = {
  bookingsData: PropTypes.object,
  session: PropTypes.object,
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
}
